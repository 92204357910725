import { BUSINESS_PROFILE_TYPE } from "@samedaycustom/types/app/index";
export const START_BUSINESS_PROFILE_ACTION = "START_BUSINESS_PROFILE_ACTION";
export const FAIL_BUSINESS_PROFILE_ACTION = "FAIL_BUSINESS_PROFILE_ACTION";
export const GET_BUSINESS_PROFILE_ACTION = "GET_BUSINESS_PROFILE_ACTION";
export const EDIT_BUSINESS_PROFILE_ACTION = "EDIT_BUSINESS_PROFILE_ACTION";
export const BUSINESS_PROFILE_STATE_RESET = "BUSINESS_PROFILE_STATE_RESET";

export type ALL_PROFILE_ACTIONS_TYPES =
  | typeof START_BUSINESS_PROFILE_ACTION
  | typeof FAIL_BUSINESS_PROFILE_ACTION
  | typeof GET_BUSINESS_PROFILE_ACTION
  | typeof EDIT_BUSINESS_PROFILE_ACTION
  | typeof BUSINESS_PROFILE_STATE_RESET;

interface ISTART_BUSINESS_PROFILE_ACTION {
  type: typeof START_BUSINESS_PROFILE_ACTION;
  payload: ALL_PROFILE_ACTIONS_TYPES;
}

interface IFAIL_BUSINESS_PROFILE_ACTION {
  type: typeof FAIL_BUSINESS_PROFILE_ACTION;
  payload: {
    type: ALL_PROFILE_ACTIONS_TYPES;
    msg: string;
  };
}

interface IGET_BUSINESS_PROFILE_ACTION {
  type: typeof GET_BUSINESS_PROFILE_ACTION;
  payload: BUSINESS_PROFILE_TYPE;
}

interface IEDIT_BUSINESS_PROFILE_ACTION {
  type: typeof EDIT_BUSINESS_PROFILE_ACTION;
  payload: BUSINESS_PROFILE_TYPE;
}

interface IBUSINESS_PROFILE_STATE_RESET {
  type: typeof BUSINESS_PROFILE_STATE_RESET;
}

export type BUSINESS_PROFILE_ACTION_TYPE =
  | ISTART_BUSINESS_PROFILE_ACTION
  | IFAIL_BUSINESS_PROFILE_ACTION
  | IGET_BUSINESS_PROFILE_ACTION
  | IEDIT_BUSINESS_PROFILE_ACTION
  | IBUSINESS_PROFILE_STATE_RESET;
