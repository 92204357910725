import { createSlice, PayloadAction } from "react-context-toolkit";
import { ISDCMessage } from "types/app/Messaging";

import { RootState } from "../../store";
import { MessageContextProp } from "./@types";


/**
 * ModifiedFile is a File that has been modified to include an id
 */
type ModifiedFile = File & {
  id: string;
};

const initialState: MessageContextProp = {
  info: {
    firstName: "",
    lastName: "",
    decoOrderId: "",
    orderId: ""
  },
  input: {
    message: {
      content: "",
      files: [],
      lineID: "",
      viewID: "",
      type: "GENERAL",
      options: {
        available: false,
        selected: null
      },
      timestamp: Date.now(),
      origin: "store"
    }
  }
};
// uses immer reducers to allow mutations on an intermediate draft state
export const messageSplice = createSlice({
  // This name becomes the key in the global state
  name: "message",
  initialState,
  reducers: {
    onAddInputFile(state, action: PayloadAction<File[]>) {
      if (Array.isArray(action.payload))
        state.input.message.files = [
          ...state.input.message.files,
          ...action.payload
        ];
    },
    /**
     * update input file that already exists on our input state
     * @param state
     * @param action
     */
    onUpdateInputFile(state, action: PayloadAction<(ModifiedFile | {
      payload: ModifiedFile;
    })[]>) {
      const updateFiles = action.payload;
      const stateFiles = state.input.message.files;
      if (Array.isArray(updateFiles) && updateFiles.length) {
        for (let i = 0; i < updateFiles.length; i++) {
          const file = 'payload' in updateFiles[i] ? (updateFiles[i] as { payload: ModifiedFile }).payload : updateFiles[i];
          const stateFileIndex = stateFiles.findIndex(o => o.id === (file as ModifiedFile).id);
          state.input.message.files[stateFileIndex] = file;
        }
      }
    },
    onRemoveFile(state, action: PayloadAction<string>) {
      if (
        Array.isArray(state.input.message?.files) &&
        state.input.message.files?.length
      ) {
        const id = action.payload;
        const oldFiles = state.input.message.files;
        const newFiles = oldFiles.filter(item => item.id !== id);
        state.input.message.files = newFiles;
      }
    },
    onAskApproval(state, action: PayloadAction<boolean>) {
      state.input.message.options.available = action.payload;
      state.input.message.options.selected = null;
    },
    onChange(state, action: PayloadAction<string>) {
      state.input.message.content = action.payload;
    },
    onLineItemSelected(state, action: PayloadAction<string>) {
      state.input.message.lineID = action.payload;
    },
    onMessageTypeSelected(state, action: PayloadAction<string>) {
      state.input.message.type = action.payload;
    },
    onResetInput(state) {
      state.input = initialState.input;
    },
    onSetInitialInput(state, action: PayloadAction<ISDCMessage>) {
      const message = action.payload;
      state.input.message = {
        options: {
          available: message?.options?.available || false,
          selected: message?.options?.selected
        },
        files: message?.files || [],
        lineID: message?.lineID,
        origin: message?.origin,
        type: message?.type,
        viewID: message?.viewID
      };
    }
  }
});
export const {
  onChange,
  onSetInitialInput,
  onAskApproval,
  onLineItemSelected,
  onMessageTypeSelected,
  onResetInput,
  onRemoveFile,
  onAddInputFile,
  onUpdateInputFile
} = messageSplice.actions;
export const messageSelector = (state: RootState) => state[messageSplice.name];
