import { Text } from "../_components";
import React from "react";

export { default as RowTag } from "./RowTag";
export { default as TeamTag } from "./TeamTag";
export { default as AvailabilityTag } from "./Availability";

export * from "./RowTag";
export * from "./TeamTag";
export * from "./Availability";
export const Tag = ({
  text,
  backgroundColor,
  color,
  children,
  ...props
}: {
  text?: string;
  backgroundColor?: string;
    color?: string;
    children?: React.ReactNode;
}) => {
  return (
    <Text
      width="20px"
      borderRadius="16px"
      color={color}
      backgroundColor={backgroundColor}
      height="15px"
      textAlign="center"
      fontFamily="Inter Bold"
      fontSize="11px"
      
      {...props}
    >
      {text ? text : children}
    </Text>
  );
};
