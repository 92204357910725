import { ToggleStateless } from "@atlaskit/toggle";
import { Box } from "../_components";
import React from "react";
import Style from "style-it";

// import "./style.scss"

export interface IToggle {
  isDisabled?: boolean;
  isChecked?: boolean;
  size?: "large" | "regular";
  label?: string;
  ref?: any;
  value?: string;
  key?: string;
  name?: string;
  onBlur?: () => void;
  onChange?: () => void;
  onFocus?: () => void;
  showIcon?: boolean;
  backgroundColor?: string;
  nobColor?: string;
  inactiveColor?: string;
}
export const Toggle = (props: IToggle) => {
  return Style.it(
    `
   .custom-toggle div {
        box-sizing: content-box;
   }
    .custom-toggle + .showIcon  span:last-child{
        display: block;
    }
    .custom-toggle span:last-child {
        display: none;
    }

     div:first-child {
        background-color: ${
          props.isChecked ? props.backgroundColor : props.inactiveColor
        };
        border-radius: 20px;
    }
    `,
    <Box className={`custom-toggle ${props.showIcon && "showIcon"}`}>
      <ToggleStateless {...props} />
    </Box>
  );
};
