import {
  BUSINESS_PROFILE_ACTION_TYPE,
  BUSINESS_PROFILE_STATE_RESET,
  EDIT_BUSINESS_PROFILE_ACTION,
  FAIL_BUSINESS_PROFILE_ACTION,
  GET_BUSINESS_PROFILE_ACTION,
  START_BUSINESS_PROFILE_ACTION,
} from "admin/providers/actions/businessProfile/actionTypes";
import { businessProfileStateType } from "types/app";

export const businessProfileState: businessProfileStateType = {
  loading: false,
  error: false,
  type: "",
  profileData: {
    id: "",
    name: "",
    status: "",
    storeCount: 0,
    usersCount: 0,
    listedStoresCount: 0,
    email: "",
    mobile: "",
    vendorType: "SINGLE",
    businessNumber: "",
    billingEmail: "",
    websiteURL: "",
    address1: "",
    city: "",
    state: "",
    stateCode: "",
    zipcode: "",
    contactInfo: {
      name: "",
      mobile: "",
      email: "",
    },
    profileImageURL: "",
  },
  done: false,
  errorMessage: "",
  fetch: true,
};
export default (state = businessProfileState, action: BUSINESS_PROFILE_ACTION_TYPE) => {
  switch (action.type) {
    case START_BUSINESS_PROFILE_ACTION:
      return {
        ...state,
        loading: true,
        done: false,
        error: false,
        errorMessage: "",
        type: action.payload,
        fetch: false,
      };
    case FAIL_BUSINESS_PROFILE_ACTION:
      return {
        ...state,
        loading: false,
        done: false,
        error: true,
        errorMessage: action.payload.msg,
        type: action.payload.type,
      };
    case GET_BUSINESS_PROFILE_ACTION:
      return {
        ...state,
        loading: false,
        done: true,
        error: false,
        errorMessage: "",
        type: GET_BUSINESS_PROFILE_ACTION,
        profileData: action.payload,
      };
    case EDIT_BUSINESS_PROFILE_ACTION:
      return {
        ...state,
        loading: false,
        done: true,
        error: false,
        errorMessage: "",
        type: EDIT_BUSINESS_PROFILE_ACTION,
        profileData: action.payload,
      };
    case BUSINESS_PROFILE_STATE_RESET:
      return {
        ...state,
        loading: false,
        done: false,
        error: false,
        errorMessage: "",
        type: "",
      };
    default:
      return state;
  }
};
