import { ICart } from "@samedaycustom/sdc-types";

export type CARTS_ACTION_PAYLOAD = {
  carts: ICart[];
  cart: ICart;
  cartsCount?: number;
  loading?: boolean;
  done?: boolean;
};
export const FETCH_CARTS = "FETCH_CARTS";
export const FETCH_CART = "FETCH_CART";
export const FETCHING_CART = "FETCHING_CART";

export type CARTS_ACTION_TYPE = typeof FETCH_CARTS | typeof FETCH_CART | typeof FETCHING_CART;

export type CARTS_ACTION_TYPES = {
  type: CARTS_ACTION_TYPE;
  payload?: Partial<CARTS_ACTION_PAYLOAD>;
};
