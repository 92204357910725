import { createStore } from "react-context-toolkit";
import { messageSplice } from "./src/message/context";

const splices = [messageSplice];

export const {
  useSelector,
  useDispatch,
  StateProvider,
  initialState: RootInitialState
} = createStore(splices);

// In case you need direct access to the type of the combined state
export type RootState = typeof RootInitialState;
export const SameDayCustomProvider = StateProvider;
