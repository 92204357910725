import React from "react";

type ChunkComponent = { default: () => JSX.Element };
function componentLoader(
	lazyComponent: () => Promise<any>,
	maxNoTries: number
): Promise<{ default: React.ComponentType<any> }> {
	return new Promise((resolve, reject) => {
		lazyComponent()
			.then(resolve)
			.catch((err) => {
				setTimeout(() => {
					if (maxNoTries === 0) {
						// if (
						// 	window.performance &&
						// 	performance.navigation.type !=
						// 		performance.navigation.TYPE_RELOAD
						// ) {
						// 	window.location.reload(true);
						// }

						reject(err);
						return;
					}
					componentLoader(lazyComponent, maxNoTries - 1).then(
						resolve,
						reject
					);
				}, 1000);
			});
	});
}

export default componentLoader;
