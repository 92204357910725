import "./style.scss";

import { Box, Text } from "../../_components";
import { ReactComponent as ArrowDown } from "@samedaycustom/assets/images/arrow-downVector.svg";
import { ReactComponent as NextMonth } from "@samedaycustom/assets/images/Next.svg";
import { ReactComponent as PrevMonth } from "@samedaycustom/assets/images/Prev.svg";
import { DefaultButton, PrimaryButton } from "@samedaycustom/core-ui";
import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import ReactCalendar from "react-calendar";
import * as QueryString from "query-string";
import { DateGroupProvider, useDateGroupContext } from "./context";
import { format } from "date-fns-tz";
import { useLocation } from "react-router-dom";

export interface IDateGroupDropdown {
  onChange: (value: Date | number) => void;
  defaultValue?: Date | number;
  defaultLabel?: string;
  onCustomDate: () => void;
  onReset?: () => void;
  className?: string;
  children: any;
  labelStyle?: React.CSSProperties;
  Indicator?: JSX.Element | React.ReactElement;
  groupLabelStyle?: React.CSSProperties;
  selectedDates?: string[]
  onApply?: (date?: { start: number, end: number }) => void;
  getStartAndEndDate: (daysAgo: number) => [Date, Date];
}

export default React.memo((prop: IDateGroupDropdown) => {
  return (
    <DateGroupProvider>
      <DateGroupContainer {...prop} />
    </DateGroupProvider>
  );
});

const DateGroupContainer = (prop: IDateGroupDropdown) => {
  const location = useLocation()
  const queryParams = QueryString.parse(location.search);
  const isRendered = React.useRef(false);
  const {
    selectedlabel,
    placeholder,
    dateValue,
    customDate,
    setSelectedLabel,
    setDateValue
  } = useDateGroupContext();
  const [date, setDate] = useState<[Date, Date]>([new Date(), new Date()]);

  useEffect(() => {
    if (!isRendered.current) isRendered.current = true;
  }, []);

  useEffect(() => {
    setDateValue(prop.defaultValue);
    prop?.defaultLabel && setSelectedLabel(prop.defaultLabel);
  }, []);

  useEffect(() => {
    const value = prop.getStartAndEndDate(prop.defaultValue as number);
    setDate(value);
  }, [prop.defaultValue])

  useEffect(() => {
    if (isRendered) {
      if (typeof prop?.onChange === "function") {
        if (dateValue === 8) {
          prop.onCustomDate()
        } else {
          prop.onChange(dateValue);
        }
      }
    }
  }, [dateValue, customDate]);

  // display date range
  function getDateGrouping(daysAgo) {
    let date: Date = new Date();
    let fromDate: Date = new Date();

    if (daysAgo === 8) {
      if (queryParams.ed && queryParams.sd) {
        date = new Date(Number(queryParams.ed));
        fromDate = new Date(Number(queryParams.sd));
      } else {
        return null;
      }
    } else {
      date = new Date()
      fromDate = new Date(date.getTime() - daysAgo * 24 * 60 * 60 * 1000);
    }

    const from = format(fromDate, 'dd-MM-yyyy');
    const to = format(date, 'dd-MM-yyyy');

    return daysAgo !== null ? (
      <span className="timeGroupingSpan">
        {`${daysAgo >= 1 ? `${from} - ${to}` : `${from}`}`}
      </span>
    ) : null;
  }

 
  /**
   *
   * display date range selected
   */
  const DisplayContainer = ({ groupLabelStyle }) => {
    return (
      <Text className="displayText" {...groupLabelStyle}>
        {getDateGrouping(dateValue)}
      </Text>
    );
  };

  return (
    <Box d="flex" className={`dateRangeDropdown ${prop.className}`}>
      <Popup
        trigger={() => {
          return (
            <Box
              className="selectedDateGroup"
              style={{
                cursor: "pointer"
              }}
              {...prop?.labelStyle}
            >
              <span> {selectedlabel || placeholder} </span>
              <DisplayContainer groupLabelStyle={prop.groupLabelStyle} />
              {prop.Indicator || <ArrowDown />}
            </Box>
          );
        }}
        contentStyle={{
          width: "unset",
          border: "none",
          padding: "0",
          height: "unset",
          borderRadius: "4px",
          boxShadow: "0px 6px 18px rgba(98, 125, 152, 0.24)"
        }}
        arrow={false}
        position="bottom left"
        on="click"
      >
        {closeFunc => (
          <div className="dateGroupingSelectionBox">
            <Box d='grid' gridTemplateColumns="1fr 2fr" padding="12px 0" paddingRight="12px">
              <Box>{prop.children}</Box>
              <Box>
                <ReactCalendar
                  nextLabel={<NextMonth />}
                  onClickDay={() => {
                    setSelectedLabel("Custom Time Range")
                    setDateValue(8)
                    prop.onChange(8)
                  }}
                  prevLabel={<PrevMonth />}
                  value={date}
                  onChange={setDate}
                  selectRange={true}
                />
                <Box d="flex" justifyContent="flex-end">
                  <DefaultButton backgroundColor="white" border="1px solid #BCCCDC" borderRadius="4px" onClick={closeFunc} marginRight="12px">Cancel</DefaultButton>
                  <PrimaryButton onClick={() => {
                    const [start, end] = date;
                    const param = {};
                    if (start && end) {
                      Object.assign(param, { start: start.getTime(), end: end.getTime() });
                    }
                    prop.onApply(param as any);
                    closeFunc();
                  }}>Apply</PrimaryButton>
                </Box>
              </Box>
            </Box>
          </div>
        )}
      </Popup>
    </Box>
  );
};
