import React, { useState } from "react";
import "./style.scss";
import { ReactComponent as Previous } from "../assets/previous.svg";
import { ReactComponent as Next } from "../assets/next.svg";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "@popmotion/popcorn";

interface ImageSliderProps {
  imageUrlArr: Array<string>;
  className?: string;
  nextIcon?: JSX.Element;
  previousIcon?: JSX.Element;
}

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

const ImageSlider = ({
  imageUrlArr,
  className,
  previousIcon,
  nextIcon
}: ImageSliderProps) => {
  const variants = {
    enter: (direction: number) => {
      return {
        x: direction > 0 ? 100 : -100,
        opacity: 0
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: (direction: number) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 100 : -100,
        opacity: 0
      };
    }
  };

  const [[page, direction], setPage] = useState([0, 0]);
  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };

  const imageIndex = wrap(0, imageUrlArr.length, page);

  const _onDrag = (e, { offset, velocity }) => {
    const swipe = swipePower(offset.x, velocity.x);

    if (swipe < -swipeConfidenceThreshold) {
      paginate(1);
    } else if (swipe > swipeConfidenceThreshold) {
      paginate(-1);
    }
  };

  return (
    <div className={`ImageSliderContainer ${className}`}>
      <AnimatePresence initial={false} custom={direction}>
        <motion.img
          key={page}
          src={imageUrlArr[imageIndex]}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: {
              type: "spring",
              stiffness: 600,
              damping: 100,
              clamp: true,
              mass: 10,
              tension: 550,
              friction: 50
            },
            opacity: { duration: 0.2 }
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={_onDrag}
        />
      </AnimatePresence>
      {imageUrlArr?.length > 1 && (
        <React.Fragment>
          <div
            className="ImageSliderContainer__button ImageSliderContainer__button--prev"
            onClick={() => paginate(1)}
          >
            {previousIcon || <Previous />}
          </div>
          <div
            className="ImageSliderContainer__button ImageSliderContainer__button--next"
            onClick={() => paginate(-1)}
          >
            {nextIcon || <Next />}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default React.memo(ImageSlider);
