import {
  ChakraInput as CInput,
  InputAddonProps,
  InputGroup,
  InputLeftAddon,
  InputProps,
  InputRightAddon,
  BoxProps
} from "../../_components";
import React from "react";

export const RightAddon = ({ children, ...props }: InputAddonProps) => (
  <InputRightAddon
    background="#D9E2EC"
    borderRadius="0px 4px 4px 0px"
    padding="0px 10px 0px 10px"
    marginLeft="0px"
    display="flex"
    alignItems="center"
    justifyContent="center"
    children={children}
    minW="64px"
    height="auto"
    {...props}
  />
);
export const LeftAddon = ({ children, ...props }: InputAddonProps) => (
  <InputLeftAddon
    width="64px"
    background="#D9E2EC"
    borderRadius="4px 0px 0px 4px"
    display="flex"
    alignItems="center"
    justifyContent="center"
    children={children}
    height="auto"
    {...props}
  />
);

export interface IEntryInput extends InputProps {
  children?: any;
}
export const EntryInput = ({ children, ...props }: IEntryInput) => (
  <CInput
    width="100%"
    border="1px solid #BCCCDC"
    height="40px"
    padding="10px 16px"
    boxSizing="border-box"
    _placeholder={{ color: "#627D98" }}
    {...props}
  >
    {children}
  </CInput>
);

export interface IAddOnInput extends InputProps {
  side: "right" | "left";
  addonStyle?: React.CSSProperties;
  children?: any;
  containerProp?: BoxProps;
}
const AddOnInput = ({ side, children, ...props }: IAddOnInput) => {
  return (
    <InputGroup size="md" {...props?.containerProp}>
      {side === "left" && (
        <LeftAddon {...props.addonStyle}>{children}</LeftAddon>
      )}
      <EntryInput
        borderRadius={side === "right" ? "4px 0px 0px 4px" : "0px 4px 4px 0px"}
        borderRight={side === "right" && "none"}
        borderLeft={side === "left" && "none"}
        autoFocus
        onFocus={e => e.target.select()}
        {...props}
      />
      {side === "right" && (
        <RightAddon {...props.addonStyle}>{children}</RightAddon>
      )}
    </InputGroup>
  );
};

export default AddOnInput;
