import "./style.scss";

import { ReactComponent as NoImage } from "@samedaycustom/assets/images/imgNone.svg";
import { MessageFileCard } from "@samedaycustom/core-ui";
import {
  ISDCMessage,
  MESSAGE_TYPE,
  MessageUser
} from "@samedaycustom/types/app/Messaging";
import moment from "moment";
import React, { useEffect, useState } from "react";

import TypeTag, { TagType } from "./TypeTag";
import { Box, Text } from "../_components";
import Linkify from "react-linkify";

export { default as NoteBubble } from "./NoteBubble";
export { default as BroadCastBubble } from "./BroadCast";
export * from "./BroadCast";

export interface ChatBubbleProp {
  user: MessageUser;
  message: ISDCMessage;
  oldMessage?: ISDCMessage;
}

function determinBorderColor(type: MESSAGE_TYPE): string {
  let color = "";
  switch (type) {
    case "GENERAL":
      color = "#007bff";
      break;
    case "LINE":
      color = "#007bff";
      break;
    case "ARTWORK":
      color = "#7A0ECC";
      break;
    case "DELIVERY":
      color = "#7A0ECC";
      break;
    case "PAYMENT":
      color = "#DE911D";
      break;
    default:
      color = "#007bff";
      break;
  }
  return color;
}

export const ChatBubble = React.memo(
  ({ message, oldMessage, user }: ChatBubbleProp) => {
    const [scrollTo, setScroll] = useState(false);

    useEffect(() => {
      if (scrollTo) {
        let element = document.querySelector(
          `#chat${message.originalMessageID}`
        );
        element?.scrollIntoView();
        element?.classList.add("flash");
        const timer = setTimeout(() => {
          element?.classList.remove("flash");
          setScroll(false);
        }, 1500);
        return () => {
          clearTimeout(timer);
        };
      }
      return () => {};
    }, [scrollTo, message.originalMessageID]);

    const IncomingType = React.useMemo(() => {
      return (
        <Box className="Message__Container--Incoming" id={"chat" + message.id}>
          <Box className="Message__bubble">
            {message?.originalMessageID ? (
              <Box
                className="oldMessage"
                onClick={() => setScroll(true)}
                style={{
                  borderLeftColor: determinBorderColor(
                    oldMessage?.type as TagType
                  )
                }}
              >
                {oldMessage?.content ? (
                  <Linkify>
                    <p> {oldMessage?.content} </p>
                  </Linkify>
                ) : (
                  <span className="oldImages">
                    {" "}
                    <NoImage />{" "}
                    <span>
                      {" "}
                      files{" "}
                      {oldMessage?.files?.length > 1
                        ? `x${oldMessage?.files?.length}`
                        : ""}{" "}
                    </span>{" "}
                  </span>
                )}
              </Box>
            ) : null}
            <p>
              {message?.originalMessageID
                ? oldMessage?.options?.selected === true ||
                  oldMessage?.options?.selected === "true"
                  ? "Yes I approve"
                  : "No I do not approve"
                : message?.content}
            </p>
            {message.files && message.files?.length > 0
              ? message.files.map((file, i) => (
                  <MessageFileCard key={i} {...file} />
                ))
              : null}
          </Box>
          <span className="timeStamp">
            {moment(message.timestamp).format("MMMM Do, h:mm a")}{" "}
          </span>
        </Box>
      );
    }, [message, oldMessage]);

    const OutGoingType = React.useMemo(() => {
      return (
        <Box className="Message__Container--Outgoing" id={"chat" + message.id}>
          <Box className="Message__bubble">
            {(message.type || message.origin === "admin") && (
              <Box className="Message__tagContainer">
                {message.type && <TypeTag type={message.type as TagType} />}
                {message.type === "LINE" ||
                (message.type === "ARTWORK" && message.lineID) ? (
                  <span className="lineText">#{message.lineID} </span>
                ) : null}
                {message.origin === "admin" && (
                  <Text color="#007BFF" fontWeight={500} fontSize="13px">
                    SameDayCustom
                  </Text>
                )}
              </Box>
            )}
            <p
              style={{
                fontWeight: "bold",
                color: "#102A43",
                lineHeight: "2rem"
              }}
            >
              {message?.options?.available ? "Do you approve?" : ""}
            </p>
            <Linkify>
              <p>{message.content}</p>
            </Linkify>
            {message.files && message.files?.length > 0
              ? message.files.map((file, i) => (
                  <MessageFileCard key={i} {...file} />
                ))
              : null}
          </Box>
          <span className="timeStamp">
            {message?.tempId && message?.id === message?.tempId ? (
              <span>sending...</span>
            ) : (
              moment(message?.timestamp).format("MMMM Do, h:mm a")
            )}{" "}
          </span>
        </Box>
      );
    }, [message]);

    return (
      <React.Fragment>
        {user.type.includes(message.origin) ? OutGoingType : IncomingType}
      </React.Fragment>
    );
  }
);
