import React from "react";
import { useDateGroupContext } from "../context";
import { Box } from "@chakra-ui/react";

export interface IDateGroupItem {
	value: number;
	label: string;
	datePeriod: "day" | "minute" | "second" | "hour";
	isCustom?: boolean;
	onClick?: (value: number) => void;
	selected?: boolean;
}
const DateGroupItem = (prop: IDateGroupItem) => {
	const {
		setSelectedLabel,
		setShowCustomDate,
		setDateValue
	} = useDateGroupContext();

	return (
		<Box
			className="dateGroupingItem"
			paddingLeft="24px"
			borderLeft={prop.selected ? "3px solid #007BFF" : "none"}
			backgroundColor={prop.selected ? "#F0F7FF" : "inherit"}
			onClick={() => {
				if (prop?.isCustom) setShowCustomDate(true);
				else setShowCustomDate(false);

				setSelectedLabel(prop.label);
				setDateValue(prop.value);

				prop?.onClick && prop.onClick(prop.value);
				// setTimeRange("15days");  yet to implement
			}}
		>
			<span>{prop.label}</span>
		</Box>
	);
};

export default DateGroupItem;
