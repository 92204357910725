import { AuthedButton } from "@samedaycustom/core-ui";
import { ISDCOrder } from "@samedaycustom/types/order/@types/order";
import React from "react";
import "./style.scss";

interface Iprops {
  actionFunc: () => void;
  formattedData: ISDCOrder;
  closeFunc: () => void;
}

const OrderNotification: React.FC<Iprops> = ({
  actionFunc,
  formattedData,
  closeFunc
}) => {
  const currency_formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    currencySign: "accounting",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  } as any);
  return (
    <div className="orderNotifContent">
      <div className="topNotifInfo">
        <div className="notificationDetail">
          <h2>Order</h2>
          <p> {formattedData?.decoOrderId} </p>
        </div>
        <div className="notificationDetail">
          <h2>Total Qty</h2>
          <p>
            {(formattedData?.totalQty || 0)}
            {/* {currency_formatter.format(formattedData?.billablePrice || 0)} */}
            {/* {formattedData?.billablePrice && (
              <React.Fragment>
                ${Math.floor(formattedData.billablePrice)}.
                <span>
                  {(formattedData.billablePrice % 1).toFixed(2).split(".")[1]}
                </span>
              </React.Fragment>
            )} */}
          </p>
        </div>
      </div>
      <div className="notifBtnGroup">
        <AuthedButton onClick={actionFunc} className="viewOrder">
          <span>View order</span>
        </AuthedButton>
        <AuthedButton onClick={closeFunc} className="viewLater">
          <span>View Later</span>
        </AuthedButton>
      </div>
    </div>
  );
};

export default OrderNotification;
