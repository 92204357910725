import { Box, BoxProps } from "../_components";
import React from "react";

export * from "./LogCard";
export * from "./SavedLayoutCard/v2";
export { SavedLayoutsCardV2 } from "./SavedLayoutCard/v2";
export { default as ArtworkCard } from "./ArtworkCard";
export { default as ShowThumbnail } from "./ArtworkCard/ShowThumbnail";
export { default as ArtworkChatCard } from "./ArtworkChatCard";
export { default as MessageView } from "./ArtworkChatCard/MessageView";
export { default as CardWithHeader } from "./CardWithHeader";
export { default as FoldableView } from "./FoldableView";
export { default as LocationListCard } from "./LocationListCard";
export { default as MobileLineItemsCard } from "./Mobile-LineItemsCard";
export { default as LineItemLoader } from "./Mobile-LineItemsCard/lineItem-loader";
export { default as OperationsCard } from "./OperationsCard";
export { default as DesignCard } from "./OperationsCard/DesignCard";
export { default as FancyNoteCard } from "./OperationsCard/FancyNoteCard";
export { default as QuoteCard } from "./OperationsCard/QuoteCard";
export { default as TimelineCard } from "./OperationsCard/TimelineCard/Timeline";
export { default as Timeline } from "./OperationsCard/TimelineCard/Timeline";
export { default as StoreSettingCard } from "./StoreSettingCard";
export { default as MessageFileCard } from "./MessageFile";
export { default as ArtworkVersionCard } from "./ArtworkVersionCard";
export { default as SavedLayoutCard } from "./SavedLayoutCard";
export { default as LogCard } from "./LogCard";
export { default as ArtworkCardV2 } from "./ArtworkCard/v2";
export { default as OrderCard } from "./OrderCard";

// export { default as UserListCard } from "./UserListCard";
export const Card = ({ children, ...props }: BoxProps) => {
  return (
    <Box
      background="#fff"
      boxShadow="0px 4px 6px rgba(159, 179, 200, 0.1), 0px 2px 4px rgba(159, 179, 200, 0.06);"
      borderRadius="16px"
      padding="16px"
      {...props}
    >
      {children}
    </Box>
  );
};
