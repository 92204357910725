import React from "react";
import { Box } from "@chakra-ui/react";
import { IDynamicRow } from "../DynamicRow";

export type IDynamicBodyContext = {
  isOpen: boolean;
  setisOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DynamicBodyContext = React.createContext<IDynamicBodyContext>({
  isOpen: false,
  setisOpen: () => null
});

export interface IDynamicBody {
  children?:
    | React.ReactElement<IDynamicRow>[]
    | React.ReactElement<IDynamicRow>;
}

export default React.memo(({ children }: IDynamicBody) => {
  const [isOpen, setisOpen] = React.useState(false);
  return (
    <DynamicBodyContext.Provider
      value={{
        isOpen,
        setisOpen
      }}
    >
      {children}
    </DynamicBodyContext.Provider>
  );
});
