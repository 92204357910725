
import {ModalContextType} from '../../index.d'
export const initialModalState: ModalContextType = {
    open: false,
	showForm: {
		isOpen: false,
		title: null
    },
	onClose: null,
	duration: 3000,
	modalType: "text",
	bodyStyle: null,
};
