import React, { useState, useEffect, useRef } from "react";
import "./style.scss";
import { ReactComponent as CloseModal } from "../assets/closeModal.svg";
import { ReactComponent as HorizontalMenu } from "../assets/horizontalMenu.svg";
import Popup from "@atlaskit/popup";
import Button from "@atlaskit/button";
import { Box, Text } from "../../_components";
import usePortal from "react-useportal";
import { ArrowBackIcon } from "@chakra-ui/icons";

type menuOption = {
  text: string;
  color: string;
  action(): void;
};

type ViewBarProps = {
  show: boolean;
  closeFunc(): void;
  height?: string;
  width?: string;
  heading: string | React.ReactElement;
  menuOptions?: Array<menuOption>;
  children: React.ReactNode;
  footerContent?: string | React.ReactElement;
  style?: React.CSSProperties;
  headerStyle?: React.CSSProperties;
  footerProp?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  className?: string;
  animateInClass?: string;
  containerClassName?: string;
  animateOutClass?: string;
  noPortal?: boolean;
  onFullyShown?: () => void;
  backBotton?: boolean;
  goBack?: () => void;
};

const ViewBar: React.FC<ViewBarProps> = ({
  show,
  heading,
  closeFunc,
  menuOptions,
  children,
  height,
  width,
  style,
  className,
  footerContent,
  footerProp,
  animateInClass,
  containerClassName,
  animateOutClass,
  noPortal,
  headerStyle,
  onFullyShown,
  containerStyle,
  backBotton,
  goBack
}) => {
  const { Portal } = usePortal();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  // logic for the portal unmounting animation
  const [shouldRender, setRender] = useState(show);
  useEffect(() => {
    if (show) {
      setRender(true);
      document.querySelector("body").classList.add("noscroll");
    } else {
      document.querySelector("body").classList.remove("noscroll");
    }
  }, [show]);

  const animationEnd = React.useCallback(() => {
    if (typeof onFullyShown === "function" && show) {
      onFullyShown();
    }
    if (!show) setRender(false);
  }, [show]);

  const mobileRef = useRef(null);
  const contentRef = useRef(null);

  const scrollfunc = () => {
    if (contentRef?.current.scrollTop === 0) {
      mobileRef.current.classList.remove("mobile__header_bordered");
    } else {
      mobileRef.current.classList.add("mobile__header_bordered");
    }
  };

  return (
    <>
      {!noPortal
        ? shouldRender && (
            <Portal>
              <div
                className={`ViewBar__background ${className ? className : ""}`}
                onClick={() => {
                  closeFunc();
                }}
              ></div>
              <Box
                onAnimationEnd={animationEnd}
                className={`ViewBar__main ${
                  show
                    ? animateInClass
                      ? animateInClass + " ViewBar__main--show"
                      : " ViewBar__main--show"
                    : animateOutClass
                    ? animateOutClass + " ViewBar__main--noShow"
                    : "ViewBar__main--noShow"
                } ${className ? className : ""}`}
                style={{ width: width || "400px", height }}
                {...containerStyle}
              >
                <div className="mainContent">
                  <Box ref={mobileRef} className="ViewBar__Mobile-Header">
                    <Text
                      fontFamily="Inter"
                      style={{
                        color: "#102a43",
                        fontWeight: 500,
                        fontSize: "16px"
                      }}
                    >
                      {" "}
                      {heading}{" "}
                    </Text>
                    {backBotton ? <ArrowBackIcon cursor="pointer" fontSize="20px" onClick={goBack} /> : <CloseModal cursor="pointer" onClick={closeFunc} />}
                  </Box>
                  <Box
                    className="ViewBar__header"
                    zIndex={9999}
                    {...headerStyle}
                  >
                    {backBotton ? <ArrowBackIcon cursor="pointer" fontSize="20px" onClick={goBack} /> : <CloseModal cursor="pointer" onClick={closeFunc} />}
                    <Text
                      fontFamily="Inter"
                      style={{
                        color: "#102a43",
                        fontWeight: 500
                      }}
                    >
                      {" "}
                      {heading}{" "}
                    </Text>
                    {menuOptions ? (
                      <Popup
                        isOpen={menuOpen}
                        onClose={() => setMenuOpen(false)}
                        content={() => {
                          return (
                            <div className={"optionGroupHolder"}>
                              {menuOptions.map(option => (
                                <div
                                  className="menuOption"
                                  onClick={() => {
                                    setMenuOpen(false);
                                    option.action();
                                  }}
                                >
                                  <span
                                    style={{
                                      color: option.color
                                    }}
                                  >
                                    {" "}
                                    {option.text}{" "}
                                  </span>
                                </div>
                              ))}
                            </div>
                          );
                        }}
                        zIndex={30000}
                        placement="bottom"
                        offset={"-80px,8px"}
                        trigger={triggerProps => (
                          <Button
                            className="transparentBtn"
                            onClick={() => setMenuOpen(!menuOpen)}
                            {...triggerProps}
                            iconBefore={<HorizontalMenu />}
                          />
                        )}
                        // popupComponent={PopComponent}
                      />
                    ) : (
                      <div></div>
                    )}
                  </Box>
                  <Box
                    ref={contentRef}
                    onScroll={scrollfunc}
                    className="ViewBar__content"
                    style={style}
                    minHeight={footerContent ? "100vh" : null}
                  >
                    {children}
                  </Box>

                  {footerContent && (
                    <Box
                      className="ViewBar__footer"
                      zIndex={9999}
                      {...footerProp}
                    >
                      {footerContent}
                    </Box>
                  )}
                </div>
              </Box>
            </Portal>
          )
        : shouldRender && (
            <div>
              <div
                className={`ViewBar__background ${className ? className : ""}`}
                onClick={() => {
                  closeFunc();
                }}
              ></div>
              <Box
                onAnimationEnd={animationEnd}
                className={`ViewBar__main ${
                  show
                    ? animateInClass
                      ? animateInClass + " ViewBar__main--show"
                      : " ViewBar__main--show"
                    : animateOutClass
                    ? animateOutClass + " ViewBar__main--noShow"
                    : "ViewBar__main--noShow"
                } ${className ? className : ""}`}
                style={{ width: width || "400px", height }}
              >
                <div className="mainContent">
                  <Box ref={mobileRef} className="ViewBar__Mobile-Header">
                    <Text
                      fontFamily="Inter"
                      style={{
                        color: "#102a43",
                        fontWeight: 500,
                        fontSize: "16px"
                      }}
                    >
                      {" "}
                      {heading}{" "}
                    </Text>
                    <CloseModal cursor="pointer" onClick={closeFunc} />
                  </Box>
                  <Box className="ViewBar__header" zIndex={9999}>
                    <CloseModal cursor="pointer" onClick={closeFunc} />
                    <Text
                      fontFamily="Inter"
                      style={{
                        color: "#102a43",
                        fontWeight: 500
                      }}
                    >
                      {" "}
                      {heading}{" "}
                    </Text>
                    {menuOptions ? (
                      <Popup
                        isOpen={menuOpen}
                        onClose={() => setMenuOpen(false)}
                        content={() => {
                          return (
                            <div className={"optionGroupHolder"}>
                              {menuOptions.map(option => (
                                <div
                                  className="menuOption"
                                  onClick={() => {
                                    setMenuOpen(false);
                                    option.action();
                                  }}
                                >
                                  <span
                                    style={{
                                      color: option.color
                                    }}
                                  >
                                    {" "}
                                    {option.text}{" "}
                                  </span>
                                </div>
                              ))}
                            </div>
                          );
                        }}
                        zIndex={30000}
                        placement="bottom"
                        offset={"-80px,8px"}
                        trigger={triggerProps => (
                          <Button
                            className="transparent"
                            onClick={() => setMenuOpen(!menuOpen)}
                            {...triggerProps}
                            iconBefore={<HorizontalMenu />}
                          />
                        )}
                        // popupComponent={PopComponent}
                      />
                    ) : (
                      <div></div>
                    )}
                  </Box>
                  <Box
                    ref={contentRef}
                    onScroll={scrollfunc}
                    className={`ViewBar__content ${
                      containerClassName ? containerClassName : ""
                    }`}
                    style={style}
                    minHeight={footerContent ? "100vh" : null}
                  >
                    {children}
                  </Box>
                </div>
              </Box>
            </div>
          )}
    </>
  );
};

export default ViewBar;
