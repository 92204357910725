import {
  InputGroup,
  InputGroupProps,
  Input as ChakraInput,
  InputProps,
  InputRightElement,
  InputLeftAddon,
  InputAddonProps,
  InputRightAddon,
  InputLeftElement,
  forwardRef,
  ComponentWithAs,
} from "@chakra-ui/react";
import React from "react";

// TODO: Fix duplicate declaration with packages/core-ui/src/input/index.tsx
const Input: ComponentWithAs<"input", InputProps> = forwardRef(({ ...props }, ref) => {
  return <ChakraInput ref={ref} {...props} />;
});

export {
  Input as ChakraInput,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  InputLeftAddon,
  InputRightAddon,
};
export type { InputProps, InputAddonProps };
