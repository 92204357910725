import { MainModal as Modal } from "@samedaycustom/core-ui";
import moment from "moment-business-days";
import React from "react";

import Navigator from "./routes";

const App: React.FC = () => {
  moment.updateLocale("en", {
    workingWeekdays: [1, 2, 3, 4, 5, 6],
  });

  /**
   *
   * intialization of states and variables
   */
  moment.updateLocale("en", {
    relativeTime: {
      future: "%s",
      past: "%s",
      s: "a few seconds",
      ss: "%d seconds",
      m: "a minute",
      mm: "%d minutes",
      h: "an hour",
      hh: "%d hrs",
      d: "%d day",
      dd: "%d days",
      M: "a month",
      MM: "%d months",
      y: "a year",
      yy: "%d years",
    },
  });
  return (
    <React.Fragment>
      <Modal>
        <Navigator />
      </Modal>
    </React.Fragment>
  );
};
export default App;
