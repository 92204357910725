import "./style.scss";

import { Box, InputGroup, Text, Textarea } from "../../_components";
import { ReactComponent as SendSvg } from "@samedaycustom/assets/images/sendsvg.svg";
import {
  AlternativeDropDowns,
  DefaultCheck,
  PrimaryButton,
  SelectedArtWork
} from "@samedaycustom/core-ui";
import { ResponseInputViewProps } from "@samedaycustom/types/order/@types/notes";
import React, { useRef } from "react";

const MessageInput = React.forwardRef(
  (
    {
      setvalue,
      value,
      messageVal,
      setMessageVal,
      _onSend,
      isFetchingLineItem,
      type,
      lineItems,
      onLineItemSelect,
      onNoteSelect,
      setAskPermission,
      askPermission,
      hideRequireApproval,
      onAddFile,
      className,
    }: Required<ResponseInputViewProps>,
    ref: React.MutableRefObject<HTMLInputElement>
  ) => {
    const textAreaRef = useRef<HTMLTextAreaElement>();
    const autoExpand = function(field) {
      // Reset field height
      field.style.height = "inherit";

      // Get the computed styles for the element
      var computed = window.getComputedStyle(field);

      // Calculate the height
      var height =
        parseInt(computed.getPropertyValue("border-top-width"), 10) +
        parseInt(computed.getPropertyValue("padding-top"), 10) +
        field.scrollHeight +
        parseInt(computed.getPropertyValue("padding-bottom"), 10) +
        parseInt(computed.getPropertyValue("border-bottom-width"), 10);

      field.style.height = !field.value ? field.style.minHeight : height + "px";
    };
    return (
      <Box
        alignItems="flex-end"
        borderRadius="4px"
        fontSize="0.95rem"
        className={`message__input ${className ? className : ""}`}
      >
        {value?.files?.length > 0 ? (
          <div className="selectedArtworkGrouping">
            {value?.files.map((el, i) => (
              <SelectedArtWork
                key={i}
                imageUrl={el?.url}
                removeFunc={() => {
                  setvalue({
                    ...value,
                    files: value?.files?.filter(item => item.url !== el.url)
                  });
                }}
                loading={el.loading}
                label={el?.name}
              />
            ))}
          </div>
        ) : null}
        {messageVal?.files?.length > 0 ? (
          <div className="selectedArtworkGrouping">
            {messageVal?.files.map((el, i) => (
              <SelectedArtWork
                key={i}
                imageUrl={el?.url}
                removeFunc={() => {
                  setMessageVal(v => ({
                    ...v,
                    files: v.files.filter(item => item.url !== el.url)
                  }));
                }}
                loading={el.loading}
                label={el?.name}
              />
            ))}
          </div>
        ) : null}
        <Box
          background="#fff"
          style={{
            padding: "0 20px"
          }}
          className="TextArea__OuterParent"
        >
          <Box width="100%" d="flex" className="TextArea__InnerParent">
            <Textarea
              ref={textAreaRef}
              className="mainTextArea"
              size="sm"
              minHeight="60px"
              maxHeight="120px"
              transition="all 0.5s ease"
              paddingTop="15px"
              width="100%"
              background="#F0F4F8"
              border="none"
              multiple
              resize="none"
              placeholder="Write your message here"
              _placeholder={{ color: "#486581" }}
              borderRadius="4px"
              fontSize="1rem"
              value={type === "messages" ? messageVal?.content : value?.message}
              onKeyDown={(e: any) => {
                if (String(e.key).toLowerCase() === "enter") {
                  e.preventDefault();
                  textAreaRef.current.style.height =
                    textAreaRef.current?.style.minHeight;
                  _onSend();
                  return;
                }
              }}
              onChange={
                type === "messages"
                  ? (e: any) => {
                      const val = e.target?.value;
                      setMessageVal(prevValue => ({
                        ...prevValue,
                        content: val
                      }));
                      autoExpand(e.target);
                    }
                  : (e: any) => {
                      const value = e?.target?.value;
                      setvalue(prevValue => ({
                        ...prevValue,
                        message: value
                      }));
                      autoExpand(e.target);
                    }
              }
            />
            <button
              onClick={() => {
                textAreaRef.current.style.height =
                  textAreaRef.current?.style.minHeight;
                _onSend();
              }}
              className="MobileSendBtn"
            >
              <SendSvg />
            </button>
          </Box>
          <Box
            paddingTop="0.5rem"
            d="flex"
            justifyContent="space-between"
            className="messageOptionsGrp"
          >
            <Box d="flex" justifyContent="space-between" position="relative">
              <InputGroup marginRight="0.5rem" size="md" width="140px">
                <AlternativeDropDowns
                  onClick={onNoteSelect}
                  placeholder="Select Note"
                  value={type === "messages" ? messageVal?.type : value?.type}
                  className="Mobile__inputDropDown"
                  loading={false}
                  position="top center"
                  listItems={[
                    {
                      label: "General Note",
                      value: "GENERAL"
                    },
                    // { label: "Line Items", value: "LINE" },
                    { label: "Artwork", value: "ARTWORK" }
                    // {
                    //   label: "Delivery",
                    //   value: "DELIVERY"
                    // },
                    // { label: "Payments", value: "PAYMENT" }
                  ]}
                />
              </InputGroup>
              <InputGroup width="130px" size="md" marginRight="1rem">
                <AlternativeDropDowns
                  onClick={onLineItemSelect}
                  placeholder="Select line..."
                  value={
                    type === "messages"
                      ? messageVal?.lineID
                      : (value?.lineId as string)
                  }
                  className="Mobile__inputDropDown"
                  loading={isFetchingLineItem}
                  position="top center"
                  listItems={lineItems.map(el => ({
                    label: el.deco_line_id,
                    value: el.id
                  }))}
                />
              </InputGroup>
              {/* )} */}
              {
                // ((value && value?.type === "ARTWORK") ||
                //     (messageVal && messageVal?.type === "ARTWORK")) &&
                <>
                  <label
                    htmlFor="attach"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      // height: "100%",
                      width: "fit-content"
                    }}
                  >
                    <Box
                      color="#007BFF"
                      d="flex"
                      alignItems="center"
                      background="#E5F1FF"
                      borderRadius="4px"
                      height="36px"
                      padding="0rem 1rem 0 1rem"
                      width="fit-content"
                      className="AttachFileBtn"
                    >
                      <input
                        ref={ref}
                        type="file"
                        name=""
                        id="attach"
                        multiple
                        hidden
                        // accept="image/*,.pdf,.cdr,.ai,.psd,.eps"
                        onChange={onAddFile}
                      />
                      <i className="fas fa-paperclip"></i>
                      <Text
                        fontFamily="Inter"
                        fontWeight={500}
                        paddingLeft="0.5rem"
                      >
                        Add Files
                      </Text>
                    </Box>
                  </label>
                </>
              }
            </Box>
            <PrimaryButton
              className="messageDesktopbtn"
              // className="Desktop__Messagebutton"
              width="70px"
              height="40px"
              onClick={() => {
                textAreaRef.current.style.height =
                  textAreaRef.current?.style.minHeight;
                _onSend();
              }}
            >
              Send
            </PrimaryButton>
          </Box>

          {!hideRequireApproval && (
            <div className="message__input-checkitem">
              <DefaultCheck
                style={{
                  marginBottom: "0 !important"
                }}
                checked={askPermission}
                label=""
                height={16}
                width={16}
                setChecked={value => {
                  setAskPermission(v => !v);
                  if (type === "messages") {
                    setMessageVal(prevValue => ({
                      ...prevValue,
                      options: {
                        available: !askPermission,
                        selected: null
                      }
                    }));
                  }
                }}
              />
              <span> Require approval from customer </span>
            </div>
          )}
        </Box>
      </Box>
    );
  }
);

export default MessageInput;
