import { usePrevious } from "@samedaycustom/hooks";
import React from "react";

import { ModalContextType, ModalFormType } from "./index.d";
import {
	hideModal,
	showForm,
	showModal,
	submittingModal,
	cancellingModal,
	updateChanges
} from "./provider/actions";
import { ModalContext } from "./provider/store";

/**
 *
 * utitily function for using the modal
 * @param value
 */
export default <T,>(value?: T) => {
	const { dispatch, ...state } = React.useContext(ModalContext);
	/**
	 *
	 * show modal
	 * @param parameters pass in extra modal parameters
	 */
	const show = (parameters: ModalContextType) =>
		dispatch(showModal(parameters));

	/**
	 *
	 * hide modal
	 */
	const hide = () => {
		setSubmitting(false);
		dispatch(hideModal());
	};

	const form = (parameters: ModalFormType) => {
		dispatch(showForm(parameters));
	};

	const setValue = (value: T) => dispatch(updateChanges(value));

	function setSubmitting(value: boolean) {
		dispatch(submittingModal(value));
	}
	function setCancelling(value: boolean) {
		dispatch(cancellingModal(value));
	}

	const getValue = React.useCallback(() => state.showForm.values, [
		state.showForm.values
	]);

	const previousValue = usePrevious(value);
	React.useMemo(() => {
		if (typeof value !== "undefined" && previousValue !== (value as any))
			setValue(value);
	}, [value]);

	return React.useMemo(
		() => ({
			...state,
			show,
			form,
			hide,
			setValue,
			getValue,
			setSubmitting,
			setCancelling
		}),
		[state]
	);
};
