export const START_IMAGE_UPLOAD_ACTION = "START_IMAGE_UPLOAD_ACTION";
export const FAIL_IMAGE_UPLOAD_ACTION = "FAIL_IMAGE_UPLOAD_ACTION";
export const IMAGE_UPLOAD_ACTION = "IMAGE_UPLOAD_ACTION";
export const IMAGE_UPLOAD_STATE_RESET = "IMAGE_UPLOAD_STATE_RESET";

interface ISTART_IMAGE_UPLOAD_ACTION {
  type: typeof START_IMAGE_UPLOAD_ACTION;
  payload: string;
}

interface IFAIL_IMAGE_UPLOAD_ACTION {
  type: typeof FAIL_IMAGE_UPLOAD_ACTION;
  payload: {
    type: string;
    msg: string;
  };
}

interface IIMAGE_UPLOAD_ACTION {
  type: typeof IMAGE_UPLOAD_ACTION;
  payload: string;
}

interface IIMAGE_UPLOAD_STATE_RESET {
  type: typeof IMAGE_UPLOAD_STATE_RESET;
}

export type UPLOAD_ACTIONS_TYPE =
  | ISTART_IMAGE_UPLOAD_ACTION
  | IFAIL_IMAGE_UPLOAD_ACTION
  | IIMAGE_UPLOAD_ACTION
  | IIMAGE_UPLOAD_STATE_RESET;
