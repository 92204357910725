import "./style.scss";

import { Spinner, ButtonProps } from "../../_components";
import React from "react";

export interface AuthedButtonProps extends ButtonProps {
  loading?: boolean;
  loaderColor?: string;
  className?: string;
  children: any;
  onClick?: (e?: any) => void;
  rest?: Array<any>;
  disabled?: boolean;
  divEl?: boolean;
  id?: string;
}
const AuthedButton = ({
  loading,
  loaderColor,
  className,
  children,
  onClick,
  disabled,
  onKeyDown,
  divEl,
  id = "",
  ...rest
}: AuthedButtonProps) => {
  return (
    <>
      {!divEl ? (
        <div
          id={id}
          disabled={disabled ? disabled : false}
          className={
            className
              ? [`ButtonMain  ${disabled ? "disabledBtn" : ""}`, className].join(" ")
              : "ButtonMain"
          }
          onClick={onClick}
          {...(rest as any)}
          style={loading ? ({ pointerEvents: "none", ...rest } as any) : { ...rest }}
          onKeyDown={!disabled && onKeyDown ? onKeyDown : () => {}}
          // _hover={{
          //   backgroundColor: "#006EE5",
          //   outline: "none",
          //   border: "none"
          // }}
          // _focus={{
          //   backgroundColor: "#006EE5",
          //   outline: "none",
          //   border: "none"
          // }}
          // _disabled={{
          //   backgroundColor: "#006EE55F",
          //   outline: "none",
          //   border: "none"
          // }}
        >
          {loading ? (
            <>
              {" "}
              <Spinner color={loaderColor} className="miniSpinner" />{" "}
              <span style={{ color: loaderColor }} className="visualCue">
                {" "}
                Please wait{" "}
              </span>{" "}
            </>
          ) : (
            children
          )}
        </div>
      ) : (
        <div
          className={
            className
              ? [`ButtonMain ${disabled ? "disabledBtn" : ""}`, className].join(" ")
              : "ButtonMain"
          }
          onClick={onClick}
          {...(rest as any)}
          style={loading ? ({ pointerEvents: "none" } as any) : null}
        >
          {loading ? <Spinner color={loaderColor} className="miniSpinner" /> : children}
        </div>
      )}
    </>
  );
};

export default AuthedButton;
