import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { events as EVENTS } from "@samedaycustom/helpers";

import { getCustomerNotesCount, getOrderNotesCount } from "./async";

export interface EventsState {
  pusher_channels: string[];
  counts: {
    order_notes: number;
    customer_notes: number;
  };
}
const initialState: EventsState = {
  pusher_channels: [EVENTS.channels.ADMIN],
  counts: {
    order_notes: 0,
    customer_notes: 0,
  },
};
const eventSplice = createSlice({
  name: "events",
  initialState,
  reducers: {
    addPusherChannel(state: EventsState, action: PayloadAction<string>) {
      state.pusher_channels = [action.payload, ...current(state.pusher_channels)];
    },
    removePusherChannel(state: EventsState, action: PayloadAction<string>) {
      state.pusher_channels = state.pusher_channels.filter((o) => o !== action.payload);
    },
    resetPusherChannel(state: EventsState) {
      state.pusher_channels = initialState.pusher_channels;
    },
    resetCounts(state: EventsState) {
      state.counts = initialState.counts;
    },
    updateCustomerNewNoteCount(state: EventsState) {
      state.counts.customer_notes = state.counts.customer_notes + 1;
    },
    updateOrderNewNoteCount(state: EventsState) {
      state.counts.order_notes = state.counts.order_notes + 1;
    },
  },
  extraReducers: {
    [getOrderNotesCount.fulfilled]: (state: EventsState, action) => {
      state.counts.order_notes = action.payload;
    },
    [getCustomerNotesCount.fulfilled]: (state: EventsState, action) => {
      state.counts.customer_notes = action.payload;
    },
  },
});

export const {
  addPusherChannel,
  removePusherChannel,
  resetPusherChannel,
  resetCounts,
  updateCustomerNewNoteCount,
  updateOrderNewNoteCount,
} = eventSplice.actions;

export default eventSplice.reducer;
