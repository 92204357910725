import './style.scss';

import { Skeleton, Text } from "../../_components";
import { AlternativeDropDowns, ListPage, OperationsCard, ViewBar } from "@samedaycustom/core-ui";
import React, { useEffect, useMemo, useState } from "react";
import { format } from 'date-fns';
import CartDetails, { ICartDetailsProps } from "./CartDetails";
import { ICart } from "@samedaycustom/sdc-types";
import CartStatusTag, { TagType } from "./CartStatusTag";
import { textToSentenceCase } from "@samedaycustom/helpers/src";
import { ISDCLocation } from "@samedaycustom/types/order/@types/order";
import { ReactComponent as ArrowDown } from "@samedaycustom/assets/images/arrow-down.svg";

export interface IAbandonedCartProps {
  getCarts: (
    isSilent?: boolean, value?: string
  ) => Promise<void>;
  // error: boolean | Error;
  rowCount: number;
  cartsLoading: boolean;
  carts: ICart[];
  cartDetailsProps: Omit<ICartDetailsProps, "id" | "locationId">,
  locations?: ISDCLocation[],
  agents?: any,
}

const head = {
  cells: [
    {
      isSortable: false,
      width: 50,
      key: "id",
      shouldTruncate: true,
      content: <Text marginLeft="20px">Cart ID</Text>
    },
    {
      isSortable: false,
      width: 120,
      key: "date",
      content: "Date"
    },
    {
      isSortable: false,
      width: 130,
      key: "email",
      content: "Email address"
    },
    {
      isSortable: false,
      width: 130,
      key: "store",
      content: "Store Location"
    },
    {
      isSortable: false,
      width: 90,
      key: "status",
      content: "Status"
    },
    {
        isSortable: false,
        width: 120,
        key: "assignee",
        content: "Assigned To"
    },
    {
      isSortable: false,
      width: 70,
      key: 9,
      content: ""
    }
  ]
};

export default ({
  getCarts, rowCount, cartsLoading, carts, cartDetailsProps, locations, agents
}: IAbandonedCartProps) => {
  const [page, setPage] = useState<number>(1);
  const [id, setId] = useState(null);
  const [locationId, setLocationId] = useState(null);
  const [code, setCode] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState("all");

  const assignees = useMemo(() => agents.map((user: any) => ({ label: textToSentenceCase(user.firstName), value: user.firstName })), [agents]);
  const localCarts = useMemo(() => (
    selectedAgent === 'all' ? carts : carts?.filter(cart => cart?.agent === selectedAgent)
  ), [carts, selectedAgent]);
  
  useEffect(() => {
    getCarts(false);
  }, [])


  const row = localCarts?.map((cart) => {
    return {
      onClick: async () => {
        setLocationId(cart?.locationId);
        setCode(cart?.code);
        setId(cart?.userId);
      },
      cells: [
        {
          isSortable: false,
          width: 50,
          shouldTruncate: true,
          content: (
            <Text marginLeft="20px" textTransform="capitalize">
              {cart?.code}
            </Text>
          )
        },

        {
          isSortable: false,
          width: 120,
          shouldTruncate: true,
          content: (<Text fontFamily="Inter" fontWeight="normal">
            {cart?.createdAt && <>{format(new Date(cart?.createdAt), "dd/MM/yyyy")}</>}
        </Text>)
        },
        {
          isSortable: false,
          width: 110,
          shouldTruncate: true,
          content: cart?.email
        },
        {
          isSortable: false,
          width: 110,
          shouldTruncate: true,
          content: locations?.find(loc => loc.id === cart.locationId)?.name
        },
        {
            isSortable: false,
            width: 70,
            shouldTruncate: true,
            content: (
                <CartStatusTag type={cart?.orderStatus as TagType} />
            )
        },
        {
            isSortable: false,
            width: 120,
            shouldTruncate: true,
            content: (
                <Text>{(cart?.agent === '' || !cart?.agent) ? 'Unassigned' : textToSentenceCase(cart?.agent)}</Text>
            )
        },
        {
          isSortable: false,
          width: 70,
          shouldTruncate: true,
          content: (
            <Text
              color="#007BFF"
              cursor="pointer"
              fontFamily="Inter"
              fontWeight={500}
              width="fit-content"
            >
              View
            </Text>
          )
        }
      ]
    };
  })

    
  
  const onPageChange = ({ options, callback }: any) => {
    if (options.buttonClick === "next") {
      setPage(page + 1);
    } else {
      setPage(page - 1);
    }
    callback();
  }

  const onSearch = (value: string) => {
    getCarts(true, value); 
  }

  const onClose = () => {
    setLocationId(null);
    setCode(null);
    setId(null);
  }

  return (
    <React.Fragment>
      <OperationsCard isSearchable={false} onSearch={onSearch} placeholder='e.g. cart ID, email, customer name...'>
        <>
          <AlternativeDropDowns
            value={selectedAgent}
            loading={cartDetailsProps.loading}
            position="bottom left"
            listItems={[{ label: "All assignees", value: "all" }, ...assignees]}
            onClick={(e: any) => {
              setSelectedAgent(e)
            }}
            className="assignee-container"
            icon={<ArrowDown fill="#07121D" />}
            containerStyle={{ marginLeft: "-25px" }}
            leftContentStyle={{ color: "#243B53", fontWeight: 500, lineHeight: "20px", fontSize: "14px" }}
          />
          <ListPage
            loading={cartsLoading}
            totalRowCount={rowCount || 0}
            row={row}
            head={head}
            page={page}
            onPageChange={onPageChange}
            rowsPerPage={20}
          />
        </>
      </OperationsCard>
      <ViewBar
          show={!!id && !!locationId && !!code}
          closeFunc={onClose}
          headerStyle={{ background: "#F0F4F8" }}
          heading={cartDetailsProps.loading ? <Skeleton width={70} height={15} /> : code ? `Cart ${code}`: 'Cart not found'}
          width="600px"
      >
        <CartDetails id={id} locationId={locationId} {...cartDetailsProps} />
      </ViewBar>
    </React.Fragment>
  );
};
