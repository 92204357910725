import "./style.scss";

import { Box } from "../../_components";
import React, { CSSProperties, useState } from "react";
import Style from "style-it";

import { ReactComponent as CheckMark } from "../assets/check-white.svg";

export interface IDefaultCheck {
  label?: string | React.ReactNode;
  stateful?: boolean;
  id?: any;
  onSelect?: (id?: any, value?: any) => void;
  setChecked?: (e?: boolean) => void;
  checked?: boolean;
  height?: number;
  width?: number;
  background?: any;
  style?: CSSProperties;
  checkColor?: string;
  disabled?: boolean;
}
export default ({ label, ...props }: IDefaultCheck) => {
  const { stateful, id, onSelect, checked, setChecked, disabled } = props;
  const [isSelected, setisSelected] = useState(checked);
  const isChecked = stateful ? isSelected : checked;

  const onClick = () => {
    setisSelected(!isChecked);
    onSelect && onSelect(id, !isChecked);
    setChecked && setChecked(!isChecked);
  };
  return Style.it(
    `
        .checkgroup .CheckBox{
            height: ${props.height}px;
            width: ${props.width}px;
            background:${(isChecked && (props.background || "#007bff")) ||
              "#fff"}  
        }
        .checkgroup .CheckBox  .CheckMark svg{
            height: ${props?.height ? props?.height - 5 : 13}px;
            width: ${props?.width ? props?.width / 2 : 13}px;
            transform: ${(isChecked && "scale(1)") || "scale(0)"};
        }
        `,
    <Box className={"checkgroup"}>
      <Box
        className={
          `${isChecked ? "CheckBox CheckBox--checked" : "CheckBox"} 
          ${disabled ? "CheckBox--disabled-state" : ""}`
        }
        onClick={() => disabled ? null : onClick()}
        {...props}
        cursor={disabled ? "not-allowed" : "pointer"}
      >
        <Box
          className={isChecked ? "CheckMark CheckMark--checked" : "CheckMark"}
        >
          <CheckMark fill={props.checkColor || "white"} />
        </Box>
      </Box>
      {label ? (
        typeof label === "string" ? (
          <span {...props}> {label} </span>
        ) : (
          label
        )
      ) : null}
    </Box>
  );
};
