import { Text, TextProps } from "../_components";
import React from "react";

export { default as UIDropDown } from "./DropDown";
export { default as ExpandableSearchBar } from "./ExpandableSearchBar";
export { default as FullPageLoader } from "./Loaders/FullPageLoader";
export { default as LoadingScreen } from "./Loaders/LoadingScreen";
export { default as ProgressLoader } from "./Loaders/ProgressLoader";
export { default as Spinner } from "./Loaders/Spinner";
export { default as MenuIcon } from "./MenuIcon";
export { default as UISearchInput } from "./SearchInput";
export { default as FileLoader } from "./Loaders/FileLoader";
export { default as Graph } from "./Graph";
export { default as Time } from "./Time";
export * from "./Graph";
export * from "./Analytic";
export * from "./Ratings";

export interface ITitle extends TextProps {
  children?: any;
}
export function Title({ children, ...props }: ITitle) {
  return (
    <Text
      color="#829AB1"
      fontWeight={500}
      letterSpacing="0.01em"
      lineHeight="25px"
      textTransform="capitalize"
      fontSize="13px"
      {...props}
    >
      {children}
    </Text>
  );
}
