import { AuthedButton } from "@samedaycustom/core-ui";
import React from "react";

import { ModalContext } from "../provider/store";

export default () => {
  const state = React.useContext(ModalContext);

  if (state.showForm && state.showForm.isOpen && state.showForm.component) {
    return (
      (state.showForm.component &&
        typeof state.showForm.component === "function" && (
          <div className="Modal__body" style={state.showForm.bodyStyle}>
            {state.showForm.component()}
          </div>
        )) ||
      (React.isValidElement(state.showForm.component) && (
        <div className="Modal__body" style={state.showForm.bodyStyle}>
          {/* <ErrorAlert ErrorMessage={} /> */}
          {state.showForm.component}
        </div>
      ))
    );
  }

  if (state.showForm && state.showForm.isOpen && !state.showForm.component)
    return (
      <div className={"confirmPopUp"}>
        <div className="top">
          {" "}
          <h2> {state.showForm.topTitle}</h2>
        </div>
        <div className="mainConfirmContent">
          <p> {state.showForm.title} </p>
        </div>
        <div className="btnGroupConfirm">
          <AuthedButton
            className="btn btn--outline"
            onClick={
              state?.showForm?.onClose ||
              state.showForm.cancelOnClick ||
              state.hide
            }
          >
            <span>{state.showForm.cancelText}</span>
          </AuthedButton>
          <AuthedButton
            className="btn btn--fill"
            onClick={() => state.showForm.okOnClick(state.showForm.values)}
          >
            <span>{state.showForm.okText}</span>
          </AuthedButton>
        </div>
      </div>
    );

  return (
    <div className={`Modal__body ${state.modalType}`} style={state.bodyStyle}>
      {state.component && <div>{state.component}</div>}
    </div>
  );
};
