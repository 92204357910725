import { Box, Grid } from "@chakra-ui/react";
import React from "react";
// import { Grid } from "@material-ui/core";
import { IDynamicCell, IDynamicCellType } from "../DynamicCell";
import { DynamicTableContext } from "..";

export type DynamicHeadType = { cells: IDynamicCellType[] };
export interface IDynamicHead {
  gridColumnTemplate?: string;
  children?:
    | React.ReactElement<IDynamicCell>[]
    | React.ReactElement<IDynamicCell>;
}
export default ({ children }: IDynamicHead) => {
  const dynamictablecontextvalue = React.useContext(DynamicTableContext);
  return (
    <Box background="#F0F4F8" className="table_row head">
      <Grid
        templateColumns={
          dynamictablecontextvalue.headColumnTemplate
            ? dynamictablecontextvalue.headColumnTemplate
            : "repeat(auto-fit, minmax(2rem,auto))"
        }
        gridGap="2px"
        marginLeft="10px"
        width="100%"
      >
        {children}
      </Grid>
    </Box>
  );
};
