import deferredObservable from "@samedaycustom/observable";

type TIframeEvent = {
	type: string;
	data: {
		key: string;
		value?: string;
	};
};

export const iframeEvent = deferredObservable<TIframeEvent>();
