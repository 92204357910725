import { Box, Text } from "../../_components";
import { TableLoader } from "@samedaycustom/core-ui";
import { ListPage } from "@samedaycustom/core-ui";
import React from "react";
import { useHistory } from "react-router-dom";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { formatCurrency } from "@samedaycustom/helpers/src";
import { ACTION_TYPE_DEFS } from "@samedaycustom/types/app";
import { ISDCAdminCustomers } from "@samedaycustom/types/customers";

export default ({
    loading,
    getCustomer,
    customers,
    view,
    showPhone = true,
    showView = true,
}: {
    getCustomer: (
        isSilent?: boolean,
        param?: {
            search: string;
            before?: string;
            after?: string;
        }
    ) => Promise<void>;
    loading: boolean;
    error: boolean | Error;
    customers: ACTION_TYPE_DEFS<ISDCAdminCustomers[]>;
    view: string;
    showPhone?: boolean;
    showView?: boolean;
}) => {
    const { push } = useHistory();

    const onPageChange = React.useCallback(
        async ({ options, callback }) => {
            const customersList = customers.data;
            if (customersList && customersList.length >= 1 && options) {
                const lastCustomer =
                    customersList[
                    options.buttonClick === "next" ? customersList.length - 1 : 0
                    ];

                const params =
                    options.buttonClick === "next"
                        ? {
                            before: lastCustomer?.createdAt || 0
                        }
                        : {
                            after: lastCustomer?.createdAt
                        };

                const result = await getCustomer(true, params as any);
                callback(result);
            }
        },
        [customers?.data]
    );

    const head = {
        cells: [
            {
                isSortable: false,
                width: 120,
                key: "id",
                shouldTruncate: true,
                content: <Text marginLeft="20px">Name</Text>
            },
            showPhone ? {
                isSortable: false,
                width: 120,
                key: "mobile",
                content: "Phone number"
            } : "",
            {
                isSortable: false,
                width: 130,
                key: "mobile",
                content: "Email address"
            },
            {
                isSortable: false,
                width: 100,
                key: "price",
                content: "Total Orders"
            },
            {
                isSortable: false,
                width: 90,
                key: "processed",
                content: "Order Volume"
            },

            showView ? {
                isSortable: false,
                width: 70,
                key: 9,
                content: ""
            } : ""
        ]
    };

    const row = React.useMemo(
        () =>
            customers.data?.map(o => {
                return {
                    onClick: () =>
                        o?.id && view && push(`${view}/${o.email}`, { email: o.email }),
                    cells: [
                        {
                            isSortable: false,
                            width: 150,
                            shouldTruncate: true,
                            content: (
                                <Text marginLeft="20px" textTransform="capitalize">
                                    {o.firstName?.toLowerCase()} {o.lastName?.toLowerCase()}
                                </Text>
                            )
                        },

                        showPhone ? {
                            isSortable: false,
                            width: 90,
                            shouldTruncate: true,
                            content: o.mobile
                                ? parsePhoneNumberFromString(o.mobile, "US")?.format("NATIONAL") ?? "-"
                                : "-"
                        } : "",
                        {
                            isSortable: false,
                            width: 110,
                            shouldTruncate: true,
                            content: o.email || "-"
                        },
                        {
                            isSortable: false,
                            width: 100,
                            shouldTruncate: true,
                            content: o?.totalOrders || o?.orderCounts
                        },
                        {
                            isSortable: false,
                            width: 100,
                            shouldTruncate: true,
                            content: formatCurrency(o?.ordersValue || o?.totalOrdersValue || 0)
                        },

                        showView ? {
                            isSortable: false,
                            width: 70,
                            shouldTruncate: true,
                            content: (
                                <Text
                                    color="#007BFF"
                                    cursor="pointer"
                                    fontFamily="Inter"
                                    fontWeight={500}
                                    width="fit-content"
                                // onClick={() =>
                                // 	push(`${VIEW_VENDOR}/${o.id}`)
                                // }
                                >
                                    View
                                </Text>
                            )
                        } : ""
                    ]
                };
            }),
        [customers]
    );

    if (loading) return <TableLoader />;
    return (
        <Box>
            <ListPage
                // onSort={(e:any) => {
                // 	setSortKey({
                // 		key: e["key"],
                // 		orderBy: e?.item?.sortOrder === "ASC" ? "DESC" : "ASC"
                // 	});
                // }}
                // totalRowCount={
                // 	target === 0
                // 		? metadata?.open
                // 		: target === 1
                // 		? metadata?.all
                // 		: target === 3
                // 		? metadata?.completed
                // 		: 0
                // }
                totalRowCount={customers?.metadata?.all || 0}
                row={row}
                head={head}
                // sortKey={sortKey}
                onPageChange={onPageChange}
            />
        </Box>
    );
};
