import { DropdownItem } from "@atlaskit/dropdown-menu";
import { Box, ChakraInput as Input, InputGroup, Text } from "../../_components";
import { ReactComponent as ErrorImage } from "@samedaycustom/assets/images/error-image.svg";
import { ReactComponent as Logo } from "@samedaycustom/assets/images/logo-full.svg";
import { LoadingScreen, PrimaryButton } from "@samedaycustom/core-ui";
import * as Sentry from "@sentry/browser";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";

// const xmlData = require("../../../../assets/sitemap.xml")
// console.log(xmlData)
// var jsonDataFromXml = new XMLParser().parseFromString(xmlData)
export default ({ screens }: { [key: string]: string }) => {
  const [loading, setloading] = useState(false);
  const [value, setValue] = useState("");
  const { push } = useHistory();

  if (loading) return <LoadingScreen />;

  const sitemap = screens
    ? Array.from(Object.values(screens))
        ?.filter(el => value && el.toLowerCase().includes(value.toLowerCase()))
        ?.map(
          el =>
            el && (
              <DropdownItem onClick={(e: any) => push(el)}>{el}</DropdownItem>
            )
        )
    : null;
  return (
    <Box d="flex" justifyContent="center">
      <Box
        d="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="10rem"
      >
        <Box>
          <ErrorImage width="320px" height="380px" />
        </Box>
        <Box marginLeft="10rem">
          <Logo height="48px" width="137.78px" />
          <Text
            paddingBottom="1.5rem"
            paddingTop="1.5rem"
            fontFamily="Inter Bold"
            fontSize="1.5rem"
            width="410px"
          >
            Sorry, the page you’re looking for cannot be found.
          </Text>
          <Text>
            Visit our
            <a href="/" style={{ color: "#007BFF" }}>
              &nbsp;homepage
            </a>
            &nbsp;or
            <a
              href="#"
              onClick={() => {
                setloading(true);
                const eventId = Sentry.captureException(new Error("hello"));
                Sentry.showReportDialog({
                  eventId: eventId,
                  onLoad: () => setloading(false)
                });
              }}
              style={{
                color: "#007BFF",
                cursor: "pointer"
              }}
            >
              &nbsp;get help&nbsp;
            </a>
            or try searching for it
            <InputGroup
              background="#F0F4F8"
              border="transparent"
              height="50px"
              d="flex"
              alignItems="center"
              padding="0px 16px 0 0px"
              borderRadius="8px"
              marginTop="2rem"
            >
              <Popup
                trigger={
                  <Input
                    border="none"
                    placeholder="What are you looking for?"
                    background="#F0F4F8"
                    padding="8px"
                    width="100%"
                    height="50px"
                    borderRadius="8px"
                    _hover={{ border: "none" }}
                    onChange={(e: any) => {
                      if (e.target.value) setValue(e.target?.value);
                    }}
                  />
                }
                position="bottom right"
                on="click"
                arrowStyle={{
                  backgroundColor: "#fff",
                  boxShadow: "none"
                }}
                offsetY={10}
                arrow={false}
                closeOnDocumentClick
                contentStyle={{
                  padding: "0px",
                  border: "none",
                  backgroundColor: "#fff",
                  borderRadius: "4px",
                  width: "unset",
                  boxShadow: "0px 6px 18px rgba(98, 125, 152, 0.2)"
                }}
              >
                {(closeFunc, isOpen) => {
                  return (
                    <Box
                      className="sidebarFlyout"
                      width="100%"
                      maxHeight="150px"
                      overflow="scroll"
                    >
                      {sitemap}
                    </Box>
                  );
                }}
              </Popup>
              <PrimaryButton
                height="30px"
                fontSize="13px"
                padding="8px 16px"
                fontWeight="light"
              >
                Search
              </PrimaryButton>
            </InputGroup>
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
