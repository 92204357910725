export const getURLExtension = (url: string) => {
  return (
    url
      ?.split(/[#?]/)[0]
      ?.split(".")
      ?.pop()
      ?.trim() ?? ""
  );
};

export const getFileNameFromUrl = (url: string) => {
  const extension = getURLExtension(url);
  return url?.split("uploads/")[1]?.split(`.${extension}`)[0] ?? "";
};

export const downloadFiles = async (url: string) => {
  const newUrl = `http:${url.split("https:")[1]}`;
  const originalImage = newUrl;
  const image = await fetch(originalImage);
  const name = `${getFileNameFromUrl(url)}.${getURLExtension(url)}`;

  const imageBlog = await image.blob();
  const imageURL = URL.createObjectURL(imageBlog);
  const link = document.createElement("a");
  link.href = imageURL;
  link.download = "" + name + "";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const isFileTypeSupported = (fileType: string): boolean => {
  const formattedFileType = fileType.toLowerCase();
  return (
    formattedFileType === "png" ||
    formattedFileType === "jpeg" ||
    formattedFileType === "jpg" ||
    formattedFileType === "svg"
  );
};

export const setRecordRangeToLocalStorage = (table: string, range: string) => {
  if (typeof window !== "undefined") {
    const rangeKey = `${table}_range`;
    localStorage?.setItem(rangeKey, range);
  }
};

export const getRecordRangeFromLocalStorage = (table: string) => {
  if (typeof window !== "undefined") {
    const rangeKey = `${table}_range`;
    const range = localStorage?.getItem(rangeKey) as string;
    if (range) return range;
    else return "";
  } else return "";
};

export const getCorrectDeliveryMethod = (deliveryMethod: string) => {
  if (deliveryMethod === "super_car") return "super_rush_car";
  else if (deliveryMethod === "super_shipping") return "super_rush_shipping";
  else return deliveryMethod;
};

export const reverseDeliveryMethod = (deliveryMethod: string, currentMethod?: boolean) => {
  if (deliveryMethod === "superRushCar") return "superCar";
  else if (deliveryMethod === "superRushShipping") return "superShipping";
  else if (currentMethod && deliveryMethod === "rush") return "rushShipping";
  else if (currentMethod && deliveryMethod === "superRush") return "superShipping";
  else return deliveryMethod;
};

export const validateCommissionRanges = (rowContents: any[]) => {
  const sortedRowContents = [...rowContents].sort((a, b) => a.min - b.min);
  const hasZeroInRange = sortedRowContents.some((item) => item.min === 0);

  if (hasZeroInRange) {
    return "Range must be greater than zero(0)";
  }

  for (let i = 1; i < sortedRowContents.length; i++) {
    if (sortedRowContents[i].min <= sortedRowContents[i - 1].max) {
      return `Range ${sortedRowContents[i - 1].min} - ${sortedRowContents[i - 1].max} overlaps with range ${sortedRowContents[i].min} - ${sortedRowContents[i].max}`;
    }
  }

  return null;
};