import { Text } from "../_components";
import { getIn, useFormikContext } from "formik";
import React from "react";

export const ErrorMessage = (props: { name: string }) => {
  const { errors } = useFormikContext();
  if (typeof props.name === "undefined" || !props.name) return null;
  const name = props?.name || "";
  const error = getIn(errors, name);
  return (
    (error && (
      <Text
        position="relative"
        color="rgba(255, 0, 0, 0.603)"
        fontFamily="Inter"
        fontWeight="normal"
        fontSize="12px"
        marginTop="2px"
      >
        {error}
      </Text>
    )) ||
    null
  );
};
