import React, { useState } from "react";
import { Box, ChakraInput as Input, Text } from "../../_components";
import { ReactComponent as LockIcon } from "@samedaycustom/assets/images/LockPage.svg";
import { Modal } from "@samedaycustom/core-ui";
import { useToast } from "../../../../hooks/src";


const UnlockExceedPayoutLimit = ({
  limit,
  showModal,
  setShowModal,
  setAllowedToExceedLimit,
}: {
  limit: number,
  showModal: boolean,
  setShowModal: (showModal: boolean) => void,
  setAllowedToExceedLimit: (exceedLimit: boolean) => void,
}) => {
  const [password, setPassword] = useState("");
  const toast = useToast();

  return (
    <Modal
      show={showModal}
      closeFunction={() => setShowModal(false)}
      actionFunction={() => {
        if (password === process.env.REACT_APP_EXCEED_PAYOUT_LIMIT_PASSWORD) {
          setAllowedToExceedLimit(true);
        } else {
          toast({
            title: "Incorrect Password:",
            description: "Password incorrect. you can't exceeding the limit",
            position: "bottom-left",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
        setShowModal(false);
      }}
      heading={"Enter password to edit payout"}
      footer={true}
      actionBtnClass="Btn-Fill Btn--Primary"
      actionBtnText="Continue"
    >
      <Box
        width={"full"}
        height={"auto"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <LockIcon />
        <Text fontWeight={"600"} marginBottom={"4px"} fontSize={"18px"} lineHeight={"24px"}>
          Password required
        </Text>
        <Text fontWeight={"400"} fontSize={"16px"} marginBottom={"20px"} lineHeight={"24px"}>
          You need a password to change payout value
        </Text>
        <Box display={"flex"} alignItems={"center"} gap={"10px"}>
          <Input
            type="password"
            onChange={(e: any) => setPassword(e.target.value)}
            borderColor={"#9FB3C8"}
            width={"300px"}
            height={"40px"}
            placeholder="Enter your password here"
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default UnlockExceedPayoutLimit;
