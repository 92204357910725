import "../AuthedButton/style.scss";

import { Button, ButtonProps } from "../../_components";
import React from "react";

import ButtonTemplate from "../ButtonTemplate";

interface IPrimaryButtonProps extends ButtonProps {
  text?: string;
  loaderColor?: string;
  icon?: React.ReactElement;
}
export default ({ loaderColor = "#fff", ...props }: IPrimaryButtonProps) => {
  return (
    <Button
      className="ButtonMain"
      variant="solid"
      backgroundColor="#fff"
      color="#EF4E4E"
      border="1px solid #9FB3C8"
      fontFamily="Inter"
      fontWeight={500}
      fontSize="14px"
      _hover={{
        backgroundColor: "#fff",
        outline: "none",
        border: "1px solid #EF4E4E"
      }}
      _focus={{
        backgroundColor: "#fff",
        outline: "none",
        border: "1px solid #EF4E4E"
      }}
      _disabled={{
        backgroundColor: "#fff",
        outline: "none",
        border: "1px solid #EF4E4E"
      }}
      isLoading={false}
      width="unset"
      {...props}
    >
      <ButtonTemplate {...props} loaderColor={loaderColor} />
    </Button>
  );
};
