import { Box, BoxProps, ChakraInput as Input, InputProps } from "../../_components";
import { isEmptyArray } from "formik";
import React from "react";
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import "./style.scss";

export type AddresInformation = {
  address: string;
  city: string;
  zipcode: string;
  state: string;
  stateCode: string;
  lat?: string;
  lng?: string;
  country?: string;
};

export type FullAddresInformation = {
  address: string;
  city: string;
  zipcode: string;
  state: string;
  stateCode: string;
  lat?: string;
  lng?: string;
  country?: string;
  countryCode?: string;
  formattedFullAddress?: string;
};
export interface LocationSearchInputProps {
  handleSelect?: (address: AddresInformation, fullAddress: FullAddresInformation) => void;
  handleChange?: (address: string) => void;
  address: string;
  size?: "lg" | "md" | "sm";
  width?: string | number;
  height?: string | number;
  containerProps?: BoxProps;
  inputProps?: InputProps;
}
export default class LocationSearchInput extends React.PureComponent<LocationSearchInputProps> {
  state = {
    address: this.props.address,
  };

  componentDidUpdate = () => {
    this.setState({ address: this.props.address });
  };

  handleChange = (address: string) => {
    this.setState({ address });
    if (typeof this.props.handleChange === "function") this.props.handleChange(address);
  };

  handleSelect = (address: string) => {
    geocodeByAddress(address).then((res) => {
      const addressFields = res[0].formatted_address.split(", ");
      const addressFieldsLength = addressFields.length;
      const streetAddressArr = addressFields.slice(0, addressFieldsLength - 3);
      const address = streetAddressArr.join(", ");

      const stateFound = res[0].address_components.find((o) =>
        o.types.includes("administrative_area_level_1")
      );

      const zipCodeFound = res[0].address_components.find((o) =>
        o.types.includes("postal_code")
      );

      const countryFound = res[0].address_components.find((o) =>
        o.types.includes("country")
      );

      const cityFound = res[0].address_components.find((o) =>
        o.types.includes("locality") || o.types.includes("sublocality")
      );

      const addressInformation: AddresInformation = {
        address,
        city: cityFound?.long_name,
        zipcode: zipCodeFound?.short_name,
        state: stateFound?.long_name,
        stateCode: stateFound?.short_name,
        lat: res[0]?.geometry?.location?.lat(),
        lng: res[0]?.geometry?.location?.lng(),
        country: countryFound?.short_name,
      };

      const fullAddressInformation: FullAddresInformation = {
        address,
        city: cityFound?.long_name,
        zipcode: zipCodeFound?.short_name,
        state: stateFound?.long_name,
        stateCode: stateFound?.short_name,
        lat: res[0]?.geometry?.location?.lat(),
        lng: res[0]?.geometry?.location?.lng(),
        country: countryFound?.long_name,
        countryCode: countryFound?.short_name,
        formattedFullAddress: res[0].formatted_address,
      };

      this.props.handleSelect(addressInformation, fullAddressInformation);
    });
    this.setState({ address });
  };

  render() {
    return (
      <PlacesAutocomplete
        value={`${this.state.address} USA`}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          return (
            <Box
              className="locationInputContainer"
              width={this.props.width}
              height={this.props.height || "40px"}
              {...this.props.containerProps}
            >
              <Input
                {...getInputProps({
                  placeholder: "Find address...",
                  className: "location-search-input",
                })}
                width={this.props.width}
                height={this.props.height || "40px"}
                value={this.state.address}
                size={this.props.size}
                {...this.props.inputProps}
              />
              <Box className="autocomplete-dropdown">
                {loading && <div>Loading...</div>}
                <Box className={`${!loading && !isEmptyArray(suggestions) && "dropdown"}`}>
                  {!loading &&
                    suggestions &&
                    suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? {
                            backgroundColor: "#fafafa",
                            cursor: "pointer",
                          }
                        : {
                            backgroundColor: "#ffffff",
                            cursor: "pointer",
                          };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                </Box>
              </Box>
            </Box>
          );
        }}
      </PlacesAutocomplete>
    );
  }
}
