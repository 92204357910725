import { Box, Text, Spinner } from "../../_components";
import { ReactComponent as CheckO } from "@samedaycustom/assets/images/check.svg";
import { Metrics as metrics } from "@samedaycustom/helpers";
import { IuseUploadType, useUpload } from "@samedaycustom/hooks";
import { TFile } from "@samedaycustom/types/order/@types/notes";
import { FileLoader } from "@samedaycustom/core-ui";
import React, { useCallback } from "react";
import { generate } from "shortid";

export type UploadType = (
  files: File[],
  cb?: (uploadedFiles?: TFile, isError?: boolean) => void,
  errorCb?: () => void
) => void;

export type ReturnUploadType = (files: TFile[]) => void;

export interface IUploadWrapper {
  id?: string | number;
  loading?: boolean;
  type: IuseUploadType;
  progress?: number;
  isMultiple?: boolean;
  stateful?: boolean;
  onUpload: UploadType;
  getUploads?: ReturnUploadType;
  onRemovedFile?: ReturnUploadType;
  beforeText?: JSX.Element | string;
  style?: React.CSSProperties;
  shouldUseInnerLoader?: boolean;
  labelClass?: string;
  labelText?: JSX.Element | string;
  spanClass?: string;
  boxClass?: string;
}
export default React.memo(
  ({
    id,
    type,
    onUpload,
    onRemovedFile,
    getUploads,
    isMultiple = true,
    beforeText = "Drag file here or",
    style,
    shouldUseInnerLoader = false,
    labelClass,
    labelText,
    spanClass,
    boxClass,
  }: IUploadWrapper) => {
    const IdRef = React.useRef(id);
    const {
      onBrowseFile,
      onDrag,
      files,
      onDragEnd,
      onDragEnter,
      onDragLeave,
      onDragOver,
      onDrop,
      ondragover,
      loadingFile,
      setFiles
    } = useUpload(type, { isMultiple, key: id });

    const [uploadedFiles, setuploadedFiles] = React.useState<TFile[]>([]);
    const [doneFiles, setDoneFiles] = React.useState<TFile[]>([]);
    const [alldone, setAllDone] = React.useState<boolean>(false);
    const fileInputRef = React.useRef(null);

    React.useEffect(() => {
      return () => {
        setDoneFiles([]);
        setuploadedFiles([]);
        setAllDone(false);
        fileInputRef.current = null;
      };
    }, []);

    /**
     *
     * return all files that are done
     */
    React.useMemo(() => {
      if (Array.isArray(doneFiles) && doneFiles.length >= 1) {
        if (typeof getUploads === "function") getUploads(doneFiles);
        setAllDone(true);
      }
    }, [doneFiles]);

    React.useEffect(() => {
      if (
        !loadingFile &&
        !ondragover &&
        files &&
        Array.isArray(files) &&
        files.length >= 1 &&
        typeof onUpload === "function"
      ) {
        /**
         *
         * uploading files logic,
         */
        onUpload(files, (ufiles, isError) => {
          if (ufiles.done && ufiles.url) {
            setDoneFiles(prev => prev.concat(ufiles));
          }
          setuploadedFiles(prev => {
            /**
             *
             * find index of already existing file id
             */
            const prevFileIndex = prev.findIndex(o => o.id === ufiles.id);

            /**
             *
             * if index is not found then add file to state
             * else mutate progress state of file id
             */
            if (prevFileIndex === -1) return [...prev, ufiles];
            return prev.map(o => {
              if (o.id === ufiles.id)
                return {
                  ...o,
                  ...ufiles
                };
              return o;
            });
          });
        },
        () => {
          setFiles([]);
          setuploadedFiles([]);
        });
      }
    }, [files, loadingFile, ondragover, IdRef]);

    /**
     *
     * removed uploaded file from view
     */
    const _onRemoveFile = useCallback(
      (file: TFile) => {
        const filteredFiles = uploadedFiles.filter(m => m.id !== file.id);
        setFiles([]);
        setuploadedFiles(filteredFiles);
        onRemovedFile && onRemovedFile(filteredFiles);
      },
      [uploadedFiles]
    );

    const acceptType = useCallback(() => {
      switch (type) {
        case "image":
          return metrics.fileType.image;
        case "doc":
          return metrics.fileType.doc;
        case "other":
          return "*";
        default:
          return metrics.fileType.image;
      }
    }, [type])();

    const _renderFilesUpload = React.useMemo(() => {
      return (
        uploadedFiles &&
        uploadedFiles.map(o => {
          return (
            <FileLoader
              key={generate()}
              file={o}
              progress={o.progress}
              onRemove={async () => {
                _onRemoveFile(o);
              }}
            />
          );
        })
      );
    }, [uploadedFiles]);

    // check if any file is uploading
    const isUploading = React.useMemo(
      () =>
        uploadedFiles.length > 0
          ? uploadedFiles.every(o => o.progress > 0 && o.progress < 100)
          : false,
      [uploadedFiles]
    );

    return (
      <Box {...style}>
        <Box
          className={boxClass}
          borderStyle="dashed"
          borderWidth="1.5px"
          borderColor="#007BFF6F"
          borderRadius="4px"
          boxSizing="border-box"
          background="#E5F1FF"
          d="flex"
          justifyContent="center"
          alignItems="center"
          padding="0.7rem"
          width="100%"
          minHeight="36px"
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDragEnd={onDragEnd}
          onDragOver={onDragOver}
          onDrop={onDrop}
          onDrag={onDrag}
        >
          <Text
            fontSize="11px"
            fontFamily="Inter"
            fontWeight={500}
            width="100%"
            textAlign="center"
          >
            <span className={spanClass}>
              {beforeText}{" "}
              <label className={labelClass} htmlFor="browse">
                <input
                  type="file"
                  ref={fileInputRef}
                  accept={acceptType}
                  hidden
                  onChange={onBrowseFile}
                  multiple={isMultiple}
                />
                <span
                  onClick={() => fileInputRef.current?.click()}
                  style={{
                    color: "#007BFF",
                    cursor: "pointer"
                  }}
                >
                  {labelText ?? "browse"}
                </span>
              </label>
            </span>
          </Text>
          {shouldUseInnerLoader ? (
            !isUploading && alldone ? (
              <CheckO />
            ) : isUploading ? (
              <Spinner color="red" size="sm" />
            ) : null
          ) : null}
        </Box>
        {!shouldUseInnerLoader &&
          Array.isArray(_renderFilesUpload) &&
          _renderFilesUpload.length >= 1 && (
            <Box marginTop="10px" maxHeight="200px" overflow="auto">
              {_renderFilesUpload}
            </Box>
          )}
      </Box>
    );
  }
);
