import React from "react";
import { useTransition, animated, UseTransitionProps } from "react-spring";

export interface IAnimatedTransition {
	components: Array<{
		component: JSX.Element;
		key: number | string;
	}>;
	target: number;
}
export default React.memo(({ components, target }: IAnimatedTransition) => {
	const animatedComponents = useTransition(
		components[target],
		(item) => item.key,
		{
			from: { opacity: 0 },
			enter: { opacity: 1 },
			leave: { opacity: 0 },
			config: {
				duration: 500
			}
		}
	);

	return (
		<React.Fragment>
			{animatedComponents.map(({ item, props, key }) => {
				return (
					<animated.div key={key} style={props}>
						{item.component}
					</animated.div>
				);
			})}
		</React.Fragment>
	);
});
