import React from 'react'
import  Spinner from '@atlaskit/spinner'
import './styles.scss'

function FullPageLoader({ className }) {
    return (
        <div className={className ? `FullLoader ${className}` : `FullLoader`}>
            <Spinner
            	size="large" 
            />
        </div>
    )
}

export default FullPageLoader
