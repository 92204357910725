import {
  USERS_ACTION_TYPES,
  FETCHING_USER_PROFILE,
  FETCH_USER_PROFILE,
} from "admin/providers/actions/user/type";

import { ADMIN_USERPROFILE_STATE_TYPE, USERS_STATE } from "./initialState";

export const USERS = (
  state = USERS_STATE,
  action: USERS_ACTION_TYPES
): ADMIN_USERPROFILE_STATE_TYPE => {
  switch (action.type) {
    case FETCH_USER_PROFILE:
      return {
        ...state,
        profile: {
          ...state.profile,
          data: action.payload.profile,
        },
      };
    case FETCHING_USER_PROFILE:
      return {
        ...state,
        profile: {
          ...state.profile,
          loading: action.payload.loading,
        },
      };

    default:
      return state;
  }
};
