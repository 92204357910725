import { ProductionSpeed } from "@samedaycustom/types/app/Settings";
import {
  DELETE_PRODUCTION_SPEED,
  EDIT_PRODUCTION_SPEED,
  GET_PRODUCTION_SPEED,
  LOADING_PRODUCTION_SPEED,
  TOGGLE_PRODUCTION_SPEED_ERROR,
  UPDATE_PRODUCTION_SPEED,
  FAILED_PRODUCTION_SPEED,
} from "admin/providers/actions/productionSpeed/types";
import { generate } from "shortid";

import { ProductionSpeedInitialStateData, ProductionSpeedState } from "./intialstate";

export type ProductionActionType = {
  type: string;
  data: ProductionSpeed[];
  error?: string;
  code?: number;
  id?: number;
  maxQty?: string | number;
  orderType?: string;
  formValues: ProductionSpeed;
  actionType?: any;
  message?: string;
  condition?: boolean;
};

export const productionSpeed = (
  state = ProductionSpeedState,
  action: ProductionActionType
): typeof ProductionSpeedState => {
  switch (action.type) {
    case GET_PRODUCTION_SPEED: {
      const newData =
        (Array.isArray(action.data) &&
          action.data.map((el) => ({
            ...el,
            minQty: Number(el.minQty),
            maxQty: Number(el.maxQty),
            isDefault: false,
          }))) ||
        [];
      return {
        ...state,
        done: true,
        data: newData,
      };
    }
    case UPDATE_PRODUCTION_SPEED: {
      const newData = action.data.map((el) => ({
        ...el,
        minQty: Number(el.minQty),
        maxQty: Number(el.maxQty),
        isDefault: false,
      }));
      return {
        ...state,
        done: true,
        data: newData,
      };
    }

    case EDIT_PRODUCTION_SPEED: {
      const { formValues } = action;

      if (formValues) return { ...state, value: formValues };

      if (state.data.length) {
        const maxValue = Number(state.data[state.data.length - 1].maxQty);
        const newMinQty = maxValue + 1;
        const newMaxQty = maxValue + 2;
        if (newMinQty <= 0 || maxValue <= 0) return state;

        const newElement: ProductionSpeed = {
          ...ProductionSpeedInitialStateData,
          id: generate(),
          isDefault: true,
          minQty: newMinQty,
          maxQty: newMaxQty,
        };
        return {
          ...state,
          done: true,
          value: newElement,
        };
      }
      return {
        ...state,
        value: ProductionSpeedInitialStateData,
      };
    }

    case LOADING_PRODUCTION_SPEED:
      return {
        ...state,
        done: false,
        error: false,
        loading: action.condition,
        type: action.actionType,
      };
    case FAILED_PRODUCTION_SPEED:
      return {
        ...state,
        loading: false,
        done: false,
        error: true,
        errorMessage: action.message,
        // type: action.actionType
      };
    case DELETE_PRODUCTION_SPEED:
      return state;
    case TOGGLE_PRODUCTION_SPEED_ERROR:
      return {
        ...state,
      };

    default:
      return state;
  }
};
