import "./style.scss";

import { ColorPicker } from "@samedaycustom/core-ui";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import { ReactComponent as Check } from "./checkedCircle.svg";

type TshirtSelectorProps = {
  currentState: Object;
  setCurrentState: React.Dispatch<React.SetStateAction<Object>>;
  name: string;
  label: string;
  image: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  groupName: string;
};

export const TshirtSelector: React.FC<TshirtSelectorProps> = ({
  currentState,
  setCurrentState,
  name,
  label,
  image: Image,
  groupName
}: TshirtSelectorProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const selectColorFunc = color => {
    setCurrentState({
      ...currentState,
      [groupName]: [...currentState[groupName], color.toUpperCase()],
      [name]: true
    });
  };

  const deselectortColorFunc = color => {
    setCurrentState({
      ...currentState,
      [groupName]: currentState[groupName].filter(
        col => col.toLowerCase() !== color.toLowerCase()
      ),
      [name]:
        currentState[groupName].filter(
          col => col.toLowerCase() !== color.toLowerCase()
        ).length < 1
          ? null
          : true
    });
  };

  return (
    <div className="tshirtBoxContainer">
      <Popup
        trigger={triggerProps => {
          return <div className="transparentAbsolute" />;
        }}
        contentStyle={{
          width: "unset",
          border: "none",
          padding: "0",
          height: "unset",
          borderRadius: "0px",
          boxShadow: "none",
          zIndex: 30000
        }}
        onClose={() => setMenuOpen(false)}
        repositionOnResize={true}
        arrow={false}
        offsetY={-50}
        offsetX={20}
        position="bottom right"
        closeOnDocumentClick={true}
      >
        {(closeFunc, isOpen) => (
          <ColorPicker
            colorArray={currentState[groupName]}
            selectClrFunc={selectColorFunc}
            deselectClrFunc={deselectortColorFunc}
          />
        )}
      </Popup>
      <div
        className={`tshirtBox ${
          currentState[groupName].length > 0 ? "activeTshirt" : ""
        }`}
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <h1> {label} </h1>
        {currentState[groupName] ? (
          currentState[groupName].length > 0 ? (
            <div className="colorGroup">
              {currentState[groupName].map(colr => (
                <div
                  className="colorBall"
                  style={{
                    backgroundColor: colr.toLowerCase(),
                    border: `1px solid ${colr === "WHITE" ? "#eee" : colr}`
                  }}
                />
              ))}
            </div>
          ) : null
        ) : null}
        <Image />
        {currentState[groupName] ? (
          currentState[groupName].length > 0 ? (
            <Check className="svgExpand" />
          ) : null
        ) : null}
      </div>
    </div>
  );
};

export default TshirtSelector;
