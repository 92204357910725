import React from "react";
import { Box } from "../../_components";
import {
  DefaultButton,
  PrimaryButton,
  PrimaryDangerButton,
  SecondaryDangerButton
} from "@samedaycustom/core-ui";
import { ModalContext } from "../provider/store";

export default ({ handleSubmit, isSubmitting, isValid }) => {
  const state = React.useContext(ModalContext);
  const buttontype = state.showForm?.okButtonType;
  const Button =
    (buttontype === "primary-danger" && PrimaryDangerButton) ||
    (buttontype === "secondary-danger" && SecondaryDangerButton) ||
    (buttontype === "primary" && PrimaryButton) ||
    PrimaryButton;

  return (
    (state.showForm && state.showForm.okText && (
      <Box className={`${state.showForm.isSingleButton ? `Modal__footer full-width ${state.showForm.SingleButtonClass}` : "Modal__footer"} ${state.showForm.noFooterBg ? "no-background" : ""}`}>
        {!state.showForm.isSingleButton && (<DefaultButton
          color="#486581"
          className={`${typeof state.showForm.cancelStyle === "string" ? state.showForm.cancelStyle : ""} ${state.showForm.showUpdatedCancelBtn ? "cancel-btn-updated" : ""}`}
          style={typeof state.showForm.cancelStyle !== "string" ? state.showForm.cancelStyle : null}
          isLoading={state.isCancelling}
          isDisabled={state.isCancelling}
          onClick={
            (state.onClose && state.onClose) ||
            (state.showForm && state.showForm.customCancelOnClick) ||
            (state.showForm && state.showForm.cancelOnClick) ||
            state.hide
          }
        >
          {state.showForm.cancelText || "Cancel"}
        </DefaultButton>)}
        <Button
          isLoading={isSubmitting}
          isDisabled={isSubmitting || !isValid}
          onClick={handleSubmit}
          className="ok-single-btn-style"
        >
          {state.showForm.okText}
        </Button>
      </Box>
    )) ||
    null
  );
};
