import "./style.scss";

import React from "react";

export interface INavigationHeader {
	title: string;
	sidebarOpen?: boolean;
	onSidebarOpen?: () => void;
	children?: React.ReactNode;
	renderMobileNav?: JSX.Element;
}
export default React.memo(
	({
		title,
		sidebarOpen,
		onSidebarOpen,
		children,
		renderMobileNav
	}: INavigationHeader) => {
		return (
			<div
				className={`header ${sidebarOpen ? "header--open" : ""}`}
				id="header"
			>
				<div className="header__leftContent">
					<div
						className="header__menuBarContainer"
						onClick={onSidebarOpen}
					>
						<div className="header__menuBar" />
					</div>
					<h2 className="header__heading"> {title} </h2>
				</div>
				<div className="navItemsGroup">
					<div className="hiddenOnMobileNav">
						{Math.max(
							document.documentElement.clientWidth || 0,
							window.innerWidth || 0
						) > 800
							? renderMobileNav
							: null}
					</div>
					{/*expandable search bar component*/}

					{/*   the page link items */}
					<div className="navItemCore">{children}</div>
				</div>
			</div>
		);
	}
);
