import { Box, Text } from "../_components";
import React from "react";

export const CountWrapper = React.memo(
  ({ number, unit }: { number: string | number; unit: string }) => (
    <Text d="flex" alignItems="flex-end" position="relative">
      <Text
        fontSize="32px"
        fontFamily="Inter"
        fontWeight={500}
        marginRight="0.3rem"
      >
        {number}
      </Text>
      <Box position="relative">
        <Text
          position="absolute"
          textTransform="uppercase"
          fontSize="12px"
          fontFamily="Inter Bold"
          bottom="8px"
        >
          {unit}
        </Text>
      </Box>
    </Text>
  )
);
