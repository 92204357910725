import "./style.scss";

import { Sidebar } from "@samedaycustom/core-ui";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Box } from "../../_components";
import { useLocation } from "react-router-dom";

type RenderHeaderFC = (
  sideBarOpen,
  onOpenSideBar: React.Dispatch<React.SetStateAction<boolean>>
) => JSX.Element;
type RenderSideChildrenFC = (props: {
  open;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fullyOpen?: boolean;
}) => JSX.Element;

export interface LayoutProps {
  children: React.ReactNode;
  renderSideBarChildren: RenderSideChildrenFC;
  renderSubChildren: JSX.Element;
  renderHeader: RenderHeaderFC;
}
const Layout = ({
  children,
  renderSideBarChildren,
  renderSubChildren,
  renderHeader
}: Partial<LayoutProps>) => {
  const loc = useLocation();
  const [sideBarOpen, setSideBarOpen] = useState(
    !(
      Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      ) < 1025
    )
  );
  const [fullyOpen, setFullyOpen] = useState(
    !(
      Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      ) < 1025
    )
  );
  // here information about the user would be fetched

  const setDocHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };
 
  useEffect(() => {
    setDocHeight();
    window.addEventListener("resize", _.debounce(setDocHeight, 300));
    window.addEventListener("orientationchange", setDocHeight);
   
    return () => {
      window.removeEventListener("resize", () => {});
      window.removeEventListener("orientationchange", () => {});
    };
  }, []);

  // using command key + \ to open or close the side bar
  useEffect(() => {
    window.addEventListener("keydown", sideBarKeyShortCut);
    return () => {
      window.removeEventListener("keydown", sideBarKeyShortCut);
    };
  }, [sideBarOpen]);

  function sideBarKeyShortCut(e: KeyboardEvent) {
    if (e?.code?.toLowerCase() === "backslash" && e?.metaKey) {
      setSideBarOpen(!sideBarOpen);
    }
  }

  const _renderChildren = React.useCallback(
    param => {
      return (
        renderSideBarChildren &&
        renderSideBarChildren({
          open: param.open,
          setOpen: param.setOpen,
          fullyOpen
        })
      );
    },
    [fullyOpen, renderSideBarChildren]
  );

  return (
    <div className="Layout">
      <Box
        className={
          sideBarOpen
            ? "SidebarContainer SidebarContainer--open"
            : "SidebarContainer"
        }
        id="SidebarContainer"
        onAnimationEnd={() => {
          if (sideBarOpen) setFullyOpen(true);
        }}
      >
        <Sidebar
          renderTopChilden={renderSubChildren}
          open={sideBarOpen}
          setOpen={condition => {
            if (!condition) setFullyOpen(false);
            setSideBarOpen(condition);
          }}
        >
          {_renderChildren}
        </Sidebar>
      </Box>
      <div
        className={`MainContainer ${
          sideBarOpen ? "MainContainer__sideOpen" : ""
        }`}
      >
        <div
          className={`sideBackGround ${
            sideBarOpen ? "sideBackGround--active" : null
          }`}
          onClick={() => {
            setSideBarOpen(false);
          }}
        ></div>
        {typeof renderHeader === "function"
          ? renderHeader(sideBarOpen, setSideBarOpen)
          : renderHeader}
        <div className={`${loc.pathname === '/settings/email-config' ? "" : "pageCount"}`}>
          <React.Fragment>{children}</React.Fragment>
        </div>
      </div>
    </div>
  );
};

export default Layout;
