import React from "react";
import "./style.scss";
import { ReactComponent as Check } from "../assets/checkedCircle.svg";

type CheckBoxProps = {
  label: string;
  checked: boolean;
  setCheckFunc: (val: boolean) => void;
};

const CheckBox: React.FC<CheckBoxProps> = ({
  label,
  checked,
  setCheckFunc
}: CheckBoxProps) => {
  return (
    <div
      className={`checkboxContainer ${checked ? "activeCheck" : ""}`}
      onClick={() => setCheckFunc(!checked)}
    >
      <span> {label} </span>
      {checked ? <Check className="svgExpand" /> : null}
    </div>
  );
};

export default CheckBox;
