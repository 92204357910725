import { ISDCOrder } from "@samedaycustom/types/order/@types/order";
import { ISDCAdminVendors } from "@samedaycustom/types/vendors";
import * as api from "admin/helpers/constants";
import request from "axios";
import { Dispatch } from "redux";
import { OrderListPaginationInfo } from "types/app/Operations";
import qs from "query-string";
import {
  FETCH_VENDOR,
  FETCH_VENDOR_LOCATIONS,
  FETCH_VENDOR_ORDERS,
  FETCH_VENDOR_ORDERS_META,
  FETCH_VENDORS,
  FETCHING_VENDOR,
  FETCHING_VENDOR_LOCATIONS,
  RESET_VENDOR_LOCATIONS,
  VENDORS_ACTION_TYPES,
} from "./type";
import { validate } from "uuid";
import { IStoreMinData } from "@samedaycustom/sdc-types";

// import { catchError } from "admin/providers/actions/app";
export const updateVendors = (vendors: ISDCAdminVendors[]): VENDORS_ACTION_TYPES => ({
  type: FETCH_VENDORS,
  payload: { vendors },
});

export const updateVendor = (vendor: ISDCAdminVendors): VENDORS_ACTION_TYPES => ({
  type: FETCH_VENDOR,
  payload: { vendor },
});

export const updateVendorOrders = (orders: ISDCOrder[]): VENDORS_ACTION_TYPES => ({
  type: FETCH_VENDOR_ORDERS,
  payload: { orders },
});
export const updateVendorOrdersMeta = (meta: OrderListPaginationInfo): VENDORS_ACTION_TYPES => ({
  type: FETCH_VENDOR_ORDERS_META,
  payload: { meta },
});

export const updateVendorLocations = (
  locations: IStoreMinData[],
  meta?: OrderListPaginationInfo
): VENDORS_ACTION_TYPES => ({
  type: FETCH_VENDOR_LOCATIONS,
  payload: { locations, meta },
});
export const resetVendorLocations = (): VENDORS_ACTION_TYPES => ({
  type: RESET_VENDOR_LOCATIONS,
});

export const fetchingVendor = (loading: boolean): VENDORS_ACTION_TYPES => ({
  type: FETCHING_VENDOR,
  payload: { loading },
});
export const fetchingVendorLocations = (loading: boolean): VENDORS_ACTION_TYPES => ({
  type: FETCHING_VENDOR_LOCATIONS,
  payload: { loading },
});

/**
 *
 * get single vendor
 */
export const getVendor = (id: string) => {
  return (dispatch: Dispatch, getState: () => any) => {
    const auth = getState().auth;

    dispatch(fetchingVendor(true));
    return request
      .get(`${api.VENDORS}/${id}`, {
        headers: {
          Authorization: "Bearer " + auth?.token,
        },
      })
      .then((result) => {
        if (result) {
          dispatch(fetchingVendor(false));
          const data: ISDCAdminVendors = result?.data?.data || result?.data;
          dispatch(updateVendor(data));
        }
      })
      .catch((err) => {
        dispatch(fetchingVendor(false));
        return Promise.reject(err);
      });
  };
};

export const reverifyEmail = (email: string): Promise<any> => {
  return request.post(
    `${api.EMAIL_REVERIFY}/`,
    { email },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

/**
 *
 * get single vendor  locations
 */
export const getVendorLocations = (
  vendorId: string,
  loadType?: "silent",
  isPartial?: boolean,
  orderId?: string,
  search?: string,
  page?: number,
  limit?: number
) => {
  return (dispatch: Dispatch, getState: () => any) => {
    const auth = getState().auth;

    loadType !== "silent" && dispatch(fetchingVendorLocations(true));

    const query = {};

    if (vendorId && validate(vendorId)) Object.assign(query, { vendor_id: vendorId });
    if (search) Object.assign(query, { search });
    if (isPartial) Object.assign(query, { name: true });
    if (orderId) Object.assign(query, { order_id: orderId || "" });
    if (page) Object.assign(query, { page });
    if (limit) Object.assign(query, { limit });

    return request
      .get(`${api.VENDOR_LOCATION}?${qs.stringify(query)}`, {
        headers: {
          Authorization: "Bearer " + auth?.token,
        },
      })
      .then((result) => {
        if (result) {
          loadType !== "silent" && dispatch(fetchingVendorLocations(false));
          const data: IStoreMinData[] = result?.data?.data || result?.data || [];
          const meta: OrderListPaginationInfo = {
            all: result?.data?.meta?.count,
            nextPage: result?.data?.meta?.nextPage,
            open: result?.data?.meta?.page,
            completed: 0,
          };
          dispatch(updateVendorLocations(data, meta));

          return Promise.resolve(data);
        }

        return Promise.resolve(null);
      })
      .catch((err) => {
        loadType !== "silent" && dispatch(fetchingVendorLocations(false));
        return Promise.reject(err);
      });
  };
};
