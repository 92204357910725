import React from "react";
import "./style.scss";
import logoImg from "../../assets/coreLogo.png";

const LoadingScreen = () => {
	return (
		<div className="whiteBackGround">
			<img src={logoImg} />
		</div>
	);
};

export default LoadingScreen;
