import { createAsyncThunk } from "@reduxjs/toolkit";
import { CUSTOMERS, ORDERS } from "admin/helpers/constants";

import { api } from "../../../api";

export const getOrderNotesCount = createAsyncThunk(
  "events/getOrderNotesCount",
  async (param: { order_id: string; business_id: string }) => {
    const response = await api
      .get(`${ORDERS}/${param?.order_id}/notes/count?business_id=${param?.business_id}`)
      .catch((err) => Promise.reject(err.response?.data?.message));
    return response.data?.data;
  }
);

export const getCustomerNotesCount = createAsyncThunk(
  "events/getCustomerNotesCount",
  async (customerId: string) => {
    const response = await api
      .get(`${CUSTOMERS}/${customerId}/notes/count`)
      .catch((err) => Promise.reject(err.response?.data?.message));
    return response.data?.data;
  }
);
