import React from "react";
import styles from "./ExpandableSearchBar.module.css";
import { ReactComponent as Search } from "../assets/search.svg";

const ExpandableSearchBar = () => {
	return (
		<div className={styles.search}>
			<Search />
			<input type="text" placeholder="Search" />
		</div>
	);
};

export default ExpandableSearchBar;
