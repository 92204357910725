import React from "react"
import ContentLoader from "react-content-loader"

interface Props {
    color?: string
    backgroundColor?: string
    speed?: number
    width?: number
    height?: number
}
const RowFormLoader = ({color, backgroundColor, height, speed, width}: Props) => (
    <ContentLoader
        speed={speed || 2}
        width={width || 400}
        height={height || 160}
        viewBox={`0 0 ${width || "400 "} ${height || "160"}`}
        backgroundColor={backgroundColor || "#f3f3f3"}
        foregroundColor={color || "#00ffff"}
    >
        <rect x="2" y="36" rx="5" ry="5" width="156" height="17" />
        <rect x="246" y="39" rx="4" ry="4" width="153" height="17" />
        <rect x="0" y="19" rx="0" ry="0" width="117" height="6" />
        <rect x="243" y="20" rx="0" ry="0" width="117" height="6" />
        <rect x="6" y="109" rx="0" ry="0" width="90" height="16" />
        <rect x="111" y="110" rx="0" ry="0" width="90" height="15" />
        <rect x="217" y="110" rx="0" ry="0" width="90" height="16" />
        <rect x="323" y="110" rx="0" ry="0" width="90" height="16" />
        <rect x="5" y="95" rx="0" ry="0" width="66" height="6" />
        <rect x="220" y="94" rx="0" ry="0" width="66" height="6" />
        <rect x="323" y="96" rx="0" ry="0" width="66" height="6" />
        <rect x="113" y="94" rx="0" ry="0" width="66" height="6" />
        <rect x="6" y="141" rx="0" ry="0" width="90" height="16" />
        <rect x="113" y="139" rx="0" ry="0" width="90" height="15" />
    </ContentLoader>
)

export default RowFormLoader
