import { ACTION_TYPE_DEFS } from "@samedaycustom/types/app";
import { ISDCAdminCustomers } from "@samedaycustom/types/customers";

export type CUSTOMER_DATA = ISDCAdminCustomers[];

export type CustomerData = Partial<ACTION_TYPE_DEFS<ISDCAdminCustomers>>;

export type ADMIN_CUSTOMER_STATE_TYPE = {
  customers: ACTION_TYPE_DEFS<CUSTOMER_DATA>;
  customer: ACTION_TYPE_DEFS<ISDCAdminCustomers>;
};
export const CUSTOMER_STATE: ADMIN_CUSTOMER_STATE_TYPE = {
  customers: {
    loading: false,
    error: false,
    errorMessage: null,
    data: [],
  },
  customer: {
    loading: false,
    error: false,
    errorMessage: null,
    data: null,
  },
};
