export type ORDERS_LIST_COUNT_STATE = {
    pendingCancellationOrders?: number;
    unassignedOrders?: number;
    confirmationMissingOrders?: number;
}

export const ORDERS_LIST_COUNT_INITIAL_STATE: ORDERS_LIST_COUNT_STATE = {
    pendingCancellationOrders: 0,
    unassignedOrders: 0,
    confirmationMissingOrders: 0,
};