import Pusher from "pusher-js";

// eslint-disable-next-line @typescript-eslint/no-var-requires
require("dotenv").config();

Pusher.logToConsole =
  process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "staging";

export const pusher = new Pusher(process.env.REACT_APP_PUSHER_API, {
  cluster: process.env.REACT_APP_PUSHER_CLUSTER || "us2",
  pongTimeout: 100,
});
