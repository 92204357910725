import ProgressBar from "@atlaskit/progress-bar";
import Style from "style-it";
import React from "react";
export default ({ value, height, color }) => {
	return Style.it(
		`
        .progress--bar div{
            height: ${height || "4px"} !important;
        }
        .progress--bar span{
            background: ${color} !important;
            width: ${value}% !important;
            height: ${height || "4px"} !important;
        }
        `,
		<div className="progress--bar">
			<ProgressBar {...{ value }} />
		</div>
	);
};
