import { useModal } from "@samedaycustom/core-ui";
import { AvailabilityStatus, AvailabilityTag } from "@samedaycustom/core-ui";
import { ISDCLocation } from "@samedaycustom/types/order/@types/order";
import React, { useCallback } from "react";
import { Box, Text } from "../../_components";
import LocationAvailableModalContent from "./LocationAvailableModalContent";
import { ReactComponent as Calendar } from "@samedaycustom/assets/images/calendar.svg";


interface LocationAvailableProp {
  title: string;
  status: AvailabilityStatus;
  onGetLocation?: () => Promise<ISDCLocation>;
  updateLocationNote?: (note: string) => Promise<void>;
  dueDate?: string;
}
const LocationAvailable: React.FC<LocationAvailableProp> = props => {
  const prevShow = React.useRef(false);
  const modal = useModal();

  const onclick = useCallback(async () => {
    modal.form({
      containerStyle: {
        maxWidth: "580px !important",
        width: "580px !important"
      },
      title: props.title,
      onClose: () => {
        prevShow.current = false;
        modal.hide();
      },
      component: (
        <LocationAvailableModalContent
          status={props.status}
          onGetLocation={props.onGetLocation}
          updateLocationNote={props.updateLocationNote}
        />
      )
    });
  }, [props]);
  return (
    <Box display={"flex"} gap={"8px"} alignItems={"center"}>
      {props.dueDate && (<Box display={"flex"} gap={"4px"}>
        <Calendar />
        <Text whiteSpace={"nowrap"} fontSize={"13px"} fontWeight={"500"} lineHeight={"16px"}>{props.dueDate}</Text>
      </Box>)}
      <AvailabilityTag status={props.status} onClick={onclick} />
    </Box>
  );
};

export default LocationAvailable;
