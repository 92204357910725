import "./style.scss";

import Select from "@atlaskit/select";
import React, { useEffect, useState } from "react";

type DropInputProps = {
  className?: string;
  asyncAction?: () => void;
  list: Array<{
    label: string;
    value: string;
  }>;
  placeholder: string;
  setValueFunc: (val) => void;
  loading: boolean;
  value?: any;
  isSearchable?: boolean;
  color?: string;
};

const DropdownInput: React.FC<DropInputProps> = ({
  asyncAction,
  list,
  placeholder,
  setValueFunc,
  loading,
  value,
  isSearchable
}: DropInputProps) => {
  const onChangeFunc = value => {
    setValueFunc(value);
  };

  useEffect(() => {
    if (list?.length < 1 && typeof asyncAction === "function") {
      asyncAction();
    }
  }, [list]);

  const [menuOpen, setMenuOpen] = useState(false);

  // each item in the list must have labels and values

  return (
    <div className={"InputGroup InputGroupMenu"}>
      <Select
        className="single-select customized-select"
        classNamePrefix="react-select"
        options={list}
        isLoading={loading}
        placeholder={placeholder}
        onChange={onChangeFunc}
        maxMenuHeight={150}
        value={value}
        onMenuOpen={() => setMenuOpen(true)}
        onMenuClose={() => setMenuOpen(false)}
        menuIsOpen={menuOpen}
        isSearchable={isSearchable ? isSearchable : true}
      />
    </div>
  );
};

export default DropdownInput;
