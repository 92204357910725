// in here we create an instance of the notification.
// and expose functions to
import { useSound } from "@samedaycustom/hooks";

import { VIEW_ORDER } from "admin/routes/screen";
import { useHistory, useLocation } from "react-router-dom";
import { generate } from "shortid";

type notificationType = {
  type: "order" | "message" | "unasssigned_order";
  orderDecoId: string;
  orderNumber: string;
  message: string;
  locationName?: string;
  locationId?: string;
  vendorId?: string;
  userType?: string;
};

export default (notificationSound: string) => {
  const history = useHistory();
  const { ping } = useSound(notificationSound);
  const { state } = useLocation();
  const notifyme = (notification: notificationType) => {
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
    }

    // Let's check whether notification permissions have already been granted
    else if (
      Notification.permission === "granted" &&
      !document.hasFocus() &&
      !(
        notification.type === "message" &&
        document.hasFocus() &&
        state?.orderID === notification.orderNumber
      )
    ) {
      // If it's okay let's create a notification
      let title = "";
      if (notification.type === "message") {
        title = `New Message ${
          notification?.orderDecoId
            ? `- ${notification?.orderDecoId}`
            : "" || notification?.locationName
            ? `- ${notification?.locationName}`
            : ""
        }`;
      } else if (notification.type === "unasssigned_order") {
        title = `Unassigned Order - ${notification?.orderDecoId}`;
      } else {
        title = `New Order - ${notification?.orderDecoId}`;
      }
      const notificationInstance = new Notification(title, {
        badge:
          "https://firebasestorage.googleapis.com/v0/b/vendors-265809.appspot.com/o/Hosted%20In-app%20Images%2FcoreLogo.png?alt=media&token=946cc47b-408d-4761-8b14-614ca84711fe",
        icon:
          "https://firebasestorage.googleapis.com/v0/b/vendors-265809.appspot.com/o/Hosted%20In-app%20Images%2FcoreLogo.png?alt=media&token=946cc47b-408d-4761-8b14-614ca84711fe",
        body:
          notification.message.length > 50
            ? notification.message.substring(0, 50) + "..."
            : notification.message,
        silent: true,
        renotify: true,
        tag: generate(),
        timestamp: Date.now(),
      });
      notificationInstance.onshow = () => {
        ping();
      };
      notificationInstance.onclick = () => {
        window.focus();
        notificationInstance.close.bind(notificationInstance);
        if (notification.type === "message") {
          history.push("/message", {
            orderID: notification.orderNumber,
            locationId: notification?.locationId,
            vendorId: notification?.vendorId,
            type: notification?.userType,
          });
        } else {
          history.push(`${VIEW_ORDER}/${notification.orderNumber}`);
        }
      };
      setTimeout(notificationInstance.close.bind(notificationInstance), 7000);
    }
  };

  return {
    notifyme,
  };
};
