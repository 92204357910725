import request from "axios";
import { Dispatch } from "redux";
import { ISDCAdminUser } from "@samedaycustom/types/user";
import * as api from "admin/helpers/constants";

import { FETCH_USER_PROFILE, FETCHING_USER_PROFILE, USERS_ACTION_TYPES } from "./type";

export const updateUserProfile = (profile: ISDCAdminUser): USERS_ACTION_TYPES => ({
  type: FETCH_USER_PROFILE,
  payload: { profile },
});

export const fetchingUserProfile = (loading: boolean): USERS_ACTION_TYPES => ({
  type: FETCHING_USER_PROFILE,
  payload: { loading },
});

/**
 *
 * get single user profile
 */
export const getUserProfile = () => {
  return (dispatch: Dispatch, getState: () => any) => {
    const auth = getState().auth;

    dispatch(fetchingUserProfile(true));
    return request
      .get(`${api.USERS}/profile`, {
        headers: {
          Authorization: "Bearer " + auth?.token,
        },
      })
      .then((result) => {
        if (result) {
          dispatch(fetchingUserProfile(false));
          const data: ISDCAdminUser = result?.data?.data || result?.data;
          dispatch(updateUserProfile(data));
        }
      })
      .catch((err) => {
        dispatch(fetchingUserProfile(false));
        return Promise.reject(err);
      });
  };
};
