import "./style.scss";
import "react-phone-number-input/style.css";

import { useField } from "formik";
import React from "react";
import {
  formatPhoneNumberIntl,
  isPossiblePhoneNumber
} from "react-phone-number-input";
import PhoneInput from "react-phone-number-input/input";
import { parsePhoneNumberFromString } from "libphonenumber-js";

interface Iprops {
  inputConfig: {
    label?: string;
    id?: string;
    placeholder?: string;
    name: string;
    type: string;
  };
  className?: string;
}

const PhoneNoInput = ({ inputConfig, className }: Iprops) => {
  const { name } = inputConfig;
  // const validate = value => {
  //   // if (value) {
  //   //   if (!isPossiblePhoneNumber(formatPhoneNumberIntl(value))) {
  //   //     return "Invalid phone number";
  //   //   }
  //   // }

  //   return null;
  // };
  const [, meta, helpers] = useField({ name });
  const { value } = meta;
  const { setValue, setTouched } = helpers;

  const changeFunc = value => {
    let formattedNumber = isPossiblePhoneNumber(value || "")
      ? formatPhoneNumberIntl(value || "")
      : value;
    setTouched(true);
    setValue(formattedNumber);
  };

  console.log(value);
  return (
    <div
      className={`InputGroup  ${className ? className : ""} ${
        meta.error && meta.touched ? "InputGroup--error" : ""
      }`}
    >
      {inputConfig.label ? (
        <label htmlFor={inputConfig.id}>{inputConfig.label}</label>
      ) : null}
      <PhoneInput
        country="US"
        defaultCountry="US"
        value={
          parsePhoneNumberFromString(value ?? "", "US")?.format("E.164") ??
          value
        }
        onChange={changeFunc}
        className="PhoneInput"
      />
      {meta.error && meta.touched ? (
        <p className="inputError"> {meta.error} </p>
      ) : null}
    </div>
  );
};

export default PhoneNoInput;
