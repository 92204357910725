import { Box, ButtonGroup, Flex, Grid, Spacer, Stack, Text, Textarea } from "../../_components";
import Skeleton from "@material-ui/lab/Skeleton";
import { ReactComponent as Location } from "@samedaycustom/assets/images/location_wl.svg";
import { ReactComponent as Phone } from "@samedaycustom/assets/images/phone_wl.svg";
import { ReactComponent as Timer } from "@samedaycustom/assets/images/timer_wl.svg";
import { ReactComponent as InfoIcon } from "@samedaycustom/assets/images/information_wl.svg";
import { ReactComponent as PrinterIcon } from "@samedaycustom/assets/images/printer-small.svg";
import { days, sortDaysFromMonday, PRINTING_METHODS_TITLE } from "@samedaycustom/helpers";
import { AvailabilityStatus, AvailabilityTag } from "@samedaycustom/core-ui";
import { ISDCLocation } from "@samedaycustom/types/order/@types/order";
import { Time } from "@samedaycustom/core-ui";
import { formatIncompletePhoneNumber } from "libphonenumber-js";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Button from "../../button";
import { ReactComponent as Copy } from "packages/assets/images/copy.svg";
import { useToast } from "../../../../hooks/src";

export interface WorkdayProp {
  isOpen: boolean;
  day: string;
  openTime: string;
  closeTime: string;
}
const WorkDay: React.FC<WorkdayProp> = (props) => (
  <Box fontSize="14px">
    <Text lineHeight="20px" color="#102A43">
      {props.day}
    </Text>
    {props.isOpen ? (
      <Text lineHeight="20px" color="#486581">
        {props.openTime} - {props.closeTime}
      </Text>
    ) : (
      <Text fontWeight={600} lineHeight="20px">
        CLOSED
      </Text>
    )}
  </Box>
);

interface ContentInfo {
  address: string;
  mobile: string;
  tzone: string;
  workDays: WorkdayProp[];
  note: string;
  id: string;
  availablePrintingMethods: string[];
}
export interface LocationAvailableModalContentProp {
  status: AvailabilityStatus;
  onGetLocation: () => Promise<ISDCLocation>;
  updateLocationNote?: (note: string) => Promise<void>;
}
const LocationAvailableModalContent: React.FC<LocationAvailableModalContentProp> = (props) => {
  const [loading, setloading] = useState(false);
  const [location, setLocation] = useState<ContentInfo>({
    address: "",
    mobile: "",
    tzone: "",
    workDays: [],
    note: "",
    id: "",
    availablePrintingMethods: []
  });
  useEffect(() => {
    fetchLocation();
  }, []);
  const toast = useToast();

  async function fetchLocation() {
    try {
      setloading(true);
      const location = await props.onGetLocation();
      const { id: locationId } = location;

      const daysKey = Object.keys(location?.workDays || {}).map((o) => days[o]);
      const sortedDays = sortDaysFromMonday(daysKey);
      const printingMethods = location?.printingMethods || {};
      const availablePrintingMethods = Object.keys(printingMethods)
        .filter(key => printingMethods[key] === true)
        .map(key => PRINTING_METHODS_TITLE[key]);


      const workDays: WorkdayProp[] = sortedDays.map((o) => {
        const workDay = location?.workDays[o.substring(0, 3).toLowerCase()];

        const openTime = moment(
          moment().format("MM/DD/YYYY") + ` ${workDay?.startHour12}:${workDay?.startMinute12}`
        );

        const closeTime = moment(
          moment().format("MM/DD/YYYY") + ` ${workDay?.endHour12}:${workDay?.endMinute12}`
        );

        const isOpen = workDay?.open;

        return {
          isOpen,
          day: o,
          openTime: `${openTime.format("hh:mm")} ${workDay?.startUnit}`,
          closeTime: `${closeTime.format("hh:mm")} ${workDay?.endUnit}`,
        };
      });

      setLocation({
        address: `${location?.address1}, ${location?.city}, ${location?.zipcode}, ${
          location?.countryCode || location?.country
        }`,
        mobile: location?.mobile,
        tzone: location?.tzone,
        workDays,
        note: location?.note,
        id: locationId,
        availablePrintingMethods
      });
      setloading(false);
    } catch (error) {
      setloading(false);
    }
  }

  const onCopy = (url: string) => {
    navigator.clipboard.writeText(url);
    toast({
      title: "Success",
      description: "Number copied successfully",
      position: "bottom-left",
      status: "success",
      duration: 1000,
      isClosable: true,
    });
    return;
  };

  return (
    <Box width="100%">
      <Box display="inline-flex" width="100%" alignItems="center" justifyContent="space-between">
        <Box display="inline-flex" alignItems="center">
          <Location width="30px" height="30px" style={{ paddingRight: "14px" }} />
          {loading ? (
            <Skeleton variant="rect" width="108px" height="20px" />
          ) : (
            <Text textTransform="capitalize">{location?.address?.toLowerCase()}</Text>
          )}
        </Box>
        <AvailabilityTag status={props.status} />
      </Box>
      <Box paddingBottom="8px" display="inline-flex" width="100%" alignItems="center">
        <Phone width="30px" height="30px" style={{ paddingRight: "14px" }} />
        {loading ? (
          <Skeleton variant="rect" width="108px" height="20px" />
        ) : (
            <Box display={"flex"} alignItems={"center"} gap={"6px"}>
              <Text color="#007BFF" textDecoration="underline">
                <a href={`tel:+${location.mobile}`}>
                  {formatIncompletePhoneNumber(location.mobile, "US")}
                </a>
              </Text>
              <Copy onClick={() => onCopy(formatIncompletePhoneNumber(location.mobile, "US"))} width={"18px"} height={"18px"} style={{ cursor: "pointer" }} />
            </Box>
        )}
      </Box>
      <Box display="inline-flex" width="100%" alignItems="flex-start">
        <Timer width="30px" height="30px" style={{ paddingRight: "14px" }} />
        <Grid gridTemplateColumns="1fr 1fr 1fr" width="100%" gridGap="10px">
          {loading ? (
            <Skeleton variant="rect" width="108px" height="20px" />
          ) : Array.isArray(location?.workDays) && location?.workDays?.length ? (
            location.workDays.map((o) => <WorkDay {...o} />)
          ) : (
            "-"
          )}
        </Grid>
      </Box>
      <Box marginLeft="30px" marginTop="10px">
        {loading ? (
          <Skeleton variant="rect" width="108px" height="20px" />
        ) : (
          <React.Fragment>
            <Text
              color="#243B53"
              fontSize="14px"
              fontWeight={500}
              lineHeight="20px"
              textTransform="capitalize"
            >
              {location.tzone?.toLowerCase()}
            </Text>
            <Time timezone={location?.tzone}>
              {({ date, time }) => <Text>Current Location Time: {`${date} ${time}`}</Text>}
            </Time>
          </React.Fragment>
        )}
      </Box>
      <Box  display="inline-flex" width="100%"  marginTop="10px" alignItems="flex-start">
        <PrinterIcon width="30px" height="20px" style={{ paddingRight: "14px" }} />
        {loading ? (
          <Skeleton variant="rect" width="108px" height="20px" />
        ) : (
          <Text>{location.availablePrintingMethods.join(", ") || "No printing methods set"}</Text>
        )}
      </Box>
      <Flex marginTop="10px">
        <InfoIcon width="30px" height="20px" style={{ paddingRight: "14px", fill: "black" }} />
        {loading ? (
          <Skeleton variant="rect" width="108px" height="20px" />
        ) : (
          <Box flex={"1"}>
            <LocationNote
              note={location?.note}
              updateLocationNote={(note) => {
                props.updateLocationNote(note);
                setLocation({ ...location, note: note });
              }}
            />
          </Box>
        )}
      </Flex>
    </Box>
  );
};

/**
 *
 * @param param0 If the note is empty, show the edit note button
 *
 */
type LocationNoteProps = {
  note: string;
  updateLocationNote?: (note: string) => void;
};
const LocationNote = ({ note, updateLocationNote }: LocationNoteProps) => {
  const [noteState, setNoteState] = useState<"show" | "edit">("show");
  let [noteValue, setNoteValue] = React.useState(note);

  const handleEditNote = () => {
    setNoteState("edit");
  };

  const handleInputChange = (e) => {
    let inputValue = e.target.value;
    setNoteValue(inputValue);
  };

  const handleSaveNote = () => {
    setNoteState("show");
    updateLocationNote && updateLocationNote(noteValue);
  };

  return (
    <Box>
      {noteState === "show" && (
        <Text paddingRight={"10px"}  fontWeight={400} lineHeight="20px">
          {note}
        </Text>
      )}

      {noteState === "show" ? (
        <Text
          color="#007BFF"
          textDecoration="underline"
          cursor={"pointer"}
          onClick={handleEditNote}
        >
          {note ? "Edit Note" : "Add Note"}
        </Text>
      ) : (
        // Set default content to note
        <Box>
          <Textarea
            width={"100%"}
            placeholder="Add a note"
            value={noteValue}
            onChange={handleInputChange}
          />
          <Flex paddingTop={"10px"}>
            <Spacer />
            <ButtonGroup gap="2">
              <Button buttonType={"default"} onClick={() => setNoteState("show")}>
                Cancel
              </Button>
              <Button buttonType={"secondary"} onClick={handleSaveNote}>
                Save note
              </Button>
            </ButtonGroup>
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default LocationAvailableModalContent;
