import "./style.scss";

import { Box, BoxProps } from "../_components";
import React from "react";

export interface Radio extends BoxProps {
  value?: string;
  isSelected: boolean;
  onSelected?: (value) => void;
  inverted?: boolean;
}
export const Radio: React.FC<Radio> = ({
  isSelected,
  onSelected,
  inverted,
  ...props
}) => {
  const SelectRadio = () => {
    onSelected && onSelected(!isSelected);
  };
  return (
    <Box
      aria-label="radio button"
      className={`radio-button ${inverted ? "inverted" : ""} ${
        isSelected ? "show" : "hide"
      }`}
      onClick={SelectRadio}
      {...props}
    />
  );
};
