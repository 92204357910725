import {
  VENDORS_ACTION_TYPES,
  FETCH_VENDORS,
  FETCH_VENDOR,
  FETCHING_VENDOR,
  FETCH_VENDOR_ORDERS,
  FETCH_VENDOR_LOCATIONS,
  RESET_VENDOR_LOCATIONS,
  FETCH_VENDOR_ORDERS_META,
  FETCHING_VENDOR_LOCATIONS,
} from "admin/providers/actions/vendors/type";

import { ADMIN_VENDOR_STATE_TYPE, VENDOR_STATE } from "./initialState";

export const VENDORS = (
  state = VENDOR_STATE,
  action: VENDORS_ACTION_TYPES
): ADMIN_VENDOR_STATE_TYPE => {
  switch (action.type) {
    case FETCH_VENDORS:
      return {
        ...state,
        vendorsList: {
          ...state.vendorsList,
          data: action.payload.vendors,
        },
      };
    case FETCH_VENDOR:
      return {
        ...state,
        vendor: {
          ...state.vendor,
          data: action.payload.vendor,
        },
      };
    case FETCHING_VENDOR:
      return {
        ...state,
        vendor: {
          ...state.vendor,
          loading: action.payload.loading,
        },
      };
    case FETCH_VENDOR_ORDERS:
      return {
        ...state,
        vendor: {
          ...state.vendor,
          orders: {
            ...state.vendor.orders,
            data: action.payload.orders,
          },
        },
      };
    case FETCH_VENDOR_ORDERS_META:
      return {
        ...state,
        vendor: {
          ...state.vendor,
          orders: {
            ...state.vendor.orders,
            all: action.payload.meta?.all ?? 0,
            ...(action.payload.meta && action.payload.meta),
          },
        },
      };

    case FETCH_VENDOR_LOCATIONS:
      return {
        ...state,
        vendor: {
          ...state.vendor,
          locations: {
            ...state.vendor.locations,
            data: action.payload.locations,
            metadata: action.payload.meta,
          },
        },
      };

    case RESET_VENDOR_LOCATIONS:
      return {
        ...state,
        vendor: {
          ...state.vendor,
          locations: {
            ...state.vendor.locations,
            data: [],
          },
        },
      };
    case FETCHING_VENDOR_LOCATIONS:
      return {
        ...state,
        vendor: {
          ...state.vendor,
          locations: {
            ...state.vendor.locations,
            loading: action.payload.loading,
          },
        },
      };

    default:
      return state;
  }
};
