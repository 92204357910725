import "./style.scss";

import { Box, Placement, Text } from "../_components";
import { ReactComponent as HOption } from "@samedaycustom/assets/images/horizotnal-option.svg";
import React, { CSSProperties, useRef, useState } from "react";
import Popup from "reactjs-popup";

import { ReactComponent as VOption } from "./vertical-option.svg";

export type OptionsListParams = {
  leftIcon?: React.ReactSVGElement | React.ReactElement;
  text?: string | React.ReactElement;
  listStyle?: CSSProperties;
  disabled?: boolean;
  showSeperator?: boolean;
  /**
   * Accept a closeFunction as a parameter
   * that can be called to close the function
   */
  onClick: (closeFun?: Function) => void;
};
export interface OptionsListProps {
  h?: boolean;
  Icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >;
  options: Array<OptionsListParams>;
  disableOption?: boolean;
  position?: Placement;
  contentStyle?: React.CSSProperties;
}
export const Option = ({
  options,
  disableOption,
  h,
  position,
  Icon,
  contentStyle
}: OptionsListProps) => {
  const ref = useRef();
  const closeTooltipFun = () => {
    ref?.current?.closePopup?.()
  };
  const OptionsContent =
    options &&
    options.map(el => el ? (
      <Box
        d="flex"
        flexDirection="row"
        alignItems="center"
        padding="10px"
        width="100%"
        borderBottom={el.showSeperator && "1px solid #D9E2EC"}
        onClick={() => el.onClick(closeTooltipFun)}
        style={el.listStyle}
        cursor={el.disabled ? "not-allowe" : "pointer"}
        pointerEvents={el.disabled ? "none" : "auto"}
        className="optionListItem"
      >
        {el.leftIcon && (
          <Box
            d="flex"
            alignItems="center"
            justifyContent="center"
            height="20px"
            width="30px"
            position="relative"
            paddingRight="5px"
          >
            {el.leftIcon}
          </Box>
        )}
        <Text
          textTransform="capitalize"
          color={el?.disabled ? "#829AB1" : "#102A43"}
          fontSize="0.9rem"
          fontWeight={
            contentStyle?.fontWeight || el?.listStyle?.fontWeight || 500
          }
        >
          {el.text}
        </Text>
      </Box>
    ) : null);

  let OptionIcon = h ? HOption : VOption;

  return (
    <Popup
      position={(position as any) || "bottom right"}
      offsetX={25}
      offsetY={15}
      arrow={false}
      ref={ref}
      contentStyle={{
        left: "1100.33px",
        width: "unset",
        border: "none",
        padding: "0",
        height: "unset",
        borderRadius: "4px",
        boxShadow: "0px 6px 18px rgba(98, 125, 152, 0.24)",
        ...contentStyle
      }}
      closeOnDocumentClick={true}
      trigger={() =>
        Icon ? (
          Icon
        ) : (
          <OptionIcon
            height="25px"
            width="16px"
            style={{
              cursor: disableOption ? "not-allowed" : "pointer",
              pointerEvents: disableOption ? "none" : "all"
            }}
          />
        )
      }
    >
      <React.Fragment>{OptionsContent}</React.Fragment>
    </Popup>
  );
};
