import {
  HIDE_MODAL,
  SHOW_MODAL,
  SHOW_MODAL_FORM,
  SUBMITTING_MODAL,
  CANCELLING_MODAL,
  VALUE_CHANGE
} from "../actions/types";
import { ModalFormType, ModalContextType } from "../../index.d";

export interface IModalAction {
  type:
    | typeof HIDE_MODAL
    | typeof SHOW_MODAL
    | typeof SHOW_MODAL_FORM
    | typeof SUBMITTING_MODAL
    | typeof VALUE_CHANGE
    | typeof CANCELLING_MODAL;
  modalType: "success" | "danger" | "warning" | "text";
  show: boolean;
  duration: number;
  message: string;
  form: ModalFormType;
  onClose?: (e: any) => void;
  showModal?: () => void;
  hideModal?: () => void;
  bodyStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  component?: React.ReactNode | React.ReactElement | any;
  isSubmitting: boolean;
  isCancelling: boolean;
  value?: any;
}
export default (state, action: Partial<IModalAction>): ModalContextType => {
  switch (action.type) {
    case HIDE_MODAL:
      return {
        ...state,
        open: false,
        showForm: {
          ...state.showForm,
          isOpen: false,
          component: null,
          title: null,
          okText: null,
          cancelText: null
        }
      };

    case SHOW_MODAL:
      return {
        ...state,
        containerStyle: action?.containerStyle,
        modalType: action.modalType,
        message: action.message,
        onClose: action.onClose,
        component: action?.component,
        bodyStyle: action?.bodyStyle,
        open: true,
        duration: action.duration
      };

    case SHOW_MODAL_FORM:
      return {
        ...state,
        containerStyle: action?.form?.containerStyle,
        modalType: null,
        showForm: {
          isOpen: true,
          ...action.form
        },
        open: true,
        duration: action.duration
      };

    case SUBMITTING_MODAL:
      return {
        ...state,
        isSubmitting: action.isSubmitting
      };

    case CANCELLING_MODAL:
      return {
        ...state,
        isCancelling: action.isCancelling
      };

    case VALUE_CHANGE:
      return {
        ...state,
        showForm: {
          values: action.value,
          ...action.form
        }
      };

    default:
      return state;
  }
};
