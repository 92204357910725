import "./style.scss";

import { ReactComponent as Cog } from "@samedaycustom/assets/images/cog-b.svg";
import { ReactComponent as Labels } from "@samedaycustom/assets/images/labels.svg";
import { ReactComponent as StoreLocationImage } from "@samedaycustom/assets/images/store-location.svg";
import {
  ReactComponent as LogoutImg,
  ReactComponent as Logout,
} from "@samedaycustom/assets/images/logoutFlyout.svg";
import { ReactComponent as MailSpecial } from "@samedaycustom/assets/images/mailSpecial.svg";
import { ReactComponent as Note } from "@samedaycustom/assets/images/note-b.svg";
import { ReactComponent as OperationImg } from "@samedaycustom/assets/images/Operation.svg";
import { ReactComponent as StorImage } from "@samedaycustom/assets/images/store.svg";
import { ReactComponent as Cart } from "@samedaycustom/assets/images/cart.svg";
import { ReactComponent as UsersImage } from "@samedaycustom/assets/images/users-o.svg";
import { ReactComponent as BrandIcon } from "@samedaycustom/assets/images/brand-icon.svg";
import { ReactComponent as NotePlain } from "@samedaycustom/assets/images/note-plain.svg";
import { ReactComponent as TemplateIcon } from "@samedaycustom/assets/images/template.svg";
import { ISideBar, RowTag, SidebarLink, Text, Box } from "@samedaycustom/core-ui";
import * as appActions from "admin/providers/actions/app";
import { logout } from "admin/providers/actions/auth";
import { MessagesState } from "admin/providers/features/message";
import {
  CUSTOMERS,
  DELIVERIES,
  LOCATIONS,
  ORDERS,
  PAYMENTS,
  SETTINGS,
  VENDORS,
  ABANDONED_CARTS,
  BRANDS,
  LOCATION_PAGES,
  TEMPLATES,
} from "admin/routes/screen";
import React from "react";
import { DefaultRootState, useDispatch, useSelector } from "react-redux";

import { VIEW_ORDER } from "../../routes/screen";

const SideBarChildren = ({ open, setOpen }: ISideBar) => {
  const dispatch = useDispatch();
  const onSideBarClose = () => {
    Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) < 1025 &&
      setOpen(false);
  };
  const appState = useSelector((state) => state["app"]);

  const messagesStore = useSelector<DefaultRootState, MessagesState>((state) => state["message"]);

  const _logout = () => dispatch(logout());
  const messageCount = React.useMemo(() => {
    return (
      messagesStore?.customer_threads?.newMessageCount +
      messagesStore?.vendor_threads?.newMessageCount
    );
  }, [
    messagesStore?.customer_threads?.newMessageCount,
    messagesStore?.vendor_threads?.newMessageCount,
  ]);

  return (
    <div className="sideLinksList">
      {/* <SidebarLink
				image={DashboardImg}
				label="Dashboard"
				targetUrl="/dashboard"
				notificationCount={0}
				sidebarOpen={open}
				setSidebarOpen={setOpen}
				closeFunc={onSideBarClose}
			/> */}
      <SidebarLink
        image={MailSpecial}
        label="Messages"
        targetUrl={"/message"}
        notificationItem={open && messageCount ? <RowTag tag="new" /> : null}
        notificationCount={!open && messageCount ? messageCount : null}
        sidebarOpen={open}
        setSidebarOpen={setOpen}
        closeFunc={onSideBarClose}
      />
      <SidebarLink
        image={OperationImg}
        label={"Orders"}
        targetUrl={ORDERS}
        childLinks={appState.recentOrders.map((order: { orderId: any; orderNumber: any }) => ({
          id: order.orderId,
          label: order.orderNumber,
          targetUrl: [`${VIEW_ORDER}/${order.orderId}`],
        }))}
        onRemoveChild={(id: any, orderNumber: any) => {
          dispatch(
            appActions.removeRecentOrder({
              orderId: id,
              orderNumber,
            })
          );
        }}
        sidebarOpen={open}
        setSidebarOpen={setOpen}
        closeFunc={onSideBarClose}
      />
      <SidebarLink
        image={Labels}
        label="Deliveries"
        targetUrl={DELIVERIES}
        notificationCount={0}
        sidebarOpen={open}
        setSidebarOpen={setOpen}
        closeFunc={onSideBarClose}
      />
      {/* <SidebarLink
        image={StorImage}
        label="Vendors"
        targetUrl={VENDORS}
        notificationCount={0}
        sidebarOpen={open}
        setSidebarOpen={setOpen}
        closeFunc={onSideBarClose}
      /> */}
      <SidebarLink
        image={Cart}
        label="Abandoned Carts"
        targetUrl={ABANDONED_CARTS}
        notificationCount={0}
        sidebarOpen={open}
        setSidebarOpen={setOpen}
        closeFunc={onSideBarClose}
      />
      <SidebarLink
        image={StoreLocationImage}
        label="Store Locations"
        targetUrl={LOCATIONS}
        notificationCount={0}
        sidebarOpen={open}
        setSidebarOpen={setOpen}
        closeFunc={onSideBarClose}
      />
      <SidebarLink
        image={TemplateIcon}
        label="Design Templates"
        targetUrl={TEMPLATES}
        notificationCount={0}
        sidebarOpen={open}
        setSidebarOpen={setOpen}
        closeFunc={onSideBarClose}
      />
      <SidebarLink
        image={UsersImage}
        label="Customers"
        targetUrl={CUSTOMERS}
        notificationCount={0}
        sidebarOpen={open}
        setSidebarOpen={setOpen}
        closeFunc={onSideBarClose}
      />
      {/* <SidebarLink
        image={Note}
        label="Payouts"
        targetUrl={PAYMENTS}
        notificationCount={0}
        sidebarOpen={open}
        setSidebarOpen={setOpen}
        closeFunc={onSideBarClose}
      /> */}
      <SidebarLink
        image={BrandIcon}
        label="Brands"
        targetUrl={BRANDS}
        notificationCount={0}
        sidebarOpen={open}
        setSidebarOpen={setOpen}
        closeFunc={onSideBarClose}
      />
      <SidebarLink
        image={NotePlain}
        label="Location Pages"
        targetUrl={LOCATION_PAGES}
        notificationCount={0}
        sidebarOpen={open}
        setSidebarOpen={setOpen}
        closeFunc={onSideBarClose}
      />

      {/* <SidebarLink
				image={UsersImage}
				label="Customers"
				targetUrl={"/customers"}
				notificationCount={0}
				sidebarOpen={open}
				setSidebarOpen={setOpen}
				closeFunc={onSideBarClose}
			/>
			<SidebarLink
				image={UsersImage}
				label="Admin Users"
				targetUrl={"/admins"}
				notificationCount={0}
				sidebarOpen={open}
				setSidebarOpen={setOpen}
				closeFunc={onSideBarClose}
			/> */}

      <div className="sideBar-smallScreens">
        {/* <p
					className={`sideBar__groupHeading ${
						open ? "sideBar__groupHeading--open" : ""
					}`}
				>
					settings
				</p> */}
        <div className="hiddenOnMobile">
          {/* <SidebarLink
						image={UserImg}
						label="Users & Permissions"
						targetUrl={USERS_AND_PERMISSIONS}
						notificationCount={0}
						closeFunc={onSideBarClose}
						sidebarOpen={open}
						setSidebarOpen={setOpen}
					/>
					<SidebarLink
						image={StoreLocationImg}
						label="Store Locations"
						targetUrl={STORE_LOCATION}
						notificationCount={0}
						closeFunc={onSideBarClose}
						sidebarOpen={open}
						setSidebarOpen={setOpen}
					/>
				</div>
				<SidebarLink
					image={Business}
					label="Business Profile"
					targetUrl={BUSINESS_PROFILE}
					notificationCount={0}
					closeFunc={onSideBarClose}
					sidebarOpen={open}
					setSidebarOpen={setOpen}
				/> */}

          <SidebarLink
            image={StorImage}
            label="Vendors"
            targetUrl={VENDORS}
            notificationCount={0}
            sidebarOpen={open}
            setSidebarOpen={setOpen}
            closeFunc={onSideBarClose}
          />
          <SidebarLink
            image={Cart}
            label="Abandoned Carts"
            targetUrl={ABANDONED_CARTS}
            notificationCount={0}
            sidebarOpen={open}
            setSidebarOpen={setOpen}
            closeFunc={onSideBarClose}
          />
          <div className="flyoutItem" onClick={_logout}>
            <LogoutImg />
            <span className="logoutText">Log Out</span>
          </div>
        </div>
      </div>
      <Box className="bottomGroup" bottom="5vh !important">
        {/* <SidebarLink
          image={Cog}
          label="Settings"
          targetUrl={SETTINGS}
          notificationCount={0}
          sidebarOpen={open}
          setSidebarOpen={setOpen}
          closeFunc={onSideBarClose}
        /> */}
        <SidebarLink
          image={Logout}
          label={
            <Text color="#EF4E4E" fontWeight={500} fontSize="14px">
              Log out
            </Text>
          }
          targetUrl={""}
          notificationCount={0}
          closeFunc={_logout}
          sidebarOpen={open}
          setSidebarOpen={setOpen}
        />
      </Box>
    </div>
  );
};

export default SideBarChildren;
