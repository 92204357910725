import {
  FAIL_IMAGE_UPLOAD_ACTION,
  IMAGE_UPLOAD_ACTION,
  IMAGE_UPLOAD_STATE_RESET,
  START_IMAGE_UPLOAD_ACTION,
  UPLOAD_ACTIONS_TYPE,
} from "../../../providers/actions/imageUploads/actionTypes";
import { IMAGE_UPLOADS_STATE } from "types/app";

export const imageUploadState: IMAGE_UPLOADS_STATE = {
  loading: false,
  error: false,
  errorMessage: "",
  done: false,
  type: "",
  imageUrl: "",
};

export default (state = imageUploadState, action: UPLOAD_ACTIONS_TYPE) => {
  switch (action.type) {
    case START_IMAGE_UPLOAD_ACTION:
      return {
        ...state,
        loading: true,
        type: action.payload,
        error: false,
        errorMessage: "",
        done: false,
      };
    case FAIL_IMAGE_UPLOAD_ACTION:
      return {
        ...state,
        loading: false,
        done: false,
        error: true,
        type: action.payload.type,
        errorMessage: action.payload.msg,
      };
    case IMAGE_UPLOAD_ACTION:
      return {
        ...state,
        loading: false,
        done: true,
        error: false,
        errorMessage: "",
        imageUrl: action.payload,
      };
    case IMAGE_UPLOAD_STATE_RESET: {
      return {
        ...state,
        loading: false,
        done: false,
        error: false,
        errorMessage: "",
        type: "",
      };
    }
    default:
      return state;
  }
};
