import { useMediaQuery as cUseMediaQuery } from "@chakra-ui/react";
import momentTz from "moment-timezone";
import React, { useEffect, useMemo } from "react";

export { default as UpdateDirtyComponent } from "./UpdateDirtyComponent";
export { default as useDebounce } from "./useDebounce";
export { default as useIFrame } from "./useIFrame";
export * from "./useInternetStatus";
export { default as useMeasure } from "./useMeasure";
export { usePrevious } from "./usePrevious";
export { default as useScrollBottom } from "./useScrollBottom";
export { default as useScrollRestoration } from "./useScrollRestoration";
export { default as useSocket } from "./useSocket";
export { default as useSound } from "./useSound";
export { default as useToast } from "./useToast";
export { default as useToploader } from "./useToploader";
export { default as useUpload } from "./useUpload";
export { default as useWindowFocus } from "./useWindowFocus";
export { default as useWindowSize } from "./useWindowSize";

export const useMediaQuery = cUseMediaQuery;
export type IuseUploadType = "image" | "other" | "doc";

export const useBlockBodyScroll = open => {
  function exit() {
    const scrollY = document.body.style.top;
    document.body.style.position = "";
    document.body.style.top = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
  }
  useEffect(() => {
    if (typeof open === "boolean" && open === true) {
      document.body.style.position = "fixed";
      document.body.style.top = `-${window.scrollY}px`;
    } else {
      exit();
    }
    return () => exit();
  }, [open]);
  return null;
};

export const useDate = (timezone?: string) => {
  const locale = "en";
  const [today, setDate] = React.useState(
    momentTz()
      .tz(timezone)
      ?.toDate() || new Date()
  ); // Save the current date to be able to trigger an update

  const timeZone = React.useMemo(() => timezone, [timezone]);

  useEffect(() => {
    const timer = setInterval(
      () =>
        setDate(
          momentTz()
            .tz(timeZone)
            ?.toDate() || new Date()
        ),
      1000
    );
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
  }, [timeZone]);

  const day = today.toLocaleDateString(locale, { weekday: "long" });
  const date = `${day}, ${today.getDate()} ${today.toLocaleDateString(locale, {
    month: "long"
  })}\n\n`;

  const hour = today.getHours();
  const wish = `Good ${(hour < 12 && "Morning") ||
    (hour < 17 && "Afternoon") ||
    "Evening"}, `;

  const time = useMemo(() => {
    const date = momentTz(today).tz(timeZone) || momentTz(today);
    return date?.format("hh:mm A");
  }, [timeZone, today]);

  return {
    date,
    time,
    wish
  };
};
