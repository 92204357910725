import { NoteValue } from "@samedaycustom/types/order/@types/notes";
import React from "react";

/**
 *
 *
 * type decalration
 */
export type NoteState = {
  showNoteInput: boolean;
  noteValue: Partial<NoteValue>;
};

export type NoteAction = {
  type: "SHOW_NOTE_INPUT" | "HIDE_NOTE_INPUT";
  value?: NoteValue;
};

export type NoteContextType = {
  noteValue: Partial<NoteValue>;
  showNoteInput: boolean;
  onHideNoteInput: () => void;
  onShowNoteInput: (noteValue?: NoteValue) => void;
};

/***
 *
 * initialization of states
 */
const initialNoteValue: Partial<NoteValue> = {
  message: "",
  lineId: "",
  type: "GENERAL",
  files: [],
  isEditing: false
};
const initialState: NoteState = {
  noteValue: initialNoteValue,
  showNoteInput: false
};

/**
 *
 *
 * context declaration
 */
export const NoteContext = React.createContext<NoteContextType>({
  noteValue: initialNoteValue,
  showNoteInput: false,
  onHideNoteInput: () => {},
  onShowNoteInput: (noteValue?: NoteValue) => {}
});

/**
 *
 * @param state
 * reducer
 * @param action
 */
export const NoteReducer = (state: NoteState, action: NoteAction) => {
  switch (action.type) {
    case "SHOW_NOTE_INPUT":
      console.log("SHOW_NOTE_INPUT", { ...state.noteValue, ...action.value });
      return {
        ...state,
        showNoteInput: true,
        noteValue: { ...state.noteValue, ...action.value }
      };
    case "HIDE_NOTE_INPUT":
      return {
        ...state,
        showNoteInput: false,
        noteValue: initialNoteValue
      };

    default:
      return state;
  }
};

/**
 *
 * @param param0
 *
 * provider component
 */
export const NoteProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(NoteReducer, initialState);
  const onHideNoteInput = () => dispatch({ type: "HIDE_NOTE_INPUT" });
  const onShowNoteInput = (noteValue?: NoteValue) =>
    dispatch({ type: "SHOW_NOTE_INPUT", value: noteValue });

  return (
    <NoteContext.Provider
      value={{ ...state, onHideNoteInput, onShowNoteInput }}
    >
      {children}
    </NoteContext.Provider>
  );
};

export default NoteProvider;
