import React from 'react'
import './style.scss'
import Skeleton from '@material-ui/lab/Skeleton'


export default () => {
     return (
        <div className="MessageLoader__container">
            <div className="MessageLoader__main">
                <Skeleton/>
                <Skeleton/>
            </div>
            <Skeleton width={35}/>
        </div>
     )
}

