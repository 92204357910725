import "./style.scss";

import { Box, ChakraInput as Input, Text } from "../../_components";
import {
  BroadCastInput,
  BroadcastInput,
  BroadCastBubble,
  BroadcastMessageContainer,
} from "@samedaycustom/core-ui";
import { FormikHelpers } from "formik";
import React, { useContext } from "react";
import { BroadCastMessageInput, BroadCastType, BroadCastValue } from "types/app";

import ViewBar from "../ViewBar";
import Provider, { BroadCastContext } from "./context";
import { ReactComponent as Search } from "@samedaycustom/assets/images/LandingImages/search.svg";

// export * from "@samedaycustom/core-ui";

export interface BroadCastProp extends BroadcastInput {
  show: boolean;
  messages: BroadcastMessageContainer[];
  onSend: (
    inputprops: BroadCastMessageInput,
    formikHelpers?: FormikHelpers<{
      type: BroadCastType;
      title: string;
      message: string;
      business_id: string;
      location_id: string;
    }>
  ) => Promise<void | BroadCastMessageInput>;
  title?: string;
  loading?: boolean;
  onClose?: () => void;
  getMessages?: () => void;
  onSearch?: (value: string) => void;
}
const Note = ({ show, title, onClose, loading, ...props }: BroadCastProp) => {
  return (
    <ViewBar
      show={show}
      closeFunc={onClose}
      heading={title}
      width="600px"
      style={{
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingTop: "0px",
      }}
      noPortal={true}
      onFullyShown={props.getMessages}
      containerClassName="BroadCast__ViewBar"
      animateInClass="BroadCastFadeIn"
      animateOutClass="BroadCastFadeOut"
      className="BroadCast__bg"
    >
      <Provider>
        <React.Fragment>
          <BroadCastBody loading={loading} {...props} />
          <BroadCastFooter {...props} />
        </React.Fragment>
      </Provider>
    </ViewBar>
  );
};

function BroadCastBody({ messages, loading, ...props }) {
  const [value, setvalue] = React.useState("");
  const { onShowInput } = useContext(BroadCastContext);
  const onSearch = React.useCallback(
    (e) => {
      setvalue(e);
      if (typeof props?.onSearch === "function") props.onSearch(e);
    },
    [props?.onSearch]
  );

  return (
    <React.Fragment>
      <Box background="#fff" padding="15px 15px" position="absolute" width="100%" zIndex={1000}>
        <Box
          background="#F0F4F8"
          borderRadius="4px"
          display="inline-flex"
          alignItems="center"
          padding="10px 10px"
          width="100%"
          height="40px"
        >
          <Search fill="#829AB1" />
          <Input
            outline="none"
            width="100%"
            background="#F0F4F8"
            border="none"
            paddingLeft="10px"
            fontSize="14px"
            placeholder="Search broadcast messages..."
            aria-placeholder="Search broadcast messages..."
            _placeholder={{ color: "#829AB1" }}
            onChange={(e) => onSearch(e.target.value)}
          />
        </Box>
      </Box>
      <Box
        paddingTop="70px !important"
        height="calc((var(--vh, 1vh) * 100) - 200px) !important"
        className="broadcast__messageView__ContentContainer"
      >
        {loading ? (
          <Text>Loading</Text>
        ) : (
          Array.isArray(messages) && (
            <Box id="note_view" className="broadcast__chatListContainer__fluid">
              {Array.isArray(messages) &&
                messages
                  .search(["message", "title"], value)
                  .map((el: BroadcastMessageContainer) => (
                    <Box marginBottom="20px">
                      <BroadCastBubble
                        key={el?.id}
                        {...el}
                        onResend={(current) => {
                          //  to change later
                          onShowInput({
                            message: current.message,
                            business_id: current?.business_id,
                            location_id: current?.location_id,
                            type: current?.type,
                            title: current?.title,
                            refresh: false,
                          });
                        }}
                      />
                    </Box>
                  ))}
            </Box>
          )
        )}
      </Box>
    </React.Fragment>
  );
}
function BroadCastFooter(props) {
  const context = useContext(BroadCastContext);
  return (
    <BroadCastInput
      initialValue={context.message as BroadCastValue}
      onShowInput={context.onShowInput}
      onHideInput={context.onHideInput}
      showInput={context.showInput}
      onSend={props.onSend}
      onVendorChange={props.onVendorChange}
      getVendors={props.getVendors}
    />
  );
}

export default Note;
