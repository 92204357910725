import "./style.scss";

import { Box, Checkbox, Stack, Text } from "../../_components";
import { DefaultCheck, RowTag } from "@samedaycustom/core-ui";
import { ISDCMessages } from "@samedaycustom/types/app/Messaging";
import moment from "moment";
import React, { useRef } from "react";

export interface ChatListItemProps extends ISDCMessages {
  decoOrderId: any;
  vendorID?: string;
  // isSelected?: boolean
}

export interface ChatListProps {
  value: ChatListItemProps;
  onClick?: (value: ChatListItemProps) => void;
  active: boolean;
  hasNewMessage?: boolean;
  title: string;
  lastMessageContent: string;
  onItemSelect?: (id: string) => void;
  isSelected?: boolean;
}

export default ({ value, onClick, active, hasNewMessage, ...props }: ChatListProps) => {
  let lastMessageContent = React.useMemo(() => {
    if (props?.lastMessageContent) {
      return props?.lastMessageContent;
    } else {
      return "Start a new conversation";
    }
  }, [props?.lastMessageContent]);

  const ref = useRef(null);

  const truncateString = (text: string) =>
    (text?.length > 30 && text?.substring(0, text?.length - 3).concat("...")) || text;

  return (
    <Box
      className={`__item ${active ? "active-Message" : ""}`}
      background={"#fff"}
      cursor="pointer"
      ref={ref}
    >
      <Box className="seenIcon">
        {" "}
        {hasNewMessage && value?.unread?.store && value?.unread?.store > 0 ? (
          <div className="msg-bluedot"></div>
        ) : null}
      </Box>
      <Stack direction={"row"}>
        <DefaultCheck
          label=""
          checked={props.isSelected}
          height={20}
          width={20}
          onSelect={() => {
            value?.orderID
              ? props?.onItemSelect(value?.orderID)
              : props?.onItemSelect(value?.locationID);
          }}
        />
        <Box className="textDetails" onClick={() => onClick(value)}>
          <Text
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textAlign: "left",
            }}
            fontWeight={500}
            fontFamily="Inter"
          >
            {truncateString(props?.title)}
          </Text>
          <div className="textHolder">
            <Text
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textAlign: "left",
                width: "100%",
                // maxWidth: "250px"
              }}
              fontFamily="Inter"
              marginTop="2px"
              lineHeight="20px"
            >
              {lastMessageContent}
            </Text>
          </div>
        </Box>
      </Stack>

      <Box overflow="hidden">
        {value?.lastMessageAt && (
          <Text
            wordBreak="break-all"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
            fontFamily="Inter"
            color="#627D98"
          >
            {moment(new Date(value?.lastMessageAt), "YYYYMMDD").fromNow()}
          </Text>
        )}
        {hasNewMessage && <RowTag tag="new" />}
      </Box>
    </Box>
  );
};
