import { Box, Text } from "../../_components";
import { ReactComponent as Send } from "@samedaycustom/assets/images/bulk-send.svg";
import { BroadCastType } from "@samedaycustom/types/app";
import moment from "moment";
import React from "react";

export interface BroadcastMessageContainer {
  id: string;
  message: string;
  business_name?: string;
  location_name?: string;
  business_id: string;
  location_id: string;
  refresh: boolean;
  type: BroadCastType;
  title: string;
  timestamp: Date | string;
}
export interface IBroadCastBubble extends BroadcastMessageContainer {
  onResend: (currentMessage: BroadcastMessageContainer) => void;
}

export default ({ onResend, ...props }: IBroadCastBubble) => {
  const _type = React.useMemo(() => {
    console.log(props);
    switch (props.type) {
      case "all":
        return "All Vendor";

      case "vendor":
        return `${props?.business_name?.toLowerCase()} - All Locations`;

      case "vendor-location":
        return `${props?.business_name?.toLowerCase()} - ${props?.location_name?.toLowerCase()}`;

      default:
        return "All Vendor";
    }
  }, [props]);

  return (
    <Box
      background="#E5F1FF"
      borderRadius="0px 32px"
      width="100%"
      padding="14px 20px 14px 16px"
    >
      <Box
        display="inline-flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Text
          color="#102A43"
          fontSize="14px"
          fontWeight={500}
          lineHeight="20px"
          textTransform="capitalize"
          isTruncated
        >
          {props.title}
        </Text>
        <Text
          color="#486581"
          fontSize="13px"
          fontWeight={500}
          lineHeight="18px"
        >
          {props?.timestamp &&
            moment(props.timestamp).format("ddd, DD MMM hh:mm")}
        </Text>
      </Box>
      <Text
        textTransform="capitalize"
        color="#627D98"
        fontWeight={500}
        fontSize="13px"
        lineHeight="18px"
      >
        {_type}
      </Text>
      <Box
        color="#102A43"
        lineHeight="20px"
        fontSize="14px"
        paddingTop="12px"
        paddingBottom="20px"
      >
        {props?.message}
      </Box>
      <Box>
        <Box
          display="inline-flex"
          as="button"
          alignItems="center"
          onClick={() => onResend(props)}
        >
          <Send />
          <Text
            color="#007BFF"
            fontWeight={500}
            paddingLeft="4px"
            fontSize="14px"
          >
            Resend
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
