import "./style.scss";

import { Box, BoxProps, Text } from "../_components";
import { ReactComponent as Warning } from "./assets/warning.svg";
import React from "react";
import { generate } from "shortid";
import { animated, useSpring } from "react-spring";

export interface IBanner extends BoxProps {
  message: string | JSX.Element;
  backgroundColor?: string;
  color?: string;
  type?: "error" | "warning" | "success";
  show?: boolean;
  containerStyle?: React.CSSProperties;
  iconStyle?: { style: React.CSSProperties };
  customIcon?: any;
}

export const Banner = React.memo(
  ({ message, backgroundColor, color, type, show, ...props }: IBanner) => {
    const { y, opacity } = useSpring({
      from: {
        y: 0,
        opacity: 0
      },
      y: show ? 5 : 0,
      opacity: show ? 10 : 0,
      config: { duration: 500 }
    });

    let Icon;
    switch (type) {
      case "warning":
        Icon = <Warning {...props.iconStyle} />;
        break;

      default:
        break;
    }
    return (
      <animated.div
        className={`banner-container`}
        style={{
          transform: y.interpolate(
            // @ts-ignore
            y => `translateY(${y}%)`
          ),
          pointerEvents: "none",
          opacity,
          ...props?.containerStyle
        }}
        key={generate()}
      >
        <Box
          className="--content"
          background={backgroundColor}
          boxShadow="0px 6px 18px rgba(98, 125, 152, 0.24)"
          {...props}
        >
          <Box
            display="inline-flex"
            alignItems="center"
            padding={["5px", "2rem"]}
          >
            {props.customIcon ? <props.customIcon /> : Icon}
            <Text
              paddingLeft="6px"
              color={color}
              fontFamily="Inter"
              fontWeight={500}
              letterSpacing="0.02em"
            >
              {message}
            </Text>
          </Box>
        </Box>
      </animated.div>
    );
  }
);
