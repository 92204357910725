import "../style.scss";

import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { generate } from "shortid";

import { DynamicTableContext } from "..";
import DynamicBody from "../DynamicBody";
import DynamicCell from "../DynamicCell";
import DynamicHead from "../DynamicHead";
import DynamicRow from "../DynamicRow";

export interface IDynamicTableFoldeContainer {
  rowColumnTemplate?: string;
  headColumnTemplate?: string;
  isOpenable?: boolean;
}
export default ({ isOpenable }: IDynamicTableFoldeContainer) => {
  const dynamictablecontextvalue = React.useContext(DynamicTableContext);

  /**
   *
   * render head
   */
  const _head = dynamictablecontextvalue?.head
    ? dynamictablecontextvalue.head.cells.map((o, i) => (
        <DynamicCell h width={o.width} key={generate()}>
          {o.content}
        </DynamicCell>
      ))
    : null;

  /**
   *
   * render row
   *  and sub content of row
   */
  const _row = React.useMemo(() => {
    return dynamictablecontextvalue?.rows
      ? dynamictablecontextvalue?.rows.map((o, i) => {
          const cell = o.cells.map((e, i) => (
            <DynamicCell
              width={e.width || dynamictablecontextvalue.head.cells[i].width}
              key={generate()}
              onClick={e.onClick}
            >
              {e.content}
            </DynamicCell>
          ));
          return (
            <DynamicRow
              id={o.key}
              hideArrow={isOpenable === false}
              key={generate()}
              subchildren={o.subcontent}
              gridColumnTemplate={dynamictablecontextvalue.rowColumnTemplate}
            >
              {cell}
            </DynamicRow>
          );
        })
      : null;
  }, [dynamictablecontextvalue.isAllOpen, dynamictablecontextvalue.rows]);

  /**
   *
   *
   * toggle all row
   *
   * and set opened tables to null
   */
  const _ontoggle = React.useCallback(() => {
    dynamictablecontextvalue.setisAllOpen(!dynamictablecontextvalue.isAllOpen);
    dynamictablecontextvalue.setOpenedTables([]);
  }, [dynamictablecontextvalue.isAllOpen]);

  /**
   *
   *
   * check if all or one is open visual cue
   */
  // BUG
  const isOpenCue = React.useMemo(() => {
    return (
      dynamictablecontextvalue.isAllOpen ||
      dynamictablecontextvalue.openedTables.length >= 1
    );
  }, [
    dynamictablecontextvalue.openedTables,
    dynamictablecontextvalue.isAllOpen
  ]);

  return (
    <Box marginTop="11px" className="dynamicfoldtable dtable_container">
      <Box d="flex" justifyContent="space-between" width="100%">
        <Box>
          {isOpenable && (
            <Text
              fontFamily="Inter"
              width="80px"
              cursor="pointer"
              fontWeight={500}
              color="#334E68"
              onClick={_ontoggle}
            >
              {isOpenCue ? "Hide all" : "Show all"}
            </Text>
          )}
          {dynamictablecontextvalue?.renderLeftContent &&
            dynamictablecontextvalue.renderLeftContent}
        </Box>
        {dynamictablecontextvalue?.renderRightContent &&
          dynamictablecontextvalue.renderRightContent}
      </Box>
      <DynamicHead>{_head}</DynamicHead>
      {_row ? (
        <DynamicBody>{_row}</DynamicBody>
      ) : dynamictablecontextvalue.emptyView ? (
        dynamictablecontextvalue.emptyView
      ) : (
        <p>No items found</p>
      )}
    </Box>
  );
};
