import React from "react";
import { Box, Text } from "../../_components";

import { ReactComponent as Artwork } from "../assets/artwork-notification.svg";
import { ReactComponent as Chat } from "../assets/chat-notification.svg";
import { ReactComponent as Order } from "../assets/order-notification.svg";
import { ReactComponent as Payment } from "../assets/payment-notification.svg";
import moment from "moment";

export type INotificationItemListMessages = {
  text: string | React.ReactElement;
  date: Date;
  type: "order" | "payment" | "message" | "artwork";
}[];

export interface INotificationItemList {
  messages: INotificationItemListMessages;
  date?: Date;
}

export default ({ messages, date }: INotificationItemList) => {
  const formatIcon = type => {
    switch (type) {
      case "order":
        return <Order />;
      case "artwork":
        return <Artwork />;
      case "message":
        return <Chat />;
      case "payment":
        return <Payment />;

      default:
        return null;
    }
  };
  const dateInWords = moment().calendar(date, {
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    nextWeek: "dddd",
    lastDay: "[Yesterday]",
    lastWeek: "[Last] dddd",
    sameElse: "dddd MM, YYYY"
  });
  const formatTime = date => {
    const { hours, minutes, seconds } = moment(date).toObject();
    // console.log(moment(date).toObject())

    // if (minutes <= 1) return "1m"
    // if (hours <= 12) return `${hours}h`
    // if (seconds <= 60) return `${seconds}s`

    return moment(date).format("hh:mm A");
  };
  // const dateInTime = moment(message.date).format("h:mm:ss")
  return (
    <Box className="notificationList__container">
      <Text
        padding="1rem 1.2rem 0.5rem 1.2rem"
        color="#829AB1"
        fontSize="13px"
        lineHeight="16px"
        fontWeight={500}
        fontStyle="normal"
        fontFamily="Inter"
      >
        {dateInWords}
      </Text>
      {messages.map((el, i) => (
        <Box key={i} className="notificationList__content">
          <Text paddingRight="0.5rem">{formatIcon(el.type)}</Text>
          <Box>
            <Text color="#102A43" lineHeight="2rem">
              {el.text}
            </Text>
            <Text
              color="#627D98"
              fontFamily="Inter"
              fontWeight={500}
              fontSize="0.8rem"
            >
              {formatTime(el.date)}
            </Text>
          </Box>
        </Box>
      ))}
    </Box>
  );
};
