import { Text } from "@chakra-ui/react";
import React from "react";
import Style from "style-it";

export type IDynamicCellType = {
  key: string | number;
  width?: number | string;
  onClick?: () => void;
  style?: React.CSSProperties;
  content: React.ReactElement[] | React.ReactElement | string;
};
export interface IDynamicCell {
  h?: boolean;
  width?: number | string;
  children?: React.ReactElement[] | React.ReactElement | string;
  onClick?: () => void;
}
export default ({ children, onClick, width, h }: IDynamicCell) => {
  if (h)
    return Style.it(
      `.dth{width:${width}}`,
      <th className="dth" onClick={onClick}>
        <Text textAlign="left" width={"100%"}>
          {children}
        </Text>
      </th>
    );
  return (
    <td width={width} onClick={onClick}>
      <Text textAlign="left" width={width}>
        {children}
      </Text>
    </td>
  );
};
