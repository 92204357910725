import { Box, Text } from "../_components";
import { ChatBubble } from "@samedaycustom/core-ui";
import React from "react";

import { MessageProp } from "./@types";

const MessageContent: React.FC<MessageProp> = React.memo(props => {
  return (
    <Box
      className="textContainer messages"
      height={`calc((var(--vh, 1vh) * 100) - 20px)`}
    >
      {props.loading ? (
        <Text> Loading... </Text>
      ) : (
        <Box id="message_view" className="chatListContainer">
          {Array.isArray(props.messages) &&
            props.messages.map((c, id) => {
              const oldMessageFound = props.messages.find(
                o => o.id === c?.originalMessageID
              );
              const oldMessage = Object.keys(oldMessageFound ?? {}).length
                ? {
                    ...oldMessageFound,
                    options: {
                      ...oldMessageFound?.options,
                      ...c?.options
                    }
                  }
                : {};

              return (
                <ChatBubble
                  user={props.user}
                  key={id}
                  message={c}
                  oldMessage={oldMessage}
                />
              );
            })}
        </Box>
      )}
    </Box>
  );
});

MessageContent.defaultProps = {
  loading: false,
  messages: []
};
export { MessageContent };
