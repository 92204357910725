import "./style.scss";

import React from "react";

import { MessageBodyProp } from "./@types";
import { MessageContent } from "./MessageContent";

export const MessageBody: React.FC<MessageBodyProp> = React.memo(props => {
  return (
    <MessageContent
      loading={props.loading}
      messages={props.messages}
      user={props.user}
    />
  );
});
