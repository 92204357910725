import { Box, Text } from "../../_components";
import { ReactComponent as Bell } from "@samedaycustom/assets/images/attention-b.svg";
import { ReactComponent as CloseIcon } from "@samedaycustom/assets/images/closeModal.svg";
import { capitalizeFirstLetter } from "@samedaycustom/helpers";
import React from "react";
import { BroadCastValue } from "types/app";
import { useClearCache } from "react-clear-cache";
import Button from "../../button";

interface BroadCastAttentionProp extends BroadCastValue {
  onClose: () => void;
}
const BroadcastAttention: React.FC<BroadCastAttentionProp> = props => {
  const { emptyCacheStorage } = useClearCache();
  const _clearCacheAndReload = async () => {
    await emptyCacheStorage();
    window.location.reload(true);
    props.onClose();
  };
  return (
    <Box height="400px" display="inline-flex" width="100%">
      <Box
        background={props.refresh ? "#007BFF" : "#EF4E4E"}
        display="flex"
        flexDirection="column"
        padding="30px"
        width="240px"
        borderRadius="8px 0px 0px 8px"
      >
        <Box
          color="#fff"
          display="flex"
          flexDir="column"
          justifyContent="space-between"
          height="100%"
        >
          <Box>
            <Text
              fontSize="36px"
              paddingBottom="20px"
              fontWeight={600}
              lineHeight="32px"
              letterSpacing="0.01em"
            >
              Attention
            </Text>
            <Text
              fontSize="14px"
              textTransform="uppercase"
              fontWeight={600}
              lineHeight="32px"
              letterSpacing="0.01em"
            >
              Subject
            </Text>
            <Text
              lineHeight="32px"
              fontSize="24px"
              fontWeight="bold"
              letterSpacing="0.01em"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
            >
              {capitalizeFirstLetter(props.title.toLowerCase())}
            </Text>
          </Box>
          <Box>
            <Bell style={{ marginTop: "10px" }} />
          </Box>
        </Box>
      </Box>
      <Box width="100%" overflow="hidden">
        <Box padding="10px 20px" float="right">
          <CloseIcon fill="#000" height="50px" onClick={props.onClose} />
        </Box>
        <Box
          display="flex"
          flexDir="column"
          justifyContent="flex-start"
          height="100%"
        >
          <Text
            color="#102A43"
            lineHeight="40px"
            padding="0px 20px"
            marginTop="30px"
            fontSize="28px"
            height={props.refresh ? "80%" : "100%"}
            overflowY="auto"
            overflowX="hidden"
          >
            {props.message}
          </Text>
          {props.refresh && (
            <Button
              height="48px"
              buttonType="default"
              width="174px"
              margin="20px"
              onClick={_clearCacheAndReload}
            >
              Update system
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BroadcastAttention;
