import { ProductionSpeed } from "@samedaycustom/types/app/Settings";
import {
  DELETE_PRODUCTION_SPEED,
  EDIT_PRODUCTION_SPEED,
  GET_PRODUCTION_SPEED,
  UPDATE_PRODUCTION_SPEED,
} from "admin/providers/actions/productionSpeed/types";
import { generate } from "shortid";

export const ProductionSpeedInitialStateData: ProductionSpeed = {
  id: generate(),
  maxQty: 0,
  minQty: 0,
  time: 0,
  timeUnit: "hour",
  upperBound: 0,
  superRushOrder: {
    timeUnit: "hour",
    time: 0,
    cost: 0,
    available: false,
    costUnit: "percent",
  },
  rushOrder: {
    available: false,
    costUnit: "percent",
    timeUnit: "hour",
    time: 0,
    cost: 0,
  },
  isDefault: true,
};

export const ProductionSpeedState: {
  loading: boolean;
  type?:
    | typeof DELETE_PRODUCTION_SPEED
    | typeof GET_PRODUCTION_SPEED
    | typeof EDIT_PRODUCTION_SPEED
    | typeof UPDATE_PRODUCTION_SPEED;
  done: boolean;
  error: boolean;
  errorMessage: string;
  data: ProductionSpeed[];
  value?: ProductionSpeed;
} = {
  loading: false,
  done: false,
  error: false,
  errorMessage: "",
  data: [],
  value: ProductionSpeedInitialStateData,
};
