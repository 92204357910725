import "./style.scss";

import Spinner from "@atlaskit/spinner";
import { SearchableList } from "@samedaycustom/core-ui";
import { ReactComponent as DropIcon } from "../assets/dropDownIcon.svg";
import React, { useState } from "react";
import Popup from "reactjs-popup";

interface IProps {
  onClick: (val) => void;
  onSearch?: (val) => void;
  listItems: Array<{ label; additionalLabel?; value }>;
  value: string;
  loading: boolean;
  placeholder: string;
  className?: string;
  label: string;
}

export default ({
  onClick,
  listItems,
  value,
  loading,
  className,
  label,
  placeholder,
  onSearch
}: IProps) => {
  const [searchValue, setSeachValue] = useState<string>("");

  const SearchFunc = (val): void => {
    setSeachValue(val);
    onSearch && onSearch(SearchFunc);
  };

  return (
    <>
      <Popup
        position="bottom center"
        offsetX={50}
        offsetY={15}
        on="click"
        onClose={(): void => {
          setSeachValue("");
        }}
        arrow={false}
        contentStyle={{
          width: "unset",
          border: "none",
          padding: "0",
          height: "unset",
          borderRadius: "4px",
          boxShadow: "0px 6px 18px rgba(98, 125, 152, 0.24)"
        }}
        closeOnDocumentClick={true}
        trigger={() => {
          return Array.isArray(listItems) &&
            listItems.find(item => item.value === value) ? (
            <div
              style={{
                pointerEvents: listItems?.length < 2 ? "none" : "all",
                cursor: listItems?.length < 2 ? "not-allowed" : "pointer"
              }}
            >
              <Popup
                on="hover"
                trigger={() => {
                  return (
                    <div className={`FDropDown__Container ${className ?? ""}`}>
                      <div className="FDropDown__LabelContainer">
                        <div className="FDropDown__LabelContainer--text">
                          {Array.isArray(listItems) &&
                          listItems.find(item => item.value === value) ? (
                            <span>
                              {" "}
                              {listItems.find(item => item.value === value)
                                .label?.length > 40
                                ? listItems
                                    .find(item => item.value === value)
                                    .label.substring(0, 40) + " ..."
                                : listItems.find(item => item.value === value)
                                    .label}{" "}
                            </span>
                          ) : placeholder?.length > 40 ? (
                            placeholder.substring(0, 40) + "..."
                          ) : (
                            placeholder
                          )}
                        </div>
                        <div className="FDropDown__LabelContainer--icon">
                          {loading ? (
                            <Spinner size="small" />
                          ) : (
                            <DropIcon className="dropIcon" fill="#334E68" />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }}
                offsetY={10}
                position="bottom center"
                closeOnDocumentClick={true}
                arrow={false}
                contentStyle={{
                  width: "unset",
                  border: "none",
                  zIndex: 5,
                  padding: "0",
                  height: "unset",
                  borderRadius: "4px",
                  boxShadow: "0px 5px 10px rgba(98, 125, 152, 0.1)"
                }}
              >
                <span className="tooltipContainer">
                  {listItems.find(item => item.value === value).label}{" "}
                  {listItems.find(item => item.value === value).additionalLabel
                    ? "- " +
                      listItems.find(item => item.value === value)
                        .additionalLabel
                    : ""}
                </span>
              </Popup>
            </div>
          ) : (
            <div
              className={`FDropDown__Container ${className ?? ""}`}
              style={{
                pointerEvents:
                  Array.isArray(listItems) && listItems.length < 2
                    ? "none"
                    : "all"
              }}
            >
              <div className="FDropDown__LabelContainer">
                <div className="FDropDown__LabelContainer--text">
                  {placeholder.length > 40
                    ? placeholder.substring(0, 40) + "..."
                    : placeholder}
                </div>
                <div className="FDropDown__LabelContainer--icon">
                  {loading ? <Spinner size="small" /> : <DropIcon />}
                </div>
              </div>
            </div>
          );
        }}
      >
        {closeFuncInner => {
          return (
            <div className="innerPopContainer">
              <SearchableList
                styles={{
                  width: "280px"
                }}
                searchFunc={SearchFunc}
                inputPlaceholder="Search for a location"
              >
                {loading ? (
                  <div className="loaderHolder">
                    <Spinner size="small" />
                  </div>
                ) : Array.isArray(listItems) &&
                  listItems.flterExtended(searchValue, [
                    "label",
                    "additionalLabel"
                  ])?.length > 0 ? (
                  Array.isArray(listItems) &&
                  listItems
                    .flterExtended(searchValue, ["label", "additionalLabel"])
                    .map(item => (
                      <div
                        className={`flyoutItem__inner searchList-Item ${
                          item.value.toLowerCase() === value?.toLowerCase()
                            ? "active--item"
                            : ""
                        }`}
                        onClick={() => {
                          closeFuncInner();
                          onClick(item.value);
                          setSeachValue("");
                        }}
                      >
                        {item.label?.length > 40 ? (
                          <Popup
                            on="hover"
                            trigger={() => {
                              return (
                                <span className="miniFlyoutItem noWrap">
                                  {item.label.substring(0, 40)}
                                  ...{" "}
                                  {item.additionalLabel
                                    ? `- ${item.additionalLabel}`
                                    : ""}
                                </span>
                              );
                            }}
                            // offsetY={10}
                            position="bottom center"
                            closeOnDocumentClick={true}
                            arrow={false}
                            contentStyle={{
                              width: "unset",
                              border: "none",
                              padding: "0",
                              height: "unset",
                              borderRadius: "4px",
                              boxShadow: "0px 5px 10px rgba(98, 125, 152, 0.1)"
                            }}
                          >
                            <span className="tooltipContainer">
                              {item.label}
                            </span>
                          </Popup>
                        ) : (
                          <span className="miniFlyoutItem noWrap">
                            {item.label}{" "}
                            {item.additionalLabel
                              ? ` - ${item.additionalLabel}`
                              : ""}
                          </span>
                        )}
                      </div>
                    ))
                ) : (
                  <p className="noResults"> No {label} found. </p>
                )}
              </SearchableList>
            </div>
          );
        }}
      </Popup>
    </>
  );
};
