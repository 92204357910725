import React from "react";
import "./style.scss";
import { ReactComponent as RemoveIcon } from "../assets/removeIcon.svg";

interface Props {
	label: string;
	clickFunc: () => void;
	className?: string;
	iconColor?: string;
}

export default ({ label, clickFunc, className, iconColor }: Props) => {
	return (
		<div className={`labelContainer ${className ? className : ""}`}>
			<span>
				{label.length > 15 ? label.substring(0, 15) + " ..." : label}
			</span>
			<div className="additionalFunctionBox" onClick={clickFunc}>
				{iconColor ? <RemoveIcon fill={iconColor} /> : <RemoveIcon />}
			</div>
		</div>
	);
};
