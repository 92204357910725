import "./style.scss";

import React from "react";

export interface ICircleProgressProps {
	progress: number;
	progressColor?: string;
	showPercentage?: boolean;
	progressWidth?: number;
	radius?: number;
	width?: number;
	height?: number;
	progressText?: any;
	progressTextStyle?: any;
	progressStyle?: any;
	backgroundColor?: string;
}
export default ({
	progress = 0,
	progressColor = "black",
	backgroundColor,
	progressTextStyle,
	showPercentage = true,
	progressWidth = 4,
	progressStyle,
	progressText,
	radius = 52,
	height = 40,
	width = 40
}: ICircleProgressProps) => {
	const circumference = radius * 2 * Math.PI;
	const offset = circumference - (progress / 100) * circumference;
	const innerOffSet = circumference - (100 / 100) * circumference;

	return (
		<div className="progressCircleContainer" style={progressStyle}>
			<div className="--reading">
				<p style={progressTextStyle}>
					{progressText}
					{showPercentage && "%"}
				</p>
			</div>
			<svg className="progress-ring" style={progressStyle}>
				<circle
					className="progress-ring__circle"
					stroke={backgroundColor || "#ddd"}
					fill="transparent"
					strokeWidth={progressWidth}
					style={{ position: "absolute", top: 0, zIndex: -1 }}
					r={radius}
					cx={width}
					cy={height}
					strokeDasharray={`${circumference} ${circumference}`}
					strokeDashoffset={innerOffSet}
				/>
				<circle
					className="progress-ring__circle"
					stroke={progressColor}
					strokeWidth={progressWidth}
					strokeLinecap="round"
					r={radius}
					fill="transparent"
					cx={width}
					cy={height}
					strokeDasharray={`${circumference} ${circumference}`}
					strokeDashoffset={offset}
				></circle>
				{/* <circle
                    r={radius / 9.5}
                    cx={width / 2 - 2 - 0.5}
                    cy={height / 2 - 0.5}
                    fill={progressColor}
                    strokeWidth={progressWidth}
                    className="progress-ring__dot"
                    id="progress-ring__dot"
                /> */}
			</svg>
		</div>
	);
};
