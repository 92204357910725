import "./style.scss";

import { ButtonProps } from "../_components";
import React from "react";

import DefaultButton from "./DefaultButton";
import PrimaryButton from "./PrimaryButton";
import PrimaryDangerButton from "./PrimaryDangerButton";
import SecondaryButton from "./SecondaryButton";
import SecondaryDangerButton from "./SecondaryDangerButton";
import TailButton from "./TailButton";

export { default as AuthedButton } from "./AuthedButton";
export { default as DefaultButton } from "./DefaultButton";
export { default as PrimaryButton } from "./PrimaryButton";
export { default as PrimaryDangerButton } from "./PrimaryDangerButton";
export { default as SecondaryButton } from "./SecondaryButton";
export { default as SecondaryDangerButton } from "./SecondaryDangerButton";
export { default as TailButton } from "./TailButton";

export type ButtonType =
  | "primary"
  | "default"
  | "primary-danger"
  | "secondary"
  | "secondary-danger"
  | "tail";
export interface IButton {
  buttonType?: ButtonType;
  chilren: React.ReactNode;
  className: string;
}
export interface IButtonProps extends ButtonProps {
  text?: string;
  loaderColor?: string;
  icon?: React.ReactElement;
  buttonType?: ButtonType;
}
const Button: React.FC<IButtonProps> = ({
  children,
  className,
  buttonType,
  ...props
}) => {
  switch (buttonType) {
    case "primary":
      return <PrimaryButton {...props}>{children}</PrimaryButton>;
    case "primary-danger":
      return <PrimaryDangerButton {...props}>{children}</PrimaryDangerButton>;
    case "default":
      return <DefaultButton {...props}>{children}</DefaultButton>;
    case "secondary":
      return <SecondaryButton {...props}>{children}</SecondaryButton>;
    case "secondary-danger":
      return (
        <SecondaryDangerButton {...props}>{children}</SecondaryDangerButton>
      );
    case "tail":
      return <TailButton {...props}>{children}</TailButton>;
    default:
      return (
        <div className={className ? [className, "Button"].join(" ") : "Button"}>
          {children}
        </div>
      );
  }
};

export default Button;
