import "nprogress/nprogress.css";

import Nprogress from "nprogress";
import React, { useEffect } from "react";

export default () => {
	Nprogress.configure({
		showSpinner: false,
		trickleSpeed: 300,
		template:
			'<div class="bar customBar" role="bar"><div class="peg"></div></div><div class="spinner" role="spinner"><div class="spinner-icon"></div></div>'
	});
	return Nprogress as {
		start: () => void;
		done: () => void;
		inc: () => void;
		set: (e: number) => void;
	};
};
