import "./style.scss";

import React from "react";

import { ReactComponent as PageLeft } from "./pageLeft.svg";
import { ReactComponent as PageRight } from "./pageRight.svg";

// the logic beh

export type PaginationChangeOption = {
  currentPageNumber: number;
  nextPageNumber: number;
  previousPageNumber: number;
  buttonClick: "previous" | "next";
};
export interface IPagination {
  currentNumber: number;
  stepNumber: number;
  maxLength: number;
  changeFunc: ({ number, options }: { number: number; options?: PaginationChangeOption }) => any;
  isLastPage?: boolean;
  isFirstPage?: boolean;
  pageStart?: number;
  pageEnd?: number;
}

export const Pagination = React.memo(
  ({
    currentNumber,
    stepNumber,
    maxLength,
    changeFunc,
    isFirstPage,
    isLastPage,
    pageStart,
    pageEnd,
  }: IPagination) => {
    currentNumber = isNaN(currentNumber) ? 1 : currentNumber;
    stepNumber = isNaN(stepNumber) ? 1 : stepNumber;
    const currentCountStart = currentNumber + 1;
    const currentCountEnd =
      currentNumber + stepNumber > maxLength ? maxLength : currentNumber + stepNumber;
    const totalCount = maxLength;

    const _onchange = (direction: "previous" | "next") => {
      let currentPageNumber = 0;
      let options: PaginationChangeOption;

      switch (direction) {
        case "previous":
          currentPageNumber = currentNumber - stepNumber > 0 ? currentNumber - stepNumber : 0;

          options = {
            currentPageNumber,
            nextPageNumber: stepNumber + currentNumber - 1,
            previousPageNumber: currentNumber - stepNumber,
            buttonClick: "previous",
          };

          break;
        case "next":
          currentPageNumber = currentNumber + stepNumber;
          options = {
            currentPageNumber,
            nextPageNumber: stepNumber + currentNumber - 1,
            previousPageNumber: currentNumber - stepNumber,
            buttonClick: "next",
          };
          break;

        default:
          break;
      }
      changeFunc({ number: currentPageNumber, options });
    };
    return (
      <div className="PaginationContainerBlock">
        <div className="PaginationBlock">
          <div className="PaginationText">
            <span>
              {pageStart ?? currentCountStart} - {pageEnd ?? currentCountEnd}{totalCount ? ` of ${totalCount}` : ''}
            </span>
          </div>
          <div className="paginationActions">
            <div
              className={
                checkIfFirstPage(currentNumber, currentCountStart, isFirstPage)
                  ? "paginationActions__box disabledIcon"
                  : "paginationActions__box"
              }
              onClick={
                checkIfFirstPage(currentNumber, currentCountStart, isFirstPage)
                  ? () => {}
                  : () => _onchange("previous")
              }
            >
              <PageLeft />
            </div>
            <div
              onClick={
                checkIfLastPage(currentNumber, stepNumber, maxLength, isLastPage)
                  ? () => {}
                  : () => _onchange("next")
              }
              className={
                checkIfLastPage(currentNumber, stepNumber, maxLength, isLastPage)
                  ? "disabledIcon paginationActions__box"
                  : "paginationActions__box"
              }
            >
              <PageRight />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

const checkIfLastPage = (
  currentNumber: number,
  stepNumber: number,
  maxLength: number,
  isLastPage?: boolean
) => {
  if (isLastPage != undefined) return isLastPage;
  return currentNumber + stepNumber >= maxLength;
};

const checkIfFirstPage = (
  currentNumber: number,
  currentCountStart: number,
  isFirstPage?: boolean
) => {
  if (isFirstPage != undefined) return isFirstPage;
  return currentNumber === 0 || currentCountStart <= 1;
};
