import {
  MAX_IMAGE_UPLOAD_SIZE,
  validateAndUpload
} from "@samedaycustom/helpers";
import React, { useState } from "react";
import { generate } from "shortid";

import useToast from "./useToast";

export type IuseUploadType = "image" | "other" | "doc";

export type UseUploadOptionsType = {
  isMultiple?: boolean;
  key?: string | number;
};
export default (
  type: Partial<IuseUploadType>,
  options?: UseUploadOptionsType
) => {
  const [ondragover, setonDragOver] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [files, setFiles] = useState<File[] | FileList>([]);
  const toast = useToast();

  const _setFiles = React.useCallback(
    copyfiles => {
      if (copyfiles.length >= 1) {
        setFiles((files as File[]).concat(copyfiles));
        setonDragOver(false);
        setLoadingFile(false);
      }
    },
    [files]
  );
  /**
   *
   *
   * loop through file array and set file state
   */
  const processFiles = React.useCallback((files: File[] | FileList) => {
    const copyfiles = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file) {
        //@ts-ignore
        file.key = options?.key || generate();
        copyfiles.push(readFileData(file));
      }
    }
    _setFiles(copyfiles);
  }, []);

  /**
   *
   *
   * loop through data transfer items array and set file state
   */
  function processDataTransferItems(items) {
    const copyfiles = [];
    for (let i = 0; i < items.length; i++) {
      if (items[i].kind === "file") {
        const file = items[i]?.getAsFile();
        if (options?.key)
          if (file) {
            file.key = options?.key || generate();
            copyfiles.push(readFileData(file));
          }
      }
    }
    _setFiles(copyfiles);
  }

  const onDragEnd = (e: any) => {
    e.preventDefault();
  };

  const onDragOver = (e: React.DragEvent<any>) => {
    e.preventDefault();
    e.dataTransfer.effectAllowed = "all";
    setonDragOver(true);
  };
  const onDragLeave = (e: any) => {
    e.preventDefault();
    // setonDragOver(false)
  };

  const onDragEnter = (e: any) => {
    e.preventDefault();
  };

  const onDrag = (e: React.DragEvent<any>) => {
    e.preventDefault();
  };

  const onDrop = async (ev: React.DragEvent<any>) => {
    ev.preventDefault();
    setLoadingFile(true);
    const result = readDropFile(ev);
    return Promise.resolve(result);
  };

  /** valdiate and read drop file */
  const readDropFile = (ev): string => {
    if (ev.dataTransfer && ev.dataTransfer.items) {
      if (ev.dataTransfer.items.length > 1 && !options.isMultiple) {
        setLoadingFile(false);
        toast({
          title: "File upload:",
          description: "Mulitple file selection is not allowed",
          position: "bottom-left",
          status: "info",
          duration: 3000,
          isClosable: true
        });
        return null;
      }
      if (ev.dataTransfer.items.length <= 0) {
        setLoadingFile(false);
        toast({
          title: "File upload:",
          description: "No file selected",
          position: "bottom-left",
          status: "info",
          duration: 3000,
          isClosable: true
        });
        return null;
      }
      processDataTransferItems(ev.dataTransfer.items);
      // for (let i = 0; i < ev.dataTransfer.items.length; i++) {
      // 	if (ev.dataTransfer.items[i].kind === "file") {
      // 		const file = ev.dataTransfer.items[i].getAsFile();
      // 		if (options?.key) file.key = options.key;
      // 		if (file) readFileData(file);
      // 	}
      // }
    } else if (ev.dataTransfer && ev.dataTransfer.files) {
      processFiles(ev.dataTransfer.files);
      // for (let i = 0; i < ev.dataTransfer.files.length; i++) {
      // 	const file = ev.dataTransfer.files[i];
      // 	if (options?.key) file.key = options.key;
      // 	if (file) readFileData(file);
      // }
    }
    return null;
  };

  /**
   *
   * cleanupFile and return the file
   * @param e
   */
  const cleanupFile = (e?: any) => {
    try {
      const URL = window.URL || window.webkitURL;
      setLoadingFile(false);
      setonDragOver(false);
      URL.revokeObjectURL(e?.src);
    } catch (error) {
      setLoadingFile(false);
      setonDragOver(false);
      URL.revokeObjectURL(e?.src);
    }
  };

  /** read files */
  const readFileData = (file: File): File => {
    let enhancedFile = file;
    if (file.size >= MAX_IMAGE_UPLOAD_SIZE) {
      toast({
        title: "File upload:",
        description: "Image file too large 10mb is the maximium upload size",
        position: "bottom-left",
        status: "warning",
        duration: 3000,
        isClosable: true
      });
      return null;
    }
    const reader = new FileReader();

    reader.onload = () => {
      if (type === "image") {
        enhancedFile = file;

        // turn off validation
        // validateAndUpload(
        //   file,
        //   (e: any) => {
        //     enhancedFile = file;
        //     cleanupFile(e);
        //   },
        //   () => {
        //     toast({
        //       title: "File upload:",
        //       description:
        //         "Error occured importing image or file is not an image",
        //       position: "bottom-left",
        //       status: "warning",
        //       duration: 3000,
        //       isClosable: true
        //     });
        //     setLoadingFile(false);
        //     setonDragOver(false);
        //   }
        // );
        return enhancedFile;
      }
      /**
       *
       * if type is document and validation true
       * then upload file
       */
      if (
        type === "doc"
        // && validateFileType(file, Metrics.fileType.doc.split(","))
      ) {
        enhancedFile = file;
      } else {
        toast({
          title: "File upload error :",
          description: "File not supported as a document file",
          position: "bottom-left",
          status: "warning",
          duration: 3000,
          isClosable: true
        });

        return null;
      }
    };
    reader.readAsDataURL(file);
    return enhancedFile;
  };

  // ** on browse is clicked
  const onBrowseFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target["files"]) return null;
    if (event.target.files.length > 1 && options.isMultiple === false) {
      setLoadingFile(false);
      toast({
        title: "File upload:",
        description: "Mulitple file selection is not allowed",
        position: "bottom-left",
        status: "info",
        duration: 3000,
        isClosable: true
      });
      return null;
    }
    setLoadingFile(true);
    setonDragOver(true);
    processFiles(event.target.files);
  };

  return {
    onBrowseFile,
    readFileData,
    onDragEnd,
    onDragOver,
    onDragLeave,
    onDragEnter,
    onDrag,
    setLoadingFile,
    setonDragOver,
    onDrop,
    ondragover,
    loadingFile,
    files,
    setFiles
  };
};
