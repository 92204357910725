import "./style.scss";

import { Box, IconButton, Spinner, Text } from "../_components";
import { ReactComponent as ClearImage } from "./clearSelectedFiles.svg";
import { ReactComponent as PaperImage } from "@samedaycustom/assets/images/paper-b.svg";
import React from "react";
import {
  getFileExtention,
  isFileExtentionImage
} from "@samedaycustom/helpers/src";

interface Iprops {
  imageUrl: string;
  removeFunc: () => void;
  className?: string;
  style?: React.CSSProperties;
  label?: string;
  loading?: boolean;
}

export const SelectedArtWork = ({
  imageUrl,
  removeFunc,
  label,
  className,
  style,
  loading
}: Iprops) => {
  const ext = React.useMemo(() => getFileExtention(imageUrl), [imageUrl]);
  const isImage = React.useMemo(() => isFileExtentionImage(ext), [ext]);
  return (
    <Box
      background="#fff"
      className={`SelectedArtwork__Container ${className ? className : ""}`}
      {...style}
    >
      {loading ? (
        <Box
          d="flex"
          justifyContent="center"
          width="100%"
          alignItems="center"
          height="100%"
        >
          <Spinner size="md" />
        </Box>
      ) : (
        <>
          <Box position="relative">
            <IconButton
              pos="absolute"
              background="transparent"
              _hover={{ background: "transparent" }}
              _focus={{ background: "transparent" }}
              _active={{ background: "transparent" }}
              right="15px"
              top="15px"
              aria-label="remove file"
              width="fit-content"
              height="fit-content"
              icon={
                <ClearImage
                  className="SelectedArtwork__clearSvg"
                  cursor="pointer"
                  onClick={removeFunc}
                />
              }
            />
            {isImage ? (
              <img
                src={imageUrl}
                alt={label ? label : "Artwork Image"}
                aria-label={label ? label : "Artwork Image"}
              />
            ) : (
              <Box
                display="inline-flex"
                alignItems="center"
                justifyContent="center"
                width="100%"
                marginTop="18px"
              >
                <PaperImage height="55px" width="55px" />
              </Box>
            )}
          </Box>
          <Text
            color="#486581"
            fontWeight="bold"
            letterSpacing="0.02em"
            textTransform="uppercase"
            textAlign="center"
            padding="5px"
          >
            {ext}
          </Text>
        </>
      )}
    </Box>
  );
};
