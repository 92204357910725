import { DateTime } from "luxon"

export const getStartAndEndDateFromPeriod = async (
  period: string
): Promise<{ start: string; end: string }> => {
  let end, start;
  end = DateTime.now()
    .endOf("day")
    .toMillis();
  switch (period?.toLowerCase()) {
    case "today":
      start = DateTime.now()
        .startOf("day")
        .toMillis();
      break;
    case "7days":
      start = DateTime.now()
        .plus({ days: -7 })
        .startOf("day")
        .toMillis();
      break;
    case "15days":
      start = DateTime.now()
        .plus({ days: -15 })
        .startOf("day")
        .toMillis();
      break;
    case "30days":
      start = DateTime.now()
        .plus({ days: -30 })
        .startOf("day")
        .toMillis();
      break;
    case "60days":
      start = DateTime.now()
        .plus({ days: -60 })
        .startOf("day")
        .toMillis();
      break;
    case "90days":
      start = DateTime.now()
        .plus({ days: -90 })
        .startOf("day")
        .toMillis();
      break;
    case "all time":
      start = DateTime.fromMillis(1000).toMillis();
      break;
    default:
      start = DateTime.now()
        .plus({ days: -7 })
        .startOf("day")
        .toMillis();
      break;
  }

  return Promise.resolve({ start, end });
};
