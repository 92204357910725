import { BASE_URL } from "admin/helpers/constants";

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import setRTKAuthorization from "vendor/helpers/setRTKAuthorization";
import { QueryResponse } from "admin/models/Response";
import { ISDCVendorsLocations } from "admin/models/IVendorLocations";

export const vendorsLocationApi = createApi({
  reducerPath: "vendorsLocationApi",
  tagTypes: ["locations"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/orders/`,
    prepareHeaders: setRTKAuthorization,
  }),
  endpoints: (builder) => ({
    getVendorLocations: builder.query<QueryResponse<ISDCVendorsLocations>, any>({
      query: ({ orderId }) => `${orderId}/locations`,
    }),
  }),
});

export const { useGetVendorLocationsQuery } = vendorsLocationApi;
