import {
  FETCH_CART,
  FETCH_CARTS,
  FETCHING_CART,
  CARTS_ACTION_TYPES,
} from "admin/providers/actions/abandoned-carts/types";

import { ADMIN_CART_STATE_TYPE, CART_STATE } from "./initialStates";

export const CARTS = (state = CART_STATE, action: CARTS_ACTION_TYPES): ADMIN_CART_STATE_TYPE => {
  switch (action.type) {
    case FETCH_CARTS:
      return {
        ...state,
        carts: {
          ...state.carts,
          data: action.payload.carts,
        },
      };
    case FETCH_CART:
      return {
        ...state,
        cart: {
          ...state.cart,
          data: action.payload.cart,
        },
      };
    case FETCHING_CART:
      return {
        ...state,
        cart: {
          ...state.cart,
          loading: action.payload.loading,
        },
      };
    default:
      return state;
  }
};
