import { ISDCLine } from "@samedaycustom/types/order/@types/line";
import { ISDCOrder } from "@samedaycustom/types/order/@types/order";
import { OrderListPaginationInfo } from "@samedaycustom/types/app/Operations";
import { DELIVERY_TYPE } from "types/order/@types/order";

export type ORDERS_ACTION_PAYLOAD = {
  orders: ISDCOrder[];
  order: ISDCOrder;
  orderLineItems: ISDCLine[];
  loading: boolean;
  error: boolean;
  errorMessage: string;
  metadata?: OrderListPaginationInfo;
  updateType?: "new";
  orderID?: string;
  isNew?: boolean;
  deliveryMethod?: DELIVERY_TYPE;
  fromVendorAccountInAdmin?: boolean;
  // refundAmount?: number;
  billableAmount?: number;
  totalRefunds?: number;
};
export const FETCH_ORDERS = "FETCH_ORDERS";
export const FETCH_ORDERS_META = "FETCH_ORDERS_META";
export const RESET_ORDERS = "RESET_ORDERS";
export const RESET_ORDER = "RESET_ORDER";
export const FETCHING_ORDERS = "FETCHING_ORDERS";
export const FETCHING_ORDERS_ERROR = "FETCHING_ORDERS_ERROR";
export const FETCH_ORDER = "FETCH_ORDER";
export const FETCHING_ORDER = "FETCHING_ORDER";
export const FETCH_ORDERS_FROM_VENDOR_ACCOUNT_IN_ADMIN =
  "FETCH_ORDERS_FROM_VENDOR_ACCOUNT_IN_ADMIN";
export const FETCHING_ORDER_LINEITEMS = "FETCHING_ORDER_LINEITEMS";
export const UPDATE_ORDER_MESSAGE_READ = "UPDATE_ORDER_MESSAGE_READ";
export const UPDATE_DELIVERY_METHOD = "UPDATE_DELIVERY_METHOD";
export const UPDATE_ORDER_REFUND_AMOUNT = "UPDATE_ORDER_REFUND_AMOUNT";
export const FETCH_ORDER_LINEITEMS = "FETCH_ORDER_LINEITEMS";
export const UPDATE_SINGLE_ORDER = "UPDATE_SINGLE_ORDER";

export type ORDERS_ACTION_TYPE =
  | typeof FETCH_ORDERS
  | typeof FETCHING_ORDER
  | typeof FETCHING_ORDER
  | typeof FETCH_ORDER
  | typeof FETCH_ORDER_LINEITEMS
  | typeof RESET_ORDER
  | typeof FETCHING_ORDER_LINEITEMS
  | typeof FETCHING_ORDERS
  | typeof FETCHING_ORDERS_ERROR
  | typeof RESET_ORDERS
  | typeof FETCH_ORDERS_META
  | typeof UPDATE_ORDER_MESSAGE_READ
  | typeof UPDATE_DELIVERY_METHOD
  | typeof UPDATE_ORDER_REFUND_AMOUNT
  | typeof FETCH_ORDERS_FROM_VENDOR_ACCOUNT_IN_ADMIN
  | typeof UPDATE_SINGLE_ORDER;

export type ORDERS_ACTION_TYPES = {
  type: ORDERS_ACTION_TYPE;
  payload: Partial<ORDERS_ACTION_PAYLOAD>;
};
