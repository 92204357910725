import "../AuthedButton/style.scss";

import { Button, ButtonProps } from "../../_components";
import React from "react";

import ButtonTemplate from "../ButtonTemplate";

interface IPrimaryButtonProps extends ButtonProps {
  text?: string;
  loaderColor?: string;
  activeColor?: string;
}
export default ({
  text,
  loaderColor = "#243B53",
  activeColor = "#D9E2EC",
  ...props
}: IPrimaryButtonProps) => {
  return (
    <Button
      className="ButtonMain"
      variant="solid"
      backgroundColor="#F0F4F8"
      color="#243B53"
      fontFamily="Inter"
      fontWeight={500}
      fontSize="14px"
      _hover={{
        backgroundColor: activeColor,
        outline: "none",
        border: "none"
      }}
      _focus={{
        backgroundColor: activeColor,
        outline: "none",
        border: "none"
      }}
      _disabled={{
        backgroundColor: activeColor,
        outline: "none",
        border: "none"
      }}
      width="unset"
      {...props}
      isLoading={false}
    >
      <ButtonTemplate {...props} loaderColor={loaderColor} />
    </Button>
  );
};
