import "./style.scss";

import { Box, ChakraInput as Input, InputGroup } from "../../_components";
import { Search2Icon } from "@chakra-ui/icons";
import React, { ReactElement, useCallback, useState } from "react";
import { ReactComponent as Close } from "@samedaycustom/assets/images/closeIcon.svg";
export interface IOperationsCardProps {
  children?: ReactElement;
  rightContent?: ReactElement | string;
  leftContent?: ReactElement | string;
  hideHeader?: boolean;
  // onSearch?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSearch?: (value: string) => void;
  hideShadow?: boolean;
  placeholder?: string;
  isSearchable?: boolean;
}
export default ({
  children,
  rightContent,
  onSearch,
  hideHeader,
  placeholder,
  isSearchable = true,
  hideShadow,
  leftContent,
}: IOperationsCardProps) => {
  const [value, setvalue] = useState("");

  const _onSearch = useCallback((value) => {
    setvalue(value);
    onSearch && onSearch(value);
  }, []);

  // useMemo(() => _onSearch(), [value]);

  return (
    <Box
      d="flex"
      flexDirection="column"
      className="tab card-container"
      style={hideShadow && { boxShadow: "none" }}
    >
      <Box
        d={(hideHeader && "none") || "flex"}
        flexDirection="row"
        width="100%"
        paddingBottom="1rem"
        justifyContent="space-between"
        className="orderTableCaption"
        alignItems="center"
      >
        {rightContent ? rightContent : <Box />}
        {isSearchable ? (
          <Box d="flex" justifyItems="end">
            <InputGroup className="searchInputGroup" width="300px" borderColor="#BCCCDC">
              {value.length && <Close className="searchClose" onClick={() => _onSearch("")} />}
              <Input
                padding="4px"
                paddingLeft={value.length ? "20px" : "4px"}
                type="text"
                placeholder={`${
                  placeholder ? placeholder : "e.g order number, value or customer name"
                }`}
                onChange={(e) => _onSearch(e?.target?.value)}
                onKeyDown={(e) => String(e?.key).toLowerCase() === "enter" && _onSearch(value)}
                value={value}
              />
              <button onClick={() => _onSearch("")} className="searchRightBtn">
                <Search2Icon fill="#627D98" />
              </button>
            </InputGroup>
          </Box>
        ) : (
          <Box />
        )}
        {leftContent ? leftContent : null}
      </Box>

      <Box className="production-content">{children}</Box>
    </Box>
  );
};
