import {
  FETCH_CUSTOMER,
  FETCH_CUSTOMERS,
  FETCHING_CUSTOMER,
  CUSTOMERS_ACTION_TYPES,
  FETCH_CUSTOMERS_COUNT,
} from "admin/providers/actions/customers/type";

import { ADMIN_CUSTOMER_STATE_TYPE, CUSTOMER_STATE } from "./initialState";

export const CUSTOMERS = (
  state = CUSTOMER_STATE,
  action: CUSTOMERS_ACTION_TYPES
): ADMIN_CUSTOMER_STATE_TYPE => {
  switch (action.type) {
    case FETCH_CUSTOMERS:
      return {
        ...state,
        customers: {
          ...state.customers,
          data: action.payload.customers,
        },
      };
    case FETCH_CUSTOMERS_COUNT:
      return {
        ...state,
        customers: {
          ...state.customers,
          metadata: {
            ...state.customers.metadata,
            all: action.payload.customersCount,
          },
        },
      };
    case FETCH_CUSTOMER:
      return {
        ...state,
        customer: {
          ...state.customer,
          data: action.payload.customer,
        },
      };
    case FETCHING_CUSTOMER:
      return {
        ...state,
        customer: {
          ...state.customer,
          loading: action.payload.loading,
        },
      };
    default:
      return state;
  }
};
