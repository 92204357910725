import "./style.scss";

import { Grid, Box, BoxProps, Image, IconButton } from "../../_components";
import { ReactComponent as PaperImage } from "@samedaycustom/assets/images/paper-b.svg";
import { ReactComponent as DownloadImage } from "@samedaycustom/assets/images/download-o.svg";
import {
  getFileExtention,
  isFileExtentionImage,
  maskFileName
} from "@samedaycustom/helpers";
import React from "react";
import { millify } from "millify";

export interface IMessageFileCard extends Pick<File, "name" | "size"> {
  url?: string;
  style?: BoxProps;
  size?: number;
}
export default React.memo((file: IMessageFileCard) => {
  const isImage = React.useMemo(
    () => isFileExtentionImage(getFileExtention(file.url)),
    [file.url]
  );
  return (
    <Grid
      gridTemplateColumns="50px 1fr 50px"
      className="MessageFile MessageFileGroup"
      {...file?.style}
    >
      {isImage ? (
        <Image src={file.url} alt={file.name} />
      ) : (
        <PaperImage style={{ marginLeft: "-6px" }} />
      )}
      <Box className="messageFileDetails">
        <a href={file.url} target="_blank" rel="noopener noreferrer" download>
          <span> {maskFileName(file.name)} </span>
        </a>

        {file?.size ? (
          <span>
            {millify(file.size, {
              units: ["B", "KB", "MB", "GB", "TB"],
              space: true
            })}
          </span>
        ) : null}
      </Box>
      <Box marginLeft="15px">
        <a href={file.url} target="_blank" rel="noopener noreferrer" download>
          <IconButton
            aria-label="download file"
            background="#E5F1FF"
            icon={<DownloadImage />}
          />
        </a>
      </Box>
    </Grid>
  );
});
