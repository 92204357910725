import "./style.scss";

import { Input, useForm, Text, Box, InputGroup } from "@samedaycustom/core-ui";
import React from "react";

const CreateOrder = () => {
  const { handleSubmit } = useForm();
  return (
    <Box>
      <Text>Please enter an order number to duplicate</Text>
      <Box marginTop="24px">
        <Text color="#486581" fontSize="14px" fontWeight={500} paddingBottom="4px">
          Order Number
        </Text>
        <InputGroup borderRadius="4px 0px 0px 4px" width="fit-content" height="40px">
          {/* <Box
            background="#BCCCDC"
            borderRadius="4px 0px 0px 4px"
            textAlign="center"
            padding="10px"
            fontWeight={600}
            fontSize="14px"
          >
            SDC
          </Box> */}
          <Box width="170px">
            <Input
              className="CR__Input"
              height="100%"
              type="input"
              inputConfig={{
                id: "orderId",
                name: "orderId",
                type: "text",
              }}
              onKeyDown={(e: any) => String(e?.key).toLowerCase() === "enter" && handleSubmit()}
            />
          </Box>
        </InputGroup>
      </Box>
    </Box>
  );
};

export default CreateOrder;
