import { ACTION_TYPE_DEFS } from "@samedaycustom/types/app";
import { ISDCOrder } from "@samedaycustom/types/order/@types/order";
import { ISDCLine } from "@samedaycustom/types/order/@types/line";

export type ORDERS_DATA = ISDCOrder[];
export type ADMIN_ORDER_STATE_TYPE = {
  ordersList: ACTION_TYPE_DEFS<ORDERS_DATA>;
  order: ACTION_TYPE_DEFS<ISDCOrder>;
  orderLineItems: ACTION_TYPE_DEFS<ISDCLine[]>;
};
export const ORDERS_STATE: ADMIN_ORDER_STATE_TYPE = {
  ordersList: {
    error: false,
    errorMessage: null,
    data: [],
    loading: false,
  },
  order: {
    error: false,
    errorMessage: null,
    data: null,
    loading: false,
  },
  orderLineItems: {
    error: false,
    errorMessage: null,
    data: [],
    loading: false,
  },
};
