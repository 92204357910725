import {
  Popover as ChakraPopover,
  PopoverProps,
  PopoverArrow as ChakraPopoverArrow,
  PopoverArrowProps,
  PopoverBody as ChakraPopoverBody,
  PopoverBodyProps,
  PopoverContent as ChakraPopoverContent,
  PopoverContentProps,
  PopoverTrigger as ChakraPopoverTrigger,
  PopoverCloseButton as ChakraPopoverCloseButton,
  PopoverCloseButtonProps,
} from "@chakra-ui/react";
import React from "react";

const Popover = ({ ...props }: PopoverProps) => {
  return <ChakraPopover {...props} />;
};

const PopoverArrow = ({ ...props }: PopoverArrowProps) => {
  return <ChakraPopoverArrow {...props} />;
};

const PopoverBody = ({ ...props }: PopoverBodyProps) => {
  return <ChakraPopoverBody {...props} />;
};

const PopoverContent = ({ ...props }: PopoverContentProps) => {
  return <ChakraPopoverContent {...props} />;
};

const PopoverCloseButton = ({ ...props }: PopoverCloseButtonProps) => {
  return <ChakraPopoverCloseButton {...props} />;
};

// TODO: Fix any
const PopoverTrigger = ({ ...props }: any) => {
  return <ChakraPopoverTrigger {...props} />;
};

export { Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, PopoverCloseButton };
