import "./style.scss";

import { Box } from "../../_components";
import { ReactComponent as DefaultUser } from "../assets/defaultAvi.svg";
import { ReactComponent as DropDownIndicator } from "../assets/down-arrow.svg";

import React from "react";
import { NavLink } from "react-router-dom";
import Popup from "reactjs-popup";

export interface INavitem {
  image?: any;
  children?: any;
  avi?: any;
  notification?: any;
  onItemClick?: (e: any) => void;
  onLogout?: () => void;
  profileStore?: any;
  profileUrl?: string;
}
const NavItem = React.memo(
  ({
    image: Image,
    children,
    avi,
    notification,
    onItemClick,
    onLogout,
    profileStore,
    profileUrl
  }: INavitem) => {
    if (avi && profileStore) {
      const logoutFunc = () => onLogout && onLogout();
      return (
        <Popup
          trigger={
            <div className="Avi">
              {profileStore?.profile?.profileImageURL ? (
                <div className="AviImage">
                  <img src={profileStore.profile.profileImageURL} alt="" />
                </div>
              ) : (
                <DefaultUser fill="#9FB3C8" />
              )}{" "}
              <div className="bottomRow">
                <DropDownIndicator />
              </div>
              {/*  */}
            </div>
          }
          position="bottom right"
          on="click"
          arrowStyle={{ backgroundColor: "#fff", boxShadow: "none" }}
          offsetY={10}
          closeOnDocumentClick
          contentStyle={{
            padding: "0px",
            border: "none",
            backgroundColor: "#fff",
            borderRadius: "4px",
            width: "unset",
            boxShadow: "0px 6px 18px rgba(98, 125, 152, 0.2)"
          }}
        >
          {closeFunc => {
            return (
              <div
                className="sidebarFlyout"
                style={{ width: "160px", border: "none" }}
              >
                <NavLink
                  className="flyoutLink"
                  to={profileUrl ? profileUrl : "#"}
                >
                  <div
                    className="flyoutItem avi-flyoutItem"
                    onClick={closeFunc}
                  >
                    <h2 className="nameHeading">
                      {" "}
                      {profileStore?.loading
                        ? "..."
                        : `${profileStore?.profile?.firstName} ${profileStore?.profile?.lastName}`}{" "}
                    </h2>
                  </div>
                </NavLink>

                <div className="flyoutItem avi-flyoutItem" onClick={logoutFunc}>
                  <span className="logoutText">Log Out</span>
                </div>
              </div>
            );
          }}
        </Popup>
      );
    } else {
      return (
        <Box className="NavItem" onClick={onItemClick} cursor="pointer">
          {notification ? <div className="notification"> </div> : null}
          {Image && <Image />}
          {children ? null : null}
          {/*  Todo create the sliding drop down component for the children */}
        </Box>
      );
    }
  }
);

export default NavItem;
