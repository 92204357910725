import "./style.scss";

import React, {
  createRef,
  ReactElement,
  RefObject,
  useEffect,
  useState
} from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

export { default as TabContent } from "./TabContent";
export { default as TabHeader } from "./TabHeader";

export interface TAB_PROPS extends RouteComponentProps<any, any, {}> {
  options?: Array<{ text: string; number }>;
  target: number;
  setTarget: (target: number) => void;
  rightContent?: ReactElement;
  tabList?: () => ReactElement;
  children?: any;
}
const Tab = ({
  options,
  match,
  history,
  target,
  setTarget,
  tabList,
  rightContent,
  ...props
}: TAB_PROPS) => {
  const refList: any = options.map(option => createRef());
  const sliderRef: RefObject<HTMLDivElement> = createRef();
  const [xpos, setXpos] = useState(0);
  const [width, setWidth] = useState(
    `${options[0].text.split("").length * 13}px`
  );

  useEffect(() => {
    document.documentElement.style.setProperty("--width", width);
  }, [width]);

  const optionFunc = (e, idx) => {
    setTarget(idx);
    setXpos(refList[idx].current.offsetLeft - 76);
    setWidth(refList[idx].current.clientWidth);
  };

  const dynamicStyles = {
    left: `${xpos}px`,
    width: `${width}px`
  };

  const TabHeader = React.Children.map(
    props.children,
    child =>
      child.type.displayName === "TabHeader" &&
      React.Children.map(child, (subChild, idx) =>
        React.cloneElement(subChild, {
          className: target === idx ? "navItem navItem--active" : "navItem",
          ref: refList[idx],
          onClick: e => optionFunc(e, idx)
        })
      )
  );

  return (
    <div>
      <div className={"HorizontalNav"}>
        <div className="navigation">
          <div className="navigationList">
            {/* {options.map((option, idx) => (
                        <div
                            className={
                                target === idx ? "navItem navItem--active" : "navItem"
                            }
                            ref={refList[idx]}
                            onClick={e => optionFunc(e, idx)}
                        >
                            <h1>{option.text}</h1>
                            <span className={"notification"}>{option.number}</span>
                        </div>
                    ))} */}
            {/* {React.cloneElement(TabList, {
                        ref: refList[0],
                        className: target === 0 ? "navItem navItem--active" : "navItem",
                        onClick:(e :any) => optionFunc(e, 1),
                    })} */}
            {TabHeader}
          </div>
          <div className="sliderTrack">
            <div className="slider" ref={sliderRef} style={dynamicStyles} />
          </div>
        </div>
        {rightContent && <div className="rightContent">{rightContent}</div>}
      </div>
    </div>
  );
};

export default withRouter(Tab);
