import React, { useEffect, useState } from "react";
import "./style.scss";
import Select from "@atlaskit/select";
import { useField } from "formik";
import { Box } from "../../_components";

type DropInputProps = {
  className?: string;
  asyncAction?: () => void;
  list: Array<{
    label: string;
    value: string;
  }>;
  placeholder: string;
  name: string;
  label?: string | JSX.Element;
  loading?: boolean;
  expandable?: boolean;
  externalChangeFunc?: (value) => void;
  inputChange?: (val) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
};

const DropdownInput: React.FC<DropInputProps> = ({
  asyncAction,
  list,
  placeholder,
  name,
  label,
  loading,
  expandable,
  externalChangeFunc,
  inputChange,
  disabled,
  labelStyle,
  style
}: DropInputProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [field, meta, helpers] = useField(name);
  const { value, error } = meta;
  const { setValue, setError } = helpers;
  const onChangeFunc = (value: any) => {
    setValue(value.value);
  };

  useEffect(() => {
    if (list.length < 1 && asyncAction) {
      asyncAction();
    }
  }, []);

  // each item in the list must have labels and values

  return (
    <Box
      className={
        meta.error && meta.touched
          ? `InputGroup InputGroup--error ${
              expandable
                ? menuOpen
                  ? "InputGroupMenu SpecialInput"
                  : "InputGroupMenu"
                : ""
            }`
          : `InputGroup ${
              expandable
                ? menuOpen
                  ? "InputGroupMenu SpecialInput"
                  : "InputGroupMenu"
                : ""
            }`
      }
      {...style}
    >
      <label style={labelStyle}>{label}</label>
      <Select
        className="single-select customized-select"
        classNamePrefix="react-select"
        options={list}
        isLoading={loading}
        placeholder={placeholder}
        value={list?.find(listItem => {
          return listItem.value === value || listItem.label === value;
        })}
        onChange={externalChangeFunc ? externalChangeFunc : onChangeFunc}
        maxMenuHeight={150}
        onMenuOpen={() => setMenuOpen(true)}
        onMenuClose={() => setMenuOpen(false)}
        menuIsOpen={menuOpen}
        onInputChange={inputChange ? inputChange : () => {}}
        isDisabled={disabled}
      />
      {meta.error && meta.touched ? (
        <p className="inputError">
          {typeof meta.error === "string" && meta.error}
        </p>
      ) : null}
    </Box>
  );
};

export default DropdownInput;
