import React from "react";
import { Redirect, Route, RouteProps } from "react-router";
import { DASHBOARD } from "../screen";

export interface IProtectedRoute extends RouteProps {
  authed: boolean;
}
export default React.memo(({ component: Component, authed, ...rest }: IProtectedRoute) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        authed === false ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: DASHBOARD,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
});
