import { ORDERS_LIST_COUNT_INITIAL_STATE, ORDERS_LIST_COUNT_STATE } from "./initialState";


type ORDERS_LIST_COUNT_ACTION_TYPES = {
    type: "update/orders-list-count";
    payload: ORDERS_LIST_COUNT_STATE;
};

export const ORDERS_LIST_COUNT = (
    state = ORDERS_LIST_COUNT_INITIAL_STATE,
    action: ORDERS_LIST_COUNT_ACTION_TYPES
) => {
    switch (action.type) {
        case "update/orders-list-count": {
            return {
                ...state,
                ...action.payload
            };
        }
        default: {
            return state;
        }
    }
}

export const updateOrdersListCount = (payload: ORDERS_LIST_COUNT_STATE): ORDERS_LIST_COUNT_ACTION_TYPES => {
    return {
        type: "update/orders-list-count",
        payload
    }
}