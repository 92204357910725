import { ISDCAdminVendors } from "@samedaycustom/types/vendors";
import { ISDCOrder } from "types/order/@types/order";
import { OrderListPaginationInfo } from "@samedaycustom/types/app/Operations";
import { IStoreMinData } from "@samedaycustom/sdc-types";

export type VENDORS_ACTION_PAYLOAD = {
  vendors: ISDCAdminVendors[];
  vendor: ISDCAdminVendors;
  loading?: boolean;
  orders?: ISDCOrder[];
  meta?: OrderListPaginationInfo;
  locations?: IStoreMinData[];
  done?: boolean;
};
export const FETCH_VENDORS = "FETCH_VENDORS";
export const FETCH_VENDOR = "FETCH_VENDOR";
export const FETCHING_VENDOR = "FETCHING_VENDOR";
export const FETCH_VENDOR_ORDERS = "FETCH_VENDOR_ORDERS";
export const FETCH_VENDOR_ORDERS_META = "FETCH_VENDOR_ORDERS_META";
export const FETCH_VENDOR_LOCATIONS = "FETCH_VENDOR_LOCATIONS";
export const FETCHING_VENDOR_LOCATIONS = "FETCHING_VENDOR_LOCATIONS";
export const RESET_VENDOR_LOCATIONS = "RESET_VENDOR_LOCATIONS";

export type VENDOR_ACTION_TYPE =
  | typeof FETCH_VENDORS
  | typeof FETCH_VENDOR
  | typeof FETCHING_VENDOR
  | typeof FETCH_VENDOR_ORDERS
  | typeof FETCH_VENDOR_ORDERS_META
  | typeof FETCH_VENDOR_LOCATIONS
  | typeof FETCHING_VENDOR_LOCATIONS
  | typeof RESET_VENDOR_LOCATIONS;

export type VENDORS_ACTION_TYPES = {
  type: VENDOR_ACTION_TYPE;
  payload?: Partial<VENDORS_ACTION_PAYLOAD>;
};
