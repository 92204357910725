import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADMIN_MESSAGING,
  NOTE_IMAGE_UPLOAD,
  CUSTOMER_VENDOR_MESSAGING,
} from "admin/helpers/constants";
import { ISDCMessage } from "types/app/Messaging";
import qs from "query-string";
import { api } from "../../../api";
import { markCustomerMessagesAsRead } from "..";

export const sendVendorMessage = createAsyncThunk(
  "message/sendVendorMessage",
  async (messageBody: ISDCMessage) => {
    const response = await api
      .post(`${ADMIN_MESSAGING}/message/${messageBody?.locationID}`, messageBody)
      .catch((err) => Promise.reject(err.response?.data?.message));
    return response.data?.data;
  }
);
export const getVendorMessages = createAsyncThunk(
  "message/getVendorMessages",
  async (param: { location_id: string }, { dispatch }) => {
    const response = await api
      .get(`${ADMIN_MESSAGING}/message/${param?.location_id}`)
      .catch((err) => Promise.reject(err.response?.data?.message));
    dispatch(getVendorUnreadMesageCount());
    return response.data?.data;
  }
);

export const sendCustomerMessage = createAsyncThunk(
  "message/sendCustomerMessage",
  async (messageBody: ISDCMessage) => {
    const response = await api
      .post(
        `${CUSTOMER_VENDOR_MESSAGING}/${messageBody.orderID}?store=${messageBody.locationID}`,
        messageBody
      )
      .catch((err) => Promise.reject(err.response?.data?.message));
    return response.data?.data;
  }
);

export const getCustomerMessages = createAsyncThunk(
  "message/getCustomerMessages",
  async (
    param: {
      location_id: string;
      orderId: string;
      business_id: string;
    },
    { dispatch }
  ) => {
    const response = await api
      .get(
        `${CUSTOMER_VENDOR_MESSAGING}/${param.orderId}?store=${param.location_id ||
          ""}&business_id=${param.business_id || ""}`
      )
      .catch((err) => Promise.reject(err.response?.data?.message));
    dispatch(getCustomerUnreadMesageCount());
    return response.data?.data;
  }
);

export const getVendorThreads = createAsyncThunk(
  "message/getVendorThreads",
  async (
    param: {
      limit: number;
      search?: string;
      locationId: string;
      cursor?: number;
    },
    { dispatch }
  ) => {
    let baseUrl = `${ADMIN_MESSAGING}/message`;
    baseUrl = `${baseUrl}?${qs.stringify({
      limit: param.limit,
      search: param?.search || "",
      locationId: param?.locationId || "",
      cursorId: param?.cursor || 0,
    })}`;
    const response = await api.get(`${baseUrl}`);
    dispatch(getVendorUnreadMesageCount());
    return response.data?.data;
  }
);

export const getCustomerThreads = createAsyncThunk(
  "message/getCustomerThreads",
  async (
    param?: {
      limit: number;
      search?: string;
      cursor?: number;
      locationId: string;
    },
    { dispatch }
  ) => {
    let baseUrl = `${CUSTOMER_VENDOR_MESSAGING}`;
    baseUrl = `${baseUrl}?${qs.stringify({
      limit: param?.limit || 50,
      search: param?.search || "",
      locationId: param?.locationId || "",
      cursorId: param?.cursor || 0,
    })}`;
    const response = await api.get(`${baseUrl}`);
    dispatch(getCustomerUnreadMesageCount());
    return response.data?.data;
  }
);

export const getVendorUnreadMesageCount = createAsyncThunk(
  "message/getVendorUnreadMesageCount",
  async (_D, { getState }) => {
    const auth = getState()?.auth;
    const response = await api.get(`${ADMIN_MESSAGING}/message/count`, {
      headers: { Authorization: "Bearer " + auth?.token },
    });
    return Number(response.data?.data?.unread_admin_count);
  }
);

export const getCustomerUnreadMesageCount = createAsyncThunk(
  "message/getCustomerUnreadMesageCount",
  async (_D, { getState }) => {
    const auth = getState()?.auth;
    const response = await api.get(`${CUSTOMER_VENDOR_MESSAGING}/count`, {
      headers: { Authorization: "Bearer " + auth?.token },
    });
    return Number(response.data?.data?.unread_admin_count);
  }
);

export const markCustomerMessageAsRead = createAsyncThunk(
  "message/markCustomerMessageAsRead",
  async (
    param: {
      location_id?: string;
      order_id: string;
    },
    { getState, dispatch }
  ) => {
    const auth = getState()?.auth;
    dispatch(markCustomerMessagesAsRead(param.order_id));
    const response = await api.patch(
      `${CUSTOMER_VENDOR_MESSAGING}/${param.order_id}?location_id=${param?.location_id || ""}`,
      {
        headers: { Authorization: "Bearer " + auth?.token },
      }
    );
    return Number(response.data?.data?.unread_admin_count);
  }
);

export const markCustomerMessagesAsUnRead = createAsyncThunk(
  "message/markCustomerMessagesAsUnRead",
  async (param: { orderIds?: string[] }) => {
    const response = await api.patch(CUSTOMER_VENDOR_MESSAGING, {
      orderIds: param.orderIds || [],
    });
    return Number(response.data?.data || 0);
  }
);

export const markVendorMessagesAsUnRead = createAsyncThunk(
  "message/markVendorMessagesAsUnRead",
  async (param: { locationIds?: string[] }) => {
    const response = await api.patch(`${ADMIN_MESSAGING}/message`, {
      locationIds: param.locationIds || [],
    });
    return Number(response.data?.data || 0);
  }
);

export const imageUpload = createAsyncThunk(
  "message/imageUpload",
  async (
    file: File & {
      id?: string;
    }
  ) => {
    const formData = new FormData();
    formData.append("image", file as any);
    const result = await api.post(`${NOTE_IMAGE_UPLOAD}?id=${file?.id || ""}`, formData, {
      // onUploadProgress: onProgress,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return result?.data?.data;
  }
);
