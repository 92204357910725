import "./style.scss";

import { Box, Text } from "../../_components";
import { ReactComponent as ArrowDown } from "@samedaycustom/assets/images/arrow-downVector.svg";
import { DatePicker } from "@samedaycustom/core-ui";
import moment from "moment";
import React, { useEffect } from "react";
import Popup from "reactjs-popup";

import {
  DateGroupCustomDate,
  DateGroupProvider,
  useDateGroupContext
} from "./context";
import DateGroupItem from "./DateGroupItem";

export * from "./DateGroupCalendar";

export interface IDateGroupDropdown {
  onChange: (value: Date | number) => void;
  defaultValue?: Date | number;
  defaultLabel?: string;
  onCustomDate: (date: DateGroupCustomDate) => void;
  onReset?: () => void;
  className?: string;
  children: any;
  labelStyle?: React.CSSProperties;
  Indicator?: JSX.Element | React.ReactElement;
  groupLabelStyle?: React.CSSProperties;
}

export * from "./context";
export { DateGroupItem };
export default React.memo((prop: IDateGroupDropdown) => {
  return (
    <DateGroupProvider>
      <DateGroupContainer {...prop} />
    </DateGroupProvider>
  );
});

const DateGroupContainer = (prop: IDateGroupDropdown) => {
  const ONE_MINUTE_BEFORE_MIDNIGHT_IN_MINUTES = 1439;
  const isRendered = React.useRef(false);
  const {
    selectedlabel,
    placeholder,
    dateValue,
    customDate,
    showCustomDate,
    setCustomDate,
    setSelectedLabel,
    setDateValue
  } = useDateGroupContext();

  useEffect(() => {
    if (!isRendered.current) isRendered.current = true;
  }, []);

  useEffect(() => {
    setDateValue(prop.defaultValue);
    prop?.defaultLabel && setSelectedLabel(prop.defaultLabel);
  }, [prop?.defaultValue, prop?.defaultLabel]);

  useEffect(() => {
    if (isRendered) {
      if (typeof prop?.onChange === "function" && !showCustomDate) {
        prop.onChange(dateValue);
      }
      if (typeof prop?.onCustomDate === "function" && showCustomDate) {
        prop.onCustomDate(customDate);
      }
    }
  }, [dateValue, customDate, showCustomDate]);

  // display date range
  function getDateGrouping(daysAgo) {
    const date = new Date();
    const fromDate = new Date(date.getTime() - daysAgo * 24 * 60 * 60 * 1000);
    const from = fromDate.toLocaleDateString(moment.locale(), {
      day: "2-digit",
      month: "short",
      year: "numeric"
    });
    const to = date.toLocaleDateString(moment.locale(), {
      day: "2-digit",
      month: "short",
      year: "numeric"
    });

    return daysAgo !== null ? (
      <span className="timeGroupingSpan">
        {`${daysAgo >= 1 ? `${from} - ${to}` : `${from}`}`}
      </span>
    ) : null;
  }

  /**
   *
   * display date range selected
   */
  const DisplayContainer = ({ groupLabelStyle }) => {
    if (showCustomDate)
      return (
        <DatePicker
          loading={false}
          startdate={customDate?.start}
          enddate={customDate?.end}
          onApply={(startdate, enddate) => {
            // startdate : 06-30-2021
            // enddate : 07-1-2021
            //  then converted to datetime
            const date = {
              start: moment(startdate)
                .toDate()
                .getTime(),
              end: moment(enddate)
                .add(ONE_MINUTE_BEFORE_MIDNIGHT_IN_MINUTES, "minutes")
                .toDate()
                .getTime()
            };
            setCustomDate(date);
          }}
          onReset={prop?.onReset}
        />
      );

    return (
      <Text className="displayText" {...groupLabelStyle}>
        {getDateGrouping(dateValue)}
      </Text>
    );
  };

  return (
    <Box d="flex" className={`dateRangeDropdown ${prop.className}`}>
      <Popup
        trigger={() => {
          return (
            <Box
              className="selectedDateGroup"
              style={{
                cursor: "pointer"
              }}
              {...prop?.labelStyle}
            >
              <span> {selectedlabel || placeholder} </span>
              {prop.Indicator || <ArrowDown />}
            </Box>
          );
        }}
        contentStyle={{
          width: "unset",
          border: "none",
          padding: "0",
          height: "unset",
          borderRadius: "4px",
          boxShadow: "0px 6px 18px rgba(98, 125, 152, 0.24)"
        }}
        arrow={false}
        position="bottom left"
        closeOnDocumentClick={true}
      >
        {closeFunc => (
          <div className="dateGroupingSelectionBox" onClick={closeFunc}>
            {prop.children}
          </div>
        )}
      </Popup>
      <DisplayContainer groupLabelStyle={prop.groupLabelStyle || {}} />
    </Box>
  );
};
