import "./style.scss";

import { Box } from "../../_components";
import { ReactComponent as BackArrow } from "../assets/backButton.svg";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { clear } from "console";

interface HeaderProps {
  fallbackURl?: string;
  linkToPreviousPage?: boolean;
  PageHeader?: React.ReactNode | string;
  rightContent?: React.ReactNode | string;
  children?: React.ReactNode;
  onGoBack?: () => void;
  fixed?: boolean;
  linkState?: Object;
  style?: React.CSSProperties;
  leftContent?: React.ReactElement;
  specialCase?: boolean;
}

const AuthedUserPageHeader: React.FC<HeaderProps> = ({
  linkToPreviousPage,
  fallbackURl,
  PageHeader,
  children,
  fixed,
  linkState,
  leftContent,
  style,
  onGoBack,
  specialCase
}) => {
  const history = useHistory();

  /**
   *
   * @isaac please document this logic thanks
   */
  const _onBack = async event => {
    if (history.length >= 2) {
      if (onGoBack) {
        onGoBack();
        return;
      } else if (fallbackURl && fallbackURl !== null && !specialCase) {
        history.push(fallbackURl, linkState ? linkState : null);
        return;
      } else if (fallbackURl && fallbackURl !== null && specialCase) {
        history.push(fallbackURl);
        return;
      } else {
        setTimeout(() => history.goBack(), 100);
        return;
      }
    } else if (fallbackURl && fallbackURl !== null && !specialCase) {
      history.push(fallbackURl, linkState ? linkState : null);
      return;
    } else if (fallbackURl && fallbackURl !== null && specialCase) {
      history.push(fallbackURl);
      return;
    } else {
      /**
       * if fallbackURl is not provided move to the nearest absolute path
       */
      let nearestPath = null;
      let pathPartition = history.location.pathname.split("/");
      let closetNode = 1;

      if (pathPartition.length > 1)
        nearestPath = `${pathPartition[closetNode]}/${
          pathPartition[closetNode + 1]
        }`;
      else nearestPath = pathPartition[closetNode];
      window.location.href = `${window.location.origin}/${nearestPath}`;
      return;
    }
  };

  return (
    <Box className={fixed ? "PageHeader fixed" : "PageHeader"} style={style}>
      <div className="mainContent">
        <div className="leftContent">
          {leftContent}
          {linkToPreviousPage ? (
            <>
              <button className="backButton" onClick={_onBack}>
                <BackArrow />
                <span className={"backText"}> Back </span>
                <div className="line"> </div>
              </button>
            </>
          ) : null}
          {PageHeader && <h1 style={{ width: "100%" }}>{PageHeader}</h1>}
        </div>
        <div className="rightContent">{children}</div>
      </div>
    </Box>
  );
};

export default AuthedUserPageHeader;
