import "./style.scss";

import { DefaultCheck } from "@samedaycustom/core-ui";
import React from "react";

const colorList = [
  {
    label: "White",
    value: "#fff"
  },
  {
    label: "Black",
    value: "#102A43"
  },
  {
    label: "Red",
    value: "#E12D39"
  },
  {
    label: "Blue",
    value: "#006EE5"
  },
  {
    label: "Purple",
    value: "#8719E0"
  },
  {
    label: "Green",
    value: "#18981D"
  }
];

type colorPickerProps = {
  colorArray: Array<string>;
  selectClrFunc: (color) => void;
  deselectClrFunc: (color) => void;
};

export const ColorPicker: React.FC<colorPickerProps> = ({
  colorArray,
  selectClrFunc,
  deselectClrFunc
}: colorPickerProps) => {
  return (
    <div className="colorPickerContainer">
      <div className="colorPickerContainer__header">
        <h2>Select the colors you have</h2>
      </div>
      <div className="colorPickerContainer__options">
        {colorList.map(color => (
          <div
            className="color-option"
            onClick={
              colorArray && colorArray.includes(color.label.toUpperCase())
                ? () => deselectClrFunc(color.label)
                : () => selectClrFunc(color.label)
            }
          >
            <div className="colorSection">
              <div
                className="colorBalls"
                style={{
                  backgroundColor: color.value,
                  border: `1px solid ${
                    color.value === "#fff" ? "#F0F4F8" : color.value
                  }`
                }}
              />
              <span> {color.label} </span>
            </div>
            <DefaultCheck
              label=""
              // stateful
              checked={
                colorArray && colorArray.includes(color.label.toUpperCase())
              }
              setChecked={() => {}}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
