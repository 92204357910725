import { Box, Text } from "../../_components";
import React, { CSSProperties, ReactElement } from "react";
import Style from "style-it";

import { ReactComponent as PickUp } from "../assets/pickup-camera.svg";
import { ReactComponent as Shipping } from "../assets/shipping-icon.svg";
import { ReactComponent as Truck } from "../assets/truck.svg";
import { ReactComponent as RushIcon } from "../assets/rush-icon.svg";


export type TagType =
  | "A.A"
  | "A"
  | "R"
  | "N.S"
  | "N.A"
  | "PROC"
  | "O.H"
  | "FUL"
  | "CAN"
  | "UNFUL"
  | "DEC"
  | "PENDING CANCELLATION"
  | "P.S"
  | "R.S"
  | "D.S"
  | "S.A"
  | "PD"
  | "CD"
  | "C.M"
  | "SD"
  | "N.O"
  | "pickup"
  | "car"
  | "shipping"
  | "C"
  | "S"
  | "new"
  | "on pickup"
  | "unassigned"
  | "new message"
  | "rush_pickup"
  | "super_pickup"
  | "rush"
  | "rush_shipping"
  | "super_rush"
  | "super_shipping"
  | "super_rush_shipping"
  | "rush_car"
  | "super_car"
  | "super_rush_car";

export const tags: {
  [key: string]: {
    text: string;
    color: string;
    backgroundColor: string;
    borderRadius?: string;
    textStyle?: CSSProperties;
    icon?: ReactElement;
  };
} = {
  "A.A": {
    text: "New order",
    color: "#FFFFFF",
    backgroundColor: "#31B237"
  },
  "A.B": {
    text: "Awaiting Blanks",
    color: "#DE911D",
    backgroundColor: "#FFFBEA"
  },
  "N.S": {
    text: "In Production",
    color: "#102A43",
    backgroundColor: "#D9E2EC"
  },
  R: {
    text: "Reviewing",
    color: "#0062CC",
    backgroundColor: "#E5F1FF"
  },
  PROC: {
    text: "In Production",
    color: "#102A43",
    backgroundColor: "#D9E2EC"
  },
  "O.H": {
    text: "On hold",
    color: "#DE911D",
    backgroundColor: "#FFFBEA"
  },
  FUL: {
    text: "Fulfilled",
    color: "#FFFFFF",
    backgroundColor: "#31B237"
  },
  DELIVERED: {
    text: "Fulfilled",
    color: "#FFFFFF",
    backgroundColor: "#31B237"
  },
  A: {
    text: "Approved",
    color: "#0F8613",
    backgroundColor: "#C1F2C7"
  },
  CAN: {
    text: "Cancelled",
    color: "#CF1124",
    backgroundColor: "#FFE3E3"
  },
  DEC: {
    text: "Declined",
    color: "#CF1124",
    backgroundColor: "#FFE3E3"
  },
  "PENDING CANCELLATION": {
    text: "Pending Cancellation",
    color: "#CF1124",
    backgroundColor: "#FFE3E3",
  },
  "P.S": {
    text: "In Production",
    color: "#102A43",
    backgroundColor: "#D9E2EC"
  },
  NS: {
    text: "Not Started",
    color: "#102A43",
    backgroundColor: "#D9E2EC"
  },
  UNFUL: {
    text: "Unfulfilled",
    color: "#102A43",
    backgroundColor: "#D9E2EC"
  },
  "P.C": {
    text: "Produced",
    color: "#102A43",
    backgroundColor: "#D9E2EC"
  },
  MS: {
    text: "Multiple Status",
    color: "#8719E0",
    backgroundColor: "#F2EBFE"
  },

  "M.S": {
    text: "Multiple Status",
    color: "#8719E0",
    backgroundColor: "#F2EBFE"
  },
  "R.S": {
    text: "Reviewing Artwork",
    color: "#102A43",
    backgroundColor: "#D9E2EC"
  },
  "D.S": {
    text: "Partial Delivery",
    color: "#102A43",
    backgroundColor: "#D9E2EC"
  },
  "N.O": {
    text: "New Order",
    color: "#fff",
    backgroundColor: "#31B237"
  },
  "N.A": {
    text: "Not Available",
    color: "#0062CC",
    backgroundColor: "#E5F1FF"
  },
  S: {
    text: "In Production",
    color: "#102A43",
    backgroundColor: "#D9E2EC"
  },
  "S.A": {
    text: "Reviewing",
    color: "#0062CC",
    backgroundColor: "#E5F1FF"
  },
  "C.M": {
    text: "Confirmation Missing",
    color: "#243B53",
    backgroundColor: "#F0B429"
  },
  unassigned: {
    text: "Unassigned",
    color: "#243B53",
    backgroundColor: "#F0B429"
  },
  C: {
    text: "Approved",
    color: "#0F8613",
    backgroundColor: "#C1F2C7"
  },
  new: {
    text: "New",
    color: "#fff",
    backgroundColor: "#0F8613",
    borderRadius: "20px",
    textStyle: { fontWeight: 600, fontSize: "12px" }
  },
  "new message": {
    text: "UNREAD MESSAGE",
    color: "#fff",
    backgroundColor: "#007bff",
    textStyle: {
      fontFamily: "Inter",
      fontWeight: 600,
      fontSize: "10px"
    }
  },
  "on pickup": {
    text: "CONFIRMATION MISSING",
    color: "#243B53",
    backgroundColor: "#F0B429",
    textStyle: {
      fontFamily: "Inter",
      fontWeight: "bold",
      fontSize: "12px",
      whiteSpace: "normal"
    }
  },
  pickup: {
    text: "Pickup",
    color: "#7A0ECC",
    backgroundColor: "#F2EBFE",
    icon: <PickUp />
  },
  rush_pickup: {
    text: "Rush Pickup",
    color: "#CF1124",
    backgroundColor: "#FFE3E3",
    icon: <RushIcon fill="#CF1124" />
  },
  super_pickup: {
    text: "Super Rush Pickup",
    color: "#CF1124",
    backgroundColor: "#FFE3E3",
    icon: <Shipping fill="#CF1124" />
  },
  super_rush_pickup: {
    text: "Super Rush Pickup",
    color: "#CF1124",
    backgroundColor: "#FFE3E3",
    icon: <Shipping fill="#CF1124" />
  },
  car: {
    text: "Car Delivery",
    color: "#F0B429",
    backgroundColor: "#FFFBEA",
    icon: <Truck fill="#F0B429" />
  },
  shipping: {
    text: "Shipping",
    color: "#006EE5",
    backgroundColor: "#E5F1FF",
    icon: <Shipping />
  },
  rush: {
    text: "Rush Shipping",
    color: "#CF1124",
    backgroundColor: "#FFE3E3",
    icon: <Shipping fill="#CF1124" />
  },
  rush_shipping: {
    text: "Rush Shipping",
    color: "#CF1124",
    backgroundColor: "#FFE3E3",
    icon: <Shipping fill="#CF1124" />
  },
  super_rush: {
    text: "Super Rush Shipping",
    color: "#CF1124",
    backgroundColor: "#FFE3E3",
    icon: <Shipping fill="#CF1124" />
  },
  super_shipping: {
    text: "Super Rush Shipping",
    color: "#CF1124",
    backgroundColor: "#FFE3E3",
    icon: <Shipping fill="#CF1124" />
  },
  super_rush_shipping: {
    text: "Super Rush Shipping",
    color: "#CF1124",
    backgroundColor: "#FFE3E3",
    icon: <Shipping fill="#CF1124" />
  },
  rush_car: {
    text: "Rush Car",
    color: "#CF1124",
    backgroundColor: "#FFE3E3",
    icon: <RushIcon fill="#CF1124" />
  },
  super_car: {
    text: "Super Rush Car",
    color: "#CF1124",
    backgroundColor: "#FFE3E3",
    icon: <Shipping fill="#CF1124" />
  },
  super_rush_car: {
    text: "Super Rush Car",
    color: "#CF1124",
    backgroundColor: "#FFE3E3",
    icon: <Shipping fill="#CF1124" />
  },
};

export type DeliveryStatus =
  | "in_transit"
  | "delivered"
  | "error"
  | "unknown"
  | "N/A"
  | "pending"
  | "pickup"
  | "pickup_complete"
  | "dropoff"
  | "canceled"
  | "returned"
  | "ongoing"
  | "deleted"
  | "C.M"
  | "picked";

export type TagAppearance =
  | "active"
  | "inprogress"
  | "removed"
  | "processing"
  | "delay"
  | "neutral";

const formStatus = (
  status: DeliveryStatus
): { text: string; appearance: TagAppearance } => {
  switch (status) {
    case "N/A":
      return {
        text: "Not Available",
        appearance: "removed"
      };

    case "in_transit":
      return {
        text: "In Transit",
        appearance: "inprogress"
      };

    case "delivered":
      return {
        text: "Delivered",
        appearance: "inprogress"
      };

    case "error":
      return {
        text: "Exception",
        appearance: "processing"
      };

    case "unknown":
      return {
        text: "UNKNOWN",
        appearance: "removed"
      };
    case "pending":
      return {
        text: "Pending",
        appearance: "inprogress"
      };

    case "pickup":
      return {
        text: "Pickup",
        appearance: "inprogress"
      };

    case "pickup_complete":
      return {
        text: "pickup complete",
        appearance: "active"
      };
    case "dropoff":
      return {
        text: "Dropoff",
        appearance: "active"
      };

    case "canceled":
      return {
        text: "canceled",
        appearance: "removed"
      };

    case "returned":
      return {
        text: "Returned",
        appearance: "processing"
      };
    case "ongoing":
      return {
        text: "ongoing",
        appearance: "inprogress"
      };
    case "deleted":
      return {
        text: "Deleted",
        appearance: "removed"
      };
    case "C.M":
      return {
        text: "Confirmation missing",
        appearance: "neutral"
      };
    case "picked":
      return {
        text: "Picked Up",
        appearance: "delay"
      };
  }
};

export interface IRowTag {
  text?: string;
  backgroundColor?: string;
  color?: string;
  width?: string;
  height?: string;
  Icon?: any;
  borderRadius?: string;
  tag?: TagType;
  isTag?: boolean;
  statusType?: boolean;
  className?: string;
  children?: any;
  onClick?: () => void;
  appearance?: TagAppearance;
  textStyle?: CSSProperties;
  status?: DeliveryStatus;
  transformText?: boolean;
}
const RowTag = ({
  text,
  backgroundColor,
  color,
  width,
  height,
  tag,
  borderRadius,
  Icon,
  isTag,
  appearance,
  statusType,
  children,
  onClick,
  status,
  transformText = true,
  ...props
}: IRowTag) => {
  if (status) {
    appearance = formStatus(status)?.appearance;
    text = formStatus(status)?.text;
  }

  const isTagStyle = {
    display: "inline-flex",
    fontFamily: "Inter",
    fontWeight: "bold",
    textTransform: "uppercase",
    borderRadius: "4px",
    height: "fit-content",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  } as React.CSSProperties;

  let textStyle: React.CSSProperties = isTag
    ? isTagStyle
    : props.textStyle || tags[tag]?.textStyle;

  if (appearance) {
    switch (appearance) {
      case "active":
        textStyle = {
          ...isTagStyle,
          backgroundColor: "#E5F1FF",
          color: "#0062CC"
        };
        break;
      case "inprogress":
        textStyle = {
          ...isTagStyle,
          backgroundColor: "#F2EBFE",
          color: "#7A0ECC"
        };
        break;
      case "processing":
        textStyle = {
          ...isTagStyle,
          backgroundColor: "#FFFBEA",
          color: "#DE911D"
        };
        break;
      case "delay":
        textStyle = {
          ...isTagStyle,
          backgroundColor: "#C1F2C7",
          color: "#0F8613"
        };
        break;
      case "removed":
        textStyle = {
          ...isTagStyle,
          backgroundColor: "#FFE3E3",
          color: "#CF1124"
        };
        break;
      case "neutral":
        textStyle = {
          ...isTagStyle,
          backgroundColor: "#F0B429",
          color: "#243B53"
        };
        break;

      default:
        break;
    }
  }

  return Style.it(
    `
        .tag-container{
            background: ${backgroundColor || tags[tag]?.backgroundColor};
            text-align: center;
            font-size: 12px;
            height:${height || "fit-content"};
            color: ${color || tags[tag]?.color};
            font-family: Inter;
            font-weight: 500;
            border-radius: ${borderRadius ||
              tags[tag]?.borderRadius ||
              "0px 5px"};
            padding: 3px 5px 3px 5px;
            width: ${width || "fit-content"}
        }

		.tag-container span{
			display: block;
    		white-space: nowrap;
    		text-overflow: ellipsis;
		}
        `,
    <Box
      d="flex"
      justifyContent="center"
      className={`tag-container ${statusType ? `status__tag` : ""}`}
      textTransform={transformText ? "capitalize" : "none"}
      fontFamily="Inter"
      padding="0.5rem"
      style={textStyle as React.CSSProperties}
      alignItems="center"
      onClick={onClick}
      {...props}
    >
      {Icon && (
        <Text padding="2px" paddingRight="5px">
          {Icon}
        </Text>
      )}
      {tags[tag]?.icon && (
        <Text padding="2px" paddingRight="5px">
          {tags[tag]?.icon}
        </Text>
      )}
      {text || tags[tag]?.text ? (
        <span className={`${statusType ? "tag__text" : ""}`}>
          {" "}
          {tags[tag]?.text || text}{" "}
        </span>
      ) : null}
      {children && (!text || !tags[tag]?.text) && children}
    </Box>
  );
};

export default RowTag;
