import { Search2Icon } from "@chakra-ui/icons";
import {
  ChakraInput as Input,
  InputGroup,
  InputLeftElement,
  InputProps
} from "../_components";
import React from "react";

interface CustomInputProps extends InputProps {
  dontShowSearchIcon?: boolean;
}

export const Search = (props: CustomInputProps) => {
  return (
    <React.Fragment>
      <InputGroup borderColor="#BCCCDC" size={props.size}>
        {props.dontShowSearchIcon ? (null) : (<InputLeftElement
          children={<Search2Icon color="#627D987F" fontSize="17px" />}
        />)}
        <Input height="36px" type="text" {...props} />
      </InputGroup>
    </React.Fragment>
  );
};
