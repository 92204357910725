import { Box, Text } from "../../_components";
import { DefaultButton } from "@samedaycustom/core-ui";
import { IDropDownProps } from "../";
import { ReactComponent as ArrowDown } from "../assets/arrow-downVector.svg";
import React from "react";
import Popup from "reactjs-popup";

export default React.memo(
  ({
    position,
    loading,
    placeholder,
    value,
    listItems
  }: Partial<IDropDownProps>) => {
    return (
      <Box>
        <Popup
          position={position}
          offsetX={25}
          offsetY={15}
          arrow={false}
          contentStyle={{
            width: "unset",
            border: "none",
            padding: "0",
            height: "unset",
            borderRadius: "4px",
            boxShadow: "0px 6px 18px rgba(98, 125, 152, 0.24)"
          }}
          closeOnDocumentClick={true}
          trigger={
            <div id="__more">
              <DefaultButton
                background="transparent"
                activeColor="#F0F4F8"
                display="flex"
                alignItems="center"
              >
                <Text
                  fontSize="14px"
                  fontWeight={500}
                  fontFamily="Inter"
                  paddingRight="8px"
                  color="#334E68"
                >
                  More
                </Text>
                <ArrowDown />
              </DefaultButton>
            </div>
          }
        >
          {(closeFuncInner, isOpen) => {
            return (
              <div className="ADropDown__OptionsContainer">
                {listItems?.map(item => {
                  return (
                    <div
                      className={`ADropDown__Option ${
                        item?.value?.toLowerCase() === value?.toLowerCase()
                          ? "activeOption"
                          : ""
                      }`}
                      onClick={item?.onClick && item.onClick}
                    >
                      {item.label.length > 20 ? (
                        <Popup
                          on="hover"
                          trigger={open => {
                            return (
                              <span className="miniFlyoutItem">
                                {item.label.substring(0, 20)} ...
                              </span>
                            );
                          }}
                          // offsetY={10}
                          position="bottom center"
                          closeOnDocumentClick={true}
                          arrow={false}
                          contentStyle={{
                            width: "unset",
                            border: "none",
                            padding: "0",
                            height: "unset",
                            borderRadius: "4px",
                            boxShadow: "0px 5px 10px rgba(98, 125, 152, 0.1)"
                          }}
                        >
                          <span className="tooltipContainer">{item.label}</span>
                        </Popup>
                      ) : (
                        <span className="miniFlyoutItem">{item.label}</span>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          }}
        </Popup>
      </Box>
    );
  }
);
