import { ChakraInput as Input, Box, InputGroup, Text } from "../../_components";
import { Select } from "@samedaycustom/core-ui";
import { Field } from "formik";
import React from "react";

type ProductionInputWithSelectType = {
  name: string;
  validate?: (value: string) => string;
  isDisabled?: boolean;
  style: React.CSSProperties;
  value: string | number;
  onChange?: (value: any) => void;
};
interface IProductionInputWithSelect {
  title: string;
  firstInput: ProductionInputWithSelectType;
  select: ProductionInputWithSelectType;
  children:
    | React.DetailedHTMLProps<React.OptionHTMLAttributes<HTMLOptionElement>, HTMLOptionElement>[]
    | React.DetailedHTMLProps<React.OptionHTMLAttributes<HTMLOptionElement>, HTMLOptionElement>;
  width?: string;
  step?: number;
}
export default ({
  firstInput,
  select,
  title,
  width,
  children,
}: IProductionInputWithSelect) => {
  return (
    <Box>
      <Text className="__text-title">{title}</Text>
      <InputGroup
        width={width || "150px"}
        d="flex"
        alignItems="center"
        paddingTop="0.5rem"
        className="inputGroupSelect"
      >
        <Field name={firstInput.name} validate={firstInput.validate}>
          {({ field }: { field: any }) => (
            <Input
              {...field}
              style={firstInput.style}
              value={firstInput.value}
              rounded="0"
              size="lg"
              isDisabled={firstInput.isDisabled}
              onChange={firstInput.onChange}
              type="number"
              min={0}
            />
          )}
        </Field>
        <Field name={select.name}>
          {({ field }: { field: any }) => (
            <InputGroup height="40px" width="75px">
              <Select
                {...field}
                style={select.style}
                value={select.value}
                isDisabled={select.isDisabled}
                borderRadius="4px"
                borderLeftRadius="0px"
                paddingLeft="0.3rem"
                // margin="1px 0px 0px 0px"
                background="#D9E2EC"
                border="none"
                onChange={select.onChange}
                className="__select"
              >
                {children}
              </Select>
            </InputGroup>
          )}
        </Field>
      </InputGroup>
    </Box>
  );
};
