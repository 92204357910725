import "./styles.scss";

import { AuthedButton, DefaultCheck } from "@samedaycustom/core-ui";
import React from "react";
import { withRouter } from "react-router-dom";

export { default as ChatListItem } from "./ChatListItem";
export { default as WorkHoursListItem } from "./WorkHoursListItem";

export * from "./ChatListItem";
const ListItem = ({
  history,
  clickable,
  smaller,
  leadComponent: Lead,
  heading,
  text,
  ButtonText,
  url,
  last,
  checkType,
  checked,
  checkFunc,
  unCheckFunc,
  children
}: any) => {
  return (
    <div
      className={smaller ? "ListItem ListItem--smaller" : "ListItem"}
      onClick={clickable ? () => history.push(url) : () => {}}
      style={clickable ? { cursor: "pointer" } : null}
    >
      <div className="rightContent">
        {checkType ? (
          <DefaultCheck
            checked={checked}
            setChecked={checked ? unCheckFunc : checkFunc}
          />
        ) : null}
        {Lead ? <Lead /> : null}
        <div className={"textContent"}>
          <h1> {heading} </h1>
          <p>{text}</p>
        </div>
      </div>
      <div className="leftContent">
        {children ? (
          children
        ) : (
          <AuthedButton className="Btn" onClick={() => history.push(url)}>
            <span> {ButtonText} </span>
          </AuthedButton>
        )}
      </div>
      {last ? null : <hr />}
    </div>
  );
};

export default withRouter(ListItem);
