import { Box, Text } from "../../_components";
import { NoteValue } from "@samedaycustom/types/order/@types/notes";
import { ISendNote, SendNote } from "@samedaycustom/core-ui";
import React from "react";

export interface INoteInputProp
  extends Pick<ISendNote, "lineItems" | "onSendNote" | "requesFileUpload"> {
  onShowNoteInput: () => void;
  onHideNoteInput: () => void;
  showNoteInput: boolean;
  initialValue: Partial<NoteValue>;
}
export default React.forwardRef(
  (props: INoteInputProp, ref: React.MutableRefObject<HTMLInputElement>) => {
    const _onStartTyping = () => props.onShowNoteInput();

    const setshow = (condition: boolean) => {
      if (condition === true) props.onShowNoteInput();
      if (condition === false) props.onHideNoteInput();
    };

    return (
      <Box>
        <SendNote
          setShow={setshow}
          show={props.showNoteInput}
          inputRef={ref}
          {...props}
        />
        <Box background="transparent" padding="15px">
          <Box background="#F0F4F8" borderRadius="4px" padding="9px 16px">
            <Text
              fontWeight="normal"
              fontSize="14px"
              color="#334E68"
              onClick={_onStartTyping}
            >
              Type your note here
            </Text>
          </Box>
        </Box>
      </Box>
    );
  }
);
