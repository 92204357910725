import { AUTH_STATE_TYPE } from "@samedaycustom/types/app";
import {
  DELETE_AUTH_TOKEN_ACTION,
  UPDATE_AUTH_TOKEN_ACTION,
} from "admin/providers/actions/auth/types";

export const AUTH_STATE: AUTH_STATE_TYPE = {
  token: "",
  emailConfirmed: false,
  isSuperAdmin: false,
  roleName: "",
  isManager: false,
  firstName: "",
  lastName: "",
};
// authentication reducers
export const AUTH = (
  state = AUTH_STATE,
  action: { type: string; payload?: AUTH_STATE_TYPE }
): AUTH_STATE_TYPE => {
  switch (action.type) {
    case UPDATE_AUTH_TOKEN_ACTION:
      return {
        ...state,
        ...action.payload,
      };
    case DELETE_AUTH_TOKEN_ACTION:
      return {
        ...state,
        token: "",
      };

    default:
      return state;
  }
};
