import React from "react";
import "./style.scss";
import { ReactComponent as Edit } from "../../assets/editPen.svg";

type CardProps = {
	heading: string;
	children: React.ReactNode;
	action(): void;
};

const Card: React.FC<CardProps> = ({ heading, children, action }) => {
	return (
		<div className="ViewCard">
			<div className="ViewCard__header">
				<h2>{heading}</h2>
				<Edit onClick={action} />
			</div>
			<div>{children}</div>
		</div>
	);
};

export default Card;
