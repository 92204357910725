import "./style.scss";

import React, { useState } from "react";

export interface RadioInput {
  value?: boolean;
  stateless?: boolean;
  isWhiteLabel?: boolean;
  onChange?: (value?: any) => void;
  disabled?: boolean;
}

function RadioInput({
  value,
  onChange,
  stateless,
  isWhiteLabel,
  disabled = false,
}: RadioInput): JSX.Element {
  const [newvalue, setValue] = useState(value ? true : false);
  const prev = React.useRef(value);

  React.useEffect(() => {
    if (value !== prev.current) setValue(value);
  }, [value]);

  function toggle(updateValue?: boolean): void {
    if (disabled) return;
    setValue(updateValue);
    onChange && onChange(updateValue);
  }

  // Dont mind the repeatation we can clean this up later
  if (stateless) {
    return (
      <div
        aria-disabled={disabled}
        className={
          newvalue
            ? `RadioInput RadioInput--active ${isWhiteLabel ? "RadioInput--active--wl" : ""} ${
                disabled ? "RadioInput--disabled" : ""
              }`
            : `RadioInput ${disabled ? "RadioInput--disabled" : ""}`
        }
        onClick={() => toggle(!newvalue)}
      >
        <div className="Knob" />
      </div>
    );
  }

  return (
    <div
      className={
        value
          ? `RadioInput RadioInput--active ${isWhiteLabel ? "RadioInput--active--wl" : ""}`
          : "RadioInput"
      }
      onClick={() => toggle(!value)}
    >
      <div className="Knob" />
    </div>
  );
}

export default RadioInput;
