import { useToploader } from "@samedaycustom/hooks";
import { ProgressLoader } from "@samedaycustom/core-ui";
import React, { useEffect } from "react";
export const Fallback = ({
  loader: PreviousElement
}: {
  loader?: JSX.Element;
  onLeave?: (pathname?: string) => void;
}) => {
  const { start, done: progressDone } = useToploader();
  useEffect(() => {
    start();

    // if (typeof onLeave === "function") {
    // 	const component = onLeave(pathname);
    // 	console.log(component, "component");
    // 	setComponent(component);
    // }
    return () => {
      progressDone();
      // onLeave && onLeave(pathname);
    };
  }, []);
  return PreviousElement || <ProgressLoader />;
};
