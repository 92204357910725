import React from "react";
import "./style.scss";

export type TagType = "GENERAL" | "LINE" | "ARTWORK" | "DELIVERY" | "PAYMENT";

interface TagProps {
	type: TagType;
}

export default ({ type }: TagProps) => {
	let className = "plain";

	switch (type) {
		case "GENERAL":
			className = "generalTag";
			break;
		case "LINE":
			className = "generalTag";
			break;
		case "ARTWORK":
			className = "artTag";
			break;
		case "DELIVERY":
			className = "artTag";
			break;
		case "PAYMENT":
			className = "moneyTag";
			break;
		default:
			className = "plain";
			break;
	}

	return (
		<p className={`tagContainer ${className}`}>
			<span>{type === "LINE" ? "LINEITEM" : type}</span>
		</p>
	);
};
