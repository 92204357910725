import { ACTION_TYPE_DEFS } from "@samedaycustom/types/app";

import { ISDCAdminUser } from "@samedaycustom/types/user";

export type ADMIN_USERPROFILE_STATE_TYPE = {
  profile?: ACTION_TYPE_DEFS<ISDCAdminUser>;
};
export const USERS_STATE: ADMIN_USERPROFILE_STATE_TYPE = {
  profile: {
    error: false,
    errorMessage: null,
    data: null,
    loading: false,
  },
};
