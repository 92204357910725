import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { ReactComponent as Close } from "@samedaycustom/assets/images/closeModal.svg";
import { Progress } from "@samedaycustom/core-ui";
import { TFile } from "@samedaycustom/types/order/@types/notes";
export interface IFileLoader {
  key?: string | number;
  file: TFile;
  progress?: number;
  onRemove?: (file: TFile) => Promise<any>;
}
export default React.memo(({ file, progress, key, onRemove }: IFileLoader) => {
  return (
    <React.Fragment key={key}>
      <Box
        d="flex"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        padding="8px 0px"
      >
        <Text
          color="#627D98"
          fontSize="13px"
          fontFamily="Inter"
          fontWeight={500}
          isTruncated
          title={file.originalName}
        >
          {file.originalName}
        </Text>
        <Close
          width="18px"
          height="18px"
          fill="#E12D39"
          onClick={(e: any) => onRemove(file)}
        />
      </Box>
      <Progress height="2px" color="#31B237" value={progress} />
    </React.Fragment>
  );
});
