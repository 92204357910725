import "../AuthedButton/style.scss";

import { Button, ButtonProps } from "../../_components";
import React from "react";

import ButtonTemplate from "../ButtonTemplate";

interface IPrimaryButtonProps extends ButtonProps {
  text?: string;
  loaderColor?: string;
  icon?: React.ReactElement;
}
export default ({ loaderColor = "#fff", ...props }: IPrimaryButtonProps) => {
  return (
    <Button
      as="button"
      className="ButtonMain"
      variant="solid"
      backgroundColor="#3479FC"
      color="#fff"
      fontFamily="Inter"
      fontWeight={500}
      fontSize="14px"
      _hover={props?.disabled || props?.isDisabled ? {} : {
        backgroundColor: "#006EE5",
        outline: "none",
        border: "none"
      }}
      _focus={{
        backgroundColor: "#006EE5",
        outline: "none",
        border: "none"
      }}
      _disabled={{
        backgroundColor: "#006EE55F",
        outline: "none",
        border: "none",
        cursor: "not-allowed",
      }}
      width="unset"
      {...props}
      isLoading={false}
    >
      <ButtonTemplate {...props} loaderColor={loaderColor} />
    </Button>
  );
};
