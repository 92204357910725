import React from "react";
import { Box } from "../../_components";
import { Input, useForm } from "@samedaycustom/core-ui";


const EditPayout = () => {
    const { values, setFieldValue } = useForm<{
      payout: "";
    }>();

    return (
        <Box paddingRight="20px" width="100%">
            <Input
                className="edit-payout-class"
                type={"input"}
                inputConfig={{
                    type: "string",
                    name: "payout",
                    id: "payout",
                }}
                onChange={(e) => {
                    const payout = e.target.value;
                    setFieldValue('payout', payout);
                }}
            />
        </Box>
    )
}

export default EditPayout
