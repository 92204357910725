import { combineReducers } from "redux";

import message from "../features/message";
import events from "../features/events";
import payments from "../features/payment";
import { APP } from "./app";
import { AUTH } from "./auth";
import BUSINESS_PROFILE from "./businessProfile";
import { CUSTOMERS } from "./customers";
import profileImageReducer from "./imageUploads";
import { ORDERS } from "./orders";
import { productionSpeed } from "./settings/productionSpeed";
import { USERS } from "./user";
import { VENDORS } from "./vendors";
import { CARTS } from "./abandoned-carts";
import { vendorsLocationApi } from "../features/vendors/async";
import { ORDERS_LIST_COUNT } from "./orders-list-count";

export default combineReducers({
  auth: AUTH,
  app: APP,
  vendors: VENDORS,
  customers: CUSTOMERS,
  orders: ORDERS,
  users: USERS,
  businessProfile: BUSINESS_PROFILE,
  profileImage: profileImageReducer,
  productionSpeed,
  message,
  events,
  payments,
  carts: CARTS,
  ordersListCount: ORDERS_LIST_COUNT,
  [vendorsLocationApi.reducerPath]: vendorsLocationApi.reducer,
});
