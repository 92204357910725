import { Box, Grid } from "@chakra-ui/react";
// import { Grid } from "@material-ui/core";
import { Foldtable } from "@samedaycustom/core-ui";
import React from "react";

import { DynamicTableContext } from "..";
import { IDynamicCell, IDynamicCellType } from "../DynamicCell";

export type DynamicRowType = {
  key: string | number;
  cells: Array<IDynamicCellType>;
  subcontent?: React.ReactElement[] | React.ReactElement | string;
  onClick?: () => void;
}[];
export interface IDynamicRow {
  id: string | number;
  gridColumnTemplate?: string;
  children?:
    | React.ReactElement<IDynamicCell>[]
    | React.ReactElement<IDynamicCell>;
  subchildren?: React.ReactElement[] | React.ReactElement | string;
  hideArrow?: boolean;
}
export default React.memo(
  ({
    id,
    children,
    gridColumnTemplate,
    subchildren,
    hideArrow
  }: IDynamicRow) => {
    const dynamictablecontextvalue = React.useContext(DynamicTableContext);
    const [isopen, setisopen] = React.useState(
      !dynamictablecontextvalue.isAllOpen
    );

    const _onopen = show => {
      setisopen(!show);

      if (
        !dynamictablecontextvalue.openedTables.includes(id.toString()) &&
        show
      )
        dynamictablecontextvalue.setOpenedTables(prev =>
          prev.concat(id.toString())
        );
      if (dynamictablecontextvalue.openedTables.includes(id.toString())) {
        dynamictablecontextvalue.setOpenedTables(prev =>
          prev.filter(o => !String(o).includes(id.toString()))
        );
      }

      if (dynamictablecontextvalue.openedTables.length >= 1) {
        dynamictablecontextvalue.setisAllOpen(false);
      }
    };
    return (
      <Box paddingTop="8px">
        <Box paddingBottom="1rem">
          <Foldtable
            hideArrow={hideArrow}
            hiddenShowMore
            background="#FBFCFE"
            border="1px solid #D9E2EC"
            isOpen={isopen}
            onOpen={_onopen}
            rightHeader={
              <Box className="table_row__body">
                <Grid
                  templateColumns={
                    gridColumnTemplate
                      ? gridColumnTemplate
                      : "repeat(auto-fit, minmax(2rem,auto))"
                  }
                  width="100%"
                  gridGap="2px"
                >
                  {children}
                </Grid>
              </Box>
            }
          >
            <React.Fragment>{subchildren}</React.Fragment>
          </Foldtable>
        </Box>
      </Box>
    );
  }
);
