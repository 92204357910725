import { ReactComponent as RedClock } from "@samedaycustom/assets/images/smallClock.svg";
import { ReactComponent as GreenClock } from "@samedaycustom/assets/images/greenClock.svg";
import { RowTag } from "@samedaycustom/core-ui";
import React from "react";

export type AvailabilityStatus = "open" | "close";
export interface AvailabilityTag {
  status: AvailabilityStatus;
  onClick?: () => void;
}
export const Availability: React.FC<AvailabilityTag> = props => {
  const infoProps = React.useMemo(() => {
    switch (props.status) {
      case "open":
        return {
          text: "Open",
          backgroundColor: "#F2FCF3",
          color: "#07121D",
          Icon: <GreenClock />
        };
      case "close":
        return {
          text: "Closed",
          backgroundColor: "#FFF0F0",
          color: "#07121D",
          Icon: <RedClock fill="#CF1124" />
        };
    }
  }, [props.status]);
  return (
    <RowTag
      textStyle={{
        fontSize: "13px",
        fontWeight: 500,
        textTransform: "capitalize",
      }}
      onClick={props.onClick}
      {...infoProps}
    />
  );
};

export default Availability;
