import "./style.scss";

import { Box } from "../_components";
import { ReactComponent as CloseModal } from "./assets/closeModal.svg";
import { AuthedButton } from "@samedaycustom/core-ui";
import React, { useEffect, useState } from "react";
import usePortal from "react-useportal";

export { default as MainModal } from "./Modal";
export { default as ModalHeader } from "./ModalHeader";
export { default as useModal } from "./useModal";
export { default as useForm } from "./useForm";

type ModalProps = {
  children: React.ReactNode;
  show: boolean;
  closeFunction: (e?: any) => void;
  actionFunction?: (e?: any, helper?: any) => void;
  heading?: string;
  footer?: boolean;
  actionBtnClass?: string;
  actionBtnText?: string;
  loading?: boolean;
  large?: boolean;
  disabled?: boolean;
};

export const Modal: React.FC<ModalProps> = ({
  children,
  show,
  closeFunction,
  actionFunction,
  heading,
  footer,
  actionBtnClass,
  actionBtnText,
  loading,
  large,
  disabled
}) => {
  const { Portal } = usePortal();
  const [shouldRender, setRender] = useState(show);
  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const animationEnd = () => {
    if (!show) {
      setRender(false);
    }
  };
  return (
    shouldRender && (
      <Portal>
        <Box
          onAnimationEnd={animationEnd}
          className={show ? "Modal Modal--show" : "Modal Modal--noShow"}
        >
          <Box
            className="Modal__Overlay"
            onClick={loading ? () => {} : closeFunction}
          />
          <Box
            className={`Modal__ContentHolder ${
              large ? "largeModal" : "smallModal"
            }`}
          >
            <Box
              className={footer ? "contentTop" : "contentTop roundedContent"}
            >
              <Box className="Modal__ContentHolder__header">
                <h2>{heading}</h2>
                <Box
                  className="closeContainer"
                  onClick={loading ? () => {} : closeFunction}
                >
                  <CloseModal />
                </Box>
              </Box>
              <Box>{children}</Box>
            </Box>
            {footer ? (
              <Box className="contentBottom">
                <AuthedButton
                  className="cancelBtn"
                  onClick={loading ? () => {} : closeFunction}
                >
                  <span>Cancel</span>
                </AuthedButton>
                <AuthedButton
                  onClick={actionFunction}
                  loading={loading ? true : false}
                  loaderColor="#fff"
                  disabled={disabled}
                  className={`${actionBtnClass}`}
                  type="submit"
                >
                  <span>{actionBtnText}</span>
                </AuthedButton>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Portal>
    )
  );
};
