import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { ActionStatus, ISDCMessage, ISDCMessages } from "@samedaycustom/types/app/Messaging";
import { uniqBy } from "lodash";
import {
  getCustomerMessages,
  getCustomerThreads,
  getCustomerUnreadMesageCount,
  getVendorMessages,
  getVendorThreads,
  getVendorUnreadMesageCount,
  // markCustomerMessagesAsUnRead,
  sendCustomerMessage,
  sendVendorMessage,
} from "./async";

function addVendorMessage(message, state) {
  let threadData = [];

  // update newMessageCount is not in message page
  const isSameMessagePage = window.location.pathname.includes("message");
  const isSameMessagePageAndSameLocationOpen =
    isSameMessagePage && state?.vendor_messages?.activeLocation === message?.locationID;

  // TODO: this might be buggy when we have other admin users
  if (message?.origin !== "admin") {
    if (!isSameMessagePage || (isSameMessagePage && !state.vendor_threads.data?.length))
      state.vendor_threads.newMessageCount = message?.unread?.admin + 1;
  }

  if (message?.locationID) {
    /**
     * update message thread if not in message page
     */
    threadData = state.vendor_threads.data;
    const foundMessageInThreadIndex = threadData.findIndex(
      (_location) => _location.locationID === message?.locationID
    );

    const foundMessageInThread = state.vendor_threads.data[foundMessageInThreadIndex];
    const unreadCount = !isSameMessagePageAndSameLocationOpen ? message?.unread?.admin + 1 : 0;

    const payloadData = {
      ...foundMessageInThread,
      ...message,
      lastMessageAt: message.timestamp,
      lastMessageID: message.id,
      unread: {
        ...foundMessageInThread?.unread,
        admin: unreadCount,
      },
    };

    const threads = [payloadData, ...current(state.vendor_threads.data)];
    const threadsUnique = uniqBy(threads, "locationID");
    state.vendor_threads.data = threadsUnique;
  }

  const locationId = message?.locationID;
  let oldMessages = state.vendor_messages.data?.[locationId];
  oldMessages = oldMessages ? current(oldMessages) : [];
  const oldMessageIndex = oldMessages.findIndex((o) => o?.id === message?.id);

  if (oldMessageIndex === -1) {
    state.vendor_messages.data[locationId] = [message, ...oldMessages];
  }
}

/**
 * add customer to message array and updat thread content
 * @param message
 * @param state
 */
function addCustomerMessage(message, state) {
  let threadData = [];

  // update newMessageCount is not in message page
  const isSameMessagePage = window.location.pathname.includes("message");
  const isSameMessagePageAndSameLocationOpen =
    isSameMessagePage && state?.customer_messages?.activeOrder === message?.orderID;

  if (!isSameMessagePage || (isSameMessagePage && !state.customer_threads.data?.length))
    state.customer_threads.newMessageCount = message?.unread?.admin;

  if (message?.orderID) {
    /**
     * update message thread if not in message page
     */
    threadData = state.customer_threads.data;
    const foundMessageInThreadIndex = threadData.findIndex((o) => o.orderID === message?.orderID);

    const foundMessageInThread = state.customer_threads.data[foundMessageInThreadIndex];
    const unreadCount = !isSameMessagePageAndSameLocationOpen ? message?.unread?.admin : 0;

    const payloadData = {
      ...foundMessageInThread,
      ...message,
      messages: { [message.id]: message },
      lastMessageAt: message.timestamp,
      lastMessageID: message.id,
      unread: {
        ...foundMessageInThread?.unread,
        admin: unreadCount,
      },
    };

    const threads = [payloadData, ...current(state.customer_threads.data)];
    const threadsUnique = uniqBy(threads, "orderID");

    state.customer_threads.data = threadsUnique;
  }

  const orderID = message?.orderID;
  let oldMessages = state.customer_messages.data?.[orderID];
  oldMessages = oldMessages ? current(oldMessages) : [];
  const oldMessageIndex = oldMessages.findIndex((o) => o?.id === message?.id);

  if (oldMessageIndex === -1) {
    state.customer_messages.data[orderID] = [message, ...oldMessages];
  }
}
export interface MessagesState {
  vendor_messages: {
    data: { [key: string]: ISDCMessage[] };
    error: any;
    status: ActionStatus;
    activeLocation: string;
  };
  customer_messages: {
    data: { [key: string]: ISDCMessage[] };
    error: any;
    status: ActionStatus;
    activeOrder: string;
  };
  vendor_threads: {
    data: ISDCMessages[];
    count: number;
    status: ActionStatus;
    error: any;
    newMessageCount: number;
  };
  customer_threads: {
    data: ISDCMessages[];
    status: ActionStatus;
    error: any;
    count: number;
    newMessageCount: number;
    hasMore?: boolean;
  };
}
const initialState: MessagesState = {
  vendor_messages: {
    data: {},
    status: "idle",
    error: null,
    activeLocation: "",
  },
  customer_messages: {
    data: {},
    status: "idle",
    error: null,
    activeOrder: "",
  },
  vendor_threads: {
    data: [],
    status: "idle",
    error: null,
    count: 0,
    newMessageCount: 0,
  },
  customer_threads: {
    data: [],
    status: "idle",
    error: null,
    count: 0,
    newMessageCount: 0,
  },
};
const messageSplice = createSlice({
  name: "message",
  initialState,
  reducers: {
    updateActiveMessageLocation(state, action: PayloadAction<string>) {
      state.vendor_messages.activeLocation = action.payload;
    },
    updateActiveMessageOrder(state, action: PayloadAction<string>) {
      state.customer_messages.activeOrder = action.payload;
    },
    addNewVendorMessage(state, action) {
      const message = action.payload;
      addVendorMessage(message, state);
    },
    addNewCustomerMessage(state, action) {
      const message = action.payload;

      addCustomerMessage(message, state);
    },
    resetCustomerMessages(state) {
      state.customer_messages = initialState.customer_messages;
    },
    resetVendorMessages(state) {
      state.vendor_messages = initialState.vendor_messages;
    },
    resetVendorMessageThread(state) {
      state.vendor_threads = initialState.vendor_threads;
    },
    resetCustomerMessageThread(state) {
      state.customer_threads = initialState.customer_threads;
    },
    markLocationMessageAsRead(state, action: PayloadAction<string>) {
      const findIndex = state.vendor_threads.data.findIndex((o) => o.locationID === action.payload);
      let unreadAdmin = 0;
      if (findIndex !== -1) {
        unreadAdmin = state.vendor_threads.data[findIndex].unread.admin;
        state.vendor_threads.data[findIndex].unread.admin = 0;
      }
      state.vendor_threads.newMessageCount = state.vendor_threads.newMessageCount - unreadAdmin;
    },
    markCustomerMessagesAsRead(state, action: PayloadAction<string>) {
      const findIndex = state.customer_threads.data.findIndex((o) => o.orderID === action.payload);

      let unreadAdmin = 0;
      if (findIndex !== -1) {
        unreadAdmin = state.customer_threads.data[findIndex].unread.admin;
        state.customer_threads.data[findIndex].unread.admin = 0;
      }

      state.customer_threads.newMessageCount = state.customer_threads.newMessageCount - unreadAdmin;
    },
    markCustomerMessagesAsUnread(state, action: PayloadAction<string[]>) {
      // Convert action.payload to Object
      const payload = action.payload.reduce((acc, curr) => {
        acc[curr] = true;
        return acc;
      }, {});

      let unreadAdmin = 0;
      state.customer_threads.data = state.customer_threads.data.map((thread) => {
        if (payload[thread.orderID]) {
          unreadAdmin += 1;
          thread.unread.admin = 1;
        }
        return thread;
      });

      state.customer_threads.newMessageCount = state.customer_threads.newMessageCount + unreadAdmin;
    },
    markLocationMessagesAsUnread(state, action: PayloadAction<string[]>) {
      // Convert action.payload to Object
      const payload = action.payload.reduce((acc, curr) => {
        acc[curr] = true;
        return acc;
      }, {});

      let unreadAdmin = 0;
      state.vendor_threads.data = state.vendor_threads.data.map((thread) => {
        if (payload[thread.locationID]) {
          unreadAdmin += 1;
          thread.unread.admin = 1;
        }
        return thread;
      });

      state.vendor_threads.newMessageCount = state.vendor_threads.newMessageCount + unreadAdmin;
    },
  },
  extraReducers: (builder) => {
    // Send vendor message
    builder
      .addCase(sendVendorMessage.fulfilled, (state) => {
        state.vendor_messages.status = "succeeded";
      })
      .addCase(sendVendorMessage.rejected, (state, action) => {
        state.vendor_messages.status = "failed";
        state.vendor_messages.error = action.error.message;
      })
      .addCase(getVendorMessages.pending, (state) => {
        state.vendor_messages.status = "loading";
      })
      .addCase(getVendorMessages.fulfilled, (state, action) => {
        state.vendor_messages.status = "succeeded";
        const locationId = action?.meta?.arg?.location_id;

        const message = action.payload[0];
        const locationID = message?.locationID;
        // let unreadCount = 0
        if (locationID) {
          const threadData = state.vendor_threads.data;
          const foundMessageInThread = threadData.find((o) => o.locationID === locationID);
          const foundMessageInThreadIndex = threadData.findIndex(
            (o) => o.locationID === locationID
          );
          const unreadCount = foundMessageInThread?.unread?.admin;

          const payloadData = {
            ...foundMessageInThread,
            lastMessageAt: message.timestamp,
            lastMessageID: message.id,
            unread: {
              ...foundMessageInThread?.unread,
              admin: 0,
            },
          };

          if (foundMessageInThreadIndex !== -1 && unreadCount > 0) {
            state.vendor_threads.data[foundMessageInThreadIndex] = payloadData;
          }
        }
        state.vendor_messages.data[locationId] = action.payload;
      })
      .addCase(getVendorMessages.rejected, (state, action) => {
        state.vendor_messages.status = "failed";
        state.vendor_messages.error = action.error.message;
      })
      .addCase(sendCustomerMessage.fulfilled, (state) => {
        state.customer_messages.status = "succeeded";

        // const message = action?.meta?.arg;
        // addMessage(message, state);
      })
      .addCase(sendCustomerMessage.rejected, (state, action) => {
        state.customer_messages.status = "failed";
        state.customer_messages.error = action.error.message;
      })
      .addCase(getCustomerMessages.pending, (state) => {
        state.customer_messages.status = "loading";
      })
      .addCase(getCustomerMessages.fulfilled, (state, action) => {
        state.customer_messages.status = "succeeded";
        const orderId = action?.meta?.arg?.orderId;

        const message = action.payload[0];
        const orderID = message?.orderID;
        // let unreadCount = 0
        if (orderID) {
          const threadData = state.customer_threads.data;
          const foundMessageInThread = threadData.find((o) => o.orderID === orderID);
          const foundMessageInThreadIndex = threadData.findIndex((o) => o.orderID === orderID);
          const unreadCount = foundMessageInThread?.unread?.admin;

          const payloadData = {
            ...foundMessageInThread,
            lastMessageAt: message.timestamp,
            lastMessageID: message.id,
            unread: {
              ...foundMessageInThread?.unread,
              admin: 0,
            },
          };

          if (foundMessageInThreadIndex !== -1 && unreadCount > 0) {
            state.customer_threads.data[foundMessageInThreadIndex] = payloadData;
          }
        }
        state.customer_messages.data[orderId] = action.payload;
      })
      .addCase(getCustomerMessages.rejected, (state, action) => {
        state.customer_messages.status = "failed";
        state.customer_messages.error = action.error.message;
      })
      .addCase(getVendorThreads.pending, (state, action) => {
        const hasPagination = action?.meta?.arg?.cursor || action?.meta?.arg?.search;
        state.vendor_threads.status = hasPagination ? "idle" : "loading";
      })
      .addCase(getVendorThreads.fulfilled, (state, action) => {
        state.vendor_threads.status = "succeeded";

        const hasPagination = action?.meta?.arg?.cursor;

        const data = hasPagination
          ? [...current(state?.vendor_threads.data), ...action.payload.data]
          : action.payload.data;
        state.vendor_threads.data = data;
        // state.vendor_threads.data = action.payload;
        state.vendor_threads.count = action.payload.count;
      })
      .addCase(getVendorThreads.rejected, (state, action) => {
        state.vendor_threads.status = "failed";
        state.vendor_threads.error = action.error.message;
      })
      .addCase(getCustomerThreads.pending, (state, action) => {
        const hasPagination = action?.meta?.arg?.cursor || action?.meta?.arg?.search;
        state.customer_threads.status = hasPagination ? "idle" : "loading";
      })
      .addCase(getCustomerThreads.fulfilled, (state, action) => {
        state.customer_threads.status = "succeeded";
        const hasPagination = action?.meta?.arg?.cursor;

        const data = hasPagination
          ? [...current(state?.customer_threads.data), ...action.payload.data]
          : action.payload.data;

        state.customer_threads.data = data;
        state.customer_threads.count = action.payload.count;
        state.customer_threads.hasMore = action.payload.hasMore;
      })
      .addCase(getCustomerThreads.rejected, (state, action) => {
        state.customer_threads.status = "failed";
        state.customer_threads.error = action.error.message;
      })
      .addCase(getVendorUnreadMesageCount.fulfilled, (state, action) => {
        state.vendor_threads.newMessageCount = action.payload;
      })
      .addCase(getCustomerUnreadMesageCount.fulfilled, (state, action) => {
        state.customer_threads.newMessageCount = action.payload;
      });
  },
});

export const {
  addNewVendorMessage,
  resetCustomerMessages,
  addNewCustomerMessage,
  resetVendorMessageThread,
  resetVendorMessages,
  resetCustomerMessageThread,
  updateActiveMessageLocation,
  updateActiveMessageOrder,
  markLocationMessageAsRead,
  markCustomerMessagesAsRead,
  markCustomerMessagesAsUnread,
  markLocationMessagesAsUnread,
} = messageSplice.actions;

export default messageSplice.reducer;
