import {
  Tag as ChakraTag,
  TagProps,
  TagLabel as ChakraTagLabel,
  TagLabelProps,
  TagCloseButtonProps,
  TagCloseButton as ChakraTagCloseButton,
  TagLeftIcon,
} from "@chakra-ui/react";
import React from "react";

// TODO: Export after fixind possible duplicates in ../tag/index.tsx
const Tag = ({ ...props }: TagProps) => {
  return <ChakraTag {...props} />;
};

const TagLabel = ({ ...props }: TagLabelProps) => {
  return <ChakraTagLabel {...props} />;
};

const TagCloseButton = ({ ...props }: TagCloseButtonProps) => {
  return <ChakraTagCloseButton {...props} />;
};

export { TagLabel, TagCloseButton, Tag as ChakraTag, TagLeftIcon };
