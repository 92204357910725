import { ORDER_STATUS } from "@samedaycustom/types/order/@types/order";
import { RECENT_ORDER_TYPE } from "types/app";

export type ADMIN_ACTION_PAYLOAD_TYPE = {
  condition: boolean;
  loading: boolean;
  error: boolean;
  errorMessage: string;
};
export const SIGNIN_LOADING = "SIGNIN_LOADING";
export const SIGNIN_ERROR = "SIGNIN_ERROR";
export const ADD_TO_RECENT_ORDERS = "ADD_TO_RECENT_ORDERS";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const ORDER_STATUS_UPDATED = "ORDER_STATUS_UPDATED";
export const ORDER_AGENTS_UPDATED = "ORDER_AGENTS_UPDATED";
export const ORDER_STATUS_RESET = "ORDER_STATUS_RESET";
export const DELIVERY_STATUS_UPDATE = "DELIVERY_STATUS_UPDATE";
export const CAR_DELIVERY_STATUS_UPDATE = "CAR_DELIVERY_STATUS_UPDATE";
export const REMOVE_FROM_RECENT_ORDERS = "REMOVE_FROM_RECENT_ORDERS";
export const DELIVERY_STATUS_RESET = "DELIVERY_STATUS_RESET";
export const ORDER_DELIVERY_METHOD_UPDATED = "ORDER_DELIVERY_METHOD_UPDATED";
export const ORDER_DUE_DATE_CHANGE_REQUEST_UPDATE = "ORDER_DUE_DATE_CHANGE_REQUEST_UPDATE";
export const ORDER_ARTWORK_CHANGE_UPDATE = "ORDER_ARTWORK_CHANGE_UPDATE";

export const TOGGLE_BROADCAST = "TOGGLE_BROADCAST";

export const SIGNIN_STATE = "signin";

export type ACTION_ERROR_TYPES = typeof SIGNIN_ERROR;
export type STATE_TYPE = typeof SIGNIN_STATE;

export type APP_ACTION_TYPE =
  | typeof SIGNIN_LOADING
  | typeof SIGNIN_ERROR
  | typeof ORDER_STATUS_UPDATED
  | typeof ORDER_STATUS_RESET
  | typeof DELIVERY_STATUS_UPDATE
  | typeof DELIVERY_STATUS_RESET
  | typeof ADD_TO_RECENT_ORDERS
  | typeof REMOVE_FROM_RECENT_ORDERS
  | typeof TOGGLE_BROADCAST
  | typeof CAR_DELIVERY_STATUS_UPDATE
  | typeof ORDER_AGENTS_UPDATED
  | typeof ORDER_DELIVERY_METHOD_UPDATED
  | typeof ORDER_DUE_DATE_CHANGE_REQUEST_UPDATE
  | typeof ORDER_ARTWORK_CHANGE_UPDATE;

export type APP_ACTION_TYPES = {
  type: APP_ACTION_TYPE;
  stateType: STATE_TYPE;
  payload: Partial<ADMIN_ACTION_PAYLOAD_TYPE>;
  updatedOrderStatus?: {
    orderId: string;
    status: ORDER_STATUS;
  };
  order?: RECENT_ORDER_TYPE;
  recordResult?: any;
  showBroadCast?: boolean;
  updatedOrderAgents?: {
    orderId: string;
    agents: string[];
  };
  updatedOrderDeliveryMethod?: {
    orderId: string;
    deliveryMethod: string;
  };
  updatedOrderDueDate?: {
    orderId: string;
    decoOrderId: string;
    timeZone: string;
    status: "approved" | "declined";
    newDueDate: string;
  };
  updatedOrderArtwork?: {
    orderId: string;
    status: "approved" | "declined";
  };
};
