import "./style.scss";
import "nprogress/nprogress.css";

import Spinner from "@atlaskit/spinner";
import { ReactComponent as Close } from "../assets/closeRed.svg";
import React, { useEffect, useRef, useState } from "react";

interface Iprops {
	file: {
		id: string;
		url: string;
		name: string;
		loading: boolean;
	};
	delFunc: (id: string) => void;
}

export default ({ file, delFunc }: Iprops) => {
	const loaderRef = useRef<HTMLDivElement>();
	const loaderContainerRef = useRef<HTMLDivElement>();
	const [width, setWidth] = useState<number>();
	useEffect(() => {
		if (file.loading) {
			let time = 1;
			let interval = setInterval(() => {
				if (loaderRef.current) {
					if (
						loaderContainerRef.current &&
						loaderRef.current &&
						(loaderRef.current?.clientWidth /
							loaderContainerRef.current?.clientWidth) *
							100 <
							95
					) {
						// generate a random increase number
						let rando =
							Math.random() *
								(loaderContainerRef.current?.clientWidth -
									loaderRef.current?.clientWidth) +
							1;
						setWidth(loaderRef.current?.clientWidth + rando / 2);
					}
				}
			}, 300);
			return () => {
				clearInterval(interval);
			};
		}
		return () => {};
	}, []);

	// Nprogress.configure({
	//     showSpinner: false,
	//     trickleSpeed: 300,
	//     parent: `#loaderfile${file.id}`,
	//     template:
	//         '<div class="bar fileBar" role="bar"><div class="peg"></div></div><div class="spinner" role="spinner"><div class="spinner-icon"></div></div>',
	// })

	return (
		<div className="fileItem">
			<div className="fileItem__top">
				<span>{file && file.name}</span>
				{file && file.loading ? (
					<Spinner size="small" />
				) : (
					<button
						onClick={() => delFunc && (delFunc(file.id) as any)}
					>
						<Close />
					</button>
				)}
			</div>
			<div
				ref={loaderContainerRef as any}
				className={`fileItem__loaderContainer ${
					!file.loading ? "uploadedFile" : ""
				}`}
				id={`loaderfile${file.id}`}
			>
				{file.loading ? (
					<div
						ref={loaderRef as any}
						className="fileLoader"
						style={{
							width: width + "px"
						}}
					></div>
				) : null}
			</div>
		</div>
	);
};
