import "./style.scss";

import { Box, Text } from "../../_components";
import { AuthedButton, LoadingScreen } from "@samedaycustom/core-ui";
import * as Sentry from "@sentry/browser";
import React from "react";
import { ReactComponent as Back } from "../assets/backVector.svg";
import { ReactComponent as ErrorImage } from "../assets/error-image.svg";
import { ReactComponent as Logo } from "../assets/logo-full.svg";
import { ReactComponent as Reload } from "../assets/reload.svg";

class ErrorBoundary extends React.Component<
  {},
  {
    error: any;
    errorInfo: { componentStack: string };
    eventId: any;
    isLoading?: boolean;
  }
> {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
      eventId: null,
      isLoading: false,
    };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({
        error: error,
        errorInfo: errorInfo,
        eventId,
      });
    });
  }

  setLoading = (condition) =>
    this.setState({
      ...this.state,
      isLoading: condition,
    });

  render() {
    if (this.state.isLoading) return <LoadingScreen />;
    if (this.state.errorInfo) {
      return (
        <Box d="flex" justifyContent="center">
          <Box d="flex" justifyContent="space-between" alignItems="center" padding="10rem">
            <Box>
              <ErrorImage width="320px" height="380px" />
            </Box>
            <Box marginLeft="10rem">
              <Logo height="48px" width="137.78px" />
              <Text
                paddingBottom="1.5rem"
                paddingTop="1.5rem"
                fontFamily="Inter Bold"
                fontSize="1.5rem"
                width="410px"
              >
                Sorry, something went wrong, our system administrator has been notified.
              </Text>
              <Text>
                Visit our
                <a href="/" style={{ color: "#007BFF" }}>
                  &nbsp;homepage
                </a>
                &nbsp;or
                <a
                  onClick={() => {
                    this.setLoading(true);
                    Sentry.showReportDialog({
                      eventId: this.state.eventId,
                      onLoad: () => this.setLoading(false),
                    });
                  }}
                  style={{
                    color: "#007BFF",
                    cursor: "pointer",
                  }}
                >
                  &nbsp;get help&nbsp;
                </a>
                .
              </Text>
              <div className="buttomGroup">
                <AuthedButton
                  onClick={() => {
                    if ("referrer" in document) {
                      window.location.replace(document.referrer);
                      /* OR */
                      //location.replace(document.referrer);
                    } else {
                      window.history.back();
                    }
                    // window.location.back()
                  }}
                  className="TransparentFlex"
                >
                  <Back />
                  <span>Go back</span>
                </AuthedButton>
                <AuthedButton
                  onClick={() => {
                    window.location.reload();
                  }}
                  className="TransparentFlex TransparentFlex--colored"
                >
                  <Reload />
                  <span>Refresh this page</span>
                </AuthedButton>
              </div>
            </Box>
          </Box>
        </Box>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
