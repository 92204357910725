import React from "react";
import { DynamicHeadType } from "./DynamicHead";
import { DynamicRowType } from "./DynamicRow";
import DynamicTableFoldContainer from "./DynamicTableFoldContainer";

export * from "./DynamicHead";
export * from "./DynamicRow";
export * from "./DynamicTableFoldContainer";

import "./style.scss";

export const DynamicTableContext = React.createContext<DynamicFoldTableType>({
	head: null,
	isAllOpen: false,
	isShowingText: false,
	rows: [],
	setisAllOpen: null,
	emptyView: <p>No Items Found</p>,
	openedTables: [],
	setOpenedTables: null,
	setisShowingText: null,
	renderRightContent: null,
	renderLeftContent: null,
	rowColumnTemplate: null,
	headColumnTemplate: null
});

export type DynamicFoldTableType = {
	isAllOpen: boolean;
	openedTables: string[];
	isShowingText: boolean;
	setisAllOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setOpenedTables: React.Dispatch<React.SetStateAction<string[]>>;
	setisShowingText: React.Dispatch<React.SetStateAction<boolean>>;
	head: DynamicHeadType;
	rows: DynamicRowType;
	renderLeftContent?: React.ReactElement;
	renderRightContent?: React.ReactElement;
	emptyView: JSX.Element;
	rowColumnTemplate?: string;
	headColumnTemplate?: string;
};

export interface IDynamicFoldTable {
	head: DynamicHeadType;
	rows: DynamicRowType;
	emptyView?: JSX.Element;
	renderLeftContent?: React.ReactElement;
	renderRightContent?: React.ReactElement;
	rowColumnTemplate?: string;
	headColumnTemplate?: string;
	isOpenable?: boolean;
}

export default React.memo(
	({
		rows,
		head,
		emptyView,
		renderLeftContent,
		renderRightContent,
		rowColumnTemplate,
		headColumnTemplate,
		isOpenable = true
	}: IDynamicFoldTable) => {
		const [isAllOpen, setisAllOpen] = React.useState(false);
		const [openedTables, setOpenedTables] = React.useState([]);
		const [isShowingText, setisShowingText] = React.useState(false);
		return (
			<DynamicTableContext.Provider
				value={{
					isAllOpen,
					setisAllOpen,
					rows,
					head,
					emptyView,
					openedTables,
					isShowingText,
					setOpenedTables,
					setisShowingText,
					renderRightContent,
					renderLeftContent,
					rowColumnTemplate,
					headColumnTemplate
				}}
			>
				<DynamicTableFoldContainer isOpenable={isOpenable} />
			</DynamicTableContext.Provider>
		);
	}
);
