import "./style.scss";

import { ReactComponent as Default } from "@samedaycustom/assets/images/defaultNotif.svg";
import { ReactComponent as Error } from "@samedaycustom/assets/images/errorNotif.svg";
import { ReactComponent as Error2 } from "@samedaycustom/assets/images/errorNotify-2.svg";
import { ReactComponent as Close } from "@samedaycustom/assets/images/notifClose.svg";
import { ReactComponent as Success } from "@samedaycustom/assets/images/successNotif.svg";
import {
  ReactComponent as Warning,
  ReactComponent as Info
} from "@samedaycustom/assets/images/warningNotif.svg";
import React from "react";
import { Box } from "../../_components";

export type NoticationType =
  | "default"
  | "success"
  | "error"
  | "warning"
  | "info"
  | "info-success"
  | "info-error";
interface NotificationComponentProps {
  status: NoticationType;
  description: ((onclose: () => void) => React.ReactNode) | string;
  header: any;
  onClose: () => void;
  isCloseAble?: boolean;
  containerStyle?: React.CSSProperties;
}

const NotificationComponent: React.FC<NotificationComponentProps> = ({
  status,
  description,
  header,
  onClose,
  isCloseAble = true,
  containerStyle
}: NotificationComponentProps) => {
  let extraClass = "defaultNotification";
  let StatusComponent = null;
  switch (status) {
    case "success":
      extraClass = "successNotification";
      StatusComponent = <Success />;
      break;
    case "error":
      extraClass = "errorNotification";
      StatusComponent = <Error />;
      break;
    case "warning":
      extraClass = "warningNotification";
      StatusComponent = <Warning />;
      break;
    case "info":
      extraClass = "infoNotification";
      StatusComponent = <Info />;
      break;
    case "info-success":
      extraClass = "defaultNotification";
      StatusComponent = <Default />;
      break;
    case "info-error":
      extraClass = "defaultNotification";
      StatusComponent = <Error2 />;
      break;
    default:
      extraClass = "defaultNotification";
      StatusComponent = <Default />;
      break;
  }

  return (
    <Box className={`Notif__dup ${extraClass}`} {...containerStyle}>
      {StatusComponent}
      <Box className="Notif__details">
        <h2>{header}</h2>
        {typeof description === "string" ? (
          <p> {description} </p>
        ) : (
          typeof description === "function" && description(onClose)
        )}
      </Box>
      {isCloseAble && (
        <Box paddingLeft="10px" className="Notif__close" onClick={onClose}>
          <Close />
        </Box>
      )}
    </Box>
  );
};

export default NotificationComponent;
