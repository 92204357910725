import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { ActionStatus } from "@samedaycustom/types/app/Messaging";

import {
  getOrderFinancials,
  getVendorPayments,
  recordVendorPayments,
  setRecordVendorPaymentParam,
  startRecordVendorPayments,
} from "./async";
import { ISDCLocation } from "types/order/@types/order";

export interface PaymentData {
  id?: string;
  vendorName?: string;
  vendorID: string;
  city?: string;
  amount: number;
  state?: string;
  locationName?: string;
  startDate: string;
  endDate: string;
  proofUrl: string;
  datePaid?: string;
  confirmation_number?: string;
  comment: string;
  orders?: { orderId: string; revenue: number, firstName: string, lastName: string }[];
  selectedOrders?: string[];
  locationId?: string;
}
export interface OrderFinancialData {
  totalRefunds: number;
  id: string;
  vendorName: string;
  city: string;
  state: string;
  locationName: string;
  total_payout: number;
  order_payout: number;
  sm_value: number;
  cc_value: number;
  server_value: number;
  sales_tax_value: number;
  commission_value: number;
  decoOrderId: string;
  date_fulfilled: string;
  billable_amount: number;
  vendorID?: string;
  locationID?: string;
  order_id?: string;
  created_at?: string;
  updated_at?: string;
  dateOrdered?: string;
  rush_fee_payout?: number;
  order_fees?: {
    c_cost: number;
    commission: number;
    delivery_cost: number;
    payout: number;
    rush_fee_payout: number;
    server_cost: number;
    tax_cost: number;
    webshop_cost: number;
  };
  payout_status?: "paid" | "unpaid";
  location?: ISDCLocation;
  edited_payout_value?: string | number;
}
export interface PaymentsState {
  vendor_payments: {
    data: PaymentData[];
    count: number;
    status: ActionStatus;
    error: string;
    errorType?: "fetch" | "post";
    statusType?: "fetch" | "post";
  };
  order_financials: {
    data: OrderFinancialData[];
    status: ActionStatus;
    count: number;
    error: string;
    errorType?: "fetch" | "post";
    meta: {
      count?: number;
      pt?: string;
      nextStartAfter: string;
      total: number;
      page: number;
      total_cc_fees: number;
      total_order_volume: number;
      total_order_processed: number;
      total_payout: number;
      total_sales_com: number;
      total_sales_tax: number;
      total_server_fees: number;
      total_shipping_cost: number;
    };
    total: {
      total_cc_fees: number;
      total_order_volume: number;
      total_order_processed: number;
      total_payout: number;
      total_sales_com: number;
      total_sales_tax: number;
      total_server_fees: number;
      total_shipping_cost: number;
    };
  };
  vendor_payment: {
    value: PaymentData;
    status: ActionStatus;
    error: string;
    errorType?: "fetch" | "post";
    statusType?: "fetch" | "post";
  };
}
const initialState: PaymentsState = {
  vendor_payments: {
    data: [],
    count: 0,
    status: "idle",
    error: null,
    errorType: "fetch",
  },
  order_financials: {
    data: [],
    count: 0,
    status: "idle",
    error: null,
    errorType: "fetch",
    meta: {
      pt: "",
      nextStartAfter: "",
      total: 0,
      page: 0,
      total_cc_fees: 0,
      total_order_volume: 0,
      total_payout: 0,
      total_sales_com: 0,
      total_sales_tax: 0,
      total_server_fees: 0,
      total_shipping_cost: 0,
      total_order_processed: 0,
    },
    total: {
      total_cc_fees: 0,
      total_order_volume: 0,
      total_payout: 0,
      total_sales_com: 0,
      total_sales_tax: 0,
      total_server_fees: 0,
      total_shipping_cost: 0,
      total_order_processed: 0,
    },
  },
  vendor_payment: {
    value: {
      startDate: null,
      endDate: null,
      vendorID: null,
      locationId: "all",
      selectedOrders: [],
      proofUrl: "",
      comment: "",
      amount: 0,
    },
    status: "idle",
    error: null,
  },
};

const paymentsSplice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    addPayments(state: PaymentsState, action: PayloadAction<PaymentData>) {
      state.vendor_payments.data = [action.payload, ...current(state.vendor_payments.data)];
    },
    updatePayment(state: PaymentsState, action: PayloadAction<PaymentData>) {
      const findPaymentIndex = state.vendor_payments.data.findIndex(
        (payment) => payment.id === action.payload.id
      );

      const findPayment = state.vendor_payments.data[findPaymentIndex];
      state.vendor_payments.data[findPaymentIndex] = {
        ...findPayment,
        ...action.payload,
      };
    },

    resetPaymemts(state: PaymentsState) {
      state.vendor_payments = initialState.vendor_payments;
    },
    resetOrderFinancials(state: PaymentsState) {
      state.order_financials = {
        ...state.order_financials,
        meta: initialState.order_financials.meta,
        data: initialState.order_financials.data,
        count: initialState.order_financials.count,
        status: initialState.order_financials.status,
        error: initialState.order_financials.error,
        errorType: initialState.order_financials.errorType,
      };
    },
  },
  extraReducers: (builder) => {
    /**
     * Payments
     * @param state
     * @param action
     */
    builder
      .addCase(getVendorPayments.pending, (state, action) => {
        const hasPagination = action?.meta?.arg?.cn_after || action?.meta?.arg?.cn_before;
        state.vendor_payments.status = hasPagination ? "idle" : "loading";
      })
      .addCase(
        getVendorPayments.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: PaymentData[];
            meta: { count: number };
          }>
        ) => {
          state.vendor_payments.data = action.payload?.data;
          state.vendor_payments.count = Number(action.payload?.meta?.count || 0);
          state.vendor_payments.status = "succeeded";
        }
      )
      .addCase(getVendorPayments.rejected, (state, action) => {
        state.vendor_payments.status = "failed";
        state.vendor_payments.error = action.error.message;
        state.vendor_payments.errorType = "fetch";
      })
      .addCase(recordVendorPayments.pending, (state, _action: PayloadAction<PaymentData>) => {
        state.vendor_payments.status = "loading";
        state.vendor_payments.statusType = "post";
      })
      .addCase(recordVendorPayments.fulfilled, (state, action: PayloadAction<PaymentData>) => {
        state.vendor_payments.status = "succeeded";
        state.vendor_payments.statusType = "post";
        state.vendor_payments.data = [action.payload, ...current(state.vendor_payments.data)];
        state.vendor_payment = initialState.vendor_payment;
      })
      .addCase(recordVendorPayments.rejected, (state, action) => {
        state.vendor_payments.status = "failed";
        state.vendor_payments.error = action.error.message;
        state.vendor_payments.errorType = "post";
      })

      /**
       * Order Financials
       * @param state
       * @param action
       */
      .addCase(getOrderFinancials.pending, (state, action) => {
        const hasPagination = action?.meta?.arg?.bod || action?.meta?.arg?.tod;
        state.order_financials.status = hasPagination ? "idle" : "loading";
      })
      .addCase(getOrderFinancials.fulfilled, (state, action: PayloadAction<any>) => {
        const data = Array.isArray(action.payload?.data)
          ? action.payload.data
          : action.payload?.data?.data;
        state.order_financials.data = data;
        const meta = action.payload.meta;

        if (meta && Object.keys(meta).length > 1) {
          state.order_financials.meta = action.payload?.meta;
        }

        state.order_financials.status = "succeeded";
      })
      .addCase(getOrderFinancials.rejected, (state, action) => {
        state.order_financials.status = "failed";
        state.order_financials.error = action.error.message;
      })

      // To run when you click the Record Payment Button
      .addCase(setRecordVendorPaymentParam, (state, { payload }) => {
        state.vendor_payment.value = {
          ...state.vendor_payment.value,
          ...payload,
        };
      })
      .addCase(startRecordVendorPayments, (state) => {
        state.vendor_payments.status = "idle";
        state.vendor_payments.error = null;
        state.vendor_payments.errorType = null;
      });
  },
});

export const {
  resetPaymemts,
  addPayments,
  resetOrderFinancials,
  updatePayment,
} = paymentsSplice.actions;

export const paymentsSelector = (state: PaymentsState): PaymentsState => state[paymentsSplice.name];

export default paymentsSplice.reducer;
