import "./style.scss";

import { default as CSelect, OptionType, SelectProps } from "@atlaskit/select";
import Spinner from "@atlaskit/spinner";
import { Box } from "../_components";
import { ReactComponent as ArrowDown } from "./assets/arrowdown.svg";
import React from "react";
import Style from "style-it";
import { isArray } from "util";

import { ErrorMessage } from "@samedaycustom/core-ui";

export { default as SelectTag } from "./SelectTag";
export { default as SelectableFolder } from "./SelectableFolder";
export { default as MultiSelect } from "./MultiSelect";
export declare type SelectValue = {
  value: string;
  label: string;
};

export interface SelectableFolderProps {
  title: string;
  isSelected?: boolean;
  setSelected?: () => void;
}

//@ts-ignore
interface Props extends SelectProps<OptionType> {
  doubleIcon?: boolean;
  isLoading?: boolean;
  children?: any;
  value?: string;
  background?: string;
  width?: string;
  height?: string;
  color?: string;
  indicatorColor?: string;
  error?: any;
  className?: string;
  name?: string;
  margin?: string;
  borderLeftRadius?: string;
}
export const Select = ({
  children,
  doubleIcon,
  isLoading,
  background,
  border,
  borderRadius,
  borderLeftRadius,
  width,
  height,
  color,
  indicatorColor,
  margin,
  className,
  error,
  name,
  ...props
}: Props) => {
  // const [field, meta, helpers] = useField(name)

  const options = React.useCallback(() => {
    const child = isArray(children)
      ? children
      : (typeof children === "object" &&
          !isArray(children) &&
          Array.from([
            {
              props: {
                children: children.props["children"],
                value: children.props["value"]
              }
            }
          ])) ||
        [];

    return child.map(el => {
      return { label: el.props.children, value: el.props.value };
    });
  }, [children])();

  const DropdownIndicator = props => {
    return (
      <Box
        d="flex"
        flexDirection={(isLoading && "row") || "column"}
        alignItems="center"
        paddingLeft={(isLoading && "5px") || "0px"}
      >
        {isLoading && <Spinner size="xsmall" />}
        {doubleIcon && (
          <ArrowDown
            height={8}
            width={8}
            style={{
              fill: indicatorColor,
              transform: "rotate(180deg)",
              marginBottom: 2
            }}
          />
        )}
        <ArrowDown
          style={{ marginLeft: "5px" }}
          height={8}
          width={8}
          fill={`${indicatorColor}`}
        />
      </Box>
    );
  };
  return Style.it(
    `
            .dropPicker div[class*="-control"] {
                background: ${background || "#fff"};
                height:  ${height || "inherit"} !important;
                width: ${width || "inherit"};
                border: ${border ||
                  (error && "1px solid rgba(255, 0, 0, 0.603) ") ||
                  "1px solid  #bcccdc"} !important;
				border-radius: ${borderRadius || "4px"} !important;
				border-top-left-radius: ${borderLeftRadius || "4px"} !important;
				border-bottom-left-radius: ${borderLeftRadius || "4px"} !important;
				margin:${margin || "initial"};
                appearance: none;
                font-weight: 500;
                font-family: Inter;
            }
            .dropPicker div[class*="-control"] div div[class*="-singleValue"]{
                color: ${color};
                   font-weight: 500;
                font-family: Inter;
            }
            .dropPicker div[class*="-control"] div[class*="-IndicatorsContainer"] div svg{
                fill: ${indicatorColor || "#334E68"} !important;
            }
         `,
    <Box
      height={"inherit"}
      width={width || "inherit"}
      className={["select"].join(className)}
    >
      <CSelect
        name={name}
        className={`dropPicker`}
        components={{
          // @ts-ignore
          DropdownIndicator
        }}
        backspaceRemovesValue
        escapeClearsValue
        // @ts-ignore
        options={(options && isArray(options) && options) || []}
        isSearchable={false}
        {...props}
        placeholder={props.placeholder}
        value={isArray(options) && options.find(el => el.value === props.value)}
      />
      {name && <ErrorMessage name={name} />}
    </Box>
  );
};
