import { ISDCAdminUser } from "@samedaycustom/types/user";

export type USERS_ACTION_PAYLOAD = {
  profile: ISDCAdminUser;
  loading: boolean;
};
export const FETCH_USER_PROFILE = "FETCH_USER_PROFILE";
export const FETCHING_USER_PROFILE = "FETCHING_USER_PROFILE";

export type USERS_ACTION_TYPE = typeof FETCH_USER_PROFILE | typeof FETCHING_USER_PROFILE;

export type USERS_ACTION_TYPES = {
  type: USERS_ACTION_TYPE;
  payload: Partial<USERS_ACTION_PAYLOAD>;
};
