import React from "react";
import Countdown from "react-countdown";
import { generate } from "shortid";
import moment from "moment";

export type CountDownType = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};
export interface CountDownTimer {
  data: {
    id?: string | number;
    date: number;
  };
  children: (dateCount: CountDownType) => React.ReactElement;
}
export const CountdownTimer = React.memo(
  ({ data, ...props }: CountDownTimer) => {
    const renderer = ({ hours, minutes, seconds, days, completed }) => {
      if (completed) {
        // Render a completed state
        return props.children({
          hours: 0,
          minutes: 0,
          seconds: 0,
          days: 0
        });
      } else {
        // Render a countdown
        return props.children({ hours, minutes, seconds, days });
      }
    };

    return data.date ? (
      <Countdown
        key={generate()}
        date={moment(data.date)
          .toDate()
          .getTime()}
        renderer={renderer}
        // precision={3}
        // zeroPadDays={2}
        // zeroPadTime={2}
      />
    ) : (
      props.children({ hours: 0, minutes: 0, seconds: 0, days: 0 })
    );
  }
);
