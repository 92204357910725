import "./style.scss";
import { useToploader } from "@samedaycustom/hooks";
import React from "react";
import { RouteProps, Switch, withRouter } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const AnimatedSwitch = withRouter(React.memo(_AnimatedSwitch));

export interface IAnimatedSwitch extends RouteProps {
  key?: any;
}
function _AnimatedSwitch({ location, children, key }: IAnimatedSwitch) {
  const { start, done } = useToploader();
  return (
    <TransitionGroup className="transition-group">
      <CSSTransition
        key={key || location.pathname}
        classNames="switch-wrapper"
        timeout={300}
        onEnter={() => start()}
        onEntered={() => done()}
        onLeave={() => start()}
      >
        <Switch location={location}>{children}</Switch>
      </CSSTransition>
    </TransitionGroup>
  );
}

export default AnimatedSwitch;
