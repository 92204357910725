import "./style.scss";

import { DatePicker as CDatePicker } from "@atlaskit/datetime-picker";
import { AuthedButton } from "@samedaycustom/core-ui";
import React, { useState } from "react";

interface Props {
  onApply: (startdate: string, enddate: string) => void;
  onStartDate?: (val) => void;
  onEndDate?: (val) => void;
  onReset?: () => void;
  startdate?: string;
  enddate?: string;
  loading?: boolean;
}

export const DatePicker = ({
  onApply,
  onStartDate,
  onEndDate,
  startdate,
  enddate,
  loading,
  onReset
}: Props) => {
  const [touched, setTouched] = useState<boolean>(false);
  const [startDate, _setStartDate] = useState(startdate);
  const [endDate, _setEndDate] = useState(enddate);

  let errorMessage = "";
  if (!(startDate && endDate)) {
    errorMessage = "Fill in all date fields";
  } else if (
    !(new Date(endDate).getTime() - new Date(startDate).getTime() > 0)
  ) {
    errorMessage = "Start date must be before the end date";
  }
  const disabled =
    loading ||
    !(
      startDate &&
      endDate &&
      new Date(endDate).getTime() > new Date(startDate).getTime()
    );

  const setStartDate = (value: string) => {
    _setStartDate(value);
    onStartDate && onStartDate(value);
  };

  const setEndDate = (value: string) => {
    _setEndDate(value);
    onEndDate && onEndDate(value);
  };

  const _onreset = () => {
    setStartDate("");
    setEndDate("");
    setTouched(false);
    onReset && onReset();
  };
  return (
    <div className="datePickerGroup">
      <div>
        <div className="dateInputGrouping">
          <div className="timeBlockdiv startDateBlock">
            <CDatePicker
              placeholder="From"
              locale="en-US"
              onChange={val => {
                setStartDate(val);
                setTouched(true);
              }}
              value={startDate}
              hideIcon={true}
              css={{ zIndex: 100000 }}
            />
          </div>
          <div className="timeBlockdiv startDateBlock">
            <CDatePicker
              placeholder="To"
              locale="en-US"
              onChange={val => {
                setEndDate(val);
                setTouched(true);
              }}
              value={endDate}
              hideIcon={true}
              css={{ zIndex: 100000 }}
            />
          </div>
        </div>
        {errorMessage && touched ? (
          <span className="date-error">{errorMessage}</span>
        ) : null}
      </div>

      <div className="datePicker__btnGroup">
        <AuthedButton
          className={`dateActionBtn marginRight Btn--Primary ${
            disabled ? "PDisabled" : ""
          }`}
          disabled={disabled}
          onClick={() => {
            onApply(startDate, endDate);
          }}
        >
          <span>Apply</span>
        </AuthedButton>
        <AuthedButton className="dateActionBtn Btn--SDanger" onClick={_onreset}>
          <span>Reset</span>
        </AuthedButton>
      </div>
    </div>
  );
};
