// import { ESDCDeliveryMethod } from "types/order/@types/order";
import { ESDCDeliveryMethod } from "@samedaycustom/sdc-types";

/**
 *
 * The major delivery methods are pickUP | car | shipping
 * There are other delivery methdos derived from those 3 such as
 *
 * PICKUP / RUSH_PICKUP / SUPER_RUSH_PICKUP => PICKUP
 * SHIPPING / RUSH_SHIPPING / SUPER_RUSH_SHIPPING => SHIPPING
 *
 * This method convert the delivery methid provided into one of the three main methods.
 *
 * @param method method
 * @returns @ESDCDeliveryMethod
 */
export const getModifiedDeliveryMethod = (method: string) => {
  switch (method) {
    case ESDCDeliveryMethod.PICKUP:
    case ESDCDeliveryMethod.RUSH_PICKUP:
    case ESDCDeliveryMethod.SUPER_RUSH_PICKUP:
      return ESDCDeliveryMethod.PICKUP;
    case ESDCDeliveryMethod.SHIPPING:
    case ESDCDeliveryMethod.RUSH_SHIPPING:
    case "rush": //TODO: Ensure consistenty in BE and FE delivery method mapping
    case ESDCDeliveryMethod.SUPER_RUSH_SHIPPING:
    case "super_rush":
      return ESDCDeliveryMethod.SHIPPING;
    case ESDCDeliveryMethod.CAR:
    case ESDCDeliveryMethod.RUSH_CAR:
    case ESDCDeliveryMethod.SUPER_RUSH_CAR:
    case "super_rush_car":
      return ESDCDeliveryMethod.CAR;
    default:
      return method;
  }
};

export const getModifiedDeliveryMethodText = (method: string) => {
  switch (method) {
    case ESDCDeliveryMethod.RUSH_PICKUP:
      return "Rush Pickup";
    case ESDCDeliveryMethod.SUPER_RUSH_PICKUP:
      return "Super Rush Pickup";
    case ESDCDeliveryMethod.RUSH_SHIPPING:
    case "rush": // For backward compatibility
      return "Rush Shipping";
    case ESDCDeliveryMethod.SUPER_RUSH_SHIPPING:
    case "super_rush":  // For backward compatibility
    case "super_rush_shipping":  // For backward compatibility
      return "Super Rush Shipping";
    case ESDCDeliveryMethod.RUSH_CAR:  // For backward compatibility
      return "Rush Car";
    case ESDCDeliveryMethod.SUPER_RUSH_CAR: // For backward compatibility
    case "super_rush_car": 
      return "Super Rush Car";
    default:
      return method;
  }
};
