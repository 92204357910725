import { Box, Text } from "../_components";
import Skeleton from "@material-ui/lab/Skeleton";
import { ReactComponent as ArrowLeft } from "@samedaycustom/assets/images/arrow-left.svg";
import { ReactComponent as Info } from "@samedaycustom/assets/images/infoVector.svg";
import { ReactComponent as Option } from "@samedaycustom/assets/images/message-nav.svg";
import React from "react";

export interface MessageOrderHeaderProp {
  orderInfo?: {
    firstName: string;
    lastName: string;
    decoOrderId: string;
    orderId: string;
  };
  showOrderHeader?: boolean;
  onCloseOrderHeader?: () => void;
  onViewOrder?: () => void;
}
const MessageOrderHeader: React.FC<MessageOrderHeaderProp> = props => {
  return (
    <Box
      background="#fff"
      borderBottom="1px solid #D9E2EC"
      d="flex"
      justifyContent="space-between"
      padding="18px 22px"
      display="flex"
      alignItems="center"
      className="__header"
    >
      <Box className="message__view--Header-left">
        <Box className="arrowLeft" onClick={props.onCloseOrderHeader}>
          <ArrowLeft />
        </Box>
        <Text color="#102A43" fontFamily="Inter" fontWeight={500}>
          {props.orderInfo.decoOrderId ? (
            `${props?.orderInfo?.firstName} ${props.orderInfo.lastName[0]}. - ${props.orderInfo.decoOrderId}`
          ) : (
            <Skeleton width={120} />
          )}
        </Text>
      </Box>
      <Box
        d="flex"
        alignItems="center"
        cursor="pointer"
        onClick={props.onViewOrder}
        className="message__view--Header-right"
      >
        <Text
          paddingRight="3rem"
          color="#007BFF"
          fontFamily="Inter"
          fontWeight={500}
          cursor="pointer"
        >
          Order Details
        </Text>
        <Option />
      </Box>
      <Box className="message__view--detailsIcon" onClick={props.onViewOrder}>
        <Info />
      </Box>
    </Box>
  );
};

MessageOrderHeader.defaultProps = {
  orderInfo: {
    firstName: "",
    lastName: "",
    decoOrderId: "",
    orderId: ""
  },
  showOrderHeader: false,
  onCloseOrderHeader: () => {},
  onViewOrder: () => {}
};
export default MessageOrderHeader;
