import { Box, Text } from "../../_components";
import React from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";

function CustomTooltip({ payload, label, active }) {
  const title = payload?.[0]?.payload?.title || label;

  if (active) {
    return (
      <Box
        background="#fff"
        boxShadow="0px 4px 6px rgba(159, 179, 200, 0.1), 0px 2px 4px rgba(159, 179, 200, 0.06);"
        borderRadius="16px"
        padding="10px"
      >
        <Text fontSize="14px" fontWeight={500}>{`${title}`}</Text>
        <Text color="#007BFF">{`Orders: ${payload?.[0]?.value}`}</Text>
      </Box>
    );
  }

  return null;
}
const Graph = ({ data }) => {
  // console.log(
  //     data.filter(o => o.name),
  //     "data"
  // )
  return (
    <ResponsiveContainer width="100%" height={400}>
      <AreaChart
        data={data}
        margin={{ top: 10, right: 30, left: 20, bottom: 20 }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#B2D7FF" stopOpacity={1} />
            <stop offset="95%" stopColor="#DAC4FF" stopOpacity={0.8} />
          </linearGradient>
        </defs>
        {/* <XAxis dataKey="name" minTickGap={0} interval={0}> */}
        <XAxis dataKey="name">
          <Label value="Date" offset={10} position="bottom" />
        </XAxis>
        <YAxis
          label={{
            value: "Order (Quantity)",
            angle: -90,
            position: "insideLeft"
          }}
          allowDecimals={false}
          padding={{ left: 0, right: 0 }}
        ></YAxis>
        <CartesianGrid stroke="#D9E2EC" vertical={false} />
        <Tooltip content={props => <CustomTooltip {...props} />} />
        <Area
          type="monotone"
          dataKey="orders"
          dot={true}
          stroke="#007bff"
          fillOpacity={1}
          fill="url(#colorUv)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default Graph;
