import { AUTH_STATE_TYPE, SIGNIN_DATA, ThunkResult } from "@samedaycustom/types/app";
import * as api from "admin/helpers/constants";
import { catchError } from "admin/providers/actions/app";
import request from "axios";

import { persistor } from "admin/providers/store";
import { APP_ACTION_TYPES, SIGNIN_ERROR, SIGNIN_LOADING, SIGNIN_STATE } from "../app/types";
import { DELETE_AUTH_TOKEN_ACTION, UPDATE_AUTH_TOKEN_ACTION } from "./types";

export const deletetoken = () => ({});

// export const forgotPassword = (
// 	email: string
// ): ThunkResult<Promise<SIGNUP_DATA>> => {
// 	return (dispatch:Dispatch) => {
// 		return request.get(`${api.FORGOT_PASSWORD}/?email=${email}`);
// 	};
// };

// export const resetPassword = (
// 	password: string,
// 	resetToken: string
// ): ThunkResult<Promise<AUTH_STATE_TYPE>> => {
// 	return (dispatch) => {
// 		return request.post(
// 			`${api.FORGOT_PASSWORD}/${resetToken}`,
// 			{ password },
// 			{
// 				headers: {
// 					"Content-Type": api.CONTENT_TYPE
// 				}
// 			}
// 		);
// 	};
// };

// sign in authentication with api

export const isSigining = (condition: boolean): APP_ACTION_TYPES => ({
  type: SIGNIN_LOADING,
  stateType: SIGNIN_STATE,
  payload: {
    condition,
  },
});

export const signin = (
  payload: SIGNIN_DATA
): ThunkResult<Promise<{ type: string; data?: AUTH_STATE_TYPE }>> => {
  return async (dispatch) => {
    dispatch(isSigining(true));
    try {
      const res = await request.post(
        `${api.SIGN_IN}`,
        { ...payload },
        {
          headers: {
            "Content-Type": api.CONTENT_TYPE_JSON,
          },
        }
      );
      const resData = res.data.data || res.data;

      dispatch(isSigining(false));
      return Promise.resolve(
        dispatch({
          type: UPDATE_AUTH_TOKEN_ACTION,
          payload: {
            ...resData,
            isLoggedin: true,
            token: resData.token,
          },
        })
      );
    } catch (err) {
      dispatch(isSigining(false));
      dispatch(catchError(SIGNIN_ERROR, err));
    }

    return null;
  };
};

export const logout = () => {
  persistor.flush();
  return {
    type: DELETE_AUTH_TOKEN_ACTION,
  };
};
