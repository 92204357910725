import "./style.scss";

import { Box, ChakraInput as Input, InputGroup, InputProps } from "../../_components";
import { Search2Icon } from "@chakra-ui/icons";
import React, { useState } from "react";

export interface SearchProp extends InputProps {
  onSearch?: (value: string) => void;
}
const Search = ({
  value: defaultValue,
  onKeyDown,
  onSearch,
  ...props
}: SearchProp) => {
  const [value, setvalue] = useState(defaultValue);
  return (
    <Box d="flex" justifyItems="end">
      <InputGroup
        className="searchInputGroup"
        width="300px"
        borderColor="#BCCCDC"
      >
        <Input
          padding="4px"
          type="text"
          onChange={e => setvalue(e?.target?.value)}
          onKeyDown={e =>
            String(e?.key).toLowerCase() === "enter" &&
            onSearch(value as string)
          }
          value={value}
          {...props}
        />
        <button
          onClick={() => onSearch && onSearch(value as string)}
          className="searchRightBtn"
        >
          <Search2Icon fill="#627D98" />
        </button>
      </InputGroup>
    </Box>
  );
};

export default Search;
