import "./styles.scss";

import React, { useState } from "react";

import { ReactComponent as MenuSvg } from "packages/assets/images/menuSvg.svg";
import { ReactComponent as Logo } from "packages/assets/images/sidebarLogo.svg";

export { default as SidebarLink } from "./SidebarLink";
export { default as SideMenuLink } from "./customer-sidebarLinks";
export type ISideBar = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fullyOpen?: boolean;
  children?: (
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
  ) => JSX.Element;
};

export const Sidebar = ({ open, setOpen, children, renderTopChilden }) => {
  return (
    <div className={open ? "Sidebar Sidebar--open" : "Sidebar"}>
      <div className="Sidebar__Header" onClick={() => setOpen(!open)}>
        <Logo
          opacity={open ? 1 : 0}
          style={{
            transition: open ? "all 0.5s ease 0.3s" : "none"
          }}
        />
        <button
          className={`menuActionBtn ${open ? "menuActionBtn--open" : ""}`}
        >
          {" "}
          <MenuSvg />{" "}
        </button>
      </div>
      <div className="hideOnDesktop">
        {Math.max(
          document.documentElement.clientWidth || 0,
          window.innerWidth || 0
        ) < 801 && renderTopChilden
          ? renderTopChilden
          : null}
      </div>
      {children && children({ open, setOpen })}
    </div>
  );
};
