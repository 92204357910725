import "./style.scss";

import React from "react";
import usePortal from "react-useportal";
import ModalContainer from "./ModalContainer";
import ModalMainProvider from "./provider/store";

const Modal = React.memo(({ children }) => {
  const { Portal } = usePortal();
  return (
    <ModalMainProvider>
      {children}
      <Portal>
        <ModalContainer />
      </Portal>
    </ModalMainProvider>
  );
});
export default Modal;
