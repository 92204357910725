import React, { useEffect, useRef } from "react";
import "./style.scss";
type NavOptions = {
	text: string;
	image?: any;
};

type VerticalNavProps = {
	navIndex: number;
	setNavIndex(index: number): void;
	imageIncluded?: true;
	options: Array<NavOptions>;
	path?: string;
	isWhiteLabel?: boolean;
};

/**
 * Let [VerticalSliderNavigation] be a component that renders a vertical slider navigation
 * And the component should determine what to show and hide based on the current location
 * @param param0 
 * @returns 
 */
const VerticalSliderNavigation: React.FC<VerticalNavProps> = ({
	options,
	navIndex: target,
	setNavIndex,
	imageIncluded,
}) => {
	const sliderRef = useRef<HTMLInputElement | null>(null);
	// extract the images
	let imgArray = null;
	if (imageIncluded) {
		imgArray = options.map((option) => {
			let Image = option.image;
			return <Image />;
		});
	}

	useEffect(() => {
		sliderRef.current.style.transform = `translateY(${target * 32}px)`;
	}, [target]);

	return (
		<div className={"VerticalNavigationContianer"}>
			<div
				className="VerticalSliderContianer"
				style={{ height: `${options.length * 32}px` }}
			>
				<div
					className="VerticalSlider"
					ref={sliderRef}
					style={{
						transform: `transformY(${target}px)`
					}}
				></div>
			</div>
			<div className="textGroup">
				{options.map((option, idx) => (
					<div
						key={idx}
						className={`optionText ${
							target === idx ? `optionText--active` : null
						}`}
						onClick={() => {
							setNavIndex(idx);
						}}
					>
						{/* Todo add an image whenever the design has been updated to include Images */}
						<span>{option.text}</span>
					</div>
				))}
			</div>
		</div>
	);
};

export default VerticalSliderNavigation;
