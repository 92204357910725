import React, { memo, useMemo, useRef } from "react";

import Popup from "reactjs-popup";
import { generate } from "shortid";
import { Box } from "../../_components";
import Style from "style-it";

export default memo(
  ({
    image,
    label,
    height,
    width,
    hoverContent,
    borderRadius,
    style
  }: {
    image?: string;
    label?: string;
    height?: string;
    width?: string;
    hoverContent?: React.ReactElement;
    borderRadius?: string;
    style?: React.CSSProperties;
  }) => {
    const ref = useRef(image);
    useMemo(() => {
      if (image !== ref.current) ref.current = image;
    }, [image]);

    const _trigger = open => {
      return (
        <img
          src={ref.current}
          defaultValue={ref.current}
          aria-label={label}
          aria-role={label}
          alt={label}
          style={{ objectFit: "cover" }}
        />
      );
    };
    return Style.it(
      `
        .row-image-container{
        } 
        .row-image-container img{
            height: ${height || "40px"};
            width: ${width || "44px"};
            border-radius:${borderRadius || "unset"};
        }
         .row-image-container .popup-content {
             margin-top: 9rem !important;
             z-index: 99999 !important;
         }
        `,
      <Box key={generate()} className="row-image-container" {...style}>
        <Popup
          on="hover"
          position="right center"
          trigger={_trigger}
          closeOnDocumentClick={true}
          arrow={false}
          contentStyle={{
            border: "none",
            padding: "0",
            height: "fit-content",
            width: "fit-content",
            borderRadius: "4px",
            boxShadow: "0px 5px 10px rgba(98, 125, 152, 0.1)",
            zIndex: 50000
          }}
        >
          <div
            style={{
              zIndex: 50000
              // position: "absolute"
            }}
          >
            {hoverContent}
          </div>
        </Popup>
      </Box>
    );
  }
);
