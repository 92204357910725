/// <reference types="react-scripts" />

declare global {
  interface Array<T> {
    search: (targetKeys: string[], targetValue: string) => T[];
  }
}

// eslint-disable-next-line no-extend-native
Array.prototype.search = function(targetKeys: string[], targetValue: string): any[] {
  let resultsArr = [];
  if (!targetValue) {
    return this;
  } else {
    resultsArr = this.filter((item) => {
      for (let i = 0; i < targetKeys.length; i++) {
        if (item[targetKeys[i]]) {
          if (
            item?.[targetKeys?.[i]]
              ?.toString()
              ?.toLowerCase()
              .includes(targetValue.toLowerCase())
          ) {
            return item;
          }
        }
      }
    });
  }
  return resultsArr;
};
