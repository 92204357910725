import "./style.scss";

import { AuthedButton } from "@samedaycustom/core-ui";
import { DefaultCheck } from "@samedaycustom/core-ui";
import React from "react";
import { useHistory } from "react-router-dom";

const LocationListCard = ({
  header,
  targetObj,
  checked,
  checkFunc,
  unCheckFunc,
  mini
}) => {
  const history = useHistory();

  return (
    <div
      className={
        header
          ? `LLC LLC--header ${mini ? "LLC--mini" : ""} `
          : `LLC ${mini ? "LLC--mini" : ""} `
      }
    >
      <span className={"no"}>
        {header ? (
          "#"
        ) : (
          <DefaultCheck
            checked={checked}
            setChecked={checked ? unCheckFunc : checkFunc}
          />
        )}
      </span>
      <span className={"name"}>
        {header ? "Location Name" : targetObj.locationName}
      </span>
      <span className={"number"}>
        {header ? "ID number" : targetObj.locationNumber}
      </span>
      {mini ? null : (
        <span className={"number"}>
          {header ? "Phone" : targetObj.phoneNumber}
        </span>
      )}
      <span className={"name"}>
        {header ? "City/State" : `${targetObj.city}/${targetObj.state}`}
      </span>
      {mini ? null : (
        <>
          <span className={"name"}>
            {header ? "Assigned Managers" : targetObj.Manager}
          </span>

          <span className={"edit"}>
            {header ? (
              ""
            ) : (
              <AuthedButton
                className={"Btn Btn--blue"}
                onClick={() =>
                  history.push(`/store-locations/edit-location/${targetObj.id}`)
                }
              >
                <span>Edit</span>
              </AuthedButton>
            )}
          </span>
        </>
      )}
    </div>
  );
};

export default LocationListCard;
