import { ToastId, useToast, UseToastOptions } from "@chakra-ui/react";
import { NoticationType, NotificationComponent } from "@samedaycustom/core-ui";
import React from "react";

interface ToastType extends Omit<UseToastOptions, "description" | "status"> {
  description?: ((onclose: () => void) => React.ReactNode) | string;
  status: NoticationType;
  containerStyle?: React.CSSProperties;
}

interface InferedCharkaProp {
  (options: ToastType): string | number;
  close: (id: ToastId) => void;
  closeAll: () => void;
  update(id: ToastId, options: Omit<ToastType, "id">): void;
  isActive: (id: ToastId) => boolean;
}

export default () => {
  let primaryToast = useToast() as InferedCharkaProp;

  const toast = React.useMemo(() => {
    const toast = (options: ToastType) => {
      const toastResponse = primaryToast({
        ...options,
        description: "",
        render:
          typeof options.render !== "undefined"
            ? options.render
            : ({ onClose }) => {
                return (
                  <NotificationComponent
                    status={options.status}
                    description={options.description}
                    header={options.title}
                    onClose={onClose}
                    isCloseAble={options.isClosable}
                    containerStyle={options.containerStyle}
                  />
                );
              }
      });
      return toastResponse;
    };

    let tempToast: {
      update?: (id: ToastId, options: ToastType) => void;
    } = {};
    tempToast.update = (id: ToastId, options: ToastType) => {
      primaryToast.update(id, {
        ...options,
        description: "",
        render:
          typeof options.render !== "undefined"
            ? options.render
            : ({ onClose }) => {
                return (
                  <NotificationComponent
                    status={options.status}
                    description={options.description}
                    header={options.title}
                    onClose={onClose}
                    isCloseAble={options.isClosable}
                    containerStyle={options.containerStyle}
                  />
                );
              }
      });
    };

    toast.close = primaryToast.close;
    toast.closeAll = primaryToast.closeAll;
    toast.update = tempToast.update;
    toast.isActive = primaryToast.isActive;

    return toast;
  }, [primaryToast]);
  return toast;
};
