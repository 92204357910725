import React, {useEffect} from "react"
import "./style.scss"
import Nprogress from "nprogress"
import "nprogress/nprogress.css"  

export default () => {
    Nprogress.configure({
        showSpinner: false,
        trickleSpeed: 300,
        template:
            '<div class="bar customBar" role="bar"><div class="peg"></div></div><div class="spinner" role="spinner"><div class="spinner-icon"></div></div>',
    })
    useEffect(() => {
        Nprogress.start()
        return () => {
            Nprogress.done()
        }
    }, [])
    return (
        <div className="mainLoaderContainer">
            <div className="pg-loader">
                <div className="a loader" style={{["--n" as any]: "5"}}>
                    <div className="dot" style={{["--i" as any]: "0"}}></div>
                    <div className="dot" style={{["--i" as any]: "1"}}></div>
                    <div className="dot" style={{["--i" as any]: "2"}}></div>
                    <div className="dot" style={{["--i" as any]: "3"}}></div>
                    <div className="dot" style={{["--i" as any]: "4"}}></div>
                </div>
            </div>
        </div>
    )
}
