import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { getStartAndEndDateFromPeriod } from "@samedaycustom/helpers/src/dateDropdown";
import { PAYMENT } from "admin/helpers/constants";

import { Canceler } from "axios";
import qs from "query-string";
import { api, apiInstance } from "../../../api";
import { PaymentData } from "..";

const CancelToken = apiInstance.CancelToken;
let getOrderFinancialsCanceler: Canceler;
let getVendorPaymentsCanceler: Canceler;

export const getVendorPayments = createAsyncThunk(
  "payments/getVendorPayments",
  async (params: {
    vendorId: string;
    cn_before?: any;
    cn_after?: any;
    location_id?: string;
    period?: string;
    start?: string;
    end?: string;
  }) => {
    getVendorPaymentsCanceler && getVendorPaymentsCanceler("cancelled");

    if (params?.period) {
      const { start, end } = await getStartAndEndDateFromPeriod(params?.period);
      params.start = start;
      params.end = end;
      params.period = "";
    }

    const response: any = await api
      .get(
        `${PAYMENT}/${params?.vendorId}?${qs.stringify({
          ...params,
          start_date: params?.start,
          end_date: params?.end,
          vendor_id: params?.vendorId || "",
        })}`,
        {
          cancelToken: new CancelToken((c) => {
            getVendorPaymentsCanceler = c;
          }),
        }
      )
      .catch((err) => {
        if (err?.message !== "cancelled") return Promise.reject(err.response?.data?.message);
        return { data: [] };
      });
    return response?.data ?? {};
  }
);

export const recordVendorPayments = createAsyncThunk(
  "payments/recordVendorPayments",
  async (
    param: {
      vendorId?: string;
      comment?: string;
      amount?: number;
      start_date?: string;
      end_date?: string;
      location_id?: string;
      orderIds?: string[];
    },
    { getState }
  ) => {
    const { vendor_payment } = getState()["payments"];
    const { value } = vendor_payment;
    const response = await api
      .post(PAYMENT, {
        start: new Date(value.startDate) || new Date(param?.start_date),
        end: new Date(value.endDate) || new Date(param?.end_date),
        ...(value.locationId !== "all" && { locationId: value.locationId ?? param?.location_id }),
        vendorId: value.vendorID || param.vendorId,
        proofUrl: value.proofUrl,
        orderIds: value.selectedOrders || param.orderIds,
        comment: value.comment || param.comment,
      })
      .catch((err) => {
        return Promise.reject(err.response?.data?.message);
      });
    return response.data?.data;
  }
);

export const uploadPaymentProof = createAsyncThunk(
  "payments/uploadPaymentProof",
  async (param: {
    vendorId: string;
    file: File;

    onProgress?: (progressEvent: any) => void;
  }) => {
    const formData = new FormData();
    formData.append("image", param.file as any);
    const response = await api
      .post(`${PAYMENT}/${param?.vendorId}/documents`, formData, {
        onUploadProgress: param?.onProgress,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .catch((err) => Promise.reject(err.response?.data?.message));
    return response.data?.data;
  }
);

export const getOrderFinancials = createAsyncThunk(
  "payments/getOrderFinancials",
  async (param?: {
    vendorId?: string;
    location_id?: string;
    period?: string;
    start?: string;
    end?: string;
    tod?: string;
    bod?: string;
  }) => {
    getOrderFinancialsCanceler && getOrderFinancialsCanceler("cancelled");
    if (param?.period) {
      const { start, end } = await getStartAndEndDateFromPeriod(param?.period);
      param.start = start;
      param.end = end;
      param.period = "";
    }
    const query = qs.stringify({
      ...param,
      start_date: param?.start,
      end_date: param?.end,
      vendor_id: param?.vendorId === "all" ? "" : param?.vendorId ?? "",
      location_id: param?.location_id === "all" ? "" : param?.location_id ?? "",
      limit: 50,
    });

    const response = await api
      .get(`${PAYMENT}/order_financial?${query}`, {
        cancelToken: new CancelToken((c) => {
          getOrderFinancialsCanceler = c;
        }),
      })
      .catch((err) => {
        if (err?.message !== "cancelled") return Promise.reject(err.response?.data?.message);
        return Promise.resolve({ data: {} });
      });

    return response?.data ?? {};
  }
);

export const setRecordVendorPaymentParam = createAction(
  "payment/recordVendorPayment",
  (payload: Partial<PaymentData>) => ({
    payload,
  })
);

export const startRecordVendorPayments = createAction("payments/recordVendorPayments/start");
