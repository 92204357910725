import React from "react";
import { Redirect, Route, RouteProps, Switch } from "react-router";

import { SIGN_IN_PAGE } from "../screen";

export interface IPrivateRoute extends RouteProps {
  authed: boolean;
}
export default React.memo(({ component: Component, authed, ...rest }: IPrivateRoute) => {
  return (
    <Switch>
      <Route
        {...rest}
        render={(props) =>
          authed === true ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: `${SIGN_IN_PAGE}`,
                state: { from: props.location },
              }}
            />
          )
        }
      />
    </Switch>
  );
});
