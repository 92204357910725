import "./style.scss";

import { Box, IconButton, Text } from "@chakra-ui/react";
import { ReactComponent as EditIcon } from "@samedaycustom/assets/images/editPen.svg";
import { ReactComponent as SDCLogoWhite } from "@samedaycustom/assets/images/sdc-white-logo.svg";
import { ReactComponent as Trash } from "@samedaycustom/assets/images/trash.svg";
import { ImageSlider, PrimaryButton } from "@samedaycustom/core-ui";
import React from "react";

import { ISavedLayoutCard } from "..";

const SavedLayoutsDesktop = ({
  imageURLs,
  onBuyNow,
  deleteUrl,
  onEdit,
  onSetupGroup,
  designName,
  designSubName,
  status,
  deleteSavedLayouts,
  deleting,
  color
}: ISavedLayoutCard) => {
  return (
    <Box
      className="savedlayoutCard_v2"
      border="1px solid #D9E2EC"
      boxSizing="border-box"
      background="#FFFFFF"
      borderRadius="12px"
      width="248px"
      padding="24px"
      minH="330px"
    >
      <Box
        className="__header"
        width="100%"
        position="relative"
        borderRadius="4px"
        marginBottom="18px"
      >
        <Box
          width="100%"
          d="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="flex-end"
          height="100%"
          position="absolute"
          zIndex="9"
        >
          {deleteUrl && (
            <IconButton
              d="flex"
              width="32px"
              aria-label="Delete layout"
              height="32px"
              background="rgba(239,78,78,0.6)"
              borderRadius="4px"
              onClick={() => deleteSavedLayouts(deleteUrl)}
              isLoading={deleting}
              isDisabled={deleting}
              _loading={{
                color: "#fff"
              }}
              alignItems="center"
              justifyContent="center"
              icon={<Trash fill="#fff" />}
            />
          )}
          <SDCLogoWhite />
        </Box>
        {Array.isArray(imageURLs) && (
          <ImageSlider
            imageUrlArr={imageURLs}
            className="savedlayoutCard_v2__image"
          />
        )}
      </Box>
      <Box className="__footer" textAlign="left">
        <Text
          fontWeight="bold"
          fontSize={["20px", "15px"]}
          lineHeight={["25px", "20px"]}
          textAlign="left"
          textTransform="capitalize"
          color="#334E68"
        >
          {designSubName ? designSubName : "-"}
        </Text>
        <Text
          color="#102A43"
          fontWeight="normal"
          fontSize={["16px", "14px"]}
          lineHeight="20px"
          textTransform="capitalize"
        >
          {designName ? `Product: ${designName}` : "-"}
        </Text>
      </Box>{" "}
      <Box
        width="100%"
        d="flex"
        justifyContent="space-between"
        flexDirection={["column"]}
        marginTop="10px"
      >
        <PrimaryButton
          d="inline-flex"
          fontSize="14px"
          onClick={onEdit}
          fontWeight={[600, 500]}
          marginBottom={["16px"]}
        >
          <EditIcon fill="white" />
          <Text marginLeft="7px">Edit Design</Text>
        </PrimaryButton>
      </Box>
    </Box>
  );
};

export default SavedLayoutsDesktop;
