import "./style.scss";

import Spinner from "@atlaskit/spinner";
import { Box, Text } from "../_components";
import { AlternativeDropDowns, TimelineCard, ViewBar } from "@samedaycustom/core-ui";
import { ISDCOrderTimeLine } from "@samedaycustom/types/order/@types/timeline";
import React, { useState } from "react";

import { ReactComponent as CloseModal } from "./closeModal.svg";
import { ReactComponent as NoteIcon } from "./note.svg";

export interface ITimelineProps {
  selectvalue:
    | "all"
    | "order"
    | "artwork"
    | "message"
    | "payment"
    | "production"
    | "delivery";
  timeline: ISDCOrderTimeLine[];
  onSelect?: (e: any) => void;
  isLoading?: boolean;
  closeFunc?: () => void;
  tzone?: string;
}
const Timeline = ({
  selectvalue,
  timeline,
  onSelect: _onSelect,
  isLoading,
  closeFunc,
  tzone
}: ITimelineProps) => {
  const [selected, setselected] = useState(selectvalue);
  const onSelect = (e: any) => {
    setselected(e);
    _onSelect && _onSelect(e);
  };
  return (
    <Box>
      <div className="mobileTimeLineHeader">
        <div className="mlItem__left">
          <h2>Timeline</h2>
          <AlternativeDropDowns
            onClick={(e: any) => onSelect(e)}
            placeholder=""
            value={selected}
            className="TLMobileDropDown"
            loading={false}
            position="bottom center"
            listItems={[
              { label: "All History", value: "all" },
              { label: "Order", value: "order" },
              { label: "Artwork", value: "artwork" },
              { label: "Messages", value: "message" },
              { label: "Payment", value: "payment" },
              { label: "Production", value: "production" },
              { label: "Delivery", value: "delivery" }
            ]}
          />
        </div>
        <CloseModal onClick={closeFunc} />
      </div>
      <Box
        background="#007BFF"
        d="flex"
        padding="0.5rem 1rem 0.5rem 1rem"
        alignItems="center"
        height="60px"
        className="desktopTLHeader"
      >
        <Text paddingRight="1rem" fontFamily="Inter Bold" color="#fff">
          Showing
        </Text>
        <Box>
          <AlternativeDropDowns
            onClick={(e: any) => onSelect(e)}
            placeholder=""
            value={selected}
            className="TLDesktopDropDown"
            loading={false}
            position="bottom center"
            listItems={[
              { label: "All History", value: "all" },
              { label: "Order", value: "order" },
              { label: "Artwork", value: "artwork" },
              { label: "Messages", value: "message" },
              { label: "Payment", value: "payment" },
              { label: "Production", value: "production" },
              { label: "Delivery", value: "delivery" }
            ]}
          />
        </Box>
      </Box>
      <Box
        position="relative"
        padding="1.5rem"
        overflowY="scroll"
        height="80vh"
      >
        <Box className="timeline">
          {(!isLoading &&
            Array.isArray(timeline) &&
            timeline.length >= 1 &&
            timeline.map((el, i) => (
              <TimelineCard
                key={i}
                icon={<NoteIcon width="20px" height="15px" />}
                title={el.title}
                subtitle={el.subtitle}
                // @ts-ignore
                date={el.timestamp as any}
                showline={timeline.length > 1}
                tzone={tzone}
              />
            ))) ||
            (timeline.length <= 0 && !isLoading && (
              <Box d="flex" justifyContent="center" width="100%">
                <Text>No activities found</Text>
              </Box>
            )) || (
              <Box d="flex" justifyContent="center" width="100%">
                <Spinner size="large" />
              </Box>
            )}
        </Box>
      </Box>
    </Box>
  );
};
export const ViewTimeLine = ({
  viewTimeline,
  timeline,
  setviewTimeLine,
  isLoading,
  onSelect,
  onLoad,
  tzone
}: {
  viewTimeline: boolean;
  isLoading: boolean;
  timeline: ISDCOrderTimeLine[];
  setviewTimeLine?: (e: any) => void;
  onSelect: (e: any) => void;
  onLoad?: (e: any) => void;
  tzone?: string;
}) => {
  return (
    <ViewBar
      show={viewTimeline}
      closeFunc={() => setviewTimeLine(false)}
      heading="Timeline"
      className="Time_line_container Messaging__ViewBar"
      animateInClass="Messaging__ViewBar--show"
      animateOutClass="Messaging__ViewBar--noShow"
      onFullyShown={onLoad}
    >
      <Timeline
        timeline={timeline}
        selectvalue="all"
        isLoading={isLoading}
        onSelect={onSelect}
        closeFunc={() => setviewTimeLine(false)}
        tzone={tzone}
      />
    </ViewBar>
  );
};
