//  this is the hook that plays notification sounds.
import React, { useEffect, useState } from "react";
const useSound = (soundurl: string) => {
  const [audio] = useState(new Audio(soundurl));
  const [playing, setPlaying] = useState(false);
  const ping = () => setPlaying(true);

  useEffect(() => {
    try {
      if (playing) {
        const playPromise = audio.play();
        playPromise
          .then((e: any) => {})
          .catch(err => {
            audio.pause();
          });
      } else {
        audio.pause();
      }
    } catch (error) {}
  }, [playing]);

  useEffect(() => {
    // audio.load();
    audio.addEventListener("ended", () => {
      setPlaying(false);
    });
    return () => {
      audio.removeEventListener("ended", () => {
        setPlaying(false);
      });
    };
  }, []);

  return { ping };
};

export default useSound;
