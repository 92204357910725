import React from "react";
import "./style.scss";
import { ReactComponent as LogoImage } from "../assets/logo-full.svg";
import { NavLink } from "react-router-dom";

type LayoutProps = {
	children: React.ReactNode;
};

export default ({ children }: LayoutProps) => {
	return (
		<div className="UnauthedContainer">
			<div className="UnauthedContainer__header">
				<NavLink to="/">
					<LogoImage />
				</NavLink>
			</div>
			<div className="UnauthedContainer__mainContentBox">{children}</div>
		</div>
	);
};
