import React, { useState } from "react";
import { ReactComponent as SearchImg } from "../assets/searchIcon.svg";
import "./style.scss";
import { Box, BoxProps } from "../../_components";

interface searchProps extends BoxProps {
  placeholder: string;
  value: string;
  setValue: (val) => void;
  inputStyle?: React.CSSProperties;
}

const SearchBar: React.FC<searchProps> = ({
  placeholder,
  value,
  setValue,
  inputStyle,
  ...props
}: searchProps) => {
  const [focused, setFocused] = useState(false);
  return (
    <Box className={`searchDiv ${focused ? "outlined" : ""}`} {...props}>
      <SearchImg />
      <input
        type="text"
        className="searchInput"
        value={value}
        placeholder={placeholder}
        onChange={(e: any) => {
          setValue(e.target.value);
        }}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={() => setFocused(false)}
        style={inputStyle}
      />
    </Box>
  );
};

export default SearchBar;
