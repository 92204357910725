import "./style.scss";

import Spinner from "@atlaskit/spinner";
import { Box, BoxProps } from "../_components";
import React, { useState } from "react";

interface SearchableProps extends BoxProps {
  searchFunc: (val) => void;
  styles?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  inputPlaceholder: string;
  children: React.ReactNode;
  onClick?: () => void;
  overlayLoading?: boolean;
}

export const SearchableList: React.FC<SearchableProps> = ({
  searchFunc,
  styles,
  children,
  inputStyle,
  inputPlaceholder,
  onClick,
  overlayLoading,
  contentStyle,
  ...props
}: SearchableProps) => {
  const [val, setValue] = useState("");
  return (
    <Box
      position="relative"
      className="searchableBox"
      onClick={onClick}
      style={styles || null}
      {...props}
    >
      <Box className="inputBlock">
        <input
          onFocus={onClick}
          placeholder={inputPlaceholder}
          style={inputStyle ? inputStyle : null}
          value={val}
          onChange={(e: any) => {
            setValue(e.target.value);
            searchFunc(e.target.value);
          }}
        />
      </Box>

      <Box position="relative" className="searchResultsBox" {...contentStyle}>
        {overlayLoading ? (
          <Box className="overLayLoader">
            <Spinner size="small" />
          </Box>
        ) : null}
        {children}
      </Box>
    </Box>
  );
};
