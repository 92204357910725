import React from "react";
import "./styles.scss";
import { ReactComponent as Error } from "@samedaycustom/assets/images/errorNotif.svg";

const ErrorAlert = ({ ErrorMessage }: { ErrorMessage: any }) => {
	return (
		<div className="ErrorAlert">
			<Error />
			<p>{ErrorMessage}</p>
		</div>
	);
};

export default ErrorAlert;
