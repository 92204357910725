import "./style.scss";

import { Box, Text } from "../../_components";
import { NoteBubble } from "@samedaycustom/core-ui";
import { NoteInput } from "@samedaycustom/core-ui";
import { IMessageContainer } from "@samedaycustom/types/order/@types/notes";
import { ISendNote } from "@samedaycustom/core-ui";
import React, { useContext } from "react";

import ViewBar from "../ViewBar";
import NoteProvider, { NoteContext } from "./notecontext";

type NoteInputType = Pick<
  ISendNote,
  "onSendNote" | "requesFileUpload" | "onSendNote" | "initialValue" | "inputRef"
>;
export interface NoteProp extends NoteInputType {
  user: any;
  title?: string;
  notes: IMessageContainer[];
  viewNote: boolean;
  loading?: boolean;
  onDeleteNote: (note?: any, cb?: any) => void;
  onClose: () => void;
}
const Note = ({ viewNote, onClose, title, loading, ...props }: NoteProp) => {
  return (
    <ViewBar
      show={viewNote}
      closeFunc={onClose}
      heading={title}
      width="600px"
      style={{
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingTop: "0px"
      }}
      noPortal={true}
      containerClassName="Notes__ViewBar"
      animateInClass="NotesFadeIn"
      animateOutClass="NotesFadeOut"
    >
      <NoteProvider>
        <NoteBody loading={loading} {...props} />
        <NoteFooter {...props} />
      </NoteProvider>
    </ViewBar>
  );
};

function NoteBody({ notes, onDeleteNote, user, loading }) {
  const { onShowNoteInput } = useContext(NoteContext);
  return (
    <Box
      height="calc((var(--vh, 1vh) * 100) - 200px) !important"
      className="note__messageView__ContentContainer"
    >
      {loading ? (
        <Text>Loading</Text>
      ) : (
        Array.isArray(notes) && (
          <Box id="note_view" className="note__chatListContainer__fluid">
            {Array.isArray(notes) &&
              notes.map((el: IMessageContainer) => (
                <NoteBubble
                  key={el?._id}
                  {...el}
                  user={user}
                  onEdit={currentMessage =>
                    onShowNoteInput({
                      id: currentMessage?._id,
                      message: currentMessage?.message,
                      lineId: currentMessage?.type?.value,
                      type: currentMessage?.type?.name,
                      timestamp: currentMessage?.timestamp,
                      files: currentMessage?.type?.property?.files,
                      isEditing: true
                    })
                  }
                  onDelete={(currentMessage, onDelete) =>
                    onDeleteNote(currentMessage?._id, onDelete)
                  }
                />
              ))}
          </Box>
        )
      )}
    </Box>
  );
}
function NoteFooter(props: NoteInputType) {
  const noteProps = useContext(NoteContext);
  return (
    <NoteInput {...noteProps} {...props} initialValue={noteProps.noteValue} />
  );
}

export default Note;
