import {
  FETCH_ORDER,
  FETCH_ORDER_LINEITEMS,
  FETCH_ORDERS,
  FETCHING_ORDER,
  FETCHING_ORDER_LINEITEMS,
  FETCHING_ORDERS,
  FETCHING_ORDERS_ERROR,
  ORDERS_ACTION_TYPES,
  RESET_ORDERS,
  UPDATE_ORDER_MESSAGE_READ,
  RESET_ORDER,
  UPDATE_DELIVERY_METHOD,
  UPDATE_ORDER_REFUND_AMOUNT,
  UPDATE_SINGLE_ORDER,
} from "admin/providers/actions/orders/type";
import { orderBy } from "lodash";

import { ADMIN_ORDER_STATE_TYPE, ORDERS_STATE } from "./initialState";
import { FETCH_ORDERS_META } from "../../actions/orders/type";

export const ORDERS = (
  state = ORDERS_STATE,
  action: ORDERS_ACTION_TYPES
): ADMIN_ORDER_STATE_TYPE => {
  switch (action.type) {
    case FETCH_ORDERS: {
      let data = action.payload.orders;

      if (action.payload.updateType === "new") {
        const hasOrderInState = state.ordersList.data.find(
          (o) => action.payload.orders[0]?.id === o.id
        );
        const orderInState = {
          ...hasOrderInState,
          ...action.payload.orders[0],
        };
        data = [orderInState, ...state.ordersList.data];
      } else {
        data = orderBy(data, "dateOrdered", "desc");
      }
      const [newFirstOrder] = data;
      const newLastOrder = data[data.length - 1];
      const [oldFirstOrder] = state.ordersList.data;
      const oldLastOrder = state.ordersList.data[state.ordersList.data.length - 1];

      /**
       * If the orders on the table did not change
       * No need to modify the state
       *
       * Use the first order and the last order to know when the table value changes
       */
      if (newFirstOrder && newLastOrder && oldFirstOrder && oldLastOrder) {
        if (newFirstOrder?.id === oldFirstOrder?.id && newFirstOrder?.id === oldLastOrder?.id) {
          return state;
        }
      }
      return {
        ...state,
        ordersList: {
          ...state.ordersList,
          data,
        },
      };
    }
    case UPDATE_ORDER_MESSAGE_READ: {
      const orderID = action.payload.orderID;
      const isNew = action.payload.isNew;
      const newData = state.ordersList.data.map((val) => ({ ...val }));
      const hasOrderIndexState = state.ordersList.data.findIndex((o) => orderID === o.id);
      if (hasOrderIndexState !== -1) {
        const hasOrderInState = state.ordersList.data[hasOrderIndexState];
        const orderInState = {
          ...hasOrderInState,
          unreadMessages: isNew
            ? Number(isNaN(hasOrderInState?.unreadMessages) ? 0 : hasOrderInState?.unreadMessages) +
              1
            : 0,
        };
        newData[hasOrderIndexState] = orderInState;
      }

      const singleViewOrder = { ...state.order.data };
      const isUpdateOrder = singleViewOrder?.id === orderID;
      if (isUpdateOrder) {
        Object.assign(singleViewOrder, {
          unreadMessages: isNew
            ? Number(isNaN(singleViewOrder?.unreadMessages) ? 0 : singleViewOrder?.unreadMessages) +
              1
            : 0,
        });
      }

      return {
        ...state,
        ordersList: {
          ...state.ordersList,
          data: newData,
        },
        order: {
          ...state.order,
          data: isUpdateOrder ? singleViewOrder : state.order.data,
        },
      };
    }
    case FETCH_ORDERS_META:
      return {
        ...state,
        ordersList: {
          ...state.ordersList,
          metadata: action.payload.metadata,
        },
      };
    case FETCHING_ORDERS:
      return {
        ...state,
        ordersList: {
          ...state.ordersList,
          loading: action.payload.loading,
        },
        ...(action.payload.fromVendorAccountInAdmin && {}),
      };
    case FETCHING_ORDERS_ERROR:
      return {
        ...state,
        ordersList: {
          ...state.ordersList,
          loading: false,
          error: action.payload.error,
          errorMessage: action.payload.errorMessage,
        },
      };
    case FETCH_ORDER:
      return {
        ...state,
        order: {
          ...state.order,
          data: action.payload.order,
        },
      };
    case UPDATE_SINGLE_ORDER: {
      const updateOrder = {
        ...state.order.data,
        ...action.payload,
      };
      return {
        ...state,
        order: {
          ...state.order,
          data: updateOrder,
        },
      };
    }
    case UPDATE_DELIVERY_METHOD:
      return {
        ...state,
        order: {
          ...state.order,
          data: {
            ...state.order.data,
            deliveryMethod: action.payload.deliveryMethod ?? state.order.data.deliveryMethod,
          },
        },
      };
    case RESET_ORDERS:
      return {
        ...state,
        ordersList: {
          ...state.ordersList,
          loading: false,
          error: false,
          errorMessage: null,
          data: [] as any,
        },
      };
    case RESET_ORDER:
      return {
        ...state,
        order: {
          ...state.order,
          data: null,
          error: false,
          errorMessage: null,
          loading: false,
        },
      };
    case FETCHING_ORDER:
      return {
        ...state,
        order: {
          ...state.order,
          loading: action.payload.loading,
        },
      };

    case FETCH_ORDER_LINEITEMS:
      return {
        ...state,
        orderLineItems: {
          ...state.orderLineItems,
          data: action.payload.orderLineItems,
        },
      };
    case FETCHING_ORDER_LINEITEMS:
      return {
        ...state,
        orderLineItems: {
          ...state.orderLineItems,
          loading: action.payload.loading,
        },
      };
    case UPDATE_ORDER_REFUND_AMOUNT:
      if (!action.payload.errorMessage) {
        return {
          ...state,
          order: {
            ...state.order,
            data: {
              ...state.order.data,
              totalRefunds: action.payload.totalRefunds,
              billablePrice: action.payload.billableAmount,
            },
            errorMessage: null,
          },
        };
      } else {
        return {
          ...state,
          order: {
            ...state.order,
            errorMessage: action.payload.errorMessage,
          },
        };
      }

    default:
      return state;
  }
};
