import React from "react";

import { ModalContextType, ModalFormType } from "../index.d";
import Reducer, { IModalAction } from "./reducer";
import { initialModalState } from "./reducer/initialState";
import { hideModal, showModal } from "./actions";

export type IModalContextType<T extends ModalContextType> = Partial<
	IModalContext
>;
export const initialModalContext: IModalContextType<ModalContextType> = {
	showForm: {
		isOpen: false,
		title: null
	},
	open: false,
	onClose: null,
	duration: 3000,
	modalType: "text",
	bodyStyle: null,
	isCancelling: false
};

export type ModalComponentType = () => JSX.Element;

export interface IModalContext extends ModalContextType {
	show: (parameters: ModalContextType) => void;
	hide: () => void;
	form: (parameters: ModalFormType) => void;
	setValue: <T>(value: T) => void;
	setSubmitting: (condition: boolean) => void;
	dispatch: React.Dispatch<Partial<IModalAction>>;
}
export const ModalContext = React.createContext<Partial<IModalContext>>(
	initialModalContext
);

export const ModalProvider = ModalContext.Provider;
export const ModalConsumer = ModalContext.Consumer;

export default ({ children }) => {
	const [state, dispatch] = React.useReducer(Reducer, initialModalState);
	/**
	 *
	 * hide modal
	 */
	const hide = () => dispatch(hideModal());

	return (
		<ModalProvider value={{ ...state, hide, dispatch }}>
			{children}
		</ModalProvider>
	);
};
