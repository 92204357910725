const prod = process.env.REACT_APP_NODE_ENV === "production";

const stag = process.env.REACT_APP_NODE_ENV === "staging";

export const socketEndpoint: string = prod ? "https://socketsdc.com" : "https://dev.socketsdc.com";

export const vendorBaseUrl = prod
  ? "https://partner.samedaycustom.com"
  : stag
  ? "https://dev-new-sdc-vendors.web.app"
  : "http://localhost:3000";

export const ioApi: string = socketEndpoint + "/order";
export const BASE_URL: string = prod
  ? "https://api.samedaycustom.com/admin"
  : stag
  ? "https://backend-staging-2iqvv.ondigitalocean.app/admin"
  : "http://localhost:8080/admin";

export const getCustomerStoreUrl = (brandDomain?: string): string => {
  if (prod) return brandDomain ? `https://${brandDomain}` : "https://samedaycustom.com";
  if (stag) return brandDomain ? `https://store-staging.${brandDomain}` : "https://store-staging.samedaycustom.com";
  return "http://localhost:3000";
};

export const getDesignerToolUrl = (): string => {
  return process.env.REACT_APP_DESIGNER_TOOL_URL;
};

export const getCustomerStoreWLUrl = (handle: string): string => {
  const url = new URL(getCustomerStoreUrl());
  if (!handle) return url.href;
  url.pathname = `/wl/${handle}`;
  return url.href;
};

const vendorEndpoint: string = prod
  ? "https://api.samedaycustom.com/vendor"
  : stag
  ? "https://backend-staging-2iqvv.ondigitalocean.app/vendor"
  : "http://localhost:8080/vendor";

export const CONTENT_TYPE_JSON = "application/json";
export const CONTENT_TYPE = "application/json";

const AUTH_URL = `${BASE_URL}/auth`;
export const SIGN_IN = `${AUTH_URL}/login`;
export const FORGOT_PASSWORD = `${AUTH_URL}/pwd/forgot`;
export const VENDORS = `${BASE_URL}/vendors`;
export const SETTINGS = `${BASE_URL}/settings`;
export const DEFAULTS = `${BASE_URL}/defaults`;
export const ABANDONED_CARTS = `${BASE_URL}/carts`;
export const EMAIL_CONFIG = `${BASE_URL}/carts/email-triggers`;
export const CUSTOMERS = `${BASE_URL}/customers`;
export const SAVED_LAYOUTS = `${CUSTOMERS}/layouts`;
export const BRANDS = `${BASE_URL}/brands`;
export const LANDING_PAGES = `${BASE_URL}/landingpages`;
export const TEMPLATES = `${BASE_URL}/templates`;

export const ORDERS = `${BASE_URL}/orders`;
export const PAYMENT = `${BASE_URL}/payment`;
export const ORDER_FINANCIAL = `${BASE_URL}/payment/order_financial`;
export const ORDER_FINANCIAL_V2 = `${BASE_URL}/payment/v2/order_financial`;
export const USERS = `${BASE_URL}/users`;
export const STORES = `${BASE_URL}/stores`;
export const VENDOR_LOCATION = `${BASE_URL}/vendors/locations`;
export const DELIVERY = `${BASE_URL}/delivery`;
export const BROADCAST = `${BASE_URL}/broadcast`;
export const LABELS = `${BASE_URL}/delivery/labels`;
export const CREATE_TEST_ORDER = `${BASE_URL}/test/createorder`;
export const QTY_COMMISSION = `${BASE_URL}/settings/payment/qty_commission`;

// VENDOR
export const EMAIL_REVERIFY = `${vendorEndpoint}/auth/email/reverify`;
export const VENDOR_PROFILE_IMG_UPLOAD = `${vendorEndpoint}/uploads/profiles`;
export const NOTE_IMAGE_UPLOAD = `${vendorEndpoint}/uploads/notes`;

export const ADMIN_MESSAGING = `${STORES}`;
export const CUSTOMER_VENDOR_MESSAGING = `${BASE_URL}/orders/messages`;
