import "./style.scss";

import { Box, Text } from "@chakra-ui/react";
import { formatInTimeZone } from "date-fns-tz";
import moment from "moment";
import React from "react";

export interface ITimelineProps {
  title?: string;
  subtitle?: string;
  description?: string;
  attachmentText?: string;
  icon?: React.ReactNode;
  showline?: boolean;
  date?: number;
  tzone?: string;
}
export default ({ subtitle, date, title, showline, tzone }: ITimelineProps) => {
  return (
    <Box
      d="flex"
      flexDirection="row"
      height={title?.length > 100 ? "16rem" : "7rem"}
      maxHeight="16rem"
      // overflow="auto"
      className="timelinecard"
    >
      <Box color="#627D98" width="90px">
        <Text d="flex" justifyContent="flex-end" fontFamily="Inter" fontWeight={500}>
          {tzone
            ? formatInTimeZone(date, tzone, "dd MMM, yyyy")
            : moment(date).format("DD MMM YYYY") || "N/A"}
        </Text>
        <Text d="flex" justifyContent="flex-end" fontFamily="Inter" fontWeight={500}>
          {tzone
            ? formatInTimeZone(date, tzone, "hh:mm a")
            : moment(date).format("hh:mm A") || "N/A"}
        </Text>
      </Box>
      <Box
        position="relative"
        height="inherit"
        marginLeft="1rem"
        marginRight="1rem"
        marginTop="4px"
      >
        {showline && (
          <Box
            display="block"
            position="absolute"
            border="0.9px solid #D9E2EC"
            height="inherit"
            top="10px"
            className="--line"
          />
        )}

        <Box
          d="flex"
          flexDirection="column"
          justifyContent="center"
          border="2px solid #007BFF"
          borderRadius="50%"
          display="flex"
          alignItems="center"
          background="tranparent"
          marginRight="10px"
          height="16px"
          width="16px"
          position="absolute"
          left="-7px"
        >
          <Box height="8px" width="8px" background="#007BFF" borderRadius="inherit" />
        </Box>
      </Box>
      <Box width="240px" marginTop="0px">
        <Text color="#102A43" fontSize="14px">
          {title}
        </Text>
        <Text color="#627D98" fontSize="12px" fontFamily="Inter" fontWeight={500}>
          {subtitle}
        </Text>
      </Box>
    </Box>
  );
};
