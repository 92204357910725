import { Box, Text } from "../../_components";
import { ReactComponent as Close } from "../assets/closeModal.svg";
import { ReactComponent as ArrowBack } from "../assets/arrowBack.svg";
import React from "react";

import { ModalContext } from "../provider/store";

export default ({ title }: { title?: string }) => {
  const state = React.useContext(ModalContext);
  return (
    <Box className={`${state.showForm.showButtomBorder ? "Modal__header" : "Modal__header no-border"}`}>
      <Box display="flex" alignItems="center" gap="8px">
        {state.showForm.hasBackButton && (<Box
          onClick={() => state.showForm.onBackClick()}
          cursor="pointer"
          display="flex"
          justifyContent="center"
          alignItems="center"
          backgroundColor="#F0F4F8"
          width="32px"
          height="32px"
          borderRadius="100%">
          <ArrowBack />
        </Box>)}
        <Text
          color="#243B53"
          fontFamily="Inter"
          fontWeight={500}
          fontSize="1.2rem"
        >
          {title}
        </Text>
      </Box>
      
      <Close
        cursor="pointer"
        onClick={
          (state.onClose && state.onClose) ||
          (state.showForm &&
            (state?.showForm?.onClose || state.showForm.cancelOnClick)) ||
          state.hide
        }
      />
    </Box>
  );
};
