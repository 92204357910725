import "./AuthedButton/style.scss";

import { Spinner, Text, Box } from "../_components";
import React from "react";

interface IButtonTemplateProps {
  isLoading?: boolean;
  children?: any;
  loaderColor?: string;
  text?: string;
  icon?: React.ReactElement;
  backgroundColor?: string;
}
export default ({ text, loaderColor = "#fff", icon, ...props }: IButtonTemplateProps) => {
  return (
    <>
      {props.isLoading ? (
        <React.Fragment>
          &nbsp;
          <Spinner color={loaderColor} className="miniSpinner" />
          &nbsp;
          <span
            style={{
              color: props["color"] || loaderColor,
              ...(props.backgroundColor && {
                backgroundColor: props.backgroundColor,
              }),
            }}
            className="visualCue"
          >
            &nbsp;Please wait&nbsp;
          </span>
          &nbsp;&nbsp;
        </React.Fragment>
      ) : props.children ? (
        <Box d="inline-flex" alignItems="center">
          {icon && (
            <Box
              marginRight="8px"
              width="20px"
              d="flex"
              justifyContent="center"
              alignItems="center"
            >
              {icon}
            </Box>
          )}
          <Text d="inline-flex" alignItems="center">
            {props.children}
          </Text>
        </Box>
      ) : (
        <Text d="flex" alignItems="center">
          {text}
        </Text>
      )}
    </>
  );
};
