import "./style.scss";

import { ReactComponent as Mail } from "@samedaycustom/assets/images/mail.svg";
import { DefaultButton, NavigationHeader, NavItem, useModal } from "@samedaycustom/core-ui";
import { useToast } from "@samedaycustom/hooks";
import CreateOrder from "admin/components/View/CreateOrder";
import * as api from "admin/helpers/constants";
import { toggleBroadCast } from "admin/providers/actions/app";
import { logout } from "admin/providers/actions/auth";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CachePolicies, useFetch } from "use-http";

interface HeaderProps {
  showLocation?: boolean;
  openSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  sideOpen: boolean;
}

const Header = ({ openSidebar, sideOpen }: HeaderProps) => {
  const userProfileState = useSelector((state) => state["users"]);
  const dispatch = useDispatch();
  const modal = useModal();
  const toast = useToast();
  const auth = useSelector((state) => state["auth"]);
  const { get: createOrder, error: createOrderError } = useFetch(`${api.CREATE_TEST_ORDER}`, {
    headers: {
      Authorization: "Bearer " + auth?.token,
    },
    retries: 2,
    cachePolicy: CachePolicies.NO_CACHE,
  });

  React.useEffect(() => {
    if (createOrderError) {
      toast({
        title: "Order creation error",
        description: createOrderError?.message,
        status: "error",
        position: "bottom-left",
      });
    }
  }, [createOrderError]);

  const _logout = () => dispatch(logout());
  const onSendTestOrder = (): void => {
    modal.form({
      title: "Send Duplicate Order",
      okText: "Assign duplicate order",
      okOnClick: async (values: { orderId: any }) => {
        try {
          if (!values.orderId) {
            return toast({
              title: "Order creation error:",
              description: "Please enter an order number to duplicate",
              status: "error",
              position: "bottom-left",
            });
          }
          const result = await createOrder(`?order=${values.orderId}`);
          if (result) {
            toast({
              title: "Order creation successful:",
              description: result,
              status: "success",
              position: "bottom-left",
            });
            modal.hide();
          } else {
            toast({
              title: "Order creation error:",
              description: result,
              status: "error",
              position: "bottom-left",
            });
          }
        } catch (error) {}
      },
      initialValue: { orderId: "" },
      component: <CreateOrder />,
    });
  };
  return (
    <NavigationHeader
      title={"SameDayCustom"}
      onSidebarOpen={() => openSidebar(true)}
      sidebarOpen={sideOpen}
    >
      <DefaultButton marginRight="20px" onClick={() => dispatch(toggleBroadCast(true))}>
        Broadcast
      </DefaultButton>
      <DefaultButton marginRight="20px" onClick={onSendTestOrder}>
        Send duplicate order
      </DefaultButton>
      <NavItem
        image={Mail}
        notification={true}
        onItemClick={() => {
          null;
        }}
      />
      <NavItem
        avi
        profileStore={{ profile: userProfileState?.profile?.data }}
        profileUrl={null}
        onLogout={_logout}
      />
    </NavigationHeader>
  );
};

export default React.memo(Header);
