import { Text } from "@chakra-ui/react";
import { ConnectionManager } from "pusher-js";
import React, { useState } from "react";

import { useToast } from ".";

export type NetworkStatus = "open" | "closed" | "connecting";
// type PusherBinder = (
//   eventName: string,
//   callback: Function,
//   context?: any
// ) => ConnectionManager;
export type NetworkBinder = ConnectionManager;

export const useInternetStatus = () => {
  const toast = useToast();
  const [status, setstatus] = useState<NetworkStatus>();
  const networkToastRef = React.useRef<string | number>(-1);
  const networkDisconnectedRef = React.useRef<boolean>(false);

  function alertNetwork(type: NetworkStatus) {
    setstatus(type);
    if (type === "closed" || type === "connecting")
      networkDisconnectedRef.current = true;
    else networkDisconnectedRef.current = false;

    if (
      networkToastRef.current === -1 &&
      networkDisconnectedRef.current === true
    )
      networkToastRef.current = toast({
        id: "net",
        position: "top",
        title: <Text color="#E12D39">Trying to reconnect</Text>,
        status: "info-error",
        isClosable: false,
        duration: 9999999,
        containerStyle: {
          marginTop: "30px"
        }
      });

    if (networkToastRef.current !== -1) {
      toast.update(networkToastRef.current, {
        position: "top",
        title:
          networkDisconnectedRef.current === true ? (
            <Text color="#E12D39">Trying to reconnect</Text>
          ) : (
            <Text color="#0F8613">
              Your computer is connected to the internet
            </Text>
          ),
        status:
          networkDisconnectedRef.current === true
            ? "info-error"
            : "info-success",
        isClosable: false,
        duration: networkDisconnectedRef.current === true ? 9999999 : 5000,
        containerStyle: {
          marginTop: "30px"
        }
      });
    }
  }
  return { alertNetwork, status };
};
