import { Box, Text, Textarea } from "../../_components";
import { AlternativeDropDowns } from "@samedaycustom/core-ui";
import { useForm } from "@samedaycustom/core-ui";
// import { ISDCOrderCancellationType } from "@samedaycustom/types/order/@types/order";
import React from "react";
import "../style.scss";

export enum ISDCOrderCancellationType {
  CopyRight = "ICOR",
  DesignQuality = "IDQ",
  OtherDesignIssue = "IOD",
  UnableToFulfilOrder = "IUFO",
  CustomerRequest = "ICUR",
  TooBusy = "ITB",
  Closed = "IC",
  MachineBreakDown = "IMB",
}

export default ({ show, setShow }: { setShow?: () => void; show?: boolean }) => {
  const { values, setFieldValue } = useForm<{
    message: string;
    reason: ISDCOrderCancellationType;
  }>();

  return (
    <Box>
      <Box width="100%">
        <Box className="view__content">
          <Text fontSize="14px" fontFamily="Inter" fontWeight={500} color="#102A43">
            You are about to cancel this order. We will put this order on a pending cancellation
            status, and our Customer Service team will review it and work to resolve the issue. You
            will not be able to process the order until it is resumed
          </Text>
          <Text
            color="#486581"
            paddingTop="1.2rem"
            paddingBottom="0.3rem"
            fontFamily="Inter"
            fontWeight={500}
            fontSize="14px"
          >
            Reason for Cancellation
          </Text>
          <AlternativeDropDowns
            onClick={(e: any) => setFieldValue("reason", e)}
            // placeholder="Tell us why you’re declining the order"
            value={values?.reason}
            loading={false}
            position="bottom center"
            listItems={[
              {
                label: "Unable to fulfill the order",
                value: "IUFO",
              },
              { label: "Currently too busy", value: "ITB" },
              {
                label: "Design quality",
                value: "IDQ",
              },
              {
                label: "The Machine broke down ",
                value: "IMB",
              },
              {
                label: "We’re currently closed",
                value: "IC",
              },
              {
                label: "Other Design issues",
                value: "IOD",
              },
              { label: "Copywriting issues", value: "ICOR" },
              { label: "Other reasons", value: "IOR" },
              {
                label: "Customer requested the order to be cancelled",
                value: "ICUR",
              },
            ]}
          />
          {values?.reason && (
            <React.Fragment>
              <Text
                color="#486581"
                paddingTop="1.2rem"
                paddingBottom="0.3rem"
                fontFamily="Inter"
                fontWeight={500}
                fontSize="14px"
              >
                Please explain your reasons further
              </Text>
              <Textarea
                multiple
                resize="none"
                placeholder="Tell us why you're cancelling the order"
                fontSize="0.9rem"
                name="message"
                value={values.message}
                onChange={(e: any) => setFieldValue("message", e?.target?.value)}
              />
            </React.Fragment>
          )}
        </Box>
      </Box>
    </Box>
  );
};
