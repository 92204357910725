// @ts-expect-error - Look into why this is an error import NotificationSound from "@samedaycustom/assets/sounds/Popcorn.wav";
import NotificationSound from "@samedaycustom/assets/sounds/Popcorn.wav";
import { ISDCMessage } from "@samedaycustom/types/app/Messaging";
import { ISDCNote } from "@samedaycustom/types/order/@types/notes";
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { sendCustomerMessage, sendVendorMessage } from "../../providers/features/message/async";
import { addNewVendorMessage, addNewCustomerMessage } from "../../providers/features/message";
import { NotificationMessage } from "packages/core-ui";
import { useHistory } from "react-router-dom";
import { useSound, useToast } from "@samedaycustom/hooks";
import useNativeNotify from "admin/components/hooks/useNativeNotify";
import { getOrder } from "admin/providers/actions/orders";
import { ADMIN_ORDER_STATE_TYPE } from "admin/providers/reducers/orders/initialState";
import { ACTION_TYPE_DEFS } from "@samedaycustom/types/app";
import { ISDCOrder } from "@samedaycustom/types/order/@types/order";
import { ESDCOrderStatusStr } from "@samedaycustom/sdc-types";

export enum IMessageType {
  MESSAGE = "new message",
  NOTE = "new note",
  MESSAGE_NOTIFICATION = "new notification",
  MESSAGE_REPLY = "reply message",
  MARK_AS_READ = "read message",
}
export enum IUserType {
  CUSTOMER = "customer",
  VENDOR = "vendor",
  ADMIN = "admin",
}

/**
 *
 * single source of truth hook for sending message and notes
 */

export default () => {
  const dispatch = useDispatch();
  const [newMessage, setNewMessage] = useState(null);
  const history = useHistory();
  const { ping } = useSound(NotificationSound);
  const { notifyme } = useNativeNotify(NotificationSound);
  const toast = useToast();
  const orderState = useSelector<
    {
      orders: ADMIN_ORDER_STATE_TYPE;
    },
    ACTION_TYPE_DEFS<ISDCOrder>
  >((state) => state.orders.order);
  const order: ISDCOrder = React.useMemo(() => orderState?.data, [orderState?.data]);

  // Should only show customer messages notification on the Admin when order is
  // AWAITING ACCEPTANCE, FULFILLED, DECLINED or CANCELLED
  const showCustomerNotification = useMemo(
    () =>
      [
        ESDCOrderStatusStr.AWAITING_ACCEPTANCE,
        ESDCOrderStatusStr.FULFILLED,
        ESDCOrderStatusStr.DECLINED,
        ESDCOrderStatusStr.CANCELLED,
      ].includes(order?.status as ESDCOrderStatusStr),
    [order]
  );

  const [newAdminMessage, setNewAdminMessage] = useState(null);
  React.useEffect(() => {
    if (newMessage) {
      _parseNewMessages();
    }
  }, [newMessage, showCustomerNotification]);

  useEffect(() => {
    if (newAdminMessage) _parseAdminNewMessages();
  }, [newAdminMessage]);

  useEffect(() => {
    if (newMessage && newMessage.orderID === order?.id) {
      dispatch(getOrder(newMessage.orderID, true));
    }
  }, [newMessage, order?.id]);

  const updateMesssages = React.useCallback(
    (message: ISDCMessage) => {
      if (newMessage?.id !== message?.id) {
        setNewMessage(parseSocketMessage(message));
      }
    },
    [newMessage]
  );

  const updateAdminMesssages = React.useCallback(
    (message: ISDCMessage) => {
      if (newAdminMessage?.id !== message?.id) setNewAdminMessage(parseSocketMessage(message));
    },
    [newAdminMessage]
  );

  function updateMesssageNotification(message: ISDCMessage) {
    setNewMessage(parseSocketMessage(message));
  }

  function parseSocketMessage(message: ISDCNote | ISDCMessage) {
    return typeof message === "string" ? (JSON.parse(message) as ISDCMessage) : message;
  }
  /**
   * parse new messages from socket
   */
  function _parseNewMessages() {
    dispatch(addNewCustomerMessage(newMessage));
    if (showCustomerNotification) {
      if (newMessage.origin === "customer" && !history.location.pathname.includes("message")) {
        if (document.hasFocus()) ping();

        notifyme({
          type: "message",
          orderDecoId: newMessage?.decoOrderId,
          orderNumber: newMessage.orderID,
          message: newMessage.originalMessageID
            ? newMessage.options?.selected
              ? "Yes I approve"
              : "No I don't approve"
            : newMessage.content,
          locationId: newMessage?.locationId ?? newMessage?.locationID,
          userType: "customer",
        });
        toast({
          position: "bottom-left",
          status: "info",
          render: ({ onClose }) => {
            return (
              <NotificationMessage
                title={`New Message - ${newMessage.decoOrderId}`}
                orderID={newMessage.orderID}
                content={
                  newMessage.originalMessageID
                    ? newMessage.options?.selected
                      ? "Yes I approve"
                      : "No I don't approve"
                    : newMessage.content
                }
                onClose={onClose}
                onClick={() => {
                  history.push("/message", {
                    orderID: newMessage.orderID,
                    type: "customer",
                  });
                }}
              />
            );
          },
          duration: 999999,
        });
      }
    }
    setNewMessage(null);
  }

  function _sendVendorMessage(messageBody: ISDCMessage) {
    dispatch(sendVendorMessage(messageBody));
  }
  function _sendCustomerMessage(messageBody: ISDCMessage) {
    dispatch(sendCustomerMessage(messageBody));
  }

  function _parseAdminNewMessages() {
    dispatch(addNewVendorMessage(newAdminMessage));
    if (newAdminMessage.origin === "store" && !history.location.pathname.includes("message")) {
      if (document.hasFocus()) ping();

      notifyme({
        type: "message",
        locationName: newAdminMessage?.locationName,
        message: newAdminMessage?.content,
        locationId: newAdminMessage?.locationId ?? newAdminMessage?.locationID,
        vendorId: newAdminMessage?.vendorID,
        userType: "admin",
        orderDecoId: undefined,
        orderNumber: undefined,
      });
      toast({
        position: "bottom-left",
        status: "default",
        render: ({ onClose }) => {
          return (
            <NotificationMessage
              title={"New Store Message"}
              orderID={newAdminMessage?.locationID}
              content={newAdminMessage?.content}
              onClose={onClose}
              onClick={() => {
                history.push("/message", {
                  orderID: null,
                  locationId: newAdminMessage?.locationId ?? newAdminMessage?.locationID,
                  vendorId: newAdminMessage?.vendorID,
                  type: "admin",
                });
              }}
            />
          );
        },
        duration: 999999,
      });
    }
    setNewAdminMessage(null);
  }

  //   function _markAsRead(orderID: string) {
  //     dispatch(markAction(orderID));
  //   }

  const sendMessage = (type: IUserType, messageBody: ISDCMessage) => {
    switch (type) {
      case IUserType.VENDOR:
        _sendVendorMessage(messageBody);
        break;
      case IUserType.CUSTOMER:
        _sendCustomerMessage(messageBody);
        break;

      default:
        break;
    }
  };

  //   const markAsRead = (orderID: string) => _markAsRead(orderID);

  return React.useMemo(
    () => ({
      sendMessage,
      //   markAsRead,
      updateAdminMesssages,
      updateMesssages,
      updateMesssageNotification,
    }),
    [newMessage]
  );
};
