import React, { useEffect, useState } from "react";
import { Box } from "../../_components";
import "./style.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

export interface imageHoverContent {
  thumbnail: string;
  artworkImage?: string;
  isFront?: boolean;
}
export default ({ thumbnail, isFront, artworkImage }: imageHoverContent) => {
  const [isValidArtworkImage, setIsValidArtworkImage] = useState(false);

  /**
   * Show only when artworkImage is valid image and able to load
   */
  useEffect(() => {
    const img = new Image();
    img.src = artworkImage;

    img.onload = () => {
      setIsValidArtworkImage(true);
    };

    img.onerror = () => {
      setIsValidArtworkImage(false);
    };

    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [artworkImage]);

  return (
    <Box className="imagehover">
      <Box className="__content">
        <Box className="--top" position="relative">
          <LazyLoadImage src={thumbnail} />
        </Box>
        {artworkImage && isValidArtworkImage && (
          <Box className="--down">
            <LazyLoadImage src={artworkImage} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
