import React from "react";
import "./style.scss";
import { useField } from "formik";
import { ReactComponent as DropdownIndicatorImage } from "../assets/dropDownIndicator.svg";
import Select, { components, OptionsType } from "@atlaskit/select";

type GroupProps = {
  inputConfig: {
    label?: string;
    placeholder?: string;
    id: string;
    name: string;
    type: string;
    min?: number;
    max?: number;
  };
  options: OptionsType<string>;
  changeFunc: (val) => void;
  selectAll?: boolean;
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownIndicatorImage />
    </components.DropdownIndicator>
  );
};

const DropInputGroup: React.FC<GroupProps> = ({
  inputConfig,
  options,
  changeFunc,
  selectAll
}: GroupProps) => {
  const [field, meta] = useField(inputConfig);
  return (
    <div
      className={
        meta.error && meta.touched
          ? "InputGroup InputGroup--error"
          : "InputGroup"
      }
    >
      {inputConfig.label ? (
        <label htmlFor={inputConfig.id}>{inputConfig.label}</label>
      ) : null}
      <div className="dropInputGrouping">
        <input
          onFocus={selectAll ? (e: any) => e.target.select() : () => {}}
          type="input"
          {...field}
          {...inputConfig}
        />
        <Select
          className={"dropPicker"}
          components={{ DropdownIndicator }}
          defaultValue={{ label: options[0], value: options[0] }}
          options={options.map(opt => ({ label: opt, value: opt }))}
          onChange={changeFunc}
          isSearchable={false}
        />
      </div>
      {meta.error && meta.touched ? (
        <p className="inputError"> {meta.error} </p>
      ) : null}
    </div>
  );
};

export default DropInputGroup;
