import React from "react";
import "./style.scss";
import { ReactComponent as NotifImg } from "../assets/newMsg 1.svg";
import { ReactComponent as Close } from "../assets/notifClose.svg";

interface Iprops {
  title: string;
  orderID?: string;
  content: string;
  buttonText?: string;
  onClose: () => void;
  onClick: () => void;
}

const MessageNotification = ({
  content,
  onClose,
  onClick,
  title,
  buttonText = "Reply Now",
}: Iprops) => {
  return (
    <div className="msgNotif__container">
      <NotifImg />
      <div className="msgNotif__details">
        <h2>{title}</h2>
        <p>{content}</p>
        <span
          onClick={() => {
            onClick();
            onClose();
          }}
        >
          {" "}
          {buttonText}{" "}
        </span>
      </div>
      <div className="msgNotif__close" onClick={onClose}>
        <Close />
      </div>
    </div>
  );
};

MessageNotification.displayName = "Message Notification";
export default MessageNotification;
