import "./config";
import "./react-app-env.d";
import "@samedaycustom/assets/index.css";
import "@samedaycustom/assets/index.scss";
import "react-datepicker/dist/react-datepicker.css";

import { ChakraProvider, CSSReset } from "@chakra-ui/react";
import { ErrorBoundary, SameDayCustomProvider } from "@samedaycustom/core-ui";
import * as Sentry from "@sentry/browser";
import { persistor, store } from "admin/providers/store";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import App from "./App";
import theme from "./assets/theme";
import * as serviceWorker from "./serviceWorker";

const MainApp = () => {
  return (
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <SameDayCustomProvider>
          <PersistGate loading={null} persistor={persistor}>
            <CSSReset />
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </PersistGate>
        </SameDayCustomProvider>
      </Provider>
    </ChakraProvider>
  );
};

if (process.env.REACT_APP_NODE_ENV !== "development")
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_API,
  });

ReactDOM.render(<MainApp />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({ env: process.env.REACT_APP_NODE_ENV as any });
