import Skeleton from "react-loading-skeleton"
import React from "react";
import { RowTag, Box, Text } from "@samedaycustom/core-ui";

export const OrderDetailFinanceSummary = ({
  title,
  subTitle,
  value,
  sign,
  symbol,
  tag,
}: {
  title: string;
  subTitle: string;
  value: number | string;
  sign: string;
  symbol?: string;
  tag?: any;
}) => {
  return (
    <Box paddingTop="0.5rem" className="--sub">
      <OrderTotalTitle title={title} />
      <SubOrderTotalTitle title={subTitle} />
      <OrderTotalValue amount={value} sign={sign} symbol={symbol} tag={tag} />
    </Box>
  );
};

const OrderTotalTitle = ({ title }: { title: string }) => {
  return (
    <Text
      d="flex"
      justifyContent="flex-end"
      paddingRight="2rem"
      color="#486581"
      fontFamily="Inter"
      fontWeight={500}
    >
      {title}
    </Text>
  );
};

const SubOrderTotalTitle = ({
  title,
  loading
}: {
  title: string;
  loading?: boolean;
}) => {
  return (
    <Text>
      {(loading && <Skeleton height={15} width={120} />) ||
        (title ? title : "-")}
    </Text>
  );
};

const OrderTotalValue = ({
  amount,
  sign,
  symbol,
  loading,
  tag,
}: {
  amount: number | string;
  sign: string;
  symbol?: string;
  loading?: boolean;
  tag?: any;
}) => {
  return (
    <Box display={"flex"} alignItems={"center"} gap={"4px"}>
      {tag && !loading ? (<RowTag backgroundColor="#E3F9E5" text={tag} textStyle={{fontWeight: "500"}} height="24px" />) : null}

      <Text
        fontFamily="Inter"
        fontWeight={500}
        marginRight="3.3rem"
        textAlign="right"
      // color={sign == "-" ? "#EF4E4E" : "#31B237"}
      >
        {loading ? (
          <Skeleton height={15} width={50} />
        ) : (
          `${sign}${symbol ? symbol : "$"}${amount ? amount : "0.00"}`
        )}
      </Text>
    </Box>
  );
};
