import { Fallback } from "@samedaycustom/core-ui";
// import { AnimatedSwitch } from "@samedaycustom/core-ui";
import { AUTH_STATE_TYPE } from "@samedaycustom/types/app";
import { NotFound } from "@samedaycustom/core-ui";
import React, { lazy, Suspense } from "react";
import { DefaultRootState, useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import App from "./Application";
import PrivateRoute from "./PrivateRoute";
import ProtectedRoute from "./ProtectedRoute";
import { CONFIRMATION, FORGOT_PASSWORD, FORGOT_PASSWORD_RESET, SIGN_IN_PAGE } from "./screen";

const SignIn = lazy(() => import("admin/pages/Onboarding/SignIn"));

const ForgotPassword = lazy(() => import("admin/pages/Onboarding/ForgotPassword"));
const ResetPasswordPage = lazy(() => import("admin/pages/Onboarding/ForgotPassword/ResetPassword"));
const ConfirmationPage = lazy(() => import("admin/pages/Onboarding/Confirmation"));
export default () => {
  const state = useSelector<DefaultRootState, AUTH_STATE_TYPE>((state) => state["auth"]);
  const authed = state.token ? true : false;
  return (
    <BrowserRouter>
      <Suspense fallback={<Fallback />}>
        <Switch>
          <ProtectedRoute strict exact authed={authed} path={SIGN_IN_PAGE} component={SignIn} />
          <ProtectedRoute authed={authed} path={FORGOT_PASSWORD} exact component={ForgotPassword} />
          <ProtectedRoute authed={authed} path={CONFIRMATION} exact component={ConfirmationPage} />
          <ProtectedRoute
            authed={authed}
            path={`${FORGOT_PASSWORD_RESET}/:token`}
            component={ResetPasswordPage}
          />

          <PrivateRoute authed={authed} component={App} />
          <Route path={"*"} exact component={NotFound} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};
