import { iframeEvent } from "@samedaycustom/helpers";
import { AnyObject } from "@samedaycustom/sdc-types";
import { useCallback, useEffect, useState } from "react";

type Options = {
	title: string;
	iframe: React.RefObject<HTMLIFrameElement>;
	callback?: (_t: string, _d: AnyObject) => void;
};

const useIframe = (url: string, { callback, iframe }: Options) => {
  const [isReady, setIsReady] = useState(false);

	const postMessage = useCallback(
		(type: string, data: AnyObject) => {
			if (iframe.current) {
				iframe.current.contentWindow?.postMessage({ type, data }, url);
			}
		},
		[iframe, url]
	);

	const onload = () => {
		setIsReady(true);
	};

	useEffect(() => {
		if (window) {
			window.addEventListener("message", e => {
				if (e.origin !== url) return;
				const { type, data } = e.data;
				callback?.(type, data);
				iframeEvent.next({ type, data });
			});
		}

		iframeEvent.subscribe(({ type, data }) => {
			postMessage(type, data);
		});
	}, []);

	return { postMessage, isReady, onload };
}

export default useIframe;
