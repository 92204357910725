import { ACTION_TYPE_DEFS } from "@samedaycustom/types/app";
import { ISDCAdminVendors } from "@samedaycustom/types/vendors";
import { OrderListPaginationInfo } from "@samedaycustom/types/app/Operations";
import { ISDCOrder } from "@samedaycustom/types/order/@types/order";
import { IStoreMinData } from "@samedaycustom/sdc-types";

export type VENDOR_DATA = ISDCAdminVendors[];

export interface OrdersList extends OrderListPaginationInfo {
  data: ISDCOrder[];
}
export interface IVendorData extends Partial<ACTION_TYPE_DEFS<ISDCAdminVendors>> {
  orders: OrdersList;
  locations: ACTION_TYPE_DEFS<
    (IStoreMinData & { isWhitelabel?: boolean; wlHandle?: string; note?: string })[]
  >; //TODO: add isWhitelabel and wlHandle to IStoreMinData
}
export type ADMIN_VENDOR_STATE_TYPE = {
  vendorsList: ACTION_TYPE_DEFS<VENDOR_DATA>;
  vendor: IVendorData;
};
export const VENDOR_STATE: ADMIN_VENDOR_STATE_TYPE = {
  vendorsList: {
    error: false,
    errorMessage: null,
    data: [],
    loading: false,
  },
  vendor: {
    error: false,
    errorMessage: null,
    data: null,
    loading: false,
    orders: { all: 0, completed: 0, open: 0, data: [] },
    locations: {
      error: false,
      errorMessage: null,
      data: [],
      loading: false,
    },
  },
};
