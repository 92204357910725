import { ILogCard, LogCard } from "@samedaycustom/core-ui";
import React from "react";

import ViewBar from "../ViewBar";

export interface IAcceptanceLog {
  view: boolean;
  onViewLog: (v?: boolean) => void;
  logs: ILogCard[];
  tzone?: string
}
const ViewLog = ({ view, onViewLog, logs, tzone }: IAcceptanceLog) => {
  return (
    <ViewBar
      show={view}
      closeFunc={() => onViewLog(false)}
      heading="Acceptance Log"
    >
      {Array.isArray(logs) && logs.map(o => <LogCard {...o} tzone={tzone} />)}
    </ViewBar>
  );
};

export default ViewLog;
