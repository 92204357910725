import "./style.scss";

import Spinner from "@atlaskit/spinner";
import { Box } from "../../_components";
import { isEmptyArray, useField } from "formik";
import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress
} from "react-places-autocomplete";
import { ReactComponent as Lock } from "../assets/locked.svg";

// This component should fetch from the google geolocation api
// the set the value of the address fields, the city fields and the state fields and leave further room for the state postal code

interface LocationInputProps {
  inputConfig: {
    label?: string;
    placeholder?: string;
    id: string;
    name: string;
    type: string;
    min?: number;
    max?: number;
  };
  disabled?: boolean;
  prevValues?: any;
  setValuesFunc?: (val: any) => void;
  targetZipCodeKey?: string;
  targetStateKey?: string;
  targetCityKey?: string;
  targetStateCodeKey?: string;
}

const LocationInput = ({
  setValuesFunc,
  inputConfig,
  prevValues,
  disabled,
  targetZipCodeKey,
  targetStateKey,
  targetCityKey,
  targetStateCodeKey
}: LocationInputProps) => {
  const [, meta, helpers] = useField(inputConfig);
  const { value } = meta;
  const { setValue } = helpers;

  const handleChange = value => {
    setValue(value);
  };

  const onError = (status, clearSuggestions) => {
    console.log("Google Maps API returned error with status: ", status);
    clearSuggestions();
  };
  const handleSelect = value => {
    try {
      geocodeByAddress(value).then(async res => {
        if (!res) return null;

        const addressFields = res[0].formatted_address.split(", ");
        const country = addressFields[3];

        const stateFound = res[0].address_components.find(o =>
          o.types.includes("administrative_area_level_1")
        );

        helpers && helpers.setValue(value);
        setValuesFunc &&
          setValuesFunc({
            ...prevValues,
            country,
            [inputConfig.name]: addressFields[0],
            [targetCityKey || "city"]: addressFields[1],
            [targetZipCodeKey || "zipcode"]:
              country === "USA" ? addressFields[2].split(" ")[1] : "",
            [targetStateCodeKey || "stateCode"]:
              country === "USA" ? stateFound?.short_name : "",
            [targetStateKey || "state"]:
              country === "USA" ? (stateFound ? stateFound?.short_name : "") : ""
          });
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <PlacesAutocomplete
      value={value}
      onChange={handleChange}
      onSelect={handleSelect}
      onError={onError}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        return (
          <Box
            className={
              meta.error && meta.touched
                ? "InputGroup InputGroup--error"
                : "InputGroup"
            }
            width="inherit"
          >
            {inputConfig.label ? (
              <label htmlFor={inputConfig.id}>{inputConfig.label}</label>
            ) : null}
            <Box className="locationInputContainer">
              {disabled ? <Lock className="lockSvg specialLockSvg" /> : null}
              <input
                {...getInputProps({
                  placeholder: "Find address...",
                  className: `inputAddress ${disabled ? "disabledInput" : ""}`,
                  name: inputConfig.name
                })}
              />
              <Box className="autocomplete-dropdown">
                {loading && (
                  <div className="loadingBox">
                    {" "}
                    <Spinner size="small" />{" "}
                  </div>
                )}
                <Box
                  className={`${!loading &&
                    !isEmptyArray(suggestions) &&
                    "dropdown"}`}
                >
                  {!loading &&
                    suggestions &&
                    suggestions.map(suggestion => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? {
                            backgroundColor: "#f0f4fe",
                            cursor: "pointer"
                          }
                        : {
                            backgroundColor: "#ffffff",
                            cursor: "pointer"
                          };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                </Box>
              </Box>
            </Box>
            {meta.error && meta.touched ? (
              <p className="inputError"> {meta.error} </p>
            ) : null}
          </Box>
        );
      }}
    </PlacesAutocomplete>
  );
};

export default LocationInput;
