import "./style.scss";

import Spinner from "@atlaskit/spinner";
import { SearchableList, Text, BoxProps, Box } from "@samedaycustom/core-ui";
import React, { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";

import { ReactComponent as DropIcon } from "../assets/dropDownIcon.svg";
import { ReactComponent as ArrowIcon } from "@samedaycustom/assets/images/arrow-right.svg";
import { generate } from "shortid"
declare global {
  interface Array<T> {
    flterExtended: (value: string, targetKeys: Array<string>) => Array<T>;
  }
}

// eslint-disable-next-line no-extend-native
Array.prototype.flterExtended = function (value, targetKeys) {
  if (value) {
    return this.filter(location => {
      for (let i = 0; i < targetKeys?.length; i++) {
        if (location[targetKeys[i]]) {
          if (
            location[targetKeys[i]]
              .toString()
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          ) {
            return true;
          }
        }
      }
      return false;
    });
  }
  return this;
};

type Position =
  | "top left"
  | "top center"
  | "top right"
  | "right top"
  | "right center"
  | "right bottom"
  | "bottom left"
  | "bottom center"
  | "bottom right"
  | "left top"
  | "left center"
  | "left bottom"
  | "center center";

export type IDropItems = Array<{
  shortLabel?: string;
  label;
  value;
  onClick?: (e: any) => void;
  onClickSubList?: (e: any) => void;
}>;
export interface IDropDownProps {
  onClick: (val) => void;
  listItems: IDropItems;
  value: string | number | string[];
  loading?: boolean;
  placeholder?: string | JSX.Element;
  className?: string;
  optionClassName?: string;
  popupClassName?: string;
  // label: string
  position?: Position;
  isSearchable?: boolean;
  title?: React.ReactElement | string;
  leftContent?: React.ReactElement | string;
  searchPlaceholder?: string;
  containerStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  labelContainerProp?: BoxProps;
  icon?: JSX.Element;
  disabled?: boolean;
  leftContentStyle?: React.CSSProperties;
  isCheckList?: boolean;
  scrollHeight?: string;
  onClickSubList?: (e: any) => void;
}

const DropDownItem = ({ onClick, onClickSubList, item, value, isCheckList, closeFuncInner, containerRef }) => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const [hoveredItemOffset, setHoveredItemOffset] = useState(0);

  const handleClick = (event) => {
    event.stopPropagation();
    onClick(event);
  };

  const handleSubListClick = (event, subItemValue) => {
    closeFuncInner();
    event.stopPropagation();
    onClickSubList(subItemValue);
  };
  return (
    <React.Fragment>
      <Box
        className={`ADropDown__Option ${isCheckList
          ? value?.includes(item?.value)
          : item?.value?.toLowerCase() === value?.toLowerCase()
            ? "activeOption"
            : ""
          }`}
        onClick={handleClick}
        onMouseEnter={(event) => {
          if (item?.subList?.length) {
            setHoveredItem(item?.value);
            const scrollableTop = containerRef.current.getBoundingClientRect().top;
            const itemTop = event.currentTarget.getBoundingClientRect().top;
            setHoveredItemOffset(itemTop - scrollableTop);
          }
        }}
        onMouseLeave={() => {
          if (item?.subList?.length) {
            setHoveredItem(null);
            setHoveredItemOffset(0);
          }
        }}
      >
        {isCheckList && <input type="checkbox" checked={value?.includes(item?.value)} onChange={onClick} />}
        <span title={item?.label} className="miniFlyoutItem">
          {item.label}
        </span>
        {item?.subList?.length ? <ArrowIcon /> : null}
        {item?.subList?.length && hoveredItem && hoveredItem === item?.value ? (
          <Box position={"absolute"} top={`${hoveredItemOffset}px`} right={"-180px"} minWidth={"200px"} maxHeight={"200px"} overflowY={"auto"} boxShadow={"0px 6px 18px 0px #627D983D"} backgroundColor={"#fff"} borderRadius={"6px"}>
            {item?.subList?.map(subItem => (
              <Box
                key={generate()}
                className={`ADropDown__Option ${isCheckList
                  ? value?.includes(subItem?.value)
                  : subItem?.value?.toLowerCase() === value?.toLowerCase()
                    ? "activeOption"
                    : ""
                  }`}
                onClick={(event) => handleSubListClick(event, subItem?.value)}
              >
                {isCheckList && <input type="checkbox" checked={value?.includes(subItem?.value)} onChange={onClick} />}
                <span title={subItem?.label} className="miniFlyoutItem">
                  {subItem.label}
                </span>
              </Box>
            ))}
          </Box>
        ): null}
      </Box>
    </React.Fragment>
  );
};

export default ({
  onClick,
  listItems,
  value,
  loading,
  className,
  title,
  optionClassName,
  placeholder,
  position = "bottom center",
  isSearchable,
  searchPlaceholder,
  leftContent,
  popupClassName,
  icon,
  labelStyle,
  labelContainerProp,
  disabled,
  leftContentStyle,
  isCheckList,
  scrollHeight,
  ...props
}: IDropDownProps) => {
  const [searchValue, setSeachValue] = useState<string>("");
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const containerRef = useRef(null);

  useEffect(() => {
    if (isCheckList) {
      setSelectedValues(value as any);
    }
  }, [value]);

  const handleItemClick = (itemValue: string, closeFuncInner: () => void) => {
    if (isCheckList) {
      if (selectedValues.includes(itemValue)) {
        setSelectedValues(selectedValues.filter(value => value !== itemValue));
        onClick(selectedValues.filter(value => value !== itemValue));
      } else {
        setSelectedValues([...selectedValues, itemValue]);
        onClick([...selectedValues, itemValue])
      }
    } else {
      closeFuncInner();
      setSeachValue("");
      onClick(itemValue);
    }
  };

  const _onSearch = (val): void => setSeachValue(val);
  // const Parent = isSearchable ? SearchableList : React.Fragment;

  const _items = React.useMemo(
    () => listItems.flterExtended(searchValue, ["label"]),
    [searchValue, listItems]
  );

  // dynamically fill the popwidth wirh the container width
  React.useEffect(() => {
    const container = document.getElementById("ADropDown");
    if (container) {
      const containerStyle = window.getComputedStyle
        ? getComputedStyle(container, null)
        : container.currentStyle;

      document.documentElement.style.setProperty(
        "--popup-width",
        containerStyle?.width
      );
      // document.documentElement.style.setProperty(
      //   "--popup-left",
      //   `${container.offsetLeft}px`
      // );
    }
  }, []);

  /**
   * popup trigger component
   * @param open
   */
  const _trigger = open => {
    const selectedValue = listItems.find(item => item.value === value);
    return (
      <Box>
        {(title || leftContent) && (
          <Box
            d="inline-flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            {title && (
              <Text
                color="#486581"
                fontWeight={500}
                fontSize="14px"
                paddingBottom="4px"
              >
                {title}
              </Text>
            )}
            {leftContent && <Box {...(leftContentStyle || {})} float="right">{leftContent}</Box>}
          </Box>
        )}
        <Box
          id="ADropDown"
          className={`
            ADropDown__Container ADropDown__LabelContainer 
            ${disabled ? "ADropDown__disabled-state" : ""} 
            ${className ? className : ""}
          `}
          style={{
            pointerEvents: listItems?.length < 2 ? "none" : "all"
          }}
          overflow="hidden"
          {...labelContainerProp}
        >
          {/* <Box
            className="ADropDown__LabelContainer"
            style={{
              pointerEvents: listItems?.length < 2 ? "none" : "all"
            }}
          > */}
          <Box className={`ADropDown__LabelContainer--text ${isCheckList ? "dropdown-checklist-label" : ""}`} overflow="hidden">
            <Text {...labelStyle}>
              {isCheckList
                ? selectedValues?.map(value => listItems.find(item => item.value === value)?.label).join(', ')
                : selectedValue
                  ? selectedValue?.shortLabel || selectedValue?.label
                  : placeholder}
            </Text>
          </Box>
          <Box className="FDropDown__LabelContainer--icon">
            {loading ? (
              <Spinner size="small" />
            ) : icon ? (
              icon
            ) : (
              <DropIcon fill="#334E68" className="dropIcon" />
            )}
            {/* </Box> */}
          </Box>
        </Box>
      </Box>
    );
  };

  const dorpDownItemsList = ({ closeFuncInner }) => {

    return _items.length >= 1 && !loading ? (
      _items.map(item => {
        return (
          item &&
          item.value && (
            <DropDownItem
              item={item}
              value={value}
              isCheckList={isCheckList}
              onClick={() => handleItemClick(item.value, closeFuncInner)}
              onClickSubList={props.onClickSubList}
              closeFuncInner={closeFuncInner}
              containerRef={containerRef}
            />
          )
        );
      })
    ) : (
      <Text width="100%" textAlign="center" padding="1rem">
        {loading ? "Loading... " : "No Items Found"}
      </Text>
    );
  };

  return (
    <Popup
      position={position}
      offsetX={25}
      offsetY={15}
      arrow={false}
      contentStyle={{
        width: "unset",
        border: "none",
        padding: "0",
        height: "unset",
        borderRadius: "4px",
        boxShadow: "0px 6px 18px rgba(98, 125, 152, 0.24)",
        ...props.containerStyle
      }}
      closeOnDocumentClick={true}
      trigger={_trigger}
      className={`dropdown ${popupClassName ? `${popupClassName}` : ""}`}
    >
      {closeFuncInner => {
        return !disabled && (
          <Box
            className={`ADropDown__OptionsContainer ${optionClassName ? optionClassName : ""
              }`}
            height={scrollHeight ? scrollHeight : ""}
            overflowY={scrollHeight ? "auto" : "hidden"}
            ref={containerRef}
          >
            {isSearchable ? (
              <SearchableList
                /**
                 * following props
                 * only applies to searchable component
                 */
                styles={{
                  width: "inherit",
                  boxShadow: "none"
                }}
                searchFunc={_onSearch}
                inputPlaceholder={searchPlaceholder}
              >
                {dorpDownItemsList({ closeFuncInner })}
              </SearchableList>
            ) : (
              <React.Fragment>
                {dorpDownItemsList({ closeFuncInner })}
              </React.Fragment>
            )}
          </Box>
        );
      }}
    </Popup>
  );
};
