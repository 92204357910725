import React from "react";

import MessageInput, { MessageInputProp } from "./MessageInput";

export interface MessageFooterProp extends MessageInputProp {}
export const MessageFooter: React.FC<MessageFooterProp> = React.forwardRef(
  (_props, ref: any) => {
    return (
      <MessageInput
        ref={(ref as unknown) as React.Ref<HTMLInputElement>}
        {..._props}
      />
    );
  }
);
