import { Box, Button, Text } from "../../_components";
import React, { useEffect } from "react";
import { useModal } from "@samedaycustom/core-ui";
import EditPayout from "./EditPayout";
import useFetch from "use-http";
import { ORDER_FINANCIAL } from "../../../../../projects/admin/src/helpers/constants";
import { useToast } from "../../../../hooks/src/index";
import UnlockEditPayoutLimit from "./UnlockEditPayoutLimit"

export default ({
  amount,
  oldAmount,
  showTaxPayout = true,
  rushFeePayout: rushFeePayout = 0,
  isAdmin,
  auth,
  order,
  isEdited,
  discountPercent,
  couponCode,
}: {
  amount: number | string;
  oldAmount?: number | string;
  showTaxPayout?: boolean;
  rushFeePayout?: number;
  isAdmin?: boolean;
  auth?: any;
  order?: any;
  isEdited?: boolean;
    discountPercent?: number;
    couponCode?: string;
}) => {
  const modal = useModal();
  const [showPasswordModal, setShowPasswordModal] = React.useState(false);
  const [allowedToExceedLimit, setAllowedToExceedLimit] = React.useState(false);
  const [newPayoutAmount, setNewPayoutAmount] = React.useState(amount);

  const toast = useToast();
  let payoutTitle = showTaxPayout
    ? "Partner payout"
    : "The payout amount will be disclosed once the order is fulfilled.";

  const { post: editPayout, response } = useFetch(`${ORDER_FINANCIAL}/${order?.id}`, {
    headers: {
      Authorization: "Bearer " + auth?.token,
    },
  });

  const handleAllowExceedLimit = async () => {
    if (allowedToExceedLimit) {
      const result = await editPayout({
        payout: newPayoutAmount
      });
      if (result?.data) {
        toast({
          title: "Edit Payout:",
          description: `Payout edited successfully`,
          position: "bottom-left",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        modal.hide();
      } else if (result.error) {
        toast({
          title: "Edit Payout:",
          description: result?.message,
          position: "bottom-left",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
    setAllowedToExceedLimit(false);
  }

  useEffect(() => {
    handleAllowExceedLimit();
  }, [allowedToExceedLimit]);

  return (
    <>
      <Box
        width={"100%"}
        maxWidth={"300px"}
        marginRight="auto"
        alignSelf={"flex-start"}
        padding={"12px 15px"}
        border={"1px solid #F8FAFC"}
        background={"#F8FAFC"}
        borderRadius={"12px"}
      >
        <Box display="flex" justifyContent="space-between">
          <Text
            color="#334E68"
            fontFamily="Inter"
            fontWeight={500}
            fontSize="13px"
            lineHeight="18px"
            marginBottom="4px"
          >
            {payoutTitle}
          </Text>
          {isAdmin && (
            <Button
              onClick={() => {
                modal.form({
                  title: "Edit partner payout",
                  okText: "Save changes",
                  showButtomBorder: false,
                  isSingleButton: true,
                  okOnClick: async (values: { payout: string }) => {
                    setNewPayoutAmount(values.payout)
                    if (!allowedToExceedLimit) {
                      setShowPasswordModal(true);
                      return;
                    }
                    const result = await editPayout({
                      payout: values.payout,
                    });
                    if (result?.data) {
                      toast({
                        title: "Edit Payout:",
                        description: `Payout edited successfully`,
                        position: "bottom-left",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                      });
                      modal.hide();
                    } else if (result.error) {
                      toast({
                        title: "Edit Payout:",
                        description: result?.message,
                        position: "bottom-left",
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                      });
                    }
                    return Promise.resolve(result);
                  },
                  initialValue: {
                    payout: amount,
                  },
                  component: <EditPayout />,
                });
              }}
              height="28px"
              borderRadius="6px"
              border="1px solid var(--neutral-200, #BCCCDC)"
              background="var(--neutral-0, #FFF)"
            >
              Edit payout
            </Button>
          )}
        </Box>
        {showTaxPayout ? (
          <Box>
            <Box display="flex" alignItems="baseline" gap="4px">
              <Text
                color="#0A1A29"
                fontSize="24px"
                fontFamily="Inter"
                fontWeight={500}
                lineHeight="32px"
              >
                ${amount}
              </Text>
              {isEdited && (
                <Text
                  color="#627D98"
                  fontSize="14px"
                  fontFamily="Inter"
                  fontWeight={500}
                  textDecoration="line-through"
                >
                  ${oldAmount}
                </Text>
              )}
            </Box>
            {rushFeePayout > 0 && (
              <Text
                color="#18981D"
                fontSize="14px"
                fontFamily="Inter"
                fontWeight={600}
                lineHeight="20px"
              >
                includes ${rushFeePayout} earned as rush fee
              </Text>
            )}
            <Text
              style={{
                color: "#07121D",
                fontSize: 14,
                fontFamily: "Inter",
                fontWeight: "500",
                wordWrap: "break-word",
              }}
            >
              Discount used: {discountPercent}%
            </Text>
            {isAdmin && (<Text
              style={{
                color: "#07121D",
                fontSize: 14,
                fontFamily: "Inter",
                fontWeight: "500",
                wordWrap: "break-word",
              }}
            >
              Coupon code: {couponCode || "N/A"}
            </Text>)}
          </Box>
        ) : (
          <Box />
        )}
      </Box>
      <UnlockEditPayoutLimit
        limit={order?.price}
        showModal={showPasswordModal}
        setShowModal={setShowPasswordModal}
        setAllowedToExceedLimit={setAllowedToExceedLimit}
      />
    </>
  );
};
