import { useDate } from "@samedaycustom/hooks";
import React from "react";

const Time = ({
  timezone,
  children
}: {
  timezone?: string;
  children?: (parma?: {
    date: string;
    time: string;
    wish: string;
  }) => JSX.Element;
}) => {
  const timer = useDate(timezone);
  return children(timer);
};

export default Time;
