import {
	HIDE_MODAL,
	SHOW_MODAL,
	SHOW_MODAL_FORM,
	SUBMITTING_MODAL,
	CANCELLING_MODAL,
	VALUE_CHANGE
} from "./types";
import { IModalAction } from "../reducer/index";
import { ModalFormType, ModalContextType } from "../../index.d";

export const hideModal = (): Partial<IModalAction> => ({
	type: HIDE_MODAL
});

export const submittingModal = (condition: boolean): Partial<IModalAction> => ({
	type: SUBMITTING_MODAL,
	isSubmitting: condition
});
export const cancellingModal = (condition: boolean): Partial<IModalAction> => ({
	type: CANCELLING_MODAL,
	isCancelling: condition
});

export const showModal = (
	parameters: ModalContextType
): Partial<IModalAction> => ({
	type: SHOW_MODAL,
	...parameters
});

export const showForm = (parameters: ModalFormType): Partial<IModalAction> => ({
	type: SHOW_MODAL_FORM,
	form: parameters
});

export const updateChanges = <T,>(value: T): Partial<IModalAction> => ({
	type: VALUE_CHANGE,
	value
});
