import React from "react"
import ContentLoader from "react-content-loader"

interface Props {
    color?: string
    backgroundColor?: string
    speed?: number
    width?: number
    height?: number
    y?: number
    x?: number
    r?: number
}
const ImageLoader = ({
    color,
    backgroundColor,
    height,
    speed,
    width,
    y = 10,
    x = 10,
    r = 50,
}: Props) => (
    <ContentLoader
        speed={speed || 2}
        width={width || 400}
        height={height || 160}
        viewBox={`0 0 ${width || "400 "} ${height || "160"}`}
        backgroundColor={backgroundColor || "#f3f3f3"}
        foregroundColor={color || "#00ffff"}
    >
        <circle cx={x} cy={y} r={r} />
    </ContentLoader>
)

export default ImageLoader
