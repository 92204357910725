import { Box } from "../../_components";
import { Formik } from "formik";
import React, { useEffect } from "react";

import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import ModalHeader from "../ModalHeader";
import { hideModal } from "../provider/actions";
import { ModalContext } from "../provider/store";

export default () => {
  const state = React.useContext(ModalContext);
  const hide = () => state.dispatch(hideModal());

  useEffect(() => {
    if (state.open === true) {
      document.body.style.position = "fixed";
      document.body.style.top = `-${window.scrollY}px`;
    } else {
      // When the modal is hidden, we want to remain at the top of the scroll position
      const scrollY = document.body.style.top;
      document.body.style.position = "";
      document.body.style.top = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    }
  }, [state.open]);
  return (
    <Box className={state.open ? "Modal Modal--show" : "Modal"}>
      <Formik
        enableReinitialize
        initialValues={state.showForm?.initialValue ?? {}}
        onSubmit={async (value, helpers) => {
          await state.showForm?.okOnClick(value, helpers);
          return Promise.resolve();
        }}
        validateOnChange
        validationSchema={state.showForm?.validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid }) => {
          return (
            <React.Fragment>
              <Box
                className="Modal__Overlay"
                onClick={
                  (state.onClose && state.onClose) ||
                  (state.showForm &&
                    (state.showForm?.onClose ||
                      state.showForm.cancelOnClick)) ||
                  hide
                }
              />
              <Box
                className={`Modal__ContentHolder${
                  state?.modalType === "text" ? "__text" : ""
                } ${
                  state?.showForm?.large ? "largeModal" : "smallModal"
                }`}
                {...state?.containerStyle}
              >
                {(state.showForm?.isOpen && !state.showForm?.hideHeader) && (
                  <ModalHeader title={state.showForm.title} />
                )}
                <ModalBody />
                {state.showForm?.isOpen && (
                  <ModalFooter
                    handleSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                    isValid={isValid}
                  />
                )}
              </Box>
            </React.Fragment>
          );
        }}
      </Formik>
    </Box>
  );
};
