import { ESDCDeliveryMethod } from "@samedaycustom/sdc-types";
import { ORDER_STATUS } from "@samedaycustom/types/order/@types/order";
import request, { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { RECENT_ORDER_TYPE } from "types/app";
import { TFile } from "types/order/@types/notes";

import {
  ADD_TO_RECENT_ORDERS,
  CAR_DELIVERY_STATUS_UPDATE,
  DELIVERY_STATUS_RESET,
  DELIVERY_STATUS_UPDATE,
  ORDER_AGENTS_UPDATED,
  ORDER_STATUS_RESET,
  ORDER_STATUS_UPDATED,
  REMOVE_FROM_RECENT_ORDERS,
  TOGGLE_BROADCAST,
  ORDER_DELIVERY_METHOD_UPDATED,
  ORDER_DUE_DATE_CHANGE_REQUEST_UPDATE,
  ORDER_ARTWORK_CHANGE_UPDATE,
} from "./types";

// import { ACTION_ERROR_TYPES } from "./types";
export const toggleBroadCast = (condition: boolean) => ({
  type: TOGGLE_BROADCAST,
  showBroadCast: condition,
});
export const addRecentOrder = (orderItem: RECENT_ORDER_TYPE) => ({
  type: ADD_TO_RECENT_ORDERS,
  order: orderItem,
});

export const removeRecentOrder = (orderItem: RECENT_ORDER_TYPE) => ({
  type: REMOVE_FROM_RECENT_ORDERS,
  order: orderItem,
});
export const realTimeUpdateStatus = (orderId: string, status: ORDER_STATUS) => ({
  type: ORDER_STATUS_UPDATED,
  updatedOrderStatus: {
    orderId,
    status,
  },
});
export const realTimeUpdateAgents = (orderId: string, agents: string[]) => ({
  type: ORDER_AGENTS_UPDATED,
  updatedOrderAgents: {
    orderId,
    agents,
  },
});

export const realTimeUpdateDueDate = (
  orderId: string,
  decoOrderId: string,
  timeZone: string,
  status: "approved" | "declined",
  newDueDate?: string
) => ({
  type: ORDER_DUE_DATE_CHANGE_REQUEST_UPDATE,
  updatedOrderDueDate: {
    orderId,
    decoOrderId,
    timeZone,
    status,
    newDueDate,
  },
});

export const realTimeUpdateDeliveryMethod = (orderId: string, deliveryMethod: string) => ({
  type: ORDER_DELIVERY_METHOD_UPDATED,
  updatedOrderDeliveryMethod: {
    orderId,
    deliveryMethod,
  },
});

export const realTimeUpdateArtwork = (orderId: string, status: "approved" | "declined") => ({
  type: ORDER_ARTWORK_CHANGE_UPDATE,
  updatedOrderArtwork: {
    orderId,
    status,
  },
});

export const updateDeliveryRecordStatus = (param: {
  orderID: string;
  recordID: string;
  status: string;
}) => ({
  type: DELIVERY_STATUS_UPDATE,
  recordResult: param,
});

export const updateCarDeliveryRecordStatus = (param: {
  orderId: string;
  trackingId: string;
  status: string;
}) => ({
  type: CAR_DELIVERY_STATUS_UPDATE,
  recordResult: {
    recordID: param.trackingId,
    orderID: param.orderId,
    status: param.status,
    type: ESDCDeliveryMethod.CAR,
  },
});

export const resetRecordResult = () => ({
  type: DELIVERY_STATUS_RESET,
});

export const resetOrderStatus = () => ({
  type: ORDER_STATUS_RESET,
});
export const setError = <T extends { errorMessage?: string }>(
  type: string,
  message: string,
  options?: T
) => (dispatch: Dispatch<any>) =>
  dispatch({
    type,
    payload: {
      ...{ ...options, error: !!message, errorMessage: message },
    },
  });

/**
 * @function catchError handles http error messages
 * @param error
 */
export const catchError = <T>(type: string, error: typeof Error | any, options?: T) => {
  return (dispatch: Dispatch<any>) => {
    let message = null;
    if ("data" in error?.response) {
      if (Array.isArray(error.response.data.message))
        for (const element of error.response.data.message) {
          message =
            (element.param && `${element.param}: ${element.msg}`) ||
            (element.field && `${element.field}: ${element.msg}`) ||
            element;
          dispatch(setError(type, message || "Error occurred while processing request"));
        }
      else if (typeof error.response.data.message === "string")
        message = error.response.data.message;
    }
    dispatch(setError<T>(type, message || "Error occurred while processing request", options));
  };
};

/**
 *
 * upload @param file file to @param url
 * @param file
 * @param url
 */
export const fileUpload = (
  file: File,
  url: string,
  data?: Record<string, unknown>,
  onProgress?: (progressEvent: any) => void
) => (_dispatch: any, getState: any): Promise<AxiosResponse<TFile>> => {
  const formData = new FormData();
  formData.append("file", file);

  if (Object.keys(data).length) Object.entries(data).forEach((o) => formData.append(o[0], o[1]));

  const authState = getState()["auth"];
  const key = authState.token;
  return request.post(url, formData, {
    onUploadProgress: onProgress,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${key}`,
    },
  });
};
