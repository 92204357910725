export const HOMEPAGE = "/";
export const SIGN_IN_PAGE = "/signin";
export const FORGOT_PASSWORD = "/forgot-password";
export const FORGOT_PASSWORD_RESET = `${FORGOT_PASSWORD}/reset`;
export const APPLICATION = "/app";
export const AUTHENTICATION = "/auth";
export const CONFIRMATION = "/confirm";

export const DASHBOARD = "/orders";
export const MESSAGE = "/message";
export const VENDORS = "/vendors";
export const LOCATIONS = "/locations";
export const CUSTOMERS = "/customers";
export const PAYMENTS = "/payments";
export const SETTINGS = "/settings";
export const EMAIL_SETTINGS = "/settings/email-config";
export const LABELS = "/labels";
export const DELIVERIES = "/deliveries";
export const ORDERS = "/orders";
export const ABANDONED_CARTS = "/abandoned-carts";
export const BRANDS = "/brands";
export const LOCATION_PAGES = "/location-pages";
export const TEMPLATES = "/templates";

export const VIEW_VENDOR = `${VENDORS}/view`;
export const VIEW_VENDOR_PROFILE = `${VENDORS}/view/profile`;
export const VIEW_CUSTOMER = `${CUSTOMERS}/view`;
export const VIEW_ORDER = `${ORDERS}/view`;
