import React, { useState } from "react";
import "./style.scss";
import Popup from "reactjs-popup";
import { SearchableList } from "@samedaycustom/core-ui";
import Spinner from "@atlaskit/spinner";
import { DefaultCheck } from "@samedaycustom/core-ui";
import MultiSelectOption from "./MultiSelectOption";

declare global {
  interface Array<T> {
    fExtended: (value: string, targetKeys: Array<string>) => Array<T>;
  }
}

Array.prototype.fExtended = function(value, targetKeys) {
  if (value) {
    return this.filter(location => {
      for (let i = 0; i < targetKeys.length; i++) {
        if (
          location[targetKeys[i]]
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
  }
  return this;
};

interface Props {
  list: Array<{
    label: string;
    value: any;
  }>;
  label: string | JSX.Element;
  loading: boolean;
  onSelect: (val) => void;
  deselect: (val) => void;
  selectedOptions: Array<{
    label: string;
    value: any;
    hoverText?: string;
  }>;
}

const MultiSelect: React.FC<Props> = ({
  list,
  loading,
  label,
  onSelect,
  deselect,
  selectedOptions
}: Props) => {
  const [searchValue, setSearchValue] = useState("");

  return (
    <div className="multiSelect__ParentBox">
      <h2> {label} </h2>

      <Popup
        trigger={open => {
          return (
            <div className="multiSelect__Container">
              {selectedOptions.map(opt => (
                <MultiSelectOption
                  label={opt.label}
                  hoverText={opt.hoverText}
                />
              ))}
            </div>
          );
        }}
        position="bottom center"
        offsetY={10}
        offsetX={0}
        arrow={false}
        contentStyle={{
          width: "90%",
          // left: 0,
          border: "none",
          padding: "0",
          height: "unset",
          borderRadius: "4px",
          boxShadow: "0px 6px 18px rgba(98, 125, 152, 0.24)"
        }}
        repositionOnResize={true}
        overlayStyle={{
          height: "200vh",
          width: "200vw",
          transform: "translate(-50%, -50%)"
        }}
        on="click"
        closeOnDocumentClick={true}
      >
        {(closeFunc, isOpen) => {
          return (
            // <div> poop </div>
            <SearchableList
              // searchFunc={(val :any) => () => {}}
              searchFunc={val => {
                setSearchValue(val);
              }}
              inputPlaceholder="Search for a location"
              styles={{
                width: "100%"
              }}
            >
              {/* <div> poop </div> */}
              {loading ? (
                <div className="multiSelect__loaderHolder">
                  <Spinner size="small" />
                </div>
              ) : list.fExtended(searchValue, ["value", "label"]).length > 0 ? (
                <div className="multiSelect__selectSearchResultItems">
                  {list
                    .fExtended(searchValue, ["value", "label"])
                    .map(listItem => {
                      let selected =
                        selectedOptions.filter(
                          selOpt => selOpt.value === listItem.value
                        ).length > 0;
                      return (
                        <div
                          className="multiSelectCheckGroup"
                          onClick={
                            selected
                              ? () => deselect(listItem)
                              : () => onSelect(listItem)
                          }
                        >
                          <DefaultCheck
                            style={{
                              marginBottom: "0 !important"
                            }}
                            checked={selected}
                            label=""
                            height={16}
                            width={16}
                            setChecked={
                              selected
                                ? () => deselect(listItem)
                                : () => onSelect(listItem)
                            }
                          />
                          <span> {listItem.label} </span>
                        </div>
                      );
                    })}
                </div>
              ) : (
                <div className="multiSelect__noResult">
                  {" "}
                  <span> No results found </span>{" "}
                </div>
              )}
            </SearchableList>
          );
        }}
      </Popup>
    </div>
  );
};

export default MultiSelect;
