import React from "react";
import { Box, ChakraInput as Input, InputGroup, Text } from "../../_components";
import { Field } from "formik";

type ProductionInputWithoutSelectType = {
  name: string;
  validate: (value: string) => string;
  isDisabled?: boolean;
  style: React.CSSProperties;
  value: string | number;
  onChange?: (value: any) => void;
};
interface IProductionInputWithoutSelect {
  title: string;
  firstInput: ProductionInputWithoutSelectType;
  secondInput: ProductionInputWithoutSelectType;
}
export default ({
  title,
  secondInput,
  firstInput
}: IProductionInputWithoutSelect) => {
  return (
    <Box>
      <Text className="__text-title">{title}</Text>
      <InputGroup
        width="120px"
        d="flex"
        alignItems="center"
        paddingTop="0.5rem"
        isTruncated
      >
        <Field name={firstInput.name} validate={firstInput.validate}>
          {({ field }: { field: any }) => (
            <Input
              {...field}
              isDisabled={firstInput.isDisabled}
              marginRight="0.5rem"
              style={firstInput.style}
              value={firstInput.value}
              borderRadius="4px"
              height="40px"
              width="100%"
              min={1}
              type="number"
              onChange={
                typeof firstInput.onChange !== "undefined"
                  ? firstInput.onChange
                  : field.onChange
              }
            />
          )}
        </Field>
        <span>&nbsp;&nbsp;&nbsp;</span>
        <Field name={secondInput.name} validate={secondInput.validate}>
          {({ field }: { field: any }) => (
            <Input
              {...field}
              marginLeft="0.5rem"
              height="40px"
              style={secondInput.style}
              value={secondInput.value}
              width="100%"
              onChange={
                typeof secondInput.onChange !== "undefined"
                  ? secondInput.onChange
                  : field.onChange
              }
              borderRadius="4px"
              min={1}
              type="number"
            />
          )}
        </Field>
      </InputGroup>
    </Box>
  );
};
