import "./style.scss";

import { ReactComponent as Locked } from "@samedaycustom/assets/images/locked.svg";
import React, { createRef, useEffect, useState } from "react";
import { NavLink, withRouter } from "react-router-dom";

import { ReactComponent as Remove } from "../removeSmallIcon.svg";

const SidebarLink = ({
  image: Image,
  label,
  childLinks,
  targetUrl,
  notificationCount,
  sidebarOpen,
  closeFunc,
  onRemoveChild,
  isActiveFunc,
  notificationItem,
  isLocked
}: any) => {
  const [showChildLinks, setChildSideLinks] = useState(true);

  const linkRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (sidebarOpen) {
      setChildSideLinks(true);
    }
    if (!sidebarOpen) {
      setChildSideLinks(false);
    }
  }, [sidebarOpen]);

  useEffect(() => {
    if (showChildLinks && linkRef.current) {
      linkRef.current.classList.add("sideLinkExpanded");
    } else {
      if (linkRef.current) {
        linkRef.current.classList.remove("sideLinkExpanded");
      }
    }
  }, [linkRef, showChildLinks]);

  const expandChildLinksStyle = {
    transform: showChildLinks ? "scaleY(1)" : "scaleY(0)",
    height: !showChildLinks
      ? "0px"
      : childLinks
      ? `${5 * childLinks.length}vh`
      : "0px",
    opacity: showChildLinks ? 1 : 0
  };

  const parentExpnadedStyle = {
    height: !showChildLinks
      ? "30px"
      : childLinks
      ? `calc(30px + ${25 * childLinks.length}px)`
      : "0px"
  };

  // if(sidebarOpen && !showChildLinks){
  //     []
  // }

  // what i want to do
  // anytime i close the sidebar then the child links will slide slowly

  const Lock = () => {
    return isLocked ? (
      <div
        style={{
          paddingRight: "30px",
          height: "16px",
          width: "16px"
        }}
      >
        <Locked style={{ margin: "0" }} />
      </div>
    ) : null;
  };

  if (childLinks) {
    return (
      <div
        ref={linkRef}
        className={
          sidebarOpen
            ? "SideLink SideLink--open SideLink--dropdown"
            : "SideLink SideLink--dropdown"
        }
        style={parentExpnadedStyle}
        onClick={closeFunc}
        // key={generate()}
      >
        <div className="slidingHighlighter"> </div>
        <NavLink to={targetUrl} className="block" activeClassName="activeLink">
          <div className="mainLink">
            {!sidebarOpen ? (
              <div className="hoverOvelay">
                {Image && <Image />}
                <span>{label ? label : null}</span>
              </div>
            ) : null}
            {Image && <Image />}
            <span className="SideLink__text">
              {label}
              {childLinks.reduce((acc, current) => {
                return acc + current.notificationCount;
              }, 0) > 0 ? (
                <div className="sideItemNotification sideItemNotification--parent notification--inner">
                  {/*  it will contain the sum of all the notification of it child links and when the sidebar is open it would disappear   */}
                  <span>
                    {" "}
                    {childLinks.reduce((acc, current) => {
                      return acc + current.notificationCount;
                    }, 0)}{" "}
                  </span>
                </div>
              ) : null}
            </span>
            <Lock />
            {childLinks.reduce((acc, current) => {
              return acc + current.notificationCount;
            }, 0) > 0 ? (
              <div className="sideItemNotification sideItemNotification--parent notification--outer">
                {/*  it will contain the sum of all the notification of it child links and when the sidebar is open it would disappear   */}
                <span>
                  {" "}
                  {childLinks.reduce((acc, current) => {
                    return acc + current.notificationCount;
                  }, 0)}{" "}
                </span>
              </div>
            ) : null}
            {/* <DropDownIndicator className={"dropdownRotateIcon"} /> */}
          </div>
        </NavLink>
        <div className="childLinksHolder" style={expandChildLinksStyle}>
          {childLinks.map(link => (
            <div className="SideLink SideLink--child childLink">
              {isActiveFunc ? (
                <NavLink
                  isActive={(match, location) => {
                    if (Array.isArray(link?.targetUrl)) {
                      for (let i = 0; i < link.targetUrl.length; i++) {
                        if (
                          (location.pathname + location.search).includes(
                            link.targetUrl[i]
                          )
                        ) {
                          return true;
                        }
                      }
                      return false;
                    }
                    return (
                      location.pathname + location.search === link.targetUrl
                    );
                  }}
                  className="childLinksItem"
                  activeClassName="childLinksItem__active"
                  to={
                    Array.isArray(link?.targetUrl)
                      ? link?.targetUrl[0]
                      : link?.targetUrl
                  }
                >
                  <span onClick={closeFunc}> - {link.label} </span>
                </NavLink>
              ) : (
                <NavLink
                  className="childLinksItem"
                  activeClassName="childLinksItem__active"
                  to={
                    Array.isArray(link?.targetUrl)
                      ? link?.targetUrl[0]
                      : link?.targetUrl
                  }
                >
                  <span onClick={closeFunc}> - {link.label} </span>
                </NavLink>
              )}
              <Remove
                className="delLink"
                onClick={() => {
                  onRemoveChild(link.id, link.label, link?.locationId);
                }}
              />
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return targetUrl ? (
      <NavLink className="block" activeClassName="activeLink" to={targetUrl}>
        <div
          className={sidebarOpen ? "SideLink SideLink--open" : "SideLink"}
          onClick={closeFunc}
        >
          {!sidebarOpen ? (
            <div className="hoverOvelay">
              {Image && <Image />}
              <span>{label ? label : null}</span>
            </div>
          ) : null}
          {Image && <Image />}
          <span className="SideLink__text">
            {label ? label : null}
            {notificationCount || notificationItem ? (
              <div className="sideItemNotification notification--inner">
                <span>{notificationCount || notificationItem}</span>
              </div>
            ) : null}
          </span>
          {notificationCount || notificationItem ? (
            <div className="sideItemNotification notification--outer">
              <span>{notificationCount || notificationItem}</span>
              {/*  it will contain the sum of all the notification of it child links and when the sidebar is open it would disappear   */}
            </div>
          ) : null}
          <Lock />
        </div>
      </NavLink>
    ) : (
      <div className="block">
        <div
          className={sidebarOpen ? "SideLink SideLink--open" : "SideLink"}
          onClick={closeFunc}
        >
          {!sidebarOpen ? (
            <div className="hoverOvelay">
              {Image && <Image />}
              <span>{label ? label : null}</span>
            </div>
          ) : null}
          {Image && <Image />}
          <span className="SideLink__text">
            {label ? label : null}
            {notificationCount ? (
              <div className="sideItemNotification notification--inner">
                <span>{notificationCount}</span>
              </div>
            ) : null}
          </span>
          {notificationCount ? (
            <div className="sideItemNotification notification--outer">
              <span>{notificationCount}</span>
              {/*  it will contain the sum of all the notification of it child links and when the sidebar is open it would disappear   */}
            </div>
          ) : null}
          <Lock />
        </div>
      </div>
    );
  }
};

export default withRouter(SidebarLink);
