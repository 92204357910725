import React from "react";
import { BroadCastValue } from "@samedaycustom/types/app";

/**
 *
 *
 * type decalration
 */
export type State = {
  showInput: boolean;
  message: Partial<any>;
};

export type Action = {
  type: "SHOW_INPUT" | "HIDE_INPUT";
  value?: BroadCastValue;
};

export type ContextType = {
  message: Partial<BroadCastValue>;
  showInput: boolean;
  onHideInput: () => void;
  onShowInput: (message?: BroadCastValue) => void;
};

/***
 *
 * initialization of states
 */
const initialValue: Partial<BroadCastValue> = {
  message: "",
  title: "",
  type: "all",
  refresh: false
};
const initialState: State = {
  message: initialValue,
  showInput: false
};

/**
 *
 *
 * context declaration
 */
export const BroadCastContext = React.createContext<ContextType>({
  message: initialValue,
  showInput: false,
  onHideInput: () => {},
  onShowInput: (_value?: BroadCastValue) => {}
});

/**
 *
 * @param state
 * reducer
 * @param action
 */
export const Reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "SHOW_INPUT":
      console.log("show input");
      return {
        ...state,
        showInput: true,
        message: { ...state.message, ...action.value }
      };
    case "HIDE_INPUT":
      return {
        ...state,
        showInput: false,
        message: initialValue
      };

    default:
      return state;
  }
};

/**
 *
 * @param param0
 *
 * provider component
 */
export const Provider = ({ children }) => {
  const [state, dispatch] = React.useReducer(Reducer, initialState);
  const onHideInput = () => dispatch({ type: "HIDE_INPUT" });
  const onShowInput = (message?: BroadCastValue) =>
    dispatch({ type: "SHOW_INPUT", value: message });

  return (
    <BroadCastContext.Provider value={{ ...state, onHideInput, onShowInput }}>
      {children}
    </BroadCastContext.Provider>
  );
};

export default Provider;
