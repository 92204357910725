import React from "react";
import styles from "./MenuIcon.module.css";

const MenuIcon = () => {
  return (
    <div className={styles.specialMenu}>
      <div className={styles.menuGroup}>
        <div className={styles.circle} />
        <div className={styles.rectangle} />
      </div>
      <div className={styles.menuGroup}>
        <div className={styles.circle} />
        <div className={styles.rectangle} />
      </div>
      <div className={styles.menuGroup}>
        <div className={styles.circle} />
        <div className={styles.rectangle} />
      </div>
    </div>
  );
};

export default MenuIcon;
