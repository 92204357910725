import {
  APP_ACTION_TYPES,
  CAR_DELIVERY_STATUS_UPDATE,
  SIGNIN_ERROR,
  SIGNIN_LOADING,
} from "admin/providers/actions/app/types";

import {
  ORDER_STATUS_UPDATED,
  ORDER_AGENTS_UPDATED,
  ORDER_STATUS_RESET,
  DELIVERY_STATUS_UPDATE,
  DELIVERY_STATUS_RESET,
  TOGGLE_BROADCAST,
  ADD_TO_RECENT_ORDERS,
  REMOVE_FROM_RECENT_ORDERS,
  ORDER_DELIVERY_METHOD_UPDATED,
  ORDER_DUE_DATE_CHANGE_REQUEST_UPDATE,
  ORDER_ARTWORK_CHANGE_UPDATE,
} from "../../actions/app/types";
import { ADMIN_APP_STATE, APP_STATE } from "./initialState";

export const APP = (state = APP_STATE, action: APP_ACTION_TYPES): ADMIN_APP_STATE => {
  switch (action.type) {
    case SIGNIN_LOADING:
      return {
        ...state,
        signin: {
          ...state.signin,
          loading: action.payload.condition,
        },
      };
    case SIGNIN_ERROR:
      return {
        ...state,
        signin: {
          ...state.signin,
          error: true,
          errorMessage: action.payload.errorMessage,
        },
      };
    case ORDER_STATUS_UPDATED: {
      const orderStatus = {
        ...state.updatedOrderStatus,
        ...action.updatedOrderStatus,
        status: action.updatedOrderStatus?.status ?? state.updatedOrderStatus?.status,
      };
      return {
        ...state,
        updatedOrderStatus: orderStatus,
      };
    }
    case DELIVERY_STATUS_UPDATE:
      return {
        ...state,
        recordResult: action.recordResult,
      };

    case CAR_DELIVERY_STATUS_UPDATE:
      return {
        ...state,
        recordResult: action.recordResult,
      };
    case DELIVERY_STATUS_RESET:
      return {
        ...state,
        recordResult: null,
      };
    case ORDER_STATUS_RESET:
      return {
        ...state,
        updatedOrderStatus: {
          orderId: null,
          status: null,
        },
      };
    case TOGGLE_BROADCAST:
      return {
        ...state,
        showBroadCast: action.showBroadCast || false,
      };

    case ADD_TO_RECENT_ORDERS: {
      // recent orders alteration process
      let newArray = [];
      if (state.recentOrders.length < 6) {
        if (
          state.recentOrders.filter((order) => order.orderId === action.order.orderId).length > 0
        ) {
          newArray = [...state.recentOrders];
        } else {
          newArray = [
            action.order,
            ...state.recentOrders.filter((order) => order.orderId !== action.order.orderId),
          ];
        }
      } else {
        if (
          state.recentOrders.filter((order) => order.orderId === action.order.orderId).length > 0
        ) {
          newArray = [...state.recentOrders];
        } else {
          newArray = [action.order, ...state.recentOrders.slice(0, 5)];
        }
      }
      return {
        ...state,
        recentOrders: [...newArray],
      };
    }
    case REMOVE_FROM_RECENT_ORDERS: {
      return {
        ...state,
        recentOrders: [
          ...state.recentOrders.filter((order) => order.orderId !== action.order.orderId),
        ],
      };
    }
    case ORDER_AGENTS_UPDATED: {
      const orderAgents = {
        ...state.updatedOrderAgents,
        ...action.updatedOrderAgents,
        assignedAgents: action.updatedOrderAgents?.agents ?? state.updatedOrderAgents?.agents,
      };
      return {
        ...state,
        updatedOrderAgents: orderAgents,
      };
    }
    case ORDER_DUE_DATE_CHANGE_REQUEST_UPDATE: {
      const orderDueDate = {
        ...state.updatedOrderDueDate,
        ...action.updatedOrderDueDate,
        dateDue: action.updatedOrderDueDate?.newDueDate ?? state.updatedOrderDueDate?.newDueDate,
      };
      return {
        ...state,
        updatedOrderDueDate: orderDueDate,
      };
    }
    case ORDER_DELIVERY_METHOD_UPDATED: {
      const orderDeliveryMethod = {
        ...state.updatedOrderDeliveryMethod,
        ...action.updatedOrderDeliveryMethod,
      };
      return {
        ...state,
        updatedOrderDeliveryMethod: orderDeliveryMethod,
      };
    }
    case ORDER_ARTWORK_CHANGE_UPDATE: {
      const orderArtwork = {
        ...state.updatedOrderArtwork,
        ...action.updatedOrderArtwork,
      };
      return {
        ...state,
        updatedOrderArtwork: orderArtwork,
      };
    }
    default:
      return state;
  }
};
