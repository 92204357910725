import { Box, Text } from "../../_components";

import { formatInTimeZone } from "date-fns-tz";
import moment from "moment";
import React from "react";

export enum ISDCOrderCancellationType {
  CopyRight = "ICOR",
  DesignQuality = "IDQ",
  OtherDesignIssue = "IOD",
  UnableToFulfilOrder = "IUFO",
  CustomerRequest = "ICUR",
  TooBusy = "ITB",
  Closed = "IC",
  MachineBreakDown = "IMB",
}

export interface ILogCard {
  type: ISDCOrderCancellationType;
  purpose: "DEC" | "CAN" | "RE-REASSIGN" | "OTHERS" | "UNASSIGNED";
  locationName: string;
  timestamp: number;
  vendorName: string;
  message?: string;
  tzone?: string;
}
export default (props: ILogCard) => {
  const logTitle =
    props?.purpose === "DEC"
      ? "Order Declined"
      : props?.purpose === "CAN"
      ? "Order Cancelled"
      : "Others";

  let purposeMessage = null;

  switch (props.type) {
    case ISDCOrderCancellationType.TooBusy:
      purposeMessage = "Currently too busy";
      break;
    case ISDCOrderCancellationType.Closed:
      purposeMessage = "Currently close";
      break;
    case ISDCOrderCancellationType.CopyRight:
      purposeMessage = "Copywriting issues";
      break;
    case ISDCOrderCancellationType.CustomerRequest:
      purposeMessage = "Customer requested the order to be canceled";
      break;
    case ISDCOrderCancellationType.DesignQuality:
      purposeMessage = "Design Quality";
      break;
    case ISDCOrderCancellationType.MachineBreakDown:
      purposeMessage = "The machine broke down";
      break;
    case ISDCOrderCancellationType.OtherDesignIssue:
      purposeMessage = "Other design issues";
      break;
    case ISDCOrderCancellationType.UnableToFulfilOrder:
      purposeMessage = "Unable to fulfill the order";
      break;

    default:
      break;
  }
  return (
    <Box
      width="100%"
      padding="16px 12px"
      background="#F0F4F8"
      borderRadius="12px"
      marginBottom="12px"
    >
      {logTitle && (
        <Text
          color="#EF4E4E"
          textTransform="uppercase"
          letterSpacing="0.02em"
          fontSize="11px"
          fontWeight={600}
        >
          {logTitle}
        </Text>
      )}
      {(props?.vendorName || props?.locationName) && (
        <Text
          color="#102A43"
          lineHeight="25px"
          fontSize="14px"
          fontWeight={500}
          textTransform="capitalize"
        >
          {props?.vendorName}
          {`${props?.locationName ? `, ${props?.locationName}` : ""}`}
        </Text>
      )}
      <Text color="#102A43" lineHeight="20px" fontSize="14px" fontWeight="normal">
        {`${purposeMessage || ""}\n ${props?.message || ""}`}
      </Text>
      {props?.timestamp && (
        <Text
          color="#627D98"
          lineHeight="25px"
          fontSize="12px"
          fontWeight={500}
          textTransform="capitalize"
        >
          {props?.tzone
            ? formatInTimeZone(props?.timestamp, props?.tzone, "dd MMM, yyyy hh:mm a")
            : moment(props?.timestamp).format("DD MMM, YYYY hh:mm A")}
        </Text>
      )}
    </Box>
  );
};
