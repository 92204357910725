import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { MaybePromise } from "@reduxjs/toolkit/dist/query/tsHelpers";

export default function setRTKAuthorization(
  headers: Headers,
  { getState }: Pick<BaseQueryApi, "getState" | "extra" | "endpoint" | "type" | "forced">
): MaybePromise<Headers> {
  const token = getState()?.auth?.token;

  if (token) {
    headers.set("authorization", `Bearer ${token}`);
  }

  return headers;
}
