import React, { Reducer, useReducer } from "react";

// [ACTOIONS]
const SET_LABEL = "SET_LABEL";
const SET_DATE_VALUE = "SET_DATE_VALUE";
const SET_CUSTOM_DATE = "SET_CUSTOM_DATE";
const SHOW_CUSTOM_DATE = "SHOW_CUSTOM_DATE";
const SET_PLACEHOLDER = "SET_PLACEHOLDER";

// [TYPES]

export type DateGroupCustomDate = {
	start: Date | number;
	end: Date | number;
};

export type DateGroupAction = {
	type:
		| typeof SET_LABEL
		| typeof SET_CUSTOM_DATE
		| typeof SHOW_CUSTOM_DATE
		| typeof SET_PLACEHOLDER
		| typeof SET_DATE_VALUE;

	customDate?: DateGroupCustomDate;
	label?: string;
	placeholder?: string;
	showCustomDate?: boolean;
	dateValue?: Date | number;
};

export interface IDateGroupState {
	selectedlabel: string;
	dateValue: Date | number;
	placeholder: string;
	showCustomDate?: boolean;
	customDate?: DateGroupCustomDate;
}

export interface IDateGroupContext extends IDateGroupState {
	setCustomDate: (dates: DateGroupCustomDate) => void;
	setSelectedLabel: (label: string) => void;
	setShowCustomDate: (condition: boolean) => void;
	setDateValue: (datevalue: Date | number) => void;
}

// [INITIALISATION]

export const initialState: IDateGroupState = {
	selectedlabel: null,
	customDate: null,
	placeholder: "Select date range",
	dateValue: 7
};

export const initialContext: IDateGroupContext = {
	...initialState,
	setCustomDate: (dates: DateGroupCustomDate) => {},
	setSelectedLabel: (label: string) => {},
	setShowCustomDate: (condition: boolean) => {},
	setDateValue: () => {}
};

export const DateGroupContext = React.createContext<Partial<IDateGroupContext>>(
	initialContext
);

//[REDUCER]
const DateGroupReducer = (
	state: IDateGroupState,
	action: DateGroupAction
): IDateGroupState => {
	switch (action.type) {
		case SET_LABEL:
			return {
				...state,
				selectedlabel: action.label
			};

		case SET_DATE_VALUE:
			return {
				...state,
				dateValue: action.dateValue
			};

		case SET_PLACEHOLDER:
			return {
				...state,
				placeholder: action.placeholder
			};

		case SET_CUSTOM_DATE:
			return {
				...state,
				customDate: action.customDate
			};

		case SHOW_CUSTOM_DATE:
			return {
				...state,
				showCustomDate: action.showCustomDate
			};

		default:
			return state;
	}
};

// [CONTEXT HOOK]
export const useDateGroupContext = () => React.useContext(DateGroupContext);

// [PROVIDER]
export const DateGroupProvider = ({ children }) => {
	const [state, dispatch] = useReducer<
		Reducer<IDateGroupState, DateGroupAction>,
		IDateGroupState
	>(
		DateGroupReducer as Reducer<IDateGroupState, DateGroupAction>,
		initialState,
		() => initialState
	);

	// set selected label
	const setSelectedLabel = (label: string) => {
		dispatch({
			type: SET_LABEL,
			label
		});
	};

	// set customdates
	const setCustomDate = (dates: DateGroupCustomDate) => {
		dispatch({
			type: SET_CUSTOM_DATE,
			customDate: dates
		});
	};

	// show custom date container or not
	const setShowCustomDate = (condition: boolean) => {
		dispatch({
			type: SHOW_CUSTOM_DATE,
			showCustomDate: condition
		});
	};

	// show custom date container or not
	const setDateValue = (value: Date | number) => {
		dispatch({
			type: SET_DATE_VALUE,
			dateValue: value
		});
	};

	return (
		<DateGroupContext.Provider
			value={{
				...state,
				setCustomDate,
				setSelectedLabel,
				setShowCustomDate,
				setDateValue
			}}
		>
			{children}
		</DateGroupContext.Provider>
	);
};
