import "./style.scss";

import { Box, Text } from "../../_components";
import { MessageFileCard } from "@samedaycustom/core-ui";
import {
  IMessageContainer,
  IMessageInfo
} from "@samedaycustom/types/order/@types/notes";
import moment from "moment";
import React from "react";

import { ReactComponent as Edit } from "./edit.svg";
import { ReactComponent as Trash } from "./trash.svg";

export interface INoteBubble extends IMessageContainer {
  onEdit: (currentMessage: IMessageInfo) => void;
  onDelete: (
    currentMessage: IMessageInfo,
    onDeleteNote: (id) => void
  ) => Promise<any>;
}

export default ({ currentMessage, onEdit, onDelete, user }: INoteBubble) => {
  const [deleting, setdeleting] = React.useState(false);
  const isSameUser = React.useMemo(() => {
    return currentMessage?.user?._id === user?._id;
  }, [currentMessage?.user?._id, user?._id]);

  const _onEdit = () => onEdit(currentMessage);
  const _onDelete = () =>
    onDelete(currentMessage, () => setdeleting(true)).then(() =>
      setdeleting(false)
    );

  return (
    <Box className="NB--container">
      <Box className="NB--header">
        <Text isTruncated>
          {isSameUser ? (
            user?.name
          ) : (
            <Box>
              <Text>
                {currentMessage?.user?.firstName}{" "}
                {currentMessage?.user?.lastName}
              </Text>
              <Text>
                {currentMessage?.user?.vendorName}&nbsp;
                {"-"}&nbsp;
                {currentMessage?.user?.locationName}
              </Text>
            </Box>
          )}
        </Text>
        <Text>
          {currentMessage?.timestamp &&
            moment(currentMessage.timestamp).format("ddd, DD MMM hh:mm")}
        </Text>
      </Box>
      <Box className="NB--body">{currentMessage?.message}</Box>
      {currentMessage?.type?.property?.files &&
        currentMessage?.type?.property?.files.map((o, i) => (
          <MessageFileCard key={i} {...o} url={o?.url ?? o?.file_url} />
        ))}
      <Box className="NB--footer">
        <Box d="flex">
          {currentMessage?.type?.name && (
            <Text className="hashTag">
              #{currentMessage?.type.name.toLowerCase()}
            </Text>
          )}
          {deleting && (
            <Text
              color="#EF4E4E"
              fontFamily="Inter"
              fontWeight={500}
              fontSize="12px"
              paddingLeft="5px"
            >
              Deleting...
            </Text>
          )}
        </Box>

        <Box className="actionTab">
          {isSameUser && <Edit onClick={_onEdit} />}
          {isSameUser && <Trash onClick={_onDelete} />}
        </Box>
      </Box>
    </Box>
  );
};
