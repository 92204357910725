import React from "react";
import ContentLoader from "react-content-loader";

interface Props {
	color?: string;
	backgroundColor?: string;
	speed?: number;
	width?: number;
	height?: number;
	style?: any;
}
const TableRow = ({
	backgroundColor = "#f3f3f3",
	color = "#196BD82F",
	height = 40,
	speed = 2,
	width = 700,
	...props
}: Props) => {
	const random = Math.random() * (1 - 0.7) + 0.7;
	return (
		<ContentLoader
			viewBox={`0 0 ${width} ${height}`}
			height={height}
			width={width}
			speed={speed}
			foregroundColor={color}
			{...props}
		>
			{/* <rect x="0" y="15" rx="4" ry="4" width="6" height="6.4" /> */}
			<rect
				x="34"
				y="13"
				rx="6"
				ry="6"
				width={200 * random}
				height="12"
			/>
			<rect x="33" y="13" rx="6" ry="6" width={23 * random} height="12" />
			<rect
				x="543"
				y="13"
				rx="6"
				ry="6"
				width={78 * random}
				height="12"
			/>
			<rect
				x="355"
				y="13"
				rx="6"
				ry="6"
				width={117 * random}
				height="12"
			/>
			{/* <rect x="938" y="13" rx="6" ry="6" width={83 * random} height="12" /> */}

			{/* <rect x="0" y="39" rx="6" ry="6" width="1060" height=".3" /> */}
		</ContentLoader>
	);
};

const Table = (props: Props) => (
	<React.Fragment>
		{Array(8)
			.fill("")
			.map((_, i) => (
				<TableRow
					key={i}
					style={{ opacity: Number(2 / i).toFixed(1) }}
					{...props}
				/>
			))}
	</React.Fragment>
);

export default Table;
