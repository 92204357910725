import React, { useRef } from "react";
import "./style.scss";
import { useToast } from "@samedaycustom/hooks";
import {
  validateAndUpload,
  MAX_IMAGE_UPLOAD_SIZE
} from "@samedaycustom/helpers";
import { generate } from "shortid";

interface Iprops {
  className: string;
  fileList: Array<{
    name: string;
    id: string;
    url: string;
    loading: boolean;
  }>;
  setFileList: React.Dispatch<
    React.SetStateAction<
      Array<{ name: string; id: string; url: string; loading: boolean }>
    >
  >;
  onUpload?: (file: File, fileid: string) => Promise<File>;
}

export default ({ className, fileList, setFileList, onUpload }: Iprops) => {
  const targetRef = useRef<HTMLDivElement>();
  const inputRef = useRef<HTMLInputElement>();
  const toast = useToast();
  const fileInputUpload = event => {
    if (!event.target["files"]) return null;
    if (event.target["files"].length > 1) {
      toast({
        title: "File upload:",
        description: "Mulitple file selection is not allowed",
        position: "bottom-left",
        status: "info",
        duration: 3000,
        isClosable: true
      });

      return null;
    }
    if (event.target["files"][0]) {
      const file = event.target["files"][0];
      inputRef.current.value = "";
      let fileId = generate();
      if (file) return readFileData(file, fileId);
    }
    return null;
  };

  const prevDefault = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    targetRef.current.classList.add("dragActive");
  };

  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    targetRef.current.classList.remove("dragActive");
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    targetRef.current.classList.remove("dragActive");
    readDropFile(e);
  };

  function readDropFile(e: React.DragEvent<HTMLDivElement>) {
    if (e.dataTransfer && e.dataTransfer.items) {
      if (e.dataTransfer.items.length > 1) {
        toast({
          title: "File upload:",
          description: "Mulitple file selection is not allowed",
          position: "bottom-left",
          status: "info",
          duration: 3000,
          isClosable: true
        });
        return null;
      }
      if (e.dataTransfer.items.length <= 0) {
        toast({
          title: "File upload:",
          description: "No file selected",
          position: "bottom-left",
          status: "info",
          duration: 3000,
          isClosable: true
        });
        return null;
      }
      if (e.dataTransfer.items[0].kind === "file") {
        var file = e.dataTransfer.items[0].getAsFile();
        let fileId = generate();
        // in here you set the files to something with the loader set to true
        return readFileData(file, fileId);
      }
    } else if (e.dataTransfer && e.dataTransfer.files) {
      if (e.dataTransfer.files.length > 1) {
        toast({
          title: "File upload:",
          description: "Mulitple file selection is not allowed",
          position: "bottom-left",
          status: "info",
          duration: 3000,
          isClosable: true
        });
        return null;
      }
      // Use DataTransfer interface to access the file(s)
      if (e.dataTransfer.files[0]) {
        let fileId = generate();
        // in here you set the files to something with the loader set to true
        return readFileData(e.dataTransfer.files[0], fileId);
      }
    }
    return null;
  }

  function readFileData(file: File, fileid) {
    if (file.size >= MAX_IMAGE_UPLOAD_SIZE) {
      toast({
        title: "File upload:",
        description: "Image file too large 10mb is the maximium upload size",
        position: "bottom-left",
        status: "warning",
        duration: 3000,
        isClosable: true
      });
      return null;
    }
    setFileList(prev => [
      ...prev,
      {
        id: fileid,
        name: file.name,
        url: "",
        loading: true
      }
    ]);
    const reader = new FileReader();
    reader.onload = async () => {
      try {
        //  const URL = window.URL || window.webkitURL;
        let result = await onUpload(file, fileid);
        setFileList(prev => [
          ...prev.filter(({ id }) => id !== fileid),
          {
            id: fileid,
            url: result["url"],
            name: result["originalName"],
            loading: false
          }
        ]);
        //  URL.revokeObjectURL(e.src);
      } catch (error) {
        setFileList(prev => [...prev.filter(({ id }) => id !== fileid)]);
        //  URL.revokeObjectURL(e.src);
      }
      // validateAndUpload(
      //   file,
      //   async (e: any) => {
      //     try {
      //       const URL = window.URL || window.webkitURL;
      //       let result = await onUpload(file, fileid);
      //       setFileList(prev => [
      //         ...prev.filter(({ id }) => id !== fileid),
      //         {
      //           id: fileid,
      //           url: result["url"],
      //           name: result["originalName"],
      //           loading: false
      //         }
      //       ]);
      //       URL.revokeObjectURL(e.src);
      //     } catch (error) {
      //       setFileList(prev => [...prev.filter(({ id }) => id !== fileid)]);
      //       URL.revokeObjectURL(e.src);
      //     }
      //   },
      //   () => {
      //     setFileList(prev => [...prev.filter(({ id }) => id !== fileid)]);
      //     toast({
      //       title: "File upload:",
      //       description:
      //         "Error occured importing image or file is not an image",
      //       position: "bottom-left",
      //       status: "warning",
      //       duration: 3000,
      //       isClosable: true
      //     });

      //     return;
      //   }
      // );
    };
    reader.readAsDataURL(file);
    return null;
  }

  return (
    <>
      <p className="fileLabel">Upload Screenshot</p>
      <div
        className={`fileInputContainer ${className ? className : ""}`}
        onDragEnter={prevDefault}
        onDrop={onDrop}
        onDragLeave={onDragLeave}
        onDragEnd={prevDefault}
        onDragOver={onDragOver}
        onDrag={prevDefault}
        ref={targetRef}
      >
        <p>
          {" "}
          Drag image here or{" "}
          <label className="browseLink">
            <span>browse</span>{" "}
            <input
              ref={inputRef}
              onChange={(e: any) => fileInputUpload(e)}
              id="browseFile"
              type="file"
            />{" "}
          </label>
        </p>
      </div>
    </>
  );
};
