import React from "react";
import "./styles.scss";

type SpinnerProps = {
  className?: string;
  color?: string;
};

const Spinner: React.FC<SpinnerProps> = ({ className, color }) => {
  return (
    <div
      className={className ? ["Spinner", className].join(" ") : "Spinner"}
      style={
        color
          ? {
              borderColor: color,
              borderTopColor: "#adadad50"
            }
          : null
      }
      about="spinner"
      aria-label="loading-spinner"
      role="spinner"
    ></div>
  );
};
export default Spinner;
