import { Box, Text } from "../../_components";
import { useModal } from "@samedaycustom/core-ui";
import {
  BroadCastMessageInput,
  BroadCastType,
  BroadCastValue
} from "@samedaycustom/types/app";
import { FormikHelpers } from "formik";
import React, { useEffect } from "react";
import * as yup from "yup";

import Content, { BroadcastInput } from "./Content";

export * from "./Content";

const validation = yup.object({
  type: yup
    .string()
    .test("type", "invalid type", value =>
      ["all", "vendor", "vendor-location"].includes(value)
    ),
  message: yup
    .string()
    .min(5, "Message cannot be less than 5 characters")
    .required(),
  title: yup
    .string()
    .min(1, "Message title cannot be less than 1 characters")
    .required(),
  business_id: yup
    .string()
    .nullable()
    .notRequired(),
  location_id: yup
    .string()
    .nullable()
    .notRequired()
});

export interface IBroadCastInputProp extends BroadcastInput {
  onShowInput: () => void;
  onHideInput: () => void;
  showInput: boolean;
  initialValue: BroadCastValue;
  onSend: (
    inputprops: BroadCastMessageInput,
    formikHelpers?: FormikHelpers<{
      type: BroadCastType;
      title: string;
      message: string;
      business_id: string;
      location_id: string;
    }>
  ) => Promise<void | BroadCastMessageInput>;
}

const BroadCastInput = React.forwardRef((props: IBroadCastInputProp) => {
  const modal = useModal();
  // const prevShow = React.useRef(false);

  const renderModal = React.useCallback(
    () => {
      modal.form({
        title: "Send a new broadcast",
        initialValue: { ...props.initialValue },
        validationSchema: validation,
        okText: "Send broadcast",
        okOnClick: async values => {
          const broadcast = await props.onSend(values);
          if (broadcast) {
            props.onHideInput();
          }
          return;
        },
        onClose: () => {
          props.onHideInput();
          modal.hide();
        },
        component: (
          <Content
            getVendors={props.getVendors}
            onVendorChange={props.onVendorChange}
          />
        )
      });
    },
    [
      // prevShow.current,
      // props.initialValue,
      // props.onSend,
      // props.getVendors,
      // props.onVendorChange
    ]
  );

  useEffect(() => {
    if (props.showInput === true) {
      renderModal();
    }
  }, [props.showInput]);

  return (
    <Box>
      <Box background="transparent" padding="15px">
        <Box background="#F0F4F8" borderRadius="4px" padding="9px 16px">
          <Text
            fontWeight="normal"
            fontSize="14px"
            color="#334E68"
            onClick={() => props.onShowInput()}
          >
            Send a broadcast
          </Text>
        </Box>
      </Box>
    </Box>
  );
});

export default React.memo(BroadCastInput);
