import "./style.scss";

import { Checkbox } from "@atlaskit/checkbox";
import { Box, Text, Textarea } from "../../_components";
import { AlternativeDropDowns, Input, useForm } from "@samedaycustom/core-ui";
import { ErrorMessage } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BroadCastValue } from "types/app";

export interface ISendBroadCast {}

const TypeLabel: React.FC<{ title: string; description: string }> = props => (
  <Box marginBottom="6px">
    <Text color="#102A43" fontSize="14px" fontWeight={500} lineHeight="24px">
      {props.title}
    </Text>
    <Text color="#102A43" fontSize="13px" fontWeight={400} lineHeight="20px">
      {props.description}
    </Text>
  </Box>
);

export type Vendor = { id: string; name: string };
export type Location = { id: string; name: string; city: string };

export interface BroadcastInput {
  getVendors: () => Promise<Vendor[]>;
  onVendorChange: (business_id: string) => Promise<Location[]>;
}
const Content: React.FC<BroadcastInput> = props => {
  const [vendors, setvendors] = useState<Vendor[]>([]);
  const [loadingVendor, setloadingVendor] = useState(false);
  const [locations, setlocations] = useState<Location[]>([]);
  const [loadingLocation, setloadingLocation] = useState(false);

  const { values, setFieldValue, handleChange, resetForm } = useForm<
    BroadCastValue
  >();

  const _vendors = useMemo(() => {
    if (Array.isArray(vendors))
      return vendors.map(o => ({ label: o.name, value: o.id }));
    return [];
  }, [vendors]);

  const _locations = useMemo(() => {
    if (Array.isArray(locations))
      return locations.map(o => ({ label: o.name, value: o.id }));
    return [];
  }, [locations]);

  /**
   * On vendor change returns the locations of the vendor by its id
   */
  const onVendorChange = useCallback(
    async e => {
      setFieldValue("business_id", e);
      if (
        typeof props?.onVendorChange === "function" &&
        ["vendor", "vendor-location"].includes(values.type)
      ) {
        setloadingLocation(true);
        const result = await props?.onVendorChange(e);

        if (Array.isArray(result)) setlocations(result);

        setloadingLocation(false);
      }
    },
    [values.type, props?.onVendorChange]
  );

  const fetchVendors = React.useCallback(async () => {
    if (
      typeof props?.getVendors === "function" &&
      ["vendor", "vendor-location"].includes(values.type)
    ) {
      setloadingVendor(true);
      const result = await props.getVendors();

      if (Array.isArray(result)) setvendors(result);

      setloadingVendor(false);
    }
  }, [values.type, props?.getVendors]);

  useEffect(() => {
    return () => resetForm();
  }, []);
  useEffect(() => {
    fetchVendors();
  }, [values.type]);

  return (
    <Box>
      <Input
        type="input"
        inputConfig={{
          label: "Broadcast Title",
          id: "title",
          name: "title",
          type: "text"
        }}
        labelStyle={{ fontWeight: 500 }}
      />
      <AlternativeDropDowns
        title="Broadcast type"
        onClick={e => setFieldValue("type", e)}
        placeholder=""
        className="BC--input"
        loading={false}
        value={values.type}
        position="bottom center"
        popupClassName="BC--popup"
        listItems={[
          {
            shortLabel: "All vendor",
            label: (
              <TypeLabel
                title="All vendor"
                description="Send to all vendors and all their store locations"
              />
            ),
            value: "all"
          },
          {
            shortLabel: "Single vendor",
            label: (
              <TypeLabel
                title="Single vendor"
                description="Send to only one vendor and all their store locations"
              />
            ),
            value: "vendor"
          },
          {
            shortLabel: "Single location",
            label: (
              <TypeLabel
                title="Single location"
                description="Send to only one vendor store location"
              />
            ),
            value: "vendor-location"
          }
        ]}
      />
      {values.type !== "all" && (
        <React.Fragment>
          {["vendor", "vendor-location"].includes(values.type) && (
            <AlternativeDropDowns
              title="Vendor"
              onClick={onVendorChange}
              placeholder="Select a vendor to send broadcast to"
              className="BC--input"
              popupClassName="BC--popup"
              loading={loadingVendor}
              value={values.business_id}
              position="bottom center"
              isSearchable
              listItems={_vendors}
            />
          )}

          {["vendor-location"].includes(values.type) && (
            <AlternativeDropDowns
              title="Store Location"
              onClick={e => setFieldValue("location_id", e)}
              placeholder="Select a location to send broadcast to"
              className="BC--input"
              popupClassName="BC--popup"
              isSearchable
              loading={loadingLocation}
              value={values.location_id}
              position="bottom center"
              listItems={_locations}
            />
          )}
        </React.Fragment>
      )}
      <Text color="#486581" lineHeight="20px" fontSize="14px" fontWeight={500}>
        Broadcast message
      </Text>
      <Textarea
        title="Broadcast message"
        height="140px"
        marginTop="3px"
        placeholder="Type your message here"
        _placeholder={{
          color: "#486581",
          fontWeight: "normal"
        }}
        resize="none"
        // border="1px solid #BCCCDC"
        paddingLeft="10px"
        borderRadius="0px 4px 4px 0px"
        name="message"
        value={values.message}
        onChange={handleChange}
      />
      <ErrorMessage name="error" />
      <Checkbox
        isChecked={values.refresh}
        label={"All vendor to hard refresh account"}
        onChange={() => setFieldValue("refresh", !values.refresh)}
        sx={{ color: "#102A43", marginLeft: "-10px" }}
      />
    </Box>
  );
};

export default React.memo(Content);
