import { Box, Text, Textarea } from "../../_components";
import { AlternativeDropDowns } from "@samedaycustom/core-ui";
import { useForm } from "@samedaycustom/core-ui";
import "../style.scss";
import React from "react";

export enum ISDCOrderCancellationType {
  CopyRight = "ICOR",
  DesignQuality = "IDQ",
  OtherDesignIssue = "IOD",
  UnableToFulfilOrder = "IUFO",
  CustomerRequest = "ICUR",
  TooBusy = "ITB",
  Closed = "IC",
  MachineBreakDown = "IMB"
}

export default ({
  show,
  setShow
}: {
  setShow?: () => void;
  show?: boolean;
}) => {
  const { values, setFieldValue } = useForm<{
    message: string;
    reason: ISDCOrderCancellationType;
  }>();

  return (
    <Box>
      <Box width="100%">
        <Box className="view__content">
          <Text
            fontSize="14px"
            fontFamily="Inter"
            fontWeight={500}
            color="#102A43"
          >
            You are about to decline this order. You will not be able to see
            this order again once you decline it
          </Text>
          <Text
            color="#486581"
            paddingTop="1.2rem"
            paddingBottom="0.3rem"
            fontFamily="Inter"
            fontWeight={500}
            fontSize="14px"
          >
            Reason for Declining
          </Text>
          <AlternativeDropDowns
            onClick={(e: any) => setFieldValue("reason", e)}
            // placeholder="Tell us why you’re declining the order"
            value={values?.reason}
            loading={false}
            position="bottom center"
            listItems={[
              {
                label: "Unable to fulfill the order",
                value: "IUFO"
              },
              { label: "Currently too busy", value: "ITB" },
              {
                label: "Design quality",
                value: "IDQ"
              },
              {
                label: "The Machine broke down ",
                value: "IMB"
              },
              {
                label: "We’re currently closed",
                value: "IC"
              },
              {
                label: "Other Design issues",
                value: "IOD"
              },
              {
                label: "Copywriting issues",
                value: "ICOR"
              },
              { label: "Other reasons", value: "IOR" },
              {
                label: "Customer requested the order to be cancelled",
                value: "ICUR"
              }
            ]}
          />
          {values?.reason &&
            (values.reason === ISDCOrderCancellationType?.OtherDesignIssue ||
              values.reason === ISDCOrderCancellationType?.CopyRight ||
              values.reason === ("IOR" as any)) && (
              <React.Fragment>
                <Text
                  color="#486581"
                  paddingTop="1.2rem"
                  paddingBottom="0.3rem"
                  fontFamily="Inter"
                  fontWeight={500}
                  fontSize="14px"
                >
                  Please explain your reasons further
                </Text>
                <Textarea
                  multiple
                  isFullWidth
                  resize="none"
                  placeholder="Tell us why you're declining the order"
                  fontSize="0.9rem"
                  name="message"
                  value={values?.message}
                  onChange={(e: any) =>
                    setFieldValue("message", e?.target?.value)
                  }
                />
              </React.Fragment>
            )}
        </Box>
      </Box>
    </Box>
  );
};
