import { ACTION_TYPE_DEFS, SIGNIN_DATA, RECENT_ORDER_TYPE } from "@samedaycustom/types/app";
import { ORDER_STATUS } from "@samedaycustom/types/order/@types/order";

export type ADMIN_APP_STATE = {
  signin: ACTION_TYPE_DEFS<SIGNIN_DATA>;
  updatedOrderStatus: {
    orderId: string;
    status: ORDER_STATUS;
  };
  recordResult: any;
  showBroadCast: boolean;
  recentOrders?: Array<RECENT_ORDER_TYPE>;
  updatedOrderAgents: {
    orderId: string;
    agents: string[];
  };
  updatedOrderDeliveryMethod: {
    orderId: string;
    deliveryMethod: string;
  };
  updatedOrderDueDate?: {
    orderId: string;
    decoOrderId: string;
    timeZone: string;
    status: "approved" | "declined";
    newDueDate: string;
  };
  updatedOrderArtwork?: {
    orderId: string;
    decoOrderId: string;
    timeZone: string;
    status: "approved" | "declined";
  }
};
export const APP_STATE: ADMIN_APP_STATE = {
  signin: {
    error: false,
    errorMessage: null,
    data: null,
    loading: false,
  },
  updatedOrderStatus: {
    orderId: null,
    status: null,
  },
  recordResult: null,
  showBroadCast: false,
  recentOrders: [],
  updatedOrderAgents: {
    orderId: null,
    agents: null,
  },
  updatedOrderDeliveryMethod: {
    orderId: null,
    deliveryMethod: null,
  },
  updatedOrderDueDate: {
    orderId: null,
    decoOrderId: null,
    timeZone: null,
    status: null,
    newDueDate: null,
  },
  updatedOrderArtwork: {
    orderId: null,
    decoOrderId: null,
    timeZone: null,
    status: null,
  }
};
