import "./style.scss";

import { Box, Textarea } from "../../_components";
import {
  AlternativeDropDowns,
  ErrorMessage,
  PrimaryButton
} from "@samedaycustom/core-ui";
import { onUploadingProgress } from "@samedaycustom/helpers";
import { useToast } from "@samedaycustom/hooks";
import { Upload } from "@samedaycustom/core-ui";
import { Modal } from "@samedaycustom/core-ui";
import { ISDCLine } from "@samedaycustom/types/order/@types/line";
import {
  InputSendProps,
  NoteValue,
  TFile
} from "@samedaycustom/types/order/@types/notes";
import { Formik } from "formik";
import { uniqBy } from "lodash";
import React from "react";
import usePortal from "react-useportal";
import { generate } from "shortid";
import * as yup from "yup";

export interface ISendNote {
  show?: boolean;
  setShow?: React.Dispatch<React.SetStateAction<boolean>>;
  inputRef: React.MutableRefObject<HTMLInputElement | HTMLTextAreaElement>;
  initialValue: Partial<NoteValue>;
  lineItems?: ISDCLine[];
  onSendNote: (inputprops: InputSendProps) => Promise<void | InputSendProps>;
  requesFileUpload: (
    file: File,
    onProgress?: (progressEvent: any) => void
  ) => Promise<TFile>;
}
export default React.memo(
  ({
    initialValue,
    show,
    setShow,
    onSendNote,
    inputRef,
    lineItems,
    requesFileUpload
  }: ISendNote) => {
    const { Portal } = usePortal();
    const toast = useToast();

    const resetValues: Partial<NoteValue> = {
      message: "",
      lineId: Array.isArray(lineItems) && lineItems[0]?.id,
      type: "GENERAL",
      files: [],
      timestamp: Date.now(),
      isEditing: false
    };

    const validation = yup.object().shape({
      message: yup.string().label("Message"),
      type: yup
        .string()
        .label("Type")
        .required("Type cannot be empty"),
      lineId: yup
        .string()
        .label("LineID")
        .required("Line id cannot be empty")
    });

    const lineDropdownItem = Array.isArray(lineItems)
      ? lineItems.map(o => ({
          label: o.id,
          value: o.id
        }))
      : [];

    /**
     *
     * upload muliple files
     */
    const _onUploadFile = React.useCallback(
      async (files: File[], callback?: (uploadedFiles: TFile) => void) => {
        if (files) {
          try {
            await files.reduce(async (promise, file) => {
              // This line will wait for the last async function to finish.
              // The first iteration uses an already resolved Promise
              // so, it will immediately continue.
              const fileId = generate();
              await promise;
              const result = await requesFileUpload(file, progressEvent =>
                onUploadingProgress(progressEvent).then(progress => {
                  const tempFile: TFile = {
                    ...file,
                    id: fileId,
                    originalName: file.name,
                    progress,
                    url: file.name,
                    done: false,
                    size: file.size.toString()
                  };
                  callback(tempFile);
                })
              );
              if (result) {
                // genarate id for each files uploaded
                result.id = fileId;
                result.name = result?.originalName;
                result.type = result?.mimetype;
                result.progress = 100;
                result.size = file.size;
                result.done = true;
                callback(result);
              }
            }, Promise.resolve());
          } catch (error) {}
        }
      },
      [requesFileUpload]
    );

    const _onSubmit = (values, helpers) => {
      values.timestamp = Date.now();
      onSendNote(values)
        .then(() => {
          let title = "Internal note added";
          let message = null;
          helpers.setSubmitting(false);
          helpers.resetForm({
            values: resetValues as any
          });
          setShow(false);
          if (values.isEditing && values.id) {
            title = `${values.id}`;
            message = "Internal note edited";
          }
          toast({
            title,
            description: message,
            position: "bottom-left",
            duration: 3000,
            status: "success"
          });
        })
        .catch(err => {
          helpers.setSubmitting(false);
          helpers.setFieldError("error", err?.message);
        });
    };

    return (
      <Portal>
        <Formik
          enableReinitialize
          initialValues={{
            ...initialValue,
            files: initialValue?.files || [],
            lineId: Array.isArray(lineItems) && lineItems[0]?.id
          }}
          validationSchema={validation}
          onSubmit={_onSubmit}
        >
          {({
            handleSubmit,
            values,
            setFieldValue,
            handleChange,
            isSubmitting
          }) => {
            return (
              <Modal
                show={show}
                closeFunction={() => setShow(!show)}
                actionFunction={handleSubmit}
                heading={"Add Note"}
                footer={false}
                actionBtnClass="Btn-Fill Btn--Primary"
                actionBtnText="Save note"
                disabled={false}
              >
                <Box>
                  <Box
                    border="1px solid #BCCCDC"
                    box-sizing="border-box"
                    borderRadius="0px 4px 4px 0px"
                  >
                    <Textarea
                      ref={inputRef as any}
                      height="200px"
                      marginTop="3px"
                      placeholder="Type your note here"
                      _placeholder={{
                        color: "#486581",
                        fontWeight: "normal"
                      }}
                      autoFocus
                      outline="none"
                      resize="none"
                      border="none"
                      _active={{ border: "none" }}
                      _focus={{ border: "none" }}
                      paddingLeft="10px"
                      name="message"
                      value={values.message}
                      onChange={handleChange}
                    />
                    <Box d="flex">
                      <AlternativeDropDowns
                        onClick={e => setFieldValue("type", e)}
                        placeholder="Select Note"
                        className="SN--input"
                        loading={false}
                        value={values.type}
                        position="bottom center"
                        listItems={[
                          {
                            label: "General Note",
                            value: "GENERAL"
                          },
                          {
                            label: "Line Items",
                            value: "LINE"
                          },
                          {
                            label: "Artwork",
                            value: "ARTWORK"
                          },
                          {
                            label: "Delivery",
                            value: "DELIVERY"
                          },
                          {
                            label: "Payments",
                            value: "PAYMENT"
                          }
                        ]}
                      />
                      {values.type !== "GENERAL" &&
                        values.type !== "DELIVERY" &&
                        values.type !== "PAYMENT" && (
                          <AlternativeDropDowns
                            onClick={e => setFieldValue("lineId", e)}
                            placeholder="Select line id"
                            value={values.lineId}
                            className="SN--input"
                            loading={false}
                            position="bottom center"
                            listItems={lineDropdownItem}
                          />
                        )}
                    </Box>
                  </Box>
                  <ErrorMessage name="error" />
                  <Box marginTop="16px" marginBottom="33px">
                    <Upload
                      type="doc"
                      onUpload={_onUploadFile}
                      getUploads={files => {
                        let concatFiles = values.files.concat(files);
                        const unique = uniqBy(concatFiles, "id");
                        setFieldValue("files", unique);
                      }}
                      onRemovedFile={files => {
                        setFieldValue("files", files);
                      }}
                    />
                  </Box>
                  <PrimaryButton
                    width="100% !important"
                    onClick={handleSubmit as any}
                    isLoading={isSubmitting}
                    isDisabled={isSubmitting}
                  >
                    Save Note
                  </PrimaryButton>
                </Box>
              </Modal>
            );
          }}
        </Formik>
      </Portal>
    );
  }
);
