import { configureStore } from "@reduxjs/toolkit";
import { AUTH_STATE_TYPE } from "@samedaycustom/types/app";
import { CombinedState } from "redux";
import logger from "redux-logger";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import Redux from "redux";
import { DELETE_AUTH_TOKEN_ACTION } from "./actions/auth/types";
import reducers from "./reducers";
import { ADMIN_APP_STATE } from "./reducers/app/initialState";
import { ADMIN_CUSTOMER_STATE_TYPE } from "./reducers/customers/initialState";
import { ADMIN_ORDER_STATE_TYPE } from "./reducers/orders/initialState";
import { ADMIN_USERPROFILE_STATE_TYPE } from "./reducers/user/initialState";
import { ADMIN_VENDOR_STATE_TYPE } from "./reducers/vendors/initialState";
import { getDefaultMiddleware } from "./middleware";
import { ORDERS_LIST_COUNT_STATE } from "./reducers/orders-list-count/initialState";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

//  this is to ensure that when the user logs out the data is returned to its initial state,
// and the data is removed from local storage

export type RootReducerState = CombinedState<{
  auth: AUTH_STATE_TYPE;
  app: ADMIN_APP_STATE;
  vendors: ADMIN_VENDOR_STATE_TYPE;
  customers: ADMIN_CUSTOMER_STATE_TYPE;
  orders: ADMIN_ORDER_STATE_TYPE;
  users: ADMIN_USERPROFILE_STATE_TYPE;
  businessProfile: any;
  profileImage: any;
  productionSpeed: any;
  ordersListCount: ORDERS_LIST_COUNT_STATE;
}>;

export type RootReducerAction = {
  type: string;
  payload?: AUTH_STATE_TYPE;
};

const rootReducer = (state: RootReducerState, action: RootReducerAction) => {
  if (action.type === DELETE_AUTH_TOKEN_ACTION) {
    storage.removeItem("persist:root");
    state = undefined;
  }
  return reducers(state as any, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const extraMiddleware: Redux.Middleware<
  Record<any, unknown>,
  any,
  Redux.Dispatch<Redux.AnyAction>
>[] = [...getDefaultMiddleware()];

if (process.env.REACT_APP_NODE_ENV !== "production") {
  extraMiddleware.push(logger);
}

export const store: any = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(...extraMiddleware),
});
export const persistor = persistStore(store);

// @ts-expect-error - ignore
if (process.env.NODE_ENV === "development" && module?.hot) {
  //@ts-expect-error - ignore
  module.hot.accept("./reducers", () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const newRootReducer = require("./reducers").default;
    store.replaceReducer(newRootReducer);
  });
}
