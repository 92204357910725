import "./style.scss";

import { Box } from "../_components";
import { useField } from "formik";
import React from "react";

import { ReactComponent as Lock } from "./assets/locked.svg";

export { default as RadioInput } from "./RadioInput";
export { default as PhoneNoInput } from "./PhoneNoInput";
export { default as LocationSearchInput } from "./LocationSearchInput";
export { default as LocationInput } from "./LocationInput";
export { default as FormattedNumberInput } from "./FormattedNumberInput";
export { default as DropdownInput } from "./DropdownInput";
export { default as DropInputGroup } from "./DropInputGroup";
export { default as CustomFileInput } from "./CustomFileInput";
export { default as ComputeInput } from "./ComputeInput";
export { default as MessageInput } from "./Message";
export { default as NoteInput } from "./Note";
export { default as HeadlessDropdown } from "./Headless-Dropdown";
export { default as AddOnInput } from "./AddOnInput";
export { default as Upload } from "./Upload";
export { default as SearchInput } from "./Search";
export { default as InputWithSelect } from "./InputWithSelect";
export { default as InputWithoutSelect } from "./InputWithoutSelect";
export { default as BroadCastInput } from "./BroadCast";

export * from "./LocationSearchInput";
export * from "./Note";
export * from "./AddOnInput";
export * from "./Upload";
export * from "./Search";
export * from "./BroadCast";
interface InputProps {
  type: "input" | "textArea";
  inputConfig: {
    label?: string;
    rightLabel?: string | JSX.Element;
    placeholder?: string;
    id: string;
    name: string;
    type: string;
    min?: number;
    max?: number;
  };
  width?: string | number;
  height?: string | number;
  disabled?: boolean;
  selectAll?: boolean;
  onKeyDown?: (e: any) => any;
  onChange?: (e: any) => any;
  className?: string;
  style?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  autoFocus?: boolean
}

export const Input: React.FC<InputProps> = ({
  type,
  inputConfig,
  disabled,
  onKeyDown,
  selectAll,
  onChange,
  className,
  autoFocus = false,
  ...props
}: InputProps) => {
  const [field, meta] = useField(inputConfig);

  let inputContent = null;

  switch (type) {
    case "input":
      inputContent = (
        onChange ? <input
          onFocus={selectAll ? (e: any) => e.target.select() : () => {}}
          className={`input ${disabled ? "disabledInput" : ""}`}
          {...field}
          disabled={disabled ? disabled : false}
          {...inputConfig}
          {...props}
          style={{ ...props?.inputStyle }}
          onKeyDown={onKeyDown ? onKeyDown : () => {}}
          onChange={onChange}
          autoFocus={autoFocus}
        /> : <input
        onFocus={selectAll ? (e: any) => e.target.select() : () => {}}
        className={`input ${disabled ? "disabledInput" : ""}`}
        {...field}
        disabled={disabled ? disabled : false}
        {...inputConfig}
        {...props}
        style={{ ...props?.inputStyle }}
        onKeyDown={onKeyDown ? onKeyDown : () => {}}
        autoFocus={autoFocus}
      />
      );
      break;
    case "textArea":
      inputContent = (
        onChange ? <textarea
          className={`input textArea ${disabled ? "disabledInput" : ""}`}
          {...field}
          {...inputConfig}
          style={{ ...props?.inputStyle }}
          autoFocus={autoFocus}
          onChange={onChange}
        /> : <textarea
        className={`input textArea ${disabled ? "disabledInput" : ""}`}
        {...field}
        {...inputConfig}
        style={{ ...props?.inputStyle }}
        autoFocus={autoFocus}
      />
      );
      break;
    default:
      inputContent = null;
  }

  return (
    <Box
      className={`InputGroup  ${className ? className : ""} ${
        meta.error && meta.touched ? "InputGroup--error" : ""
      }`}
      width="inherit"
      {...props.style}
    >
      <Box d="flex" justifyContent="space-between">
        {inputConfig.label ? (
          <label htmlFor={inputConfig.id} style={props?.labelStyle}>
            {inputConfig.label}
          </label>
        ) : null}
        {inputConfig?.rightLabel}
      </Box>

      <div className="InputGroup" style={{ marginBottom: "unset" }}>
        {inputContent}
        {disabled ? <Lock className="lockSvg" /> : null}
      </div>
      {meta.error && meta.touched ? (
        <p className="inputError"> {meta.error} </p>
      ) : null}
    </Box>
  );
};

/**
 * TODO: Fix this laters
 * I tried to modify the input above but the login for the vendor stopped working then
 * @param 
 * @returns 
 */
export const InputModified: React.FC<InputProps> = ({
  type,
  inputConfig,
  disabled,
  onKeyDown,
  selectAll,
  onChange,
  className,
  ...props
}: InputProps) => {
  const [field, meta] = useField(inputConfig);

  let inputContent = null;

  switch (type) {
    case "input":
      inputContent = (
        <input
          onFocus={selectAll ? (e: any) => e.target.select() : () => {}}
          className={`input ${disabled ? "disabledInput" : ""}`}
          {...field}
          disabled={disabled ? disabled : false}
          {...inputConfig}
          {...props}
          style={{ ...props?.inputStyle }}
          onKeyDown={onKeyDown ? onKeyDown : () => {}}
          onChange={onChange && onChange}
        />
      );
      break;
    case "textArea":
      inputContent = (
        <textarea
          className={`input textArea ${disabled ? "disabledInput" : ""}`}
          {...field}
          {...inputConfig}
          style={{ ...props?.inputStyle }}
        />
      );
      break;
    default:
      inputContent = null;
  }

  return (
    <Box
      className={`InputGroup  ${className ? className : ""} ${
        meta.error && meta.touched ? "InputGroup--error" : ""
      }`}
      width="inherit"
      {...props.style}
    >
      <Box d="flex" justifyContent="space-between">
        {inputConfig.label ? (
          <label htmlFor={inputConfig.id} style={props?.labelStyle}>
            {inputConfig.label}
          </label>
        ) : null}
        {inputConfig?.rightLabel}
      </Box>

      <div className="InputGroup" style={{ marginBottom: "unset" }}>
        {inputContent}
        {disabled ? <Lock className="lockSvg" /> : null}
      </div>
      {meta.error && meta.touched ? (
        <p className="inputError"> {meta.error} </p>
      ) : null}
    </Box>
  );
};
