import { Box, InputGroup, Text } from "../../_components";
import {
  AlternativeDropDowns,
  DefaultCheck,
  Input,
  LocationInput,
  Radio,
  RadioInput,
  Select,
  useForm
} from "@samedaycustom/core-ui";
import { ESDCDeliveryMethod, ESDCDeliveryMethodV2 } from "@samedaycustom/sdc-types";
import { OPERATION_ORDERS_STATE } from "@samedaycustom/types/app/Operations";
import {
  ISDCDeliveryDetails,
  US_STATE_ABBR_MAPPING
} from "@samedaycustom/types/order/@types/order";
import { getModifiedDeliveryMethod } from "packages/helpers/src/order";
import React from "react";

const _stateOption =
  US_STATE_ABBR_MAPPING &&
  Object.entries(US_STATE_ABBR_MAPPING).map(el => {
    return {
      label: el[0],
      value: el[1]
    };
  });
export default React.memo(
  ({
    order
  }: {
    setShow?: () => void;
    show?: boolean;
    order: OPERATION_ORDERS_STATE;
  }) => {
    const { values, setFieldValue, setValues } = useForm<{
      zipcode: string;
      address: string;
      country: string;
      state: string;
      discountType: string;
      city: string;
      discountAmount: string;
      method: string;
      sendcharges: boolean;
      billing?: ISDCDeliveryDetails;
      newlySelectedMethod?: ESDCDeliveryMethod;
    }>();
    return (
      <Box>
        {values.method !== "pickup" && (
          <Box
            width="100%"
            d="flex"
            flexDirection="row"
            alignItems="center"
            paddingBottom="1rem"
          >
            <Text
              paddingRight="0.5rem"
              fontSize="1rem"
              fontFamily="Inter"
              fontWeight={500}
              color="#243B53"
            >
              Use Billing Address
            </Text>
            <RadioInput
              stateless
              onChange={value => {
                if (value === true)
                  setValues({
                    ...values,
                    zipcode: values?.billing.zipcode,
                    address: values?.billing?.address1,
                    country: values?.billing?.country,
                    state: values?.billing?.stateCode,
                    city: values?.billing?.city ?? ""
                  });
              }}
            />
          </Box>
        )}
        <Box width="100%">
          <Text
            color="#486581"
            paddingBottom="0.5rem"
            fontFamily="Inter"
            fontWeight={500}
          >
            Delivery Method
          </Text>
          <Box
            d="flex"
            flexDirection="row"
            width="80%"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box d="flex" flexDirection="row" alignItems="center">
              <Radio
                isSelected={getModifiedDeliveryMethod(values.method) === ESDCDeliveryMethodV2.SHIPPING}
                onSelected={() => {
                  setFieldValue("method", "shipping");
                  setFieldValue("deliveryType", null)
                }}
              />
              <Text
                paddingLeft="0.5rem"
                fontSize="1rem"
                fontFamily="Inter"
                fontWeight={500}
                color="#243B53"
              >
                Shipping
              </Text>
            </Box>
            <Box d="flex" flexDirection="row" alignItems="center">
              <Radio
                isSelected={getModifiedDeliveryMethod(values.method) === ESDCDeliveryMethodV2.PICKUP}
                onSelected={() => {
                  setFieldValue("method", "pickup");
                  setFieldValue("deliveryType", null)
                }}
              />
              <Text
                paddingLeft="0.5rem"
                fontSize="1rem"
                fontFamily="Inter"
                fontWeight={500}
                color="#243B53"
              >
                Pick up
              </Text>
            </Box>
            <Box d="flex" flexDirection="row" alignItems="center">
              <Radio
                isSelected={getModifiedDeliveryMethod(values.method) === ESDCDeliveryMethodV2.CAR}
                onSelected={() => {
                  setFieldValue("method", "car");
                  setFieldValue("deliveryType", null)
                }}
              />
              <Text
                paddingLeft="0.5rem"
                fontSize="1rem"
                fontFamily="Inter"
                fontWeight={500}
                color="#243B53"
              >
                Car Delivery
              </Text>
            </Box>
          </Box>
        </Box>
        {values.method !== ESDCDeliveryMethodV2.PICKUP && (
          <React.Fragment>
            <Box paddingTop="1rem" width="100%">
              <Text
                color="#486581"
                paddingBottom="0.3rem"
                fontFamily="Inter"
                fontWeight={500}
                fontSize="0.93rem"
              >
                Delivery Type
              </Text>
              <Box paddingLeft="2px" width="100%">
                {getModifiedDeliveryMethod(values.method) === ESDCDeliveryMethodV2.SHIPPING ? (
                  <Select
                    width="100%"
                    name="deliveryType"
                    placeholder="Delivery type"
                    value={values.newlySelectedMethod}
                    onChange={(e: any) =>
                      setFieldValue("deliveryType", e["value"])
                    }
                  >
                    <option value={ESDCDeliveryMethodV2.SHIPPING}>Shipping</option>
                    <option value={ESDCDeliveryMethodV2.RUSH_SHIPPING}>
                      Rush shipping
                    </option>
                    <option value={ESDCDeliveryMethodV2.SUPER_RUSH_SHIPPING}>Super rush shipping</option>
                  </Select>
                ) : (
                  <Select
                    width="100%"
                    name="deliveryType"
                    placeholder="Delivery type"
                    value={values.newlySelectedMethod}
                    onChange={(e: any) =>
                      setFieldValue("deliveryType", e["value"])
                    }
                  >
                    <option value={ESDCDeliveryMethodV2.CAR}>Car</option>
                    <option value={ESDCDeliveryMethodV2.RUSH_CAR}>Rush car</option>
                    <option value={ESDCDeliveryMethodV2.SUPER_RUSH_CAR}>Super rush car</option>
                  </Select>
                )}
              </Box>
            </Box>
            <Box d="flex" justifyContent="space-between" paddingTop="1rem">
              <Box paddingRight="20px" width="100%">
                <Text fontFamily="Inter" fontWeight={500} color="#486581">
                  First name
                </Text>
                <Input
                  type={"input"}
                  inputConfig={{
                    type: "string",
                    name: "firstName",
                    id: "firstName",
                  }}
                />
              </Box>
              <Box width="100%">
                <Text fontFamily="Inter" fontWeight={500} color="#486581">
                  Last name
                </Text>
                <Input
                  type={"input"}
                  inputConfig={{
                    type: "string",
                    name: "lastName",
                    id: "lastName",
                  }}
                />
              </Box>
            </Box>
            <Box width="100%">
              <Text
                color="#486581"
                paddingBottom="0.5rem"
                fontFamily="Inter"
                fontWeight={500}
              >
                Delivery Address
              </Text>
              <InputGroup size="lg" width="100%">
                <LocationInput
                  setValuesFunc={setValues}
                  prevValues={values}
                  inputConfig={{
                    label: "",
                    type: "text",
                    name: "address",
                    id: "address"
                  }}
                />
              </InputGroup>
            </Box>
            <Box d="flex" flexDirection="row" width="100%">
              <Box width="100%" paddingRight="20px">
                <Text
                  fontFamily="Inter"
                  fontWeight={500}
                  color="#486581"
                  paddingBottom="0.3rem"
                >
                  City
                </Text>
                <Input
                  type={"input"}
                  inputConfig={{
                    type: "text",
                    name: "city",
                    id: "city"
                  }}
                />
              </Box>
              <Box width="100%">
                <Text
                  color="#486581"
                  paddingBottom="0.3rem"
                  fontFamily="Inter"
                  fontWeight={500}
                >
                  State
                </Text>
                <Box width="100%">
                  <AlternativeDropDowns
                    isSearchable
                    placeholder="State"
                    value={values.state}
                    listItems={_stateOption}
                    onClick={(e: any) => {
                      setFieldValue("state", e);
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box marginTop="1rem" d="flex" flexDirection="row">
              <Box width="100%" paddingRight="20px">
                <Text
                  color="#486581"
                  paddingBottom="0.3rem"
                  fontFamily="Inter"
                  fontWeight={500}
                >
                  Zip Code
                </Text>
                <InputGroup width="100%" size="lg">
                  <Input
                    type={"input"}
                    inputConfig={{
                      type: "number",
                      name: "zipcode",
                      id: "zipcode"
                    }}
                  />
                </InputGroup>
              </Box>
              <Box width="100%">
                <Text
                  color="#486581"
                  paddingBottom="0.3rem"
                  fontFamily="Inter"
                  fontWeight={500}
                >
                  Country
                </Text>
                <InputGroup width="100%" size="lg">
                  <Input
                    type={"input"}
                    inputConfig={{
                      type: "text",
                      name: "country",
                      id: "country"
                    }}
                  />
                </InputGroup>
              </Box>
            </Box>
            <Box marginTop="1rem">
              <Text
                paddingBottom="0.3rem"
                fontFamily="Inter"
                fontWeight={500}
                fontSize="1rem"
              >
                Discount
              </Text>
              <Box paddingTop="0.5rem" d="flex" flexDirection="row">
                <Box paddingRight="20px" width="100%">
                  <Text
                    color="#486581"
                    paddingBottom="0.3rem"
                    fontFamily="Inter"
                    fontWeight={500}
                    fontSize="0.93rem"
                  >
                    Discount Type
                  </Text>
                  <Select
                    name="discountType"
                    placeholder="Type"
                    value={values.discountType}
                    onChange={(e: any) =>
                      setFieldValue("discountType", e["value"])
                    }
                  >
                    <option value="FLAT">FLAT</option>
                    <option value="PERCENT">%</option>
                  </Select>
                </Box>
                <Box width="100%">
                  <Text
                    color="#486581"
                    paddingBottom="0.3rem"
                    fontFamily="Inter"
                    fontWeight={500}
                    fontSize="0.93rem"
                  >
                    Amount / Percentage
                  </Text>
                  <InputGroup width="100%" size="lg">
                    <Input
                      type={"input"}
                      inputConfig={{
                        type: "number",
                        name: "discountAmount",
                        id: "discountAmount"
                      }}
                    />
                  </InputGroup>
                </Box>
              </Box>
            </Box>
            <Box marginTop="1rem">
              <Box d="flex" flexDirection="row" alignItems="center">
                <Text
                  color="#486581"
                  fontFamily="Inter"
                  fontWeight={600}
                  textTransform="uppercase"
                  fontSize="0.9rem"
                >
                  Extra Charge:
                </Text>
                <Text
                  paddingLeft="10px"
                  fontSize="1rem"
                  color="#102A43"
                  fontFamily="Inter"
                  fontWeight={600}
                  lineHeight="19px"
                >
                  {`$${order?.deliveryCharges}`}
                </Text>
              </Box>
              <Box
                d="flex"
                flexDirection="row"
                paddingTop="1rem"
                alignItems="center"
              >
                <DefaultCheck
                  checked={values.sendcharges}
                  label=""
                  height={20}
                  width={20}
                  onSelect={() =>
                    setFieldValue("sendcharges", !values.sendcharges)
                  }
                />
                <Text
                  paddingLeft="5px"
                  fontSize="14px"
                  lineHeight="17px"
                  fontFamily="Inter"
                  fontWeight="normal"
                  color="#243B53"
                >
                  Request payment from customer
                </Text>
              </Box>
            </Box>
          </React.Fragment>
        )}
      </Box>
    );
  }
);
