import { RowTag } from "@samedaycustom/core-ui";
import { getFileExtention } from "@samedaycustom/helpers";
import { ISDCView } from "@samedaycustom/types/order/@types/line";
import React from "react";

export interface ITeamTag {
  views?: ISDCView[];
  url?: string;
}
export default ({ views, url }: ITeamTag) => {
  /**
   *
   * checks if views production url is zip file
   *
   * if it is a zip file then its a team design
   */
  const isTeamView = React.useMemo(
    () =>
      !url
        ? views &&
          views.length >= 1 &&
          views.filter(o => getFileExtention(o.production_file_url) === "zip")
            .length >= 1
        : getFileExtention(url) === "zip",
    [url, views]
  );
  return isTeamView ? (
    <RowTag
      text="team design"
      color="#243B53"
      textStyle={{
        fontSize: "11px"
      }}
      backgroundColor="#F0B429"
      isTag
    />
  ) : null;
};
